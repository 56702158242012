const RESET_USER_STATE = 'RESET_USER_STATE'
const USER_NAME = 'USER_NAME' // user full name
const USER_ID = 'USER_ID'
const USER_EMAIL = 'USER_EMAIL'
const IS_ADMIN = 'IS_ADMIN' // Is User Admin of any active project in PULSE
const HAS_SUBSCRIPTION= 'HAS_SUBSCRIPTION'
const IS_BILLING_ADMIN = 'IS_BILLING_ADMIN' // user is creator of any project in PULSE

export {
    RESET_USER_STATE,
    USER_NAME,
    USER_ID,
    USER_EMAIL,
    IS_ADMIN,
    HAS_SUBSCRIPTION,
    IS_BILLING_ADMIN
}