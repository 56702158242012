import { Fragment, useEffect, useState } from 'react'
import './index.scss'
import DriverPanel from '../../components/DriverPanel'
import { controlType } from "../../utilities/constants";


import { ApiClient } from '../../api/client';
import {
    FreeTextQuestion,
    RangeSliderQuestion,
    ChoiceQuestion,
    MultiTopicQuestion
} from '../../components/UserEndQuestions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Loader from '../../components/Loader';
import { BaseButton } from '../../Elements/Button';
import NikelTour from '../../components/Nikeltour';


const AboutMeUser = () => {

    const navigate = useNavigate()
    const client = ApiClient()
    const [taketour, setTakeTour] = useState(false);
    const { projectId, projectUserId, currentProjectName } = useSelector(state => state.project)
    const [driverList, setDriverList] = useState([]);
    const [activeDriverId, setActiveDriverId] = useState();
    const [amQuestions, setAMQuestions] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [driverIndex, setDriverIndex] = useState(0)
    const { userId } = useSelector(state => state.user)

    useEffect(() => {
        if (projectId === null || projectId === undefined || projectId === '') {
            navigate('/app/project-not-found')
        }
    }, [projectId])


    useEffect(() => {
        if (projectId) {
            client
                .get(`/api/v1/project-status/${projectId}/`)
                .then(res => {
                    if (res?.data?.is_locked) {
                        navigate('/app/access-denied')
                    }
                })
        }
    }, [projectId])


    function createCookie(value) {
        var date = new Date();
        date.setTime(date.getTime() + (31 * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
        document.cookie = value + "=" + 'taketour' + expires + "; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    useEffect(() => {
		if (readCookie(userId+'taketour')=='taketour') {
			createCookie( userId+'taketour')
		}
		else {
            console.log("Cookie not found")
			if (!taketour) {
				setTakeTour(true)

			}
			setTakeTour(!taketour)
			createCookie( userId+'taketour')
		}

	

}, [])

    useEffect(() => {
        if (projectId) {
            setLoading(true)
            setDriverList([])
            setActiveDriverId()
            setAMQuestions([])
            client.get('api/v1/am-questions-list/?' + //?driver_id=' + activeDriverId +
                '&project_id=' + projectId + '&project_user_id=' + projectUserId)
                .then(response => {
                    if (response?.data) {
                        let drivers_list = response.data
                        drivers_list = drivers_list.map(item => {
                            return (
                                {
                                    ...item,
                                    questions_ids: item.am_questions.map(ques => ques.id),
                                    answered_ids: item.am_questions
                                        .filter(ques => ques.response_data.length > 0)
                                        .map(ques => ques.id)
                                }
                            )
                        })
                        setDriverList(drivers_list)
                        setActiveDriverId(drivers_list[0].driverId)
                        setAMQuestions(drivers_list[0].am_questions)
                        setLoading(false)
                    }
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }, [projectId])


    useEffect(() => {
        if (activeDriverId && driverList.length) {
            let driverIdx = driverList.map(item => item.driverId).findIndex((id) => id === activeDriverId) + 1
            setDriverIndex(driverIdx)
        }

    }, [activeDriverId])



    const moveScrollToTop = () => {
        const divRef = document.querySelector("#back-to-top-anchor");

        if (divRef) {
            divRef.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    const handleClickDriver = (driver_id) => {
        setActiveDriverId(driver_id)
        setAMQuestions(driverList.filter(item => item.driverId === driver_id)?.[0]?.am_questions)
        setTimeout(() => {
            moveScrollToTop();
        }, 50);
    }

    const handleContinue = () => {
        if (driverIndex === driverList.length) {
            navigate('/app/about-others')
        }
        else {
            handleClickDriver(driverList[driverIndex].driverId)
            // setActiveDriverId(driverList[driverIndex].driverId)
            // setAMQuestions(driverList[driverIndex].am_questions)
            // setTimeout(() => {
            //     moveScrollToTop();
            // }, 50);
        }
    }

    const handleAnswer = (answer) => {
        client.post('api/v1/am-response/', {
            response: answer
        })
            .then(res => {
                setDriverList(state => state.map(item => {
                    if (item.driverId === activeDriverId) {
                        item.answered_ids = [...new Set([...item.answered_ids, answer?.am_question?.toString()])]
                        item.am_questions = item.am_questions.map(ques => {
                            if (ques.id == answer.am_question){
                                ques.response_data = [res.data]
                            }
                            return ques
                        })
                    }
                    return item
                }))
            })
            .catch(err => console.log(err))
    }

    const NoQuestionsContent = () => {
        return isLoading ?
            <Loader />
            :
            <h2 className="no-questions">No Questions</h2>
    }
    return <>
       {taketour && < NikelTour handleModal={setTakeTour}/>}
        <div className="about-me-main-content">
            {
                driverList.length > 0 ?
                    <Fragment>
                        <div className="driver-scroll-box">
                            <div className="driver-section">
                                <DriverPanel
                                    activeDriverId={activeDriverId}
                                    driverList={driverList}
                                    onDriverClick={handleClickDriver}
                                />
                            </div>
                            <div className="survey-container">

                                <div className="survey-driver-container">
                                    <div className="question-driver-container">
                                        <div id="back-to-top-anchor"></div>
                                        {
                                            amQuestions.length > 0 ?
                                                amQuestions?.map(question => {
                                                    switch (question.control_type) {
                                                        case controlType.TEXT:
                                                            return (
                                                                <div
                                                                    key={`question-page-${question.id}`}
                                                                    className="control-container"
                                                                >
                                                                    <FreeTextQuestion
                                                                        currentUser={projectUserId}
                                                                        question={question}
                                                                        onAnswer={(answer) => handleAnswer(answer)}
                                                                        projectTitle={currentProjectName}
                                                                        surveyType="me"
                                                                    />
                                                                </div>
                                                            );
                                                        case controlType.SLIDER:
                                                            return (
                                                                <div
                                                                    key={`question-page-${question.id}`}
                                                                    className="control-container"
                                                                >
                                                                    <RangeSliderQuestion
                                                                        currentUser={projectUserId}
                                                                        question={question}
                                                                        onAnswer={(answer) => handleAnswer(answer)}
                                                                        // skipQuestionList={skipQuestionList}
                                                                        projectTitle={currentProjectName}
                                                                        surveyType="me"
                                                                    />
                                                                </div>
                                                            );

                                                        // case controlType.TWO_OPTIONS:
                                                        //     return (
                                                        //         <div
                                                        //             key={`question-page-${question.id}`}
                                                        //             className="control-container"
                                                        //         >
                                                        //             <TwoOptions
                                                        //                 currentUser={projectUserId}
                                                        //                 options={optionList}
                                                        //                 question={question}
                                                        //                 onAnswer={(answer) => handleAnswer(answer)}
                                                        //                 skipQuestionList={skipQuestionList}
                                                        //                 projectTitle={currentProjectName}
                                                        //                 surveyType="me"
                                                        //             />
                                                        //         </div>
                                                        //     );

                                                        case controlType.CHOICE:
                                                            return (
                                                                <div
                                                                    key={`question-page-${question.id}`}
                                                                    className="control-container"
                                                                >
                                                                    <ChoiceQuestion
                                                                        type="am"
                                                                        currentUser={projectUserId}
                                                                        // options={optionList}
                                                                        question={question}
                                                                        onAnswer={(answer) => handleAnswer(answer)}
                                                                        // skipQuestionList={skipQuestionList}
                                                                        projectTitle={currentProjectName}
                                                                        surveyType="me"
                                                                    />
                                                                </div>
                                                            );

                                                        case controlType.MULTI_TOPICS:
                                                            return (
                                                                <div
                                                                    key={`question-page-${question.id}`}
                                                                    className="control-container"
                                                                >
                                                                    <MultiTopicQuestion
                                                                        type="am"
                                                                        currentUser={projectUserId}
                                                                        activeDriverId={activeDriverId}
                                                                        setDriverList={setDriverList}
                                                                        question={question}
                                                                        onAnswer={(answer) => handleAnswer(answer)}
                                                                        // skipQuestionList={skipQuestionList}
                                                                        projectTitle={currentProjectName}
                                                                        surveyType="me"
                                                                    />
                                                                </div>
                                                            );

                                                        // case controlType.SMART_TEXT:
                                                        //     return (
                                                        //         <div
                                                        //             key={`question-page-${question.id}`}
                                                        //             className="control-container"
                                                        //         >
                                                        //             <SmartText
                                                        //                 currentUser={projectUserId}
                                                        //                 question={question}
                                                        //                 onAnswer={(answer) => handleAnswer(answer)}
                                                        //                 skipQuestionList={skipQuestionList}
                                                        //                 projectTitle={currentProjectName}
                                                        //                 surveyType="me"
                                                        //             />
                                                        //         </div>
                                                        //     );

                                                        default:
                                                            return null
                                                        // (
                                                        //     <div key={`question-page-${question.id}`}></div>
                                                        // );
                                                    }
                                                })
                                                :
                                                <NoQuestionsContent />

                                        }
                                    </div>
                                    {
                                        !isLoading && <div className='continue-section'>

                                            <BaseButton
                                                className="am-question-continue-btn"
                                                variant='contained'
                                                text={driverIndex === driverList.length ? "Complete" : "Continue"}
                                                onClick={handleContinue}
                                            />

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <NoQuestionsContent />
            }
        </div>
    </>
}


export default AboutMeUser;