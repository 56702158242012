import Joyride from 'react-joyride';
import TourToolTip from './tooltip';
import { img_01,img_02 } from '../../assets/svgs/tourImages'

const Tour_Wizard = ({setTourGuide, tourGuide, settourSwitch, tourSwitch}) => {
    
    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
     
        if(action=='skip' || action=='reset')
        {
            setTourGuide(false);
            settourSwitch(!tourSwitch);
        
        }
    }

    const steps = [
       
      {
            target: '.my-projects',
            title:'Step 1',
            img:img_01,
            content: 'First, select the appropriate project',
            disableBeacon : true,
            placement:'right',
        },
        {
            target: '.about-me',
            title:'Step 2',
            img:img_02,
            content: 'Secondly, tell us more about how you feel about the project in the About Me section',
            disableBeacon : true,
            placement:'bottom',
        
        },
        {
            target: '.about-others',
            title:'Step 3',
            img:img_02,
            content: "Thirdly, we'd like to learn more about the stakeholders that you work with, and those that you think are important to the project.",
            disableBeacon : true,
            placement:'right',
            isLastStep:true,
            
        },
    ]

    return <Joyride
        steps={steps}
        continuous={true}
        disableScrollParentFix={true}
        showProgress={true}
        showSkipButton={true}
        hideCloseButton={true}
        run={tourGuide}
        callback={handleJoyrideCallback}
       
        locale={{
            last: "End tour",
            skip: "Close tour"
          }}
     
        styles={{
            options: {
              arrowColor: '#fff',
              backgroundColor: '#3F4042',
              primaryColor: '#000',
              textColor: '#fff',
              width: 'fit-content',
              zIndex: 1000,
              fontSize: '13px',
             
            }
          
          }}

          tooltipComponent={TourToolTip}
        

    />
}

export default Tour_Wizard;