import axios from "axios";
import axiosInstance from "./axios";

const baseUrl = process.env.REACT_APP_BASE_URL;


export const ApiClient = (onResponse) => {
    // Create a new axios instance
    const api = axios.create({
        baseURL: baseUrl,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    // Add a request interceptor to add the JWT token to the authorization header
    api.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("access_token");
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Add a response interceptor to refresh the JWT token if it's expired
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;
            // If the error is a 401 and we have a refresh token, refresh the JWT token
            if (
                error.response.status === 401 &&
                localStorage.getItem("refresh_token")
            ) {
                const refreshToken = localStorage.getItem("refresh_token");
                let data = JSON.stringify({
                    refresh: refreshToken,
                });

                post("api/v1/auth/jwt/refresh", data)
                    .then((response) => {
                        localStorage.setItem("access_token", response.access);
                        localStorage.setItem("refresh_token", response.refresh);

                        // Re-run the original request that was intercepted
                        originalRequest.headers.Authorization = `Bearer ${response.access}`;
                        api(originalRequest)
                            .then((response) => {
                                if(response.data){
                                    return onResponse(response.data);
                                }
                                return onResponse(response);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        // return api(originalRequest)
                    })
                    .catch((err) => {
                        // If there is an error refreshing the token, log out the user
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("refresh_token");
                    });
            }

            // Return the original error if we can't handle it
            return Promise.reject(error);
        }
    );

    const login = async (username, password) => {
        username=username.toLowerCase();
        
        return axiosInstance
            .post("/api/v1/auth/login/", { username, password })
            .then((response) => {
                if(response.data?.ephemeral_token){
                    onResponse(prevState => (
                        { 
                            ...prevState, 
                            loginSuccess : false,
                            navigateTo: '',
                            additionalStep: true,
                            responseData : response.data?.ephemeral_token
                        }
                    ))
                }else{
                    // Store the JWT and refresh tokens in session storage
                    localStorage.setItem('access_token', response.data.access);
                    localStorage.setItem('refresh_token', response.data.refresh);
                    api.get('/api/v1/auth/users/me/').then((res) => {
                        let results = res.data
                        let navigate_path;

                        if (results['is_admin_user'] === true || results['is_biling_admin'] === true) {
                            navigate_path = '/app/my-projects'
                        }
                        else if (results['is_invited_user'] === true) {
                            navigate_path = '/app/welcome'
                        }
                        else {
                            navigate_path = '/setup/welcome'
                        }
                        onResponse(prevState => (
                            {
                                ...prevState,
                                loginSuccess: true,
                                navigateTo: navigate_path,
                                additionalStep: false,
                                responseData : response.data,
                                isAdmin: (results['is_admin_user'] === true),
                                userId: results['user_id'],
                                isBillingAdmin: results['is_billing_admin']
                            }
                        ))
                    })
                }
                return true
            })
            .catch((err) => {
                // Return the error if the request fails
                onResponse(prevState => (
                    { 
                        ...prevState, 
                        loginSuccess : false,
                        navigateTo: '',
                        additionalStep: false,
                        responseData : err.response.data?.error
                    }
                ))
                return err;
            });
    };


    const get = async(path) => {
        return await api.get(path).then((response) => {
            return response.data
        })
        .catch((error) => {
            return error
        })
        ;
    };

    const post = async(path, data) => {
        return await api.post(path, data).then((response) => response.data);
    };

    const put = async(path, data) => {
        return await api.put(path, data).then((response) => response.data);
    };

    const del = async(path) => {
        return await api.delete(path).then((response) => response.data);
    };

    const patch = async(path, data) => {
        return await api.patch(path, data).then((response) => response.data);
    };

    const getBlob = async(path) => {
        api.defaults.responseType = 'blob'
        return await api.get(path).then((response) =>  response);
    };

    return {
        login,
        get,
        post,
        put,
        patch,
        del,
        getBlob
    };
};
