
import './pgraph.scss';
import { useState, } from 'react'
import { Chart } from '../../../react-keylines'
import KeyLines from 'keylines/esm';
import { data } from './data'
import { useSelector } from 'react-redux'
import 'keylines';
import WebFont from 'webfontloader';
import { useEffect } from 'react';

let chart;
let graph;
let isDragging = false;
function layout(mode) {
  return chart.layout('organic', { mode });
}

const comboAnimations = {};

function foregroundSelection(ids) {
  if (ids.length === 0) {
    // restore all the elements in the foreground
    chart.foreground(() => true, { type: 'all' });
    // clear revealed items
    chart.combo().reveal([]);
  } else {
    // find the connections for all of the selected ids
    const neighbours = graph.neighbours(ids);
    const foregroundMap = {};
    const linksToReveal = [];
    const propsToUpdate = [];
    neighbours.links.forEach((linkId) => {
      // build map of neighbouring links to foreground
      foregroundMap[linkId] = true;
      // add neighbouring links to reveal array
      linksToReveal.push(linkId);
    });
    neighbours.nodes.forEach((nodeId) => {
      // add neighbouring nodes to foreground map
      foregroundMap[nodeId] = true;
    });

    const selectedItems = chart.getItem(ids);
    selectedItems.forEach((item) => {
      // add selected items to foreground map
      foregroundMap[item.id] = true;
      if (item.type === 'link') {
        // add only the selected links to the reveal array
        linksToReveal.push(item.id);
      }
    });

    // run foreground on underlying links and nodes
    chart.foreground(item => foregroundMap[item.id], { type: 'all' });

    // reveal the links
    chart.combo().reveal(linksToReveal);

    // background all combolinks
    chart.combo().find(linksToReveal, { parent: 'first' }).forEach((id) => {
      if (id !== null && chart.getItem(id).type === 'link') {
        propsToUpdate.push({ id, bg: true });
      }
    });

    chart.setProperties(propsToUpdate);
  }
}
function onSelection() {
  // grab current selection
  const selectedIds = chart.selection();
  // filter out any combo items to get only the underlying selection
  const ids = selectedIds.filter(id => !chart.combo().isCombo(id));
  // remove the combos from the selection 
  chart.selection(ids);
  // foreground the filtered selection of items and their connections
  foregroundSelection(ids);
}

function openOrCloseCombo(ids, open, cb) {
  if (Object.keys(comboAnimations).length > 0) {
    return false;
  }

  const action = open ? chart.combo().open : chart.combo().close;
  let targets = Array.isArray(ids) ? ids : [ids];

  targets = targets.filter((id) => {
    if (!chart.combo().isCombo(id) || chart.combo().isOpen(id) === open) {
      return false;
    }
    comboAnimations[id] = true;
    return true;
  });

  action(targets, { adapt: 'inCombo', time: 300 })
    .then(() => targets.length > 0 ? layout('adaptive') : null)
    .then(() => {
      targets.forEach((id) => { delete comboAnimations[id]; });
      if (cb) { cb(); }
    });
  return targets.length > 0;

}
function openCombo(ids, cb) {
  return openOrCloseCombo(ids, true, cb);
}
function isCombo(ids, type = 'node') {
  return chart.combo().isCombo(ids, { type });
}
function closeCombo(ids, cb) {
  return openOrCloseCombo(ids, false, cb);
}
function setUpEventHandlers() {
  chart.on('selection-change', onSelection);
  chart.on('drag-start', ({ type, id, setDragOptions }) => {
    if (type === 'node'
      && chart.combo().isOpen(id)
      && !chart.options().handMode) {
      setDragOptions({ type: 'marquee' });
    }
    isDragging = true;
  });
  chart.on('drag-end', () => {
    isDragging = false;
  });
  chart.on('double-click', ({ id, preventDefault, button }) => {
    if (isCombo(id)) {
      if (chart.combo().isOpen(id)) {
        closeCombo(id);
      } else {
        openCombo(id);
      }
    }
    preventDefault();
  });
  chart.on('click', ({ id, preventDefault, button }) => {
    const item = chart.getItem(id);
    if(item?.id?.startsWith('user_node_')){
      preventDefault()
    }
  });
}

const Pgraph = ({
  graphdata,
  combineSegmentNames,
  uncombineGraph,
  graphCombineDisplayMode,
  setGraphCombineDisplayMode,
  setToggleActionButtonDisplay,
  setUncombineGraph,
  graphDisplayMode
}) => {
  let flag=0;
  const { projectId } = useSelector(state => state.project);
  const [structuriseData, setStructurisedata] = useState();
  const [comboIds, setComboIds] = useState([]);

  async function combine(properties, delay) {
    const comboDefs = {};
    const vals_req = {}
    const avg_list = {}
    chart.each({ type: 'node', items: 'toplevel' }, (node) => {
      let comboId = '';
      const d = {
        segmentTypeData : {},
        segmentIcon : {}
      };
      let icon;
      let color = '';
      properties.forEach((property) => {
        icon = (node?.d?.segmentIcon) ? node?.d?.segmentIcon[property] : 'fa-globe-americas';
        comboId = comboId.concat(node.d.segmentTypeData[property], '-');
        d.segmentTypeData[property] = node.d.segmentTypeData[property];
        d.segmentIcon[property] = node.d.segmentIcon[property];
      });
      //Suggested code
      if (vals_req[comboId]) {
        vals_req[comboId].push(node.d.score)
      } else {
        vals_req[comboId] = [node.d.score]
      }
      // Check if comboDefs already has an entry for comboId
      if (comboDefs[comboId]) {
        comboDefs[comboId] = {
          ...comboDefs[comboId],
          ids: [...comboDefs[comboId].ids, node.id],
          style: {
            e: ([...comboDefs[comboId].ids, node.id].length),
            u: "",
            t : [
              {
                  fi: {
                      t: KeyLines.getFontIcon(icon),
                      c: '#5b5b5b',
                  },
                  fs: 35,
                  fbc: 'transparent',
              },
              {
                  t: node.d.segmentTypeData[properties[properties.length - 1]],
                  position: 's',
                  fs: 15,
                  fbc: 'transparent',
                  fc: '#FFFFFF'
              }
            ]
          },
        };
      } else {
        comboDefs[comboId] = {
          ids: [node.id],
          d,
          label: node.d.segmentTypeData[properties[properties.length - 1]],
          style: {
            e: [node.id].length,
            u: "",
            t : [
              {
                  fi: {
                      t: KeyLines.getFontIcon(icon),
                      c: '#5b5b5b',
                  },
                  fs: 35,
                  fbc: 'transparent',
              },
              {
                  t: node.d.segmentTypeData[properties[properties.length - 1]],
                  position: 's',
                  fs: 15,
                  fbc: 'transparent',
                  fc: '#FFFFFF'
              }
            ]
          },
          layout: {
            type: 'organic',
            options: {
              mode: 'adaptive',
              packing: 'adaptive',
            },
          },
        };
      }
    });

   if (flag==0) {
  
    Object.entries(vals_req).forEach(([comboId, value]) => {
      const avg = value.reduce((partialSum, a) => partialSum + a, 0) / value.length
      if (comboDefs[comboId].ids.length < 3) {
        comboDefs[comboId].openStyle = { c: '#BFBFBF', }
        comboDefs[comboId].style.c = '#A9A9A9'
      }
      else {
        if (0 < avg && avg < 3) {
          comboDefs[comboId].openStyle = { c: '#E56865', }
          comboDefs[comboId].style.c = '#E56865'
        }
        else if (3 <= avg && avg <= 4) {
          comboDefs[comboId].openStyle = { c: '#C5B4C9' }
          comboDefs[comboId].style.c = '#C5B4C9'
          comboDefs[comboId].style.b = '#000000'
          // comboDefs[comboId].style.fi.c = '#ffffff'
        }
        else if (4 < avg && avg <= 6) {
          //node backgroundcolor
          comboDefs[comboId].openStyle = { c: '#95A2CE' }
          //Font icon color
          comboDefs[comboId].style.c = '#95A2CE'
        }
        else if (6 < avg && avg <= 8) {
          comboDefs[comboId].openStyle = { c: '#50A8F2' }
          comboDefs[comboId].style.c = '#50A8F2'
        }
        else if (8 < avg && avg <= 10) {
          comboDefs[comboId].openStyle = { c: '#03B7A5' }
          comboDefs[comboId].style.c = '#03B7A5'
        }
        else {
          comboDefs[comboId].openStyle = { c: '#E56865', }
          comboDefs[comboId].style.c = '#E56865'
        }
      }
      comboDefs[comboId].style.b = '#000000'
      comboDefs[comboId].style.fi = {c:'#ffffff'};
      // also apply values/avg to d property of comboDefs here
    
      comboDefs[comboId].d.score = avg;
    })
    flag=1;


  }
  else
  {
  
    for (let key in comboDefs) {
    
      
      let avg_list01=[]
      for (let i = 0; i < comboDefs[key].ids.length; i++) {
        let childnodes=chart.combo().info(comboDefs[key].ids[i])
       
       
        for (let j=0;j<childnodes.nodes.length;j++ )
        {
    
          avg_list01.push(childnodes.nodes[j].d.score)
          
        }
       

       

      }

     const avg = avg_list01.reduce((partialSum, a) => partialSum + a, 0) / avg_list01.length;

        if(avg_list01.length<3)
        {
          comboDefs[key].openStyle = { c: '#BFBFBF', }
          comboDefs[key].style.c = '#A9A9A9'
        }
    
        else if (0 <= avg && avg < 3) {

          comboDefs[key].openStyle = { c: '#E56865', }
          comboDefs[key].style.c = '#E56865'

        }

        else if (3 <= avg && avg <= 4) {

          comboDefs[key].openStyle = { c: '#C5B4C9' }
          comboDefs[key].style.c = '#C5B4C9'
          comboDefs[key].style.b = 'rgb(0,0,0)'
         
        }

        else if (4 < avg && avg <= 6) {
          //node backgroundcolor
          comboDefs[key].openStyle = { c: '#95A2CE' }
          //Font icon color
          comboDefs[key].style.c = '#95A2CE'

        }

        else if (6 < avg && avg <= 8) {
          comboDefs[key].openStyle = { c: '#50A8F2' }
          comboDefs[key].style.c = '#50A8F2'


        }

        else if (8 < avg && avg <= 10) {
          comboDefs[key].openStyle = { c: '#03B7A5' }
          comboDefs[key].style.c = '#03B7A5'


        }
        else {
          comboDefs[key].openStyle = { c: '#E56865', }
          comboDefs[key].style.c = '#E56865'


        }
        comboDefs[key].style.b = 'rgb(0,0,0)'
        comboDefs[key].style.fi={c : '#ffffff'}
     
        comboDefs[key].style.e= avg_list01.length

      

      }
    flag=0;

    }
    await new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
    return await chart.combo().combine(Object.values(comboDefs), { name: 'concentric', time: 350}).then((comboIds) => {
      setComboIds(prev => [...prev, ...comboIds])
      // chart.combo().arrange(chart.selection(), { tightness: 9 })
    });
  }

  async function handleCombine(properties, graphDisplayMode) {
    await chart.load(structuriseData);
    await chart.layout();
    let delay = 200; // Delay between each combining step in milliseconds  
    // Delay between each combining step in milliseconds
    for (let propertySet of properties) {

      await combine(propertySet, delay);
      if(properties.length==1){
      flag=0;
      }
      delay += 50; // Increment the delay for each step
    }
    chart.layout();
    if(graphDisplayMode) handleUpdateModeStyling(graphDisplayMode)
  }

  async function handleUnCombine(disableActionButton = false, graphDisplayMode) {
    if(structuriseData && comboIds.length > 0){
      await chart.load(structuriseData).then(() => {
        chart.layout();
      });
      if(disableActionButton){
        setToggleActionButtonDisplay(false)
        setUncombineGraph(false)
      }
      if(graphDisplayMode) handleUpdateModeStyling(graphDisplayMode)
    }
  }

  async function handleUpdateModeStyling(displayMode) {
    if(chart){
      let color;
      if(displayMode === 'dark'){
        color = '#FFFFFF'
      }else if(displayMode === 'light'){
        color = '#696969'
      }
      await chart.each({ type : 'all' , items : 'all'}, (item) => {
        if(item.type === 'link'){
          chart.setProperties({ id: item.id, c: color });
        }else if(item.id.startsWith('_combo_')){
          chart.setProperties({ id: item.id, t: item.t.map(obj => ((obj.fc) ? {...obj, fc : color} : {...obj})) });
        }else if(item.type === 'node'){
          chart.setProperties({ id: item.id, t: item.t.map(obj => ({...obj, fc : color}))});
        }
      });
    }
  }

  const loadCombine = (combineProps, graphDisplayMode) => {
    handleCombine(combineProps, graphDisplayMode)
  }

  const loadUnCombine = (graphDisplayMode) => {
    handleUnCombine(true, graphDisplayMode)
  }

  const updateModeStyling = (displayMode) => {
    handleUpdateModeStyling(displayMode)
  }

  const graphCombineBulkAction = async (mode) => {
    if(comboIds.length > 0){
      if(mode === 'collapse'){
        await chart.combo().close(comboIds, { adapt : 'all', animate: true})
        chart.layout();
      }else if(mode === 'expand'){
        await chart.combo().open(comboIds, { adapt : 'all', animate: true})
        chart.layout();
      }
    }
  }

  const changeChart = newChart => {
    chart = newChart;
    setUpEventHandlers()
  }

  const contruct_structure_data = (data) => {
    let structure_data = {
      type: 'LinkChart',
      items: []
    }
    let link_data = []
    let link_nodes = []
    data.map(item => {
      structure_data.items.push({
        'id' : item.node_id,
        't' : { t: item.user_full_name , fc: '#FFFFFF', fbc: 'rgba(0,0,0,0.0)'},
        'd' : { 'segmentTypeData' : {...item.segment_data}, 'segmentIcon' : {...item.segment_icons}, 'score' : item.score },
        "type" : "node",
        "x" : 0, "y" : 0,
        "u" : null,
        "c" : "#E9EAEA",
        "fi" : { "t" : KeyLines.getFontIcon('fa-user'), "c" : "#000000" },
        "b" : "#E9EAEA",
        "bw" : 10
      })
      if(item.mapped_nodes.length > 0){
        item.mapped_nodes.map(link => {
          if(!link_nodes.includes(link + '/p/' + item.node_id )){
            link_nodes.push(item.node_id + '/p/' + link)
            link_data.push({
              id1 : item.node_id, 
              id2 : link, 
              id : item.node_id + '/p/' + link, 
              // c : "#696969",
              c : "#FFFFFF",
              type : "link"
            })  
          }
          return true
        })
      }
      return true
    })
    if(link_data.length > 0){
      structure_data.items.push(...link_data)
    }
    setStructurisedata(structure_data)
  }

  useEffect(() => {
    if(graphdata){
      contruct_structure_data(graphdata)
    }
  }, [graphdata])

  useEffect(() => {
    if(combineSegmentNames?.length > 0){
      loadCombine(combineSegmentNames, graphDisplayMode)
    }
  }, [combineSegmentNames, structuriseData, graphDisplayMode])

  useEffect(() => {
    if(uncombineGraph){
      loadUnCombine(graphDisplayMode)
    }
  }, [uncombineGraph, graphDisplayMode])

  useEffect(() => {
    if(graphCombineDisplayMode?.length > 0){
      graphCombineBulkAction(graphCombineDisplayMode)
      setGraphCombineDisplayMode('')
    }
  }, [graphCombineDisplayMode, structuriseData])

  useEffect(() => {
    if(graphDisplayMode){
      updateModeStyling(graphDisplayMode)
    }
  }, [graphDisplayMode, structuriseData])

  useEffect(() => {
    WebFont.load({
        custom: {
            families: ["FontAwesome"],
            urls: [
            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css'
            ],
        },
        timeout: 3000,
    });
  }, [])

  return <>
    {structuriseData &&
      <Chart
        data={structuriseData}
        ready={changeChart}
        containerClassName="pchart-container"
        options={{
          handMode: true,
          selectedNode: {
            b: '#72B300',
          },
          iconFontFamily: 'FontAwesome',
        }}
      />
    }
    </>
}

export default Pgraph;
