import { useEffect, useState } from "react";
import LabeledInput from "../../Elements/LabeledInput"
import SkipOptions from "./SkipOption";




const MultiTopicQuestion = ({handleChange, ...args}) => {

    const [inputVal, setInputVal] = useState((args?.isEdit) ? args?.questionData?.comment_prompt: 'Write a comment to explain why you gave that score')
    const [topicVal, setTopicVal] = useState((args?.isEdit) ? args?.questionData?.topic_prompt: '')

    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const { errField, errMsg } = err;

    useEffect(() => {
        setErr(args?.err)
    }, [args?.err])

    const handleInputOnChange = (e) => {
        setInputVal(e.target.value)
        handleChange(e)
    }

    const handleTopicValChange = (e) => {
        setTopicVal(e.target.value)
        handleChange(e)
    }

    return <div>
        <LabeledInput
            type='text'
            name='topicPrompt'
            label='Topic Prompt'
            value={topicVal}
            onChange={handleTopicValChange}
             error={errField === 'topicPrompt' && (errMsg)}
            textarea

        />
        <LabeledInput
            type='text'
            name='commentPrompt'
            label='Comment Prompt'
            value={inputVal}
            onChange={handleInputOnChange}
            error={errField === 'commentPrompt' && (errMsg)}
            placeholder='Write a comment here to explain why you gave that score.'
            textarea
        />
        <div >
            <SkipOptions handleOptionChange={args?.handleSkipOptionType} isEdit={args.isEdit} questionData={args.questionData}/>
        </div>
    </div>
}

export default MultiTopicQuestion;