import './index.scss';
import { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';
import SentimentBar from '../../../components/OverallSentiment';
import CultureComponent from '../../../components/CultureSummary';
import TopPositiveNegativeComponent from '../../../components/TopPositiveNegative';
import FeedbackSummary from '../../../components/dashboard/feedbackSummary';
import { ApiClient } from '../../../api/client';
import Participation from '../../../components/dashboard/participation';
import { OverallTrend } from '../../../components/OverallTrends';
import NoDashboard from '../../NoDashboard';


const ProjectSummary = () => {

    const navigate = useNavigate()
    const client = ApiClient()

    const feedbackSummaryRef = useRef()
    const trendRef = useRef()
    const { isAdmin } = useSelector(state => state.user)
    const { projectId, projectUserId, currentProjectName, isProjectAdmin } = useSelector(state => state.project)
    const [isLoading, setLoading] = useState(false)
    const [feedbackGraphHeight, setFeedbackGraphHeight] = useState(500)
    const [participationHeight, setParticipationHeight] = useState(0)
    const [segmentTypes, setSegmentTypes] = useState([])

    useEffect(() => {
        if (projectId === null || projectId === undefined || projectId === '') {
            navigate('/app/project-not-found')
        }
    }, [projectId, navigate])

    useEffect(() => {
        if (projectId) {
            client
                .get(`/api/v1/project-status/${projectId}/`)
                .then(res => {
                    if (res?.data?.is_locked) {
                        navigate('/app/access-denied')
                    }
                    else {
                        if(!isAdmin){
                        client.get('/api/v1/project-anonymity-threshold/' + projectId + '/')
                            .then((response) => {
                                if (response?.data) {
                                    if (!response?.data?.criteria_met) {
                                        navigate('/app/no-dashboard', {
                                            state: {
                                                anonymity_threshold: response?.data?.anonymity_threshold,
                                                criteria_met: response?.data?.criteria_met,
                                                status_type: response?.data?.status_type,
                                            }
                                        })
                                    }
                                }
                            })
                        }
                        client.get('/api/v1/segments/' + projectId + '/?is_active=true')
                            .then((response) => {
                                if (response?.data) {
                                    setSegmentTypes(response.data)
                                }
                            })
                    }
                })
        }
    }, [])


    useEffect(() => {
        if (feedbackSummaryRef) setFeedbackGraphHeight(feedbackSummaryRef.current.clientHeight)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setParticipationHeight(trendRef?.current?.clientHeight)
        }, 2500)
    }, [])

    const NoContent = () => {
        return isLoading ?
            <Loader />
            :
            ''
    }

    return <div className="main-content" >
        <main>
            {isLoading &&
                <NoContent/>
            }
            <div className='summary-container'>
                <div className='row-1-1'>
                <SentimentBar/>
                </div>
                <div className='row-1-3'>
                    <div className='overall-trends-container'>
                        <OverallTrend/>
                    </div>
                    <div ref={trendRef} className='positive-negative-container'>
                        <TopPositiveNegativeComponent/>
                    </div>
                    <div className='participation-container'>
                        <Participation segmentTypesList={segmentTypes} pHeight={participationHeight} />
                    </div>
                </div>
                <div className='row-1-2'>
                    <div ref={feedbackSummaryRef} id='feedback-summary-container' className='feedback-summary-container'>
                        <FeedbackSummary segmentTypesList={segmentTypes} />
                    </div>
                    <div className='culture-results-container'>
                        <CultureComponent feedbackHeight={feedbackGraphHeight}/>
                    </div>
                </div>
            </div>
        </main>
    </div>
}

export default ProjectSummary;