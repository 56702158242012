
import { BaseButton } from '../../Elements/Button';
import Modal from '../Modal';
import './index.scss';


const ConfirmationPopupModal = ({ modalClassName = '', text, handleCancel, handleSubmit, deleteText = 'Delete' }) => {
    return <Modal
        modalClassName={modalClassName}
        handleModal={handleCancel}
        modal_content={
            <div className="confirmation-modal-content">
                {/* 
                <div className='header-div'>
                    <h3 className='title'>Delete User!</h3>
                </div> 
                */}
                <div className="content-div">
                    <p>{text}</p>
                </div>
                <div className='modal-action-button-section'>
                    <BaseButton
                        text="Cancel"
                        className='cancel-btn modal-cancel-btn'
                        onClick={() => handleCancel(false)}
                    />

                    <BaseButton
                        variant='contained'
                        text={deleteText}
                        className='delete-user-submit-btn modal-submit-btn'
                        onClick={handleSubmit}
                    />

                </div>
            </div>
        }
    />
}

export default ConfirmationPopupModal;