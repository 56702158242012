
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UserLogo from '../../../assets/svgs/user.svg'
import { CircularProgress } from '@mui/material';


// AO SCREEN NAMES
export const MY_STAKEHOLDERS = 'MY_STAKEHOLDERS'
export const PROJECT_USERS_LIST = 'PROJECT_USERS_LIST'
export const ADD_STAKEHOLDER = 'ADD_STAKEHOLDER'
export const UPDATE_STAKEHOLDER = 'UPDATE_STAKEHOLDER'
export const QUESTIONS_LIST = 'QUESTIONS_LIST'

export const getColorFromValue = (val) => {
    if (val <= 10) return "#e56965"
    if (val <= 20) return "#c0788a";
    if (val <= 30) return "#a185a8";
    if (val <= 40) return "#8296c6";
    if (val <= 50) return "#659fe2";
    if (val <= 60) return "#4ba8ed";
    if (val <= 70) return "#3bacdd";
    if (val <= 80) return "#2aaecc";
    if (val <= 90) return "#19b2bb";
    if (val <= 100) return "#24beb3";
    return "#24beb3"; // solid green
}

export const getGradientColorFromValue = (val) => {
    // if (val === 0) return '#c0c0c0'
    if(val < 2) return '#FF0000'
    // Define the RGB values for the gradient
    var gradient = [[230, 104, 100], [83, 167, 245], [0, 183, 162]];

    // Normalize the val to the range of 0 to 1
    var value_normalized = (val - 1) / (100 - 1);

    // Calculate the indices of the two colors in the gradient to interpolate
    var index1 = Math.floor(value_normalized * (gradient.length - 1));
    var index2 = Math.min(index1 + 1, gradient.length - 1);

    // Calculate the interpolation factor
    var t = value_normalized * (gradient.length - 1) - index1;

    // Interpolate between the two colors
    var color = [];
    for (var i = 0; i < 3; i++) {
        color[i] = Math.round((1 - t) * gradient[index1][i] + t * gradient[index2][i]);
    }
    return 'rgb(' + color[0] + ', ' + color[1] + ', ' + color[2] + ')';
}  

export const UserCard = ({ fName, userTitle, segments, onClick, rightArrow = true, value = 0, overall_sentiment_score = 0 }) => {
    return <div className="user-card" onClick={onClick}>
        <div className='profile-pic-progress-div'>
            <CircularProgress
                variant="determinate"
                style={{'color': getGradientColorFromValue(overall_sentiment_score)}}
                size={55}
                value={value}
                thickness={value ? 3 : 0.1}
            />
            <div className='profile-pic-div'>
                <img className="profile-pic" src={UserLogo} alt='user-profile' />
            </div>
        </div>
        <div className="user-detail">
            <span className="full-name">{fName}</span>
            <span className="user-title">{userTitle}</span>
            <span className="segments">{segments}</span>
        </div>
        {
            rightArrow && <div className="user-detail-link">
                <ChevronRightIcon />
            </div>
        }
    </div>
}


export const MappingIcon = ({ icon, title, onClick, isSelected, styles, screen }) => {
    return <div
        className={`mapping-icon-container ${isSelected ? 'selected' : ''} ${screen === 'filter' ? 'filter-icon' : ''}`}
        onClick={onClick}
    >
        <div className='mapping-icon' style={styles}>
            <i className={icon} />
        </div>
        <div className='mapping-icon-text'>
            {title}
        </div>

    </div>
}


export const mapsColors = [
    '#f2d9a1',
    '#E8BDB4',
    '#A2D7D1',
    '#D2C2CC',
    '#b4dfe8',
    '#d7a2a8',
    '#c2d2c8',
    '#d6e0ea',
    '#a1baf2',
]