
import {
    drag,
    chevronDown,
    chevronUp,
    pen
} from './../../assets/svgs/questions'
import {
    MultiChoiceQuestionRowContent,
    MultiTopicQuestionRowContent,
    SliderQuestionRowContent,
    TextQuestionRowContent
} from './QuestionRowContent'
import { BaseButton } from "./../../Elements/Button"


const TableRow = ({ index, item, provided, openQuestionId, onClickChevron, onClickEdit, onChange, ...args }) => {
    if (item) {
        let openQuestionCondtion = openQuestionId === item.id
        return <>
            <tr
                key={item.id + '-' + item.subdriver}
                className={`table-row ${openQuestionCondtion ? 'active-row' : ''}`}
                ref={provided?.innerRef} {...provided?.draggableProps}
            >
                {
                    item.is_mandatory ?
                        <td colSpan={1}></td>
                        :
                        <td {...provided?.dragHandleProps} className='drag-drop-td'>
                            <img src={drag} alt="drag me" />
                        </td>
                }
                {
                    item.is_mandatory ?
                        <td colSpan={1} />
                        :
                        <td className='edit-btn-td'>
                            <BaseButton
                                variant='text'
                                text={<img src={pen} alt="Edit" width={17} />}
                                className="edit-btn"
                                onClick={() => onClickEdit(item.id)}
                            />
                        </td>
                }
                <td className='select-row select'>
                    <input
                        disabled={item.is_mandatory}
                        type='checkbox'
                        checked={!item.is_mandatory && args?.selectedQuestions.includes(item.id)}
                        onChange={(e) => args?.handleQuestionSelection(e, item.id)}
                    />
                </td>
                <td className='subdriver-td'>{item.subdriver}</td>
                <td className='question-td'>{item.question}</td>
                <td className='type-td'>{item.type_readable}</td>
                {
                    item?.segments?.sort((a,b) => a.segment_am_question_id - b.segment_am_question_id).map((data, columnIdx) => {
                        return ((parseInt(args.selectedSegmentTypeId) === parseInt(data?.segment?.segment_type?.segment_type_id)) &&
                            <td className='segments-td' key={data.segment.segment_id + 'td'}>
                                <input disabled={item.is_mandatory} type='checkbox' key={data.segment.segment_id + 'td'} checked={data.is_selected} onChange={() => onChange(index, columnIdx)} />
                            </td>
                        )
                    })
                }

                <td className='chevron-td' onClick={onClickChevron}>
                    <img
                        src={openQuestionCondtion ? chevronUp : chevronDown}
                        alt={`${openQuestionCondtion ? chevronUp : chevronDown}`}
                    />
                </td>

            </tr>
            {
                openQuestionCondtion && <tr className={`table-row ${openQuestionCondtion ? 'active-row' : ''}`}>
                    <td></td>
                    <td colSpan={6 + Object.keys(item.segments).length}>
                        {
                            item.type === 'TEXT' && <TextQuestionRowContent questionData={item} />
                        }
                        {
                            item.type === 'SLIDER' && <SliderQuestionRowContent questionData={item} />
                        }
                        {
                            item.type === 'MULTI_TOPICS' && <MultiTopicQuestionRowContent questionData={item} />
                        }
                        {
                            item.type === 'CHOICE' && <MultiChoiceQuestionRowContent questionData={item} />
                        }
                    </td>
                </tr>
            }

        </>
    }

}

export default TableRow;