import { useEffect, useState, useMemo } from 'react'
import { ApiClient } from '../../../../api/client'
import { useSelector } from 'react-redux'
import './index.scss'

import btn_flag_grey from '../Icons/flag/btn_flag_grey.png'
import btn_star_grey from '../Icons/acknowledgement/btn_star_grey.png'
import btn_like_grey from '../../../../assets/images/btn_like_grey.png'
import btn_like_white from '../../../../assets/images/btn_like_white.png'
import Emoji from '../../../../components/Emoji';
import {
    ack_agree,
    ack_great_idea,
    ack_lets_talk_about_it,
    ack_thanks_for_sharing,
    ack_working_on_it,
    ack_tell_us_more,

    flag_individual,
    flag_commenter,
    flag_non_constructive,
    flag_out_of_policy,
    flag_aggressive,

} from '../Icons'




const Carousel = (data, value,) => {
    const { projectUserId, } = useSelector(state => state.project)
    //For acknowlegement
    const [ackbtntext, setAckbtntext] = useState('')
    const [ackbtnicon, setAckbtnicon] = useState('')
    const [ack_isActive, setack_isActive] = useState(false)
    const [ack_id, setAck_id] = useState()




    //For like
    const [likebtnstatus, setLikebtnstatus] = useState(0)
    const [likebtncount, setLikebtncount] = useState(56)
    const [like_id, setLike_id] = useState()


    //For Flag
    const [selectedflag, setSelectedflag] = useState('')

    const onFlag = (value) => {
        setSelectedflag(value)
        const params = {

            'flag_status': value,
            'am_response': data.responseId,
            'project_user': projectUserId
        }
        const client = ApiClient()
        client.post('/api/v1/key-theme-flag/', { 'data': params }).then(response => { }).catch(err => {
            console.log(err)
        })
        data.onToggleFlag();
        data.callback();

    }

    const onAck = (value) => {


        const params = {

            'acknowledge_status': value,
            'am_response': data.responseId,
            'project_user': projectUserId

        }

        const client = ApiClient()
        if (!ack_isActive) {
            client.post('/api/v1/key-theme-acknowledgement/', { 'data': params }).then(res => { setAck_id(res.data.id) }).catch(err => {
                console.log(err)
            })
        }
        else {
            client.put(`/api/v1/key-theme-acknowledgement/${ack_id}`, { 'data': params }).then(response => { }).catch(err => {
                console.log(err)
            })

        }

        setAckbtntext(ack[value].title)
        setAckbtnicon(ack[value].img)
        setack_isActive(true)
    }

    const onLiketoggle = () => {
        const params = {

            'like_status': (value) ? 1 : 0,
            'am_response': data.responseId,
            'project_user': projectUserId
        }
        if (likebtnstatus <= 0) {
            setLikebtnstatus(1)
            params.like_status = 1
            setLikebtncount(likebtncount + 1)

        }
        else {
            setLikebtnstatus(0)
            setLikebtncount(likebtncount - 1)
            params.like_status = 0

        }

        const client = ApiClient()
        if (likebtnstatus == -1) {
            client.post('/api/v1/key-theme-like/', { 'data': params }).then(res => { setLike_id(res.data.id) }).catch(err => {
                console.log(err)
            })
        }
        else {
            client.put(`/api/v1/key-theme-like/${like_id}`, { 'data': params }).then(response => { }).catch(err => {
                console.log(err)
            })
        }

    }
    const ack = {
        5: { img: ack_agree, title: "I agree" },
        1: {
            img: ack_thanks_for_sharing,
            title: "Thanks for sharing",
        },
        2: { img: ack_great_idea, title: "Great idea" },
        3: {
            img: ack_working_on_it,
            title: "Working on it",
        },
        4: {
            img: ack_lets_talk_about_it,
            title: "Let's talk about it",
        },
        6: {
            img: ack_tell_us_more,
            title: "Tell us more",
        },
    };

    const flag = {
        1: {
            img: flag_individual,
            title: "Individual can be identified",
        },
        2: {
            img: flag_commenter,
            title: "Commenter can be identified",
        },
        3: { img: flag_non_constructive, title: "Non-Constructive Feedback" },
        4: { img: flag_out_of_policy, title: "Out of Policy" },
        5: { img: flag_aggressive, title: "Aggressive or Hostile" },
    };



    const getAckbtn = (data) => {
        if (data) {
            setAckbtntext(ack[data].title)
            setAckbtnicon(ack[data].img)
            setack_isActive(true)
        }
        else {
            setAckbtntext('Acknowledgement')
            setAckbtnicon(btn_star_grey)
            setack_isActive(false)
        }
    }
    const getLikeStatus = (data) => {
        if (data == null) {
            setLikebtnstatus(-1)
        }
        else {
            setLikebtnstatus(data)
        }
    }

    useEffect(() => {
        getAckbtn(data.acknowledge_status)
        getLikeStatus(data.like_status)
        setLikebtncount(data.like_count)
        setAck_id(data.ack_id)
        setLike_id(data.like_id)


    }, [])

    return (


        // <div id={data.response_id}>
            <div className='own-words-root'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Emoji satisfaction={data.value} keytheme={true}></Emoji></div>
                <div className='content'>
                    <div className='text'>{data.text}</div>
                    <div className='action'>
                        {/* Like Button Start */}
                        <div className="action-item">
                            <div
                                role="button"
                                className={(likebtnstatus > 0) ? 'action-item-btn active' : 'action-item-btn'}
                                onClick={(e) => onLiketoggle()}>


                                <img
                                    className="action-item-icon"
                                    src={(likebtnstatus > 0) ? btn_like_white : btn_like_grey}
                                    height="20"
                                    style={{ margin: '0 2px', backgroundColor: 'bldue' }}
                                />
                                <span className="action-item-icon" style={{ fontWeight: '500', margin: '0 0 2px 2px', }}>{likebtncount}</span>
                            </div>
                        </div>

                        {/* Acknowledge Button Start */}
                        <div className="action-item">
                            <div
                                role="button"
                                className={ack_isActive ? 'action-item-btn active' : 'action-item-btn'}
                                onClick={(e) => {
                                    data.onToggleAck();
                                }}


                            >
                                <span className="action-item-title">{ackbtntext}</span>
                                <img
                                    className="action-item-icon"
                                    src={ackbtnicon}
                                    height="20"

                                />
                            </div>
                            <div
                                className={(data.ackPanelOpen && data.selectedId == data.data.am_response_id) ? "action-item-panel" : "action-item-panel hide"}


                            >
                                <div className="action-item-panel-triangle"></div>
                                <div className="action-item-panel-content">
                                    {Object.keys(ack).map((key) => (
                                        <div
                                            className="action-item-panel-item"
                                            key={`ack_img_${key}`}
                                            onClick={(e) => {
                                                data.onToggleAck();
                                                onAck(key);
                                            }}
                                        >
                                            <span>{ack[key].title}</span>
                                            <img src={ack[key].img} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>

                        {/* Flag Button Start */}
                        <div className="action-item">
                            <div
                                role="button"
                                className="action-item-btn"
                                onClick={(e) => {
                                    data.onToggleFlag();
                                }}


                            >
                                <span className="action-item-title">Flag</span>
                                <img
                                    className="action-item-icon"
                                    src={btn_flag_grey}
                                    height="20"
                                />
                            </div>
                            <div className={(data.flagPanelOpen && data.selectedId == data.data.am_response_id) ? "action-item-panel" : "action-item-panel hide"}

                            >
                                <div className="action-item-panel-triangle"></div>
                                <div className="action-item-panel-content">
                                    {Object.keys(flag).map((key) => (
                                        <div
                                            className="action-item-panel-item"
                                            key={`flag_img_${key}`}
                                            onClick={(e) => {

                                                onFlag(key);
                                            }}
                                        >
                                            <span>{flag[key].title}</span>
                                            <img src={flag[key].img} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        // </div>
        )

}

export default Carousel;