

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';

import projectReducer from './projectReducer';
import userReducer from './userReducer';


const persistConfigProject = {
    key: 'root',
    storage: sessionStorage,
    // whitelist: ['projectId', 'isEdit', 'username', 'projectUserId', 'currentProjectName']
}

const persistConfigUser = {
    key: 'user',
    storage: sessionStorage,
    // whitelist: ['username', 'userId', 'useremail', 'isAdmin']
    blacklist: ['isBillingAdmin', 'isAdmin']
}

const persistedProjectReducer = persistReducer(persistConfigProject, projectReducer)
const persistedUserReducer = persistReducer(persistConfigUser, userReducer)


const rootReducer = combineReducers({
    project: persistedProjectReducer,
    user: persistedUserReducer,
})

export default rootReducer;