import { useEffect, useRef, useState } from 'react';
// import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TableRow from './UsersTableRow';
import arrowup from '../../assets/svgs/arrowup.svg'
import arrowdown from '../../assets/svgs/arrowdown.svg'
import filterfunnelsolid from '../../assets/svgs/filterfunnelsolid.svg'
import filterfunneloutline from '../../assets/svgs/filterfunneloutline.svg'
import { ApiClient } from '../../api/client';
import { useSelector } from 'react-redux';


const Table = ({
    userResponse,
    handleUserEdit,
    segmentsData,
    handleSorting,
    sort,
    handleInvitation,
    isAllSelected,
    selectedUsers,
    updateSelectedUser,
    setUserSelect,
    setUserResponse,
    projectId,
    activeUserTypeQueryParams,
    segmentsFilters,
    setSegmentFilters,
    showInviteHeader,
    editMode
}) => {

    let users = userResponse.users
    const { isDraft } = useSelector(state => state.project)

    useEffect(() => {
        setSegmentFilters({
            showFiltersList: '',
            checkedSegments: [],
            filteredSegmentTypes: [],
            filteredObj: {}
        })
    }, [activeUserTypeQueryParams])

    const [openUser, setOpenUser] = useState()

    const filtersRef = useRef(null);

    const { showFiltersList, checkedSegments, filteredSegmentTypes, filteredObj } = segmentsFilters

    const handleClickOutside = (event) => {
        if (filtersRef.current && !filtersRef.current.contains(event.target)) {
            setSegmentFilters(prevState => ({ ...prevState, showFiltersList: '' }));
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);


    const handleSegmentTypeClick = (segmentTypeName) => setSegmentFilters(prevState => (
        {
            ...prevState,
            showFiltersList: prevState.showFiltersList === segmentTypeName ? '' : segmentTypeName
        }
    ))

    const handleFilterInputClick = (segment_type_id, seg_id) => {
        let newFilterObj = filteredObj
        if (newFilterObj.hasOwnProperty(segment_type_id)) {
            if (newFilterObj[segment_type_id].includes(seg_id)) {
                newFilterObj[segment_type_id] = newFilterObj[segment_type_id].filter(item => item !== seg_id)
                if (newFilterObj[segment_type_id].length === 0) {
                    delete newFilterObj[segment_type_id]
                }
            }
            else {
                newFilterObj[segment_type_id].push(seg_id)
            }
        }
        else {
            newFilterObj[segment_type_id] = [seg_id]
        }
        let newCheckedSegments = []
        let newfilteredSegmentTypes = []
        for (let key in newFilterObj) {
            newCheckedSegments = [...newCheckedSegments, ...newFilterObj[key]]
            newfilteredSegmentTypes = [...newfilteredSegmentTypes, parseInt(key)]
        }

        setSegmentFilters(prevState => (
            {
                ...prevState,
                filteredSegmentTypes: newfilteredSegmentTypes,
                checkedSegments: newCheckedSegments,
                filteredObj: newFilterObj
            }
        ))

        let segments_list_string = newCheckedSegments.join(",")
        let segmentsTypes_list_string = newfilteredSegmentTypes.join(",")
        const client = ApiClient()
        let API_URL = '/api/v1/project-user/' + projectId +
            '/?segments=' + segments_list_string +
            '&segmentTypes=' + segmentsTypes_list_string +
            '&page_size=50' +
            activeUserTypeQueryParams
        client.get(API_URL)
            .then((response) => {
                if (response?.data) {
                    setUserResponse({
                        users: response.data,
                        nextLink: response.next,
                        prevLink: response.prev,
                        usersCount: response.count
                    })
                }
            })
    }

    return <table className='users-table'>
        <thead className='table-header'>
            <tr className='table-head-row'>
                <th colSpan='1' className='blank-th'>
                </th>
                <th className='select-all-rows select'>
                    <input type='checkbox' onChange={(e) => {
                        let ids = users.map(item => { return item.id })
                        setUserSelect(prevState => (
                            {
                                ...prevState,
                                isAllSelected: !prevState.isAllSelected,
                                selectedUsers: prevState.isAllSelected ? [] : [...new Set([...selectedUsers, ...ids])]
                            }
                        ))
                    }}
                        checked={isAllSelected}
                    />
                    <span></span>
                </th>
                <th className='fullname-th' onClick={() => handleSorting('first_name')}>
                    <span>Full Name
                        {
                            sort.by === 'first_name' && (
                                sort.order === 'asc' ? <img alt='None' width={15} src={arrowdown} />
                                    :
                                    <img alt='None' width={15} src={arrowup} />
                            )
                        }
                    </span>
                </th>
                <th className='project-title-th' onClick={() => handleSorting('usertitle')}>
                    <span>Project Title
                        {
                            sort.by === 'usertitle' && (
                                sort.order === 'asc' ? <img alt='None' width={15} src={arrowdown} />
                                    :
                                    <img alt='None' width={15} src={arrowup} />
                            )
                        }
                    </span>
                </th>
                <th className='email-th' onClick={() => handleSorting('email')}>
                    <span>Email
                        {
                            sort.by === 'email' && (
                                sort.order === 'asc' ? <img alt='None' width={15} src={arrowdown} />
                                    :
                                    <img alt='None' width={15} src={arrowup} />
                            )
                        }
                    </span>
                </th>
                {
                    segmentsData.map(segType => {
                        let segmentTypeName = segType?.segmentTypeName
                        let segmentTypeId = segType?.segmentTypeId
                        return <th key={segmentTypeName} className='segment-th'>
                            <div className='segment-th-content' onClick={() => handleSegmentTypeClick(segmentTypeName)}>
                                {segmentTypeName}
                                {
                                    filteredSegmentTypes?.includes(segmentTypeId) ?
                                        <img alt='None' width={12} src={filterfunnelsolid} />
                                        :
                                        <img alt='None' width={12} src={filterfunneloutline} />

                                }
                            </div>

                            <span></span>

                            {
                                showFiltersList === segmentTypeName
                                &&
                                <div className='segments-filter-list-box' ref={filtersRef}>
                                    <ul>
                                        {
                                            segType.segmentsList.map(item => {
                                                return <li key={item.title} className='segments-list-options'>
                                                    <input
                                                        id={'filter-input-' + item.title}
                                                        className='segments-list-options-input'
                                                        type='checkbox'
                                                        onChange={() => handleFilterInputClick(segmentTypeId, item.segmentId)}
                                                        checked={checkedSegments.includes(item.segmentId)}
                                                    />
                                                    <label htmlFor={'filter-input-' + item.title} className='segments-list-options-title'>{item.title}</label>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                        </th>
                    })
                }
                <th className='invite-th'>{(showInviteHeader || isDraft) ? 'Assign Seats' : 'Assigned Seats'} </th>

                {
                    (editMode && !showInviteHeader)
                    &&
                    <th className='admin-th'>Project Admin</th>
                }
            </tr>
        </thead>
        <tbody className='table-body'>
            {
                users.length ?
                    users
                        .filter(user => editMode ? user.invite_sent === !showInviteHeader : user)
                        .map((item, idx) => {
                            return <TableRow
                                key={item.email + idx}
                                item={item}
                                openUserId={openUser}
                                onClickChevron={() => {
                                    if (openUser === item.id) {
                                        setOpenUser()
                                    }
                                    else {
                                        setOpenUser(item.id)
                                    }
                                }}
                                onClickEdit={handleUserEdit}
                                onClickInvite={handleInvitation}
                                segmentsData={segmentsData}
                                selectedUsers={selectedUsers}
                                isAllSelected={isAllSelected}
                                setUserSelect={setUserSelect}
                                updateSelectedUser={updateSelectedUser}
                                showInviteHeader={showInviteHeader}
                                editMode={editMode}
                                setUserResponse={setUserResponse}
                            />

                        })
                    :
                    <tr >
                        <td colSpan={10} style={{ textAlign: 'center', padding: '30px 0' }}>No Users Found</td>
                    </tr>
            }
        </tbody>
    </table>
}

export default Table;