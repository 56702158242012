

import { applyMiddleware, compose, configureStore } from '@reduxjs/toolkit'
import rootReducer from '../reducers/'
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

export const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk]
},
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)


export const persistedStore = persistStore(store)