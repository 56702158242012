import { useLayoutEffect, useRef, useState } from 'react';
import { replaceQuestionTextKeyWord } from '../../../utilities';
import './index.scss'
import SkipQuestionOptionList from '../CommentSkipQuestion';
import { DebounceInput } from 'react-debounce-input';
import { DEBOUNCE_DELAY } from '../../../utilities/constants';



const FreeTextQuestion = ({
    currentUser,
    question,
    onAnswer,
    projectTitle,
    surveyType,
    otherUser
}) => {


    const firstUpdate = useRef(true);
    const [answer, setAnswer] = useState({
        'project_user': currentUser,
        'project': question.project,
        ...(surveyType === 'me' && { 'am_question': parseInt(question.id) }),
        ...(surveyType === 'others' && { 'ao_question': parseInt(question.id) }),
        'control_type': question.control_type_readable,
        'integer_value': 0,
        'topic_value': '',
        'comment_value': '',
        'skip_value': '',
        'topic_tags': '',
        'comment_tags': '',
        ...question.response_data[0]
    })

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const debounce = setTimeout(() => {
            if (answer.topic_value.trim()) {
                onAnswer(answer)
            }
        }, DEBOUNCE_DELAY)

        return () => {
            clearTimeout(debounce)
        }
    }, [answer.topic_value, answer.comment_value, answer.skip_value])

    const handleFreeTextChange = (e) => {
        setAnswer(state => ({ ...state, topic_value: e.target.value, skip_value: "" }))
    }

    const handleSkip = (skip_text) => {
        setAnswer(state => ({ ...state, skip_value: skip_text, topic_value: '' }))
    }

    const handleComment = (comment_text) => {
        setAnswer(state => ({ ...state, comment_value: comment_text }))
    }

    return (
        <div className="freetext-question main-question">
            <h2 className="question-text">
                {replaceQuestionTextKeyWord(question.question_text, otherUser, projectTitle)}
            </h2>
            <div className="answer-section">
                <DebounceInput
                    element={'input'}
                    multiline={1}
                    className={`answer-base-input ${answer.is_flagged ? 'flagged-input' : ''}`}
                    value={answer?.topic_value}
                    debounceTimeout={DEBOUNCE_DELAY}
                    onChange={handleFreeTextChange}
                    placeholder={question.topic_prompt}
                />
                {
                    answer.is_flagged
                    &&
                    <span className='flagged-statement'>
                        *Your response has been flagged for moderation. Please revise your response.
                        If already revised, it will be reflected once reviewed.
                    </span>
                }
            </div>
            <div className="comment-skip-section">
                <SkipQuestionOptionList
                    answer={answer?.topic_value}
                    commentValue={answer?.comment_value}
                    commentPrompt={question.comment_prompt}
                    skipValue={answer?.skip_value}
                    skipOptionsList={question.skip_option_list}
                    skipOption={question.skipOption}
                    onSkip={(skipAnswer) => handleSkip(skipAnswer)}
                    onComment={(commentAnswer) => handleComment(commentAnswer)}
                />
            </div>
        </div>
    );
}


export default FreeTextQuestion;