import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from 'papaparse';


const exportToCSV = (apiData, fileName) => {
    let csvdata = Papa.unparse(apiData)
    const fileType = 'text/csv;charset=utf-8;'
    const fileExtension = ".csv";
    const data = new Blob([csvdata], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};


const exportToEXCEL = (apiData, fileName, responseType) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let excelBuffer;
    if (responseType === 'json') {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    }
    else if (responseType === 'blob') {
        excelBuffer = apiData
    }
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}

export {
    exportToCSV,
    exportToEXCEL
}



export const replaceQuestionTextKeyWord = (questionText, user, projectName) => {
    if (user == null || user === undefined) {
        user = { first_name: '', last_name: '' }
    }

    if (projectName == null || projectName === undefined) {
        projectName = "Project";
    }

    var str = questionText;

    str = str.replace("{{{PROJECTNAME}}}", projectName);
    // str = str.replace("{{{TEAMNAME}}}", user.team);
    str = str.replace("{{{FULLNAME}}}", user?.first_name + ' ' + user?.last_name);
    str = str.replace("{{{STAKEHOLDERNAME}}}", user?.first_name + ' ' + user?.last_name);

    str = str.replace("{{PROJECTNAME}}", projectName);
    // str = str.replace("{{TEAMNAME}}", user?.team);
    str = str.replace("{{FULLNAME}}", user?.first_name + ' ' + user?.last_name);
    str = str.replace("{{STAKEHOLDERNAME}}", user?.first_name + ' ' + user?.last_name);
    return str;
};


export const capitalizeWords = (str) => {
    // Split the string into an array of words
    const wordsArray = str.split(' ');

    // Map over the array and capitalize the first letter of each word
    const capitalizedWordsArray = wordsArray.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the capitalized words back into a string
    const capitalizedString = capitalizedWordsArray.join(' ');

    return capitalizedString;
};


export const tabledatacolor = (value) => {
    if (value == null || !value) {
        return ['#FFFFFF', 'rgba(128,128,128, 0.22)']
    }
    else if (value < 2) {
        return ['#E56965', 'rgba(229, 105, 101, 0.14)']
    }
    else if (value >= 2 && value < 4) {
        return ['#A185A8', 'rgba(161, 133, 168, 0.22)']
    }
    else if (value >= 4 && value < 6) {
        return ['#8292C6', 'rgba(130, 146, 198, 0.22)']
    }
    else if (value >= 6 && value < 8) {
        return ['#4BA8ED', 'rgba(75, 168, 237, 0.13)']
    }
    else if (value >= 8) {
        return ['#07B6A9', 'rgba(7, 182, 169, 0.16)']
    }
}



export const scrollToBottom = () => {
    return window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: 'smooth',
    })
}