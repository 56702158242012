import './index.scss';
import Layout from "../../components/Layout";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { BaseButton } from "../../Elements/Button";
import PermissionsTable from "../../components/PermissionsTable";
import { useEffect, useState } from 'react';
import Modal from '../../components/Modal';

import { ApiClient } from "../../api/client";
import { useSelector } from "react-redux";
import PermissionsPopup from "../../components/PermissionsPopup";

const Permissions = () => {
    const navigate = useNavigate()
    const { state } = useLocation();
    const [showPermissionAddModal, setShowPermissionAddModal] = useState(false)
    const [showPermissionEditModal, setShowPermissionEditModal] = useState(false)
    const [editPermission, setEditPermission] = useState({})
    const [permissions, setPermissions] = useState([])
    const { projectId, isEdit, isDraft } = useSelector(state => state.project)
    const [segmentsData, setSegmentData] = useState([])
    const [segmentsObj, setSegmentsObj] = useState([])
    const [segmentsForList, setSegmentsForList] = useState([])
    const [viewedSegmentsList, setViewedSegmentsList] = useState([])
    const [segmentsList, setSegmentsList] = useState([])
    const [permissionType, setPermissionType] = useState('only_exclude')
    const [errorTxt, setErrorTxt] = useState('')
    const [segmentFilter, setSegmentFilter] = useState(0)

    const fetchPermissions = (projectId, url_params = '') => {
        if(projectId){
            const client = ApiClient(setPermissions)
            client.get('/api/v1/permissions/' + projectId + '/?' + url_params)
            .then((response) => {
                if (response?.data.length) {
                    setPermissions([...response?.data]);
                }
            })
        }
    }

    useEffect(() => {
        if (projectId) {
            fetchPermissions(projectId)
            const client = ApiClient(setSegmentData)
            client.get('/api/v1/segments/' + projectId + '/?is_active=true')
            .then((response) => {
                if (response?.data) {
                    setSegmentData(response.data)
                }
            })
        }
    }, [projectId])

    useEffect(() => {
        if(segmentsData){
            segmentsData.map((segment) => {
            return(
                    setSegmentsObj((prevState) => [...prevState, ...segment?.segmentsList])
                )
            })
       }
    }, [segmentsData])

    useEffect(() => {
        if(segmentsObj){
            setSegmentsList(Object.values(segmentsObj))
       }
    }, [segmentsObj])

    useEffect(() => {
        if(segmentFilter !== 0){
            fetchPermissions(projectId, 'segment_id=' + segmentFilter)
        }else{
            fetchPermissions(projectId)
        }
    }, [segmentFilter, projectId])
    
    const handleCancel = () => {
        setErrorTxt('')
        setShowPermissionAddModal(false)
        setShowPermissionEditModal(false)
    }

    const nextBtnConditions = () =>{
        const client = ApiClient()
        client.post('api/v1/journeysteps/', {
            project:projectId,
            step_id: 7,
            step_name:'Permissions'
        }).then((res) => {
        //  console.log(res)    
        }).catch((error) => {
           console.log(error)
        })
     
        return navigate({
            pathname: isDraft ? '/setup/launch' : '/setup/flagged-response',
            search: '?projectId=' + projectId
        }, { state: state })
    }
    const handleConfirm = () => {
        if(segmentsForList.length && viewedSegmentsList.length && permissionType){
            const client = ApiClient()
            client.post('/api/v1/permissions/' + projectId + '/', {
                segmentsForList: segmentsForList,
                viewedSegmentsList: viewedSegmentsList,
                permissionType: permissionType
            }).then((response) => {
                if (response?.data) {
                    setPermissions(prevState => [response.data, ...prevState]);
                    setErrorTxt('')
                    setShowPermissionAddModal(false)
                }
            }).catch((error) => {
                let errorTxt = error?.response?.data?.data?.permission_applicants?.find(obj => Object.keys(obj).length > 0)?.non_field_errors
                if(Array.isArray(errorTxt)){
                    setErrorTxt(errorTxt[0])
                }else{
                    setErrorTxt(errorTxt)
                }
            })
        }else{
            setErrorTxt('Select segments to setup permission')
        }
    }

    const handleEditConfirm = (permissionId) => {
        if(segmentsForList.length && viewedSegmentsList.length && permissionType){
            const client = ApiClient()
            client.put('/api/v1/permissions/' + projectId + '/' + permissionId + '/', {
                segmentsForList: segmentsForList,
                viewedSegmentsList: viewedSegmentsList,
                permissionType: permissionType
            }).then((response) => {
                if (response?.data) {
                    setErrorTxt('')
                    setPermissions([...response?.data]);
                    setShowPermissionEditModal(false)
                }
            }).catch((error) => {
                let errorTxt = error?.response?.data?.data?.permission_applicants?.find(obj => Object.keys(obj).length > 0)?.non_field_errors
                if(Array.isArray(errorTxt)){
                    setErrorTxt(errorTxt[0])
                }else{
                    setErrorTxt(errorTxt)
                }
            })
        }
    }

    const handleEdit = (permission) => {
        if(Object.keys(permission)?.length){
            setEditPermission(permission)
            setShowPermissionEditModal(true)
        }
        
    }

    return <Layout 
    active_page={'Permissions'}
    // {...((isEdit) ? {isNextShown : false} : {})}
    onClickNext={nextBtnConditions}
    onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#Permissions','_blank')}
    onClickBack={() => navigate({
        pathname: '/setup/users',
        search: '?projectId=' + projectId
    }, { state: { projectId } } )}
    >
    <div className="permission-section section">
        <div className='permission section-container'>
            <h1 className='psection-title'> Permissions</h1>
            <p className="psection-description">
            Transparency is ideal, but sometimes its important to control what people can see. This screen allows you to define which dashboard data is visible to different stakeholder segments.
            </p>
            <div className="pquestions-section" >
                <div className='pquestion-quick-actions'>
                    <span className='padd-new' >
                        <BaseButton
                            variant="outlined"
                            className={'padd-new-permission'}
                            text={<> + Add Rule</>}
                            onClick={() => {setShowPermissionAddModal(true)}}
                        />
                    </span>
                </div>
            </div>
            <div className='ptable-box'>
                <PermissionsTable
                    projectId={projectId}
                    permissions={permissions}
                    segmentsObj={segmentsObj}
                    setSegmentFilter={setSegmentFilter}
                    setPermissions={setPermissions}
                    handleOnEdit={handleEdit}
                    />
            </div>
        </div>
    </div>
    {showPermissionAddModal &&
        <Modal
            handleModal={() => {
                setErrorTxt('')
                setShowPermissionAddModal(!showPermissionAddModal)
            }}
            modal_content={
                <PermissionsPopup
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                segments_list={segmentsList}
                setSegmentsForList={setSegmentsForList}
                setPermissionType={setPermissionType}
                setViewedSegmentsList={setViewedSegmentsList}
                errorTxt={errorTxt}
                />
            }
        />
    }
    {showPermissionEditModal &&
        <Modal
            handleModal={() => {
                setErrorTxt('')
                setShowPermissionEditModal(!showPermissionEditModal)
            }}
            modal_content={
                <PermissionsPopup
                isEdit={true}
                handleConfirm={handleEditConfirm}
                handleCancel={handleCancel}
                segments_list={segmentsList}
                editPermission={editPermission}
                setSegmentsForList={setSegmentsForList}
                setPermissionType={setPermissionType}
                setViewedSegmentsList={setViewedSegmentsList}
                errorTxt={errorTxt}
                />
            }
        />
    }
    </Layout>
}

export default Permissions;