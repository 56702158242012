import './index.scss'
import { Sidebar as ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Switch from "react-switch";
import Tour_Wizard from '../../../components/enduser_tourWizard';
import logo from '../../../assets/images/project-ai-logo-light.png'
import {
    IconMyProject,
    IconAboutMe,
    IconAboutOthers,
    IconDashboard,
    IconMoreInfo,
    IconAdministration,
    IconHelp
} from './Icons';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router';
import { ApiClient } from '../../../api/client';
import { useSelector, useDispatch } from 'react-redux';
import { isProjectAdminAction, projectAction, projectNameAction, projectUserIdAction, userIdAction } from '../../../redux/actions';
import Joyride from 'react-joyride';
import NikelTour from '../../../components/Nikeltour'



const EndUserSidebar = ({ isSidebarOpen, toggleSidebar }) => {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();

    const { projectId, projectUserId, currentProjectName, isProjectAdmin } = useSelector(state => state.project)
    const { isAdmin } = useSelector(state => state.user)
    const [taketour, setTaketour] = useState(false)
    const [projectSelected, selectProject] = useState(projectId)
    const [tourGuide, setTourGuide] = useState(false)
    const [tourSwitch, settourSwitch] = useState(false)
    const [projectsList, setProjectsList] = useState([])

    useEffect(() => {
        const client = ApiClient()
        client.get('/api/v1/projects-list/' + '?invited_user=true').then((response) => {
            setProjectsList(response.data)
            if (response.data.length === 1) {
                let res = response.data[0]
                handleSelectProject(res.project_id, res.project_name, res.project_user_id, res.is_project_admin, false)
            }
        })
    }, [])


    const handleSelectProject = (projectId, projectName, projectUserId, is_project_admin, isNavigate = false) => {
        selectProject(projectId)
        dispatch(projectAction(projectId))
        dispatch(projectNameAction(projectName))
        dispatch(projectUserIdAction(projectUserId))
        dispatch(isProjectAdminAction(is_project_admin))
        if (isNavigate) {
            if(window.innerWidth < 880){
                toggleSidebar()
            }
            navigate('/app/about-me')
        }
    }

    const handleSetGuideShowStatus = () => {
        if (!tourSwitch) {
            setTourGuide(true)
        }
        settourSwitch(!tourSwitch)
    }
   const handleNikeltour = () =>{
    setTaketour(!taketour)

    }

    const navigateTo = (path) => {
        if(window.innerWidth < 880){
            toggleSidebar()
        }
        navigate(path)
    }

    return <div className={`end-user-sidebar ${isSidebarOpen ? 'sidebar-open' : 'sidebar-close'} `}>
        <div className='logo'>
            <img src={logo} alt='Pulse' style={{ marginLeft: '0px' }} />
            <div className='close-btn' onClick={toggleSidebar}>
                <CloseIcon sx={{ color: '#ababab', fontSize: '22px' }} />
            </div>
        </div>
        <div className='sidebar-top'>
            <ProSidebar className='pro-sidebar' width='225px'>
                <Menu className='pro-sidebar-menu my-projects'>
                    <SubMenu
                        defaultOpen={projectSelected ? true : false}
                        label="My Projects"
                        icon={<IconMyProject />}
                        className='pro-sidebar-submenu'
                    >
                        {
                            projectsList.length > 0 && projectsList.map(item => {
                                return <MenuItem
                                    key={item.project_id}
                                    className={`pro-sidebar-menuitem ${item.project_id === projectSelected ? 'menutiem-selected' : ''} `}
                                    onClick={() => {
                                        handleSelectProject(item.project_id, item.project_name, item.project_user_id, item.is_project_admin, true)
                                    }}
                                >
                                    {item.project_name}
                                </MenuItem>
                            })
                        }
                    </SubMenu>
                </Menu>
                <Menu iconShape="square" className="pro-sidebar-menu about-me">
                    <MenuItem
                        icon={<IconAboutMe />}
                        onClick={(e) => navigateTo("/app/about-me")}
                        className={`pro-sidebar-menuitem ${location.pathname === '/app/about-me' ? 'menutiem-selected' : ''} `}
                    >
                        About Me
                    </MenuItem>
                </Menu>
                <Menu iconShape="square" className="pro-sidebar-menu about-others">
                    <MenuItem
                        icon={<IconAboutOthers />}
                        onClick={(e) => navigateTo("/app/about-others")}
                        className={`pro-sidebar-menuitem ${location.pathname === '/app/about-others' ? 'menutiem-selected' : ''} `}

                    >
                        About Others
                    </MenuItem>
                </Menu>
                <Menu iconShape="square" className='pro-sidebar-menu'>
                    <SubMenu
                        defaultOpen={false}
                        icon={<IconDashboard />}
                        label="Dashboard"
                        className='pro-sidebar-submenu'
                    >
                        <MenuItem
                            className={`pro-sidebar-menuitem ${location.pathname === '/app/project-summary' ? 'menutiem-selected' : ''} `}
                            onClick={(e) => navigateTo("/app/project-summary")}
                        >
                            Summary
                        </MenuItem>
                        <MenuItem
                            className={`pro-sidebar-menuitem ${location.pathname === '/app/driver-analysis' ? 'menutiem-selected' : ''} `}
                            onClick={(e) => navigateTo("/app/driver-analysis")}
                        >
                            Driver Analysis
                        </MenuItem>
                        <MenuItem
                            className={`pro-sidebar-menuitem ${location.pathname === '/app/key-themes' ? 'menutiem-selected' : ''} `}
                            onClick={() => { navigateTo("/app/key-themes") }}
                        >
                            Key Themes
                        </MenuItem>
                        <MenuItem
                            className={`pro-sidebar-menuitem ${location.pathname === '/app/advisor-insights' ? 'menutiem-selected' : ''} `}
                            onClick={() => { navigateTo("/app/advisor-insights") }}
                        >
                            Advisor Insights
                        </MenuItem>
                        <MenuItem
                            className={`pro-sidebar-menuitem ${location.pathname === '/app/people-galaxy' ? 'menutiem-selected' : ''} `}
                            onClick={() => { navigateTo("/app/people-galaxy") }}
                        >
                            People Galaxy
                        </MenuItem>
                    </SubMenu>
                </Menu>

            </ProSidebar>
        </div>

        <div className='sidebar-bottom'>
            <ProSidebar className='pro-sidebar' >
                {
                    isAdmin &&
                    <Menu iconShape="square" className='pro-sidebar-menu'>
                        <MenuItem
                            icon={<IconAdministration />}
                            onClick={(e) => navigateTo("/app/my-projects")}
                            className={`pro-sidebar-menuitem ${location.pathname === '/app/my-projects' ? 'menutiem-selected' : ''} `}
                        >
                            Administration
                        </MenuItem>
                    </Menu>
                }

                <Menu iconShape="square" className="pro-sidebar-menu">
                    {/*<SubMenu
                        label="More Info"
                        icon={<IconMoreInfo />}
                        className='pro-sidebar-submenu'
                    >
                        <MenuItem
                            className={`pro-sidebar-menuitem`}
                            onClick={() => { }}
                        >
                            Welcome
                        </MenuItem>
                    </SubMenu>
            */}
                    <SubMenu
                        label="Help"
                        icon={<IconHelp />}
                        className='pro-sidebar-submenu'
                    >
                        {projectId && <MenuItem
                            className={`pro-sidebar-menuitem`}
                            onClick={() => { handleNikeltour() }}
                        >
                            Take the Tour

                        </MenuItem>}
                        {taketour && < NikelTour handleModal={setTaketour} />}
                        <MenuItem
                            className={`pro-sidebar-menuitem ${location.pathname === '/app/how-to-use-pulse' ? 'menutiem-selected' : ''} `}
                            onClick={() => { navigate('/app/how-to-use-pulse') }}
                        >
                            How to use Pulse
                        </MenuItem>
                        { }
                        <MenuItem
                            className={`pro-sidebar-menuitem`}
                            onClick={() => {}}
                        >
                            Turn on Guide Mode
                        </MenuItem>
            
                        <MenuItem>
                            <Switch
                                checked={tourSwitch}
                                onChange={() => { handleSetGuideShowStatus() }}
                                onColor="#7fcdc1"
                                onHandleColor="#4cb9a8"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={38}
                                className="react-switch"
                            />
                            {tourGuide && <Tour_Wizard setTourGuide={setTourGuide} tourGuide={tourGuide} settourSwitch={settourSwitch} tourSwitch={tourSwitch} />}
                        </MenuItem>
                        <MenuItem
                            className={`pro-sidebar-menuitem`}
                            onClick={ () => window.open('https://projectai.com/pulse-user-help','_blank')}
                        >
                           More help/FAQ 
                        </MenuItem>
                    </SubMenu>
                </Menu>
            </ProSidebar>
        </div>
    </div >
}


export default EndUserSidebar;