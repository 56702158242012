import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ApiClient } from "../../api/client"
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { getGradientColorFromValue, mapsColors } from './userSection/utils';


am5.addLicense("AM5CC-0920-7853-9251-8187")



const AMChartContainer = ({ mapType = 'my-map', groupData, graphLayout, handleUserNodeClick ,setView,view,...args }) => {

    const client = ApiClient()

    const { projectId, currentProjectName } = useSelector(state => state.project)
    const [chartDataSet, setChartDataSet] = useState({})
    const [segmentData, setSegmentData] = useState();

    // default dataset for graph
    const baseData = [{
        name: mapType === 'my-map' ? 'ME' : currentProjectName,
        value: 30,
        icon: mapType === 'my-map' ? 'fa fa-user' : 'fa fa-sitemap',
        bgcolor: 'rgb(217,228,238)',
        x: am5.percent(50),
        y: am5.percent(50),
    }]
    const [dataSet, setDataSet] = useState(baseData)
    const isLargeDevice = window.innerWidth > 820

    // get segments list
    useEffect(() => {
        if (projectId) {
            client.get('/api/v1/segments/' + projectId + '/').then((response) => {
                if (response?.data) {
                    setSegmentData(response.data)
                    // console.log(response.data)
                }
            })
        }
    }, [projectId])


    // get about others graph mapping data
    useEffect(() => {
        if (args?.reloadMap && projectId) {
            client.get('/api/v1/ao-graph-data/' + projectId + '/').then(
                (response) => {
                    if (response?.data) {
                        setChartDataSet(response?.data)
                        args?.setReloadMap(false)
                    }
                })
        }
    }, [projectId, args?.reloadMap])


    // get index of key from object
    function getKeyIndex(obj, key) {
        const keysArray = Object.keys(obj);
        return keysArray.indexOf(key);
    }


    // get segmenticon from level and segment name
    function getSegmentIcon(level, segment_name) {
        return segmentData
            .filter(segmentDataItem => segmentDataItem['segmentTypeName'] === level)?.[0]?.segmentsList
            .filter(segmentsListItem => segmentsListItem.title === segment_name)?.[0]?.icon
    }

    function clickHandler(id) {
        if (id) {
            client
                .get(`/api/v1/ao-project-users/${projectId}/${id}/?mapped=true`)
                .then(res => {
                    if (res?.data) {

                        handleUserNodeClick(res.data[0])
                        if(!isLargeDevice)
                        {
                            setView (view=== 'MAP_VIEW' ? 'LIST_VIEW' : 'MAP_VIEW')
                        }

                    }
                })
        }
    }

    function createNodeObject(name, value, icon, bgcolor, project_user_id, count) {
        return ({ name, value, icon, bgcolor, project_user_id, count })
    }

    function addBgcolor(score) {
        return score ? getGradientColorFromValue(score) : '#c0c0c0'
    }

    function setCoordinates(n, idx) {
        let radius = Math.sqrt(n) * 7;
        let angle = (2 * Math.PI * idx) / n;
        let x = am5.percent(50 + radius * Math.cos(angle));
        let y = am5.percent(50 + radius * Math.sin(angle));
        return [x, y]
    }

    useEffect(() => {
        if (Object.keys(chartDataSet).length) {
            // console.log(chartDataSet)
            let mtype = mapType === 'my-map' ? 'my_map' : 'project_map'
            let mapData = chartDataSet[mtype]

            let transformedData = [];

            let l1 = groupData.level1
            let l2 = groupData.level2
            const VALUE = 30


            // grouping view data
            if (l1) {
                for (const key in mapData) {
                    if (mapData.hasOwnProperty(key)) {
                        const originalItem = mapData[key];
                        let userData = originalItem.data
                        let l1SegmentsList = [...new Set(userData.map(d => d['segment_data'][l1]))]
                        let [x1, y1] = setCoordinates(Object.keys(mapData).length, getKeyIndex(mapData, key))
                        const newItem = {
                            ...createNodeObject(key, VALUE, originalItem.icon, mapsColors[getKeyIndex(mapData, key)], null, userData.length),
                            x: x1,
                            y: y1,
                            children: l1SegmentsList.map(segment => {

                                let usersHaveL1 = userData.filter(d => d['segment_data'][l1] === segment)

                                let obj = createNodeObject(segment, VALUE, getSegmentIcon(l1, segment), '#ebebeb', null, usersHaveL1.length) // #FFBF69
                                let children;
                                let uniqueL2Segments = [...new Set(usersHaveL1.map(d => d['segment_data'][l2]))]

                                if (l2 === 'None' || l2 === '') {
                                    children = usersHaveL1.map(user => createNodeObject(user.project_user_full_name, VALUE, 'fa fa-user', addBgcolor(user.overall_sentiment_score), user.project_user_id))
                                }
                                else {
                                    children = uniqueL2Segments.map(segment2 => {
                                        let usersHaveL1L2 = usersHaveL1.filter(d => d['segment_data'][l2] === segment2)
                                        return {
                                            ...createNodeObject(segment2, VALUE, getSegmentIcon(l2, segment2), '#F4EAE6', null, usersHaveL1L2.length),
                                            children: usersHaveL1L2.map(user => createNodeObject(user.project_user_full_name, VALUE, 'fa fa-user', addBgcolor(user.overall_sentiment_score), user.project_user_id))
                                        }
                                    })
                                }
                                return {
                                    ...obj, children
                                }

                            })
                        }
                        transformedData.push(newItem);
                    }
                }
            }
            // Normal About Others view
            else {
                for (const key in mapData) {
                    let [x, y] = setCoordinates(Object.keys(mapData).length, getKeyIndex(mapData, key))
                    if (mapData.hasOwnProperty(key)) {
                        const originalItem = mapData[key];
                        const newItem = {
                            x: x,
                            y: y,
                            ...createNodeObject(key, VALUE, originalItem.icon, mapsColors[getKeyIndex(mapData, key)], null, originalItem.data.length),
                            children: originalItem.data.map(dataItem => ({
                                ...createNodeObject(dataItem.project_user_full_name, 30, dataItem.icon ? dataItem.icon : 'fa fa-user', addBgcolor(dataItem.overall_sentiment_score), dataItem.project_user_id),
                            }))
                        };
                        transformedData.push(newItem);
                    }
                }
            }

            setDataSet([{ ...baseData[0], children: transformedData }])

        }


    }, [chartDataSet, mapType, groupData])

    useEffect(() => {

        // select div to create chart
        let root = am5.Root.new("ao-chart-container");

        //add theme to chart
        root.setThemes([
            // am5themes_Animated.new(root)
            // am5hierarchy.DefaultTheme.new(root),
            am5themes_Responsive.newEmpty(root)
        ]);

        // append a zoomable container to div
        var container = root.container.children.push(
            am5.ZoomableContainer.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                // x: '50%',
                //             downDepth: 10,
                // topDepth: 1,
                // paddingBottom: 50,
                // paddingLeft: 50,
                // paddingRight: 50,
                // paddingTop: 50,
                wheelable: true,
                pinchZoom: true,
                layout: root.verticalLayout,
                // manyBodyStrength: 1,
                // centerStrength: 0.5,
                // linkWithStrength: 1,
                // manyBodyStrength: -10,
                // centerStrength: 0.8
            })
        );

        // add zooming tools
        container.children.push(am5.ZoomTools.new(root, {
            target: container
        }));

        const initialDepth = (groupData.level2 && groupData.level2 !== 'None') ? 3 : groupData.level1 ? 2 : 1
        // add heirarchy - Force Directed chart
        if (graphLayout == 'organic') {
            var series = container.contents.children.push(

                am5hierarchy.ForceDirected.new(root, {
                    singleBranchOnly: false,
                    initialDepth,
                    maskContent: false, //!important with zoomable containers

                    valueField: "value",
                    categoryField: "name",
                    childDataField: "children",
                    xField: "x",
                    yField: "y",
                    // // colorFields: "color",
                    minRadius: 35,
                    // // maxRadius: 20,
                    nodePadding: groupData.level1 ? -1 : 5,
                    // // linkWithField: "link",
                    // // idField: "name",
                    // animationDuration: 1,
                    // initialFrames: 100,
                    // showOnFrame: 100,
                    velocityDecay: groupData.level1 ? 0.1 : 0.1,
                    manyBodyStrength: groupData.level2 ? -10 : groupData.level1 ? -15 : -30,
                    centerStrength: groupData.level2 ? 0.2 : groupData.level1 ? 0.5 : 0.5,
                    // calculateAggregates: true
                    // showOnFrame: 100

                })
            );
        }
        else {
            var series = root.container.children.push(
                am5hierarchy.Tree.new(root, {
                    valueField: "value",
                    categoryField: "name",
                    childDataField: "children",
                    orientation: "vertical",
                    xField: "x",
                    yField: "y",

                    paddingBottom: 40,

                    paddingTop: 30,
                    // // colorFields: "color",
                    minRadius: 35,

                })
            );
        }




        // settings for node circle
        series.circles.template.setAll({
            templateField: "nodeSettings",
            fillOpacity: 0,
            // strokeWidth: 5,
            // strokeOpacity: 1
        });

        // settings for node outer circle
        series.outerCircles.template.setAll({
            templateField: "nodeOuterCircleSettings",
            strokeWidth: 0.5,
            strokeDasharray: 10,
            strokeOpacity: 0,
        });
        // series.outerCircles.template.states.create("hover", {
        //     strokeOpacity: 0.5,
        //     strokeDasharray: 0
        // });
        // series.outerCircles.template.states.create("hoverDisabled", {
        //     strokeOpacity: 0.5,
        //     strokeDasharray: 0
        // });

        // // to set node properties
        // series.nodes.template.setAll({
        //     draggable: true
        // });

        // set label property
        if (graphLayout == 'organic') {
            series.labels.template.setAll({
                fill: am5.color(0x000000),

                // ignoreFormatting: true,
                // isMeasured: false,
                lineHeight: am5.p100,
                // // height: am5.p100,
                // verticalScrollbar: null,
                // horizontalScrollbar: null,
            });
        }
        else {
            series.labels.template.setAll({
                fill: am5.color(0x000000),
                height: am5.p100,
                oversizedBehavior: "none",
                width: 50,
                height: 50,

                // ignoreFormatting: true,
                // isMeasured: false,

            });

        }

        // adding custom label
        if (graphLayout == 'organic') {
            series.labels.template.adapters.add("html", function (html, target) {

                let count = target?.parent?.dataItem?.dataContext?.count
                let countHtml = count !== undefined ?
                    ` <div 
                    style="position: absolute; top:0px; right:5px; 
                            display:flex; align-items:center; justify-content:center;
                            height: 15px; min-width: 15px; padding: 2px; width: fit-content; 
                            background-color:#000; color: #fff; font-size:10px;
                            border-radius:50%;
                            "
                >
                {count}
            </div>`
                    :
                    ''

                html = `
            <div className="node-wrapper" style="position:relative; align-items: center;width:60px;background-color: #fff; border-radius: 50%; text-align: center; display: flex ;justify-content: center; flex-direction:column">
                ${countHtml}
                <div className="node-icon-box" 
                    style="height:40px; width:40px; border-radius: 50%;padding: 8px 10px;display: flex;align-items: center;justify-content: center;
                        background-color: {bgcolor}"
                >
                    <i class="{icon}"></i>
                </div>
                <p style="font-size: 0.725rem;  ">{name}</p>
            </div>
            `

                return html;
            });
        }
        else {

            series.circles.template.setAll({
                radius: 40,
               
            });

            series.outerCircles.template.set("forceHidden", true);
            
            series.labels.template.adapters.add("html", function (html, target) {
 // var circle = target.dataItem.get("circle");
            // var radius = target.get("radius") + 10;
            // var width = target.width();
            // console.log(target?.parent?.dataItem?.get("circle").get('radius'), '{count}')
            // var radius = target?.dataItem?.get('circle')?.get('radius');
            
                let count = target?.parent?.dataItem?.dataContext?.count
                let countHtml = count !== undefined ?
                    ` <div 
                    style="position: absolute; top:0px; right:5px; 
                            display:flex; align-items:center; justify-content:center;
                            height: 15px; min-width: 15px; padding: 2px; width: fit-content; 
                            background-color:#000; color: #fff; font-size:10px;
                            border-radius:50%;
                            "
                >
                {count}
            </div>`
                    :
                    ''

                html = `
            <div className="node-wrapper" style="position:relative;height:100px background-color: #000; border-radius: 50%; text-align: center; display: flex;align-items: center;justify-content: center; flex-direction:column">
                ${countHtml}
                <div className="node-icon-box" 
                    style="height:40px; width:40px; border-radius: 50%;padding: 8px 10px;display: flex;align-items: center;justify-content: center;
                        background-color: {bgcolor}"
                >
                    <i class="{icon}"></i>
                </div>
                <p style="font-size: 0.725rem;">{name}</p>
            </div>
            `

            return html;
        });
    }


        series.nodes.template.events.on('click', function (ev) {
            clickHandler(ev.target.dataItem.dataContext.project_user_id)
            // console.log(ev.target.dataItem.dataContext)
        })

        //disable tooltip on hover
        series.set("tooltip", am5.Tooltip.new(root, {})).setAll({ forceHidden: true })

        // add data to chart
        series.data.setAll(dataSet)

        series.set("selectedDataItem", series.dataItems[0]);


        return () => {
            // remove chart
            root.dispose();
        };
    }, [projectId, args?.reloadMap, segmentData, chartDataSet, mapType, dataSet, graphLayout])


    return <div style={{ width: "100%", height: "calc(100vh - 188px)", overflow: 'hidden' }}>

        <div id="ao-chart-container" style={{ width: "100%", height: "calc(100vh - 188px)" }}></div>

    </div>
}

export default AMChartContainer