
import { useState, useEffect, useRef } from 'react'
import './index.scss'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Chips from '../Chips';


const removeItemOnce = (arr, value) => {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}


const SelectDropdownChips = ({
    label,
    optionList,
    valueField,
    itemField,
    handleOnChange,
    selectedList
}) => {

    const [selected, setSelected] = useState([])
    const [dropdownVisible, setDropdownVisibility] = useState(false)
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setDropdownVisibility(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        handleOnChange([...new Set([...selected])])
    }, [handleOnChange, selected]);

    const handleListItemClick = (e) => {
        setSelected(prevState => [...new Set([...prevState, e.target.value])])
    }
    
    useEffect(() => {
        if(selectedList){
            setSelected([...new Set([...selectedList])])
        }
    }, [selectedList]);

    return <div className='sdco-container' ref={ref}> {/* scdo: select dropdown chips options*/}
        <div className='sdco-box'>
            <div className='selected-chips'>
                {
                    selected.length ? 
                    optionList
                    .filter(opt => selected.includes(opt[valueField]))
                    .map(item => {
                        return <Chips
                            key={item[valueField]}
                            text={item[itemField]}
                            onCloseClick={() => {
                                let newSelected = removeItemOnce(selected, item[valueField])
                                setSelected([...new Set([...newSelected])])
                            }}
                        />
                    })
                    :
                    <div className='selected-placeholder' onClick={() => setDropdownVisibility(!dropdownVisible)}>
                        Select from Segments
                    </div>
                }
            </div>
            <div className='dropdown-action-btn' onClick={() => setDropdownVisibility(!dropdownVisible)}>
                    {
                        dropdownVisible ?
                            <ArrowDropUpIcon sx={{ color: '#000000' }} />
                            :
                            <ArrowDropDownIcon sx={{ color: '#000000' }} />

                    }
               
            </div>
        </div>
        {
            dropdownVisible && <div className='sdco-list-box'>
                <ul className='sdco-list'>
                    {
                        optionList.map(opt => {
                            return <li
                                key={opt[valueField]}
                                className={
                                    selected.includes(opt[valueField]) ?
                                        'sdco-list-item active'
                                        :
                                        'sdco-list-item'
                                }
                                value={opt[valueField]}
                                onClick={handleListItemClick}
                            >
                                {opt[itemField]}
                            </li>
                        })
                    }
                </ul>
            </div>
        }

    </div>
}

export default SelectDropdownChips;