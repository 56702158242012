import { useState } from 'react'
import './index.scss'
import EndUserSidebar from './EndUserSidebar'
import AdminTopBar from '../AdminTopBar'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'


const EndUserLayout = ({ children, ...args }) => {

    const [isSidebar, setIsSidebar] = useState(window.innerWidth > 880)

    const toggleSidebar = () => setIsSidebar(!isSidebar)
    const { currentProjectName } = useSelector(state => state.project)
    const location = useLocation()

    let hideProjectNameOnPageList = ['/app/my-projects']
    let projectName = !hideProjectNameOnPageList.includes(location.pathname) ? currentProjectName : ''

    return <div className='end-user-layout'>
        <div className='end-user-sidebar-box'>
            <EndUserSidebar isSidebarOpen={isSidebar} toggleSidebar={toggleSidebar} />
        </div>
        <div className={`end-user-content ${isSidebar ? 'sidebar-open' : 'sidebar-close'}`}>
            <div className='end-user-topbar-box'>
                <AdminTopBar
                    isSidebar={isSidebar}
                    toggleSidebar={toggleSidebar}
                    topBarTitle={args.topBarTitle}
                    setUserInfo={args.setUserInfo}
                    projectName={projectName}
                />
            </div>

            <main>
                {children}
            </main>
        </div>
    </div>
}


export default EndUserLayout