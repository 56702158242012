import { useEffect, useState } from "react";
import LabeledInput from "../../Elements/LabeledInput"
import SkipOptions from "./SkipOption";




const SliderQuestion = ({handleChange, ...args}) => {
    const [sliderLeftVal, setSliderLeftVal] = useState((args?.isEdit) ? args?.questionData?.slider_text_left: '')
    const [sliderRightVal, setSliderRightVal] = useState((args?.isEdit) ? args?.questionData?.slider_text_right: '')
    const [inputVal, setInputVal] = useState((args?.isEdit) ? args?.questionData?.comment_prompt: 'Write a comment to explain why you gave that score')
    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const { errField, errMsg } = err;
    
    const [isIncludedOnFeedbackSummary, setIsIncludedOnFeedbackSummary] = useState(args?.isEdit ? args.questionData.is_included_on_feedback_summary : true)
    
    const handleFeedbackSummary = (e) => {
        setIsIncludedOnFeedbackSummary(!isIncludedOnFeedbackSummary)
        handleChange(e)
    }
    
    const handleLeftValChange = (e) => {
        setSliderLeftVal(e.target.value)
        handleChange(e)
    }

    const handleRightValChange = (e) => {
        setSliderRightVal(e.target.value)
        handleChange(e)
    }

    const handleOnChange = (e) => {
        setInputVal(e.target.value)
        handleChange(e)
    }

    useEffect(() => {
        setErr(args?.err)
    }, [args?.err])
    
    return <div>
        <LabeledInput
            type='text'
            name='sliderLeft'
            label='Slider Left (Red)'
            value={sliderLeftVal}
            onChange={handleLeftValChange}
            error={errField === 'sliderLeft' && (errMsg)}
            required

        />
        <LabeledInput
            type='text'
            name='sliderRight'
            label='Slider Right (Green)'
            value={sliderRightVal}
            onChange={handleRightValChange}
            error={errField === 'sliderRight' && (errMsg)}
           required

        />
        {
            args.isAboutMe && <div className="checkbox-input-div">
                <label className="input-label">
                    Include this question's response on Feedback Summary.
                    <input
                        name="isIncludedOnFeedbackSummary"
                        type="checkbox"
                        checked={isIncludedOnFeedbackSummary}
                        onChange={handleFeedbackSummary}
                    />
                </label>
            </div>
        }
        <LabeledInput
            type='text'
            name='commentPrompt'
            label='Comment Prompt'
            value={inputVal}
            onChange={handleOnChange}
            error={errField === 'commentPrompt' && (errMsg)}
            placeholder='Write a comment here to explain why you gave that score.'
            textarea

        />
        <div>
            <SkipOptions handleOptionChange={args?.handleSkipOptionType} isEdit={args.isEdit} questionData={args.questionData}/>
        </div>

        
    </div>
}

export default SliderQuestion;