import { useEffect, useState } from "react";
import LabeledInput from "../../Elements/LabeledInput"
import SkipOptions from "./SkipOption";




const TextQuestion = ({ handleChange, ...args }) => {

    const [inputVal, setInputVal] = useState(args?.isEdit ? args?.questionData?.comment_prompt : 'Write a comment here to explain why you gave that score.')
    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const { errField, errMsg } = err;

    const handleOnChange = (e) => {
        setInputVal(e.target.value)
        handleChange(e)
    }
    
    const [isIncludedOnFeedbackSummary, setIsIncludedOnFeedbackSummary] = useState(args?.isEdit ? args.questionData.is_included_on_feedback_summary : true)

    const handleFeedbackSummary = (e) => {
        setIsIncludedOnFeedbackSummary(!isIncludedOnFeedbackSummary)
        handleChange(e)
    }

    useEffect(() => {
        setErr(args?.err)
    }, [args?.err])

    return <div>
        <LabeledInput
            type='text'
            name='commentPrompt'
            label='Comment Prompt'
            value={inputVal}
            onChange={handleOnChange}
            error={errField === 'commentPrompt' && (errMsg)}
            placeholder='Write a comment here to explain why you gave that score.'
            textarea
        />
        {
            args.isAboutMe && <div className="checkbox-input-div">
                <label className="input-label">
                    Include this question's response on Feedback Summary.
                    <input
                        name="isIncludedOnFeedbackSummary"
                        type="checkbox"
                        checked={isIncludedOnFeedbackSummary}
                        onChange={handleFeedbackSummary}
                    />
                </label>
            </div>
        }

        <div >
            <SkipOptions handleOptionChange={args?.handleSkipOptionType} isEdit={args.isEdit} questionData={args.questionData} />
        </div>
    </div>
}

export default TextQuestion;