import "./index.scss"
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { ApiClient } from '../../api/client';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function OverallTrend() {
  const [overallTrendData, setOverallTrendData] = useState({})
  const [mapData, setMapData] = useState({})
  const { projectId } = useSelector(state => state.project)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
          display: false,
      },
      tooltip: {
        usePointStyle: true,
        xAlign: 'center',
        yAlign: 'bottom',
        callbacks: {
          title : () => null,
          label: function(context) {
            let label = context.dataset.label || '';

            if (context.parsed.y !== null) {
                label += ' ' + context.label + ' : ' + context.parsed.y;
            }
            return label;
          },
          labelColor: function(context) {
            return {
                backgroundColor: 'rgb(64,64,65)',
            };
          },
        }
      }
    },
    scales: {
      x: {
          grid: {
              display: false,
          },
          ticks: {
              font: {
                  size: '10px',
              }
          },
      },
      y: {
          border: {
              display: false
          },
          min: 0,
          max: 10,
          ticks: {
            stepSize: 2
          }
      }
    }
  };

  useEffect(() => {
      if (projectId) {
          const client = ApiClient(setOverallTrendData)
          client.get('/api/v1/overall-trends/' + projectId + '/')
          .then((response) => {
              if (response?.data) {
                  setOverallTrendData(response.data)
              }
          })
      }
  }, [projectId])

  useEffect(() => {
    if(Object.keys(overallTrendData).length > 0){
      setMapData({
        labels: Object.keys(overallTrendData),
        datasets: [
          {
              clip: {left: false, top: false, right: false, bottom: false},
              data: overallTrendData,
              borderColor: 'rgb(64, 64, 65)',
              backgroundColor: 'rgb(242, 245, 249)',
              tension: 0.2,
              borderWidth: 2,
              pointBorderWidth: 2,
              pointRadius: 5,
              pointBackgroundColor: 'rgb(242, 245, 249)',
              pointBorderColor: 'rgb(64, 64, 65)',
          }
        ]
      })
    }
  }, [overallTrendData])

  return <div className='overall-trend-content'>
            <div className='overall-trend-header'>
                <span className='title' style={{color: '#212529', fontSize: '1.125rem', fontWeight: '700'}}>Overall Trends</span>
            </div>
            {Object.keys(mapData).length > 0 &&
              <div className='overall-trend-graph'>
                <Line options={options} data={mapData} />
              </div>
            }
        </div>
}
