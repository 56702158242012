
import './index.scss'
import permissions from './../../assets/svgs/permissions.svg'
import { BaseButton } from '../../Elements/Button'
import SelectDropdownChips from '../../Elements/SelectDropdownChips'
import SelectDropDown from '../../Elements/SelectDropDown'
import { useEffect, useState } from 'react'

const permissionOptionList = [
    {
        permissionValue: 'only_exclude',
        permissionText: 'Can View All Except',
    },
    {
        permissionValue: 'only_include',
        permissionText: 'Can View Only',
    }
]

const PermissionsPopup = ({ isEdit, handleConfirm, handleCancel, segments_list, ...args}) => {

    const [editForPermissionId, setEditForPermissionId] = useState()
    const [editPermission, setEditPermission] = useState([])
    const [editForPermission, setEditForPermission] = useState([])
    const [editForPermissionType, setEditForPermissionType] = useState('')

    useEffect(() => {
        if(args?.editPermission){
            setEditForPermissionId(args?.editPermission?.id)
            setEditPermission(args?.editPermission?.segment_group.map(obj => parseInt(obj.segment_id)))
            setEditForPermission(args?.editPermission?.viewed_segment_group.map(obj => parseInt(obj.segment_id)))
            setEditForPermissionType(args?.editPermission?.permission_type)
        }

    }, [args?.editPermission])

    return <div style={{ width: '100%' , flex: '1' }}>
                <ul className="progress">
                    <li className="progress__item progress__item--active">
                        <p className="progress__title"><b>Add Segment Condition <span style={{ color: 'red', fontSize: '0.75rem', 'verticalAlign': 'super'}}>*</span></b></p>
                        <p className="progress__info">
                            <SelectDropdownChips
                                label="Segment"
                                optionList={segments_list}
                                valueField="segmentId"
                                itemField="title"
                                handleOnChange={args?.setSegmentsForList}
                                selectedList={editPermission}
                            />
                        </p>
                        
                    </li>
                    <li className="progress__item progress__item--active">
                        <p className="progress__title"><b>Add Action</b></p>
                        <p className="progress__info">
                            <SelectDropDown
                                isRequired
                                optionList={permissionOptionList}
                                valueField='permissionValue'
                                itemField='permissionText'
                                defaultValue={(isEdit && editForPermissionType) ? editForPermissionType : permissionOptionList[0].permissionValue}
                                handleOnChange={args?.setPermissionType}
                            />
                        </p>
                    </li>
                    <li className="progress__item progress__item--active">
                        <p className="progress__title"><b>For Segment <span style={{ color: 'red', fontSize: '0.75rem', 'verticalAlign': 'super'}}>*</span></b></p>
                        <p className="progress__info">
                            <SelectDropdownChips
                                label="Segment"
                                optionList={segments_list}
                                valueField="segmentId"
                                itemField="title"
                                handleOnChange={args?.setViewedSegmentsList}
                                selectedList={editForPermission}
                            />
                        </p>
                    </li>
                </ul>
                {args?.errorTxt &&
                    <p className="lsection-description" style={{ color: 'red' }}>
                        {args?.errorTxt}
                    </p>
                }
                <hr style={{'margin' : '15px 0px'}}></hr>
                <BaseButton
                    className="submit-btn"
                    text={isEdit ? "Update" : "Create"}
                    variant="filled"
                    onClick={isEdit ? () => handleConfirm(editForPermissionId) : handleConfirm}
                />
                <BaseButton
                    className="submit-btn cancel-btn"
                    text="Cancel"
                    variant="filled"
                    onClick={handleCancel}
                />
            </div>
}

export default PermissionsPopup