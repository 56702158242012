
const PASSWORD_LENGTH = 8;

export const PwdValidator = (pwd) => {
    if (!(pwd.length > PASSWORD_LENGTH)) {
        return 'Password length must be greater than ' + PASSWORD_LENGTH + ' characters.'
    }
    if (pwd.substring(0, 1) !== pwd.substring(0, 1).toUpperCase()) {
        return 'First letter of the password should be capital.'
    }
    if (!(pwd.match(/[a-z]/g))) {
        return 'Password must contain a Lowercase Letter.'
    }
    if (!(pwd.match(/[A-Z]/g))) {
        return 'Password must contain a Uppercase Letter.'
    }
    if (!(pwd.match(/[0-9]/g))) {
        return 'Password must contain a Numeric.'
    }
    if (!(pwd.match(/(?=.*?[\W+])/g))) {
        return 'Password must contain a special character.'
    }
}

export const CnfPwdValidator = (pwd, cnfPwd) => {
    if(pwd !== cnfPwd){
        return 'Passwords do not match'
    }
}

export const NameValidator = (string) =>{
    let nameRegex = /^[A-Za-z'.-\s]+$/
    if (!string.trim().length){
        return 'Field can\'t be empty.'
    }
    if(!string.match(nameRegex)){
        return 'Field should have only Alphabets.'
    }
}

export const AlphaNumericValidator = (string) => {
    const alpha_numeric_regex = /^[\w '.-]+$/
    if(!string.match(alpha_numeric_regex)){
        return 'Field should not have Special Characters'
    }
}

export const EmptyStringValidator = (string) => {
    if (!string.trim().length){
        return 'Field can\'t be empty.'
    }
}


export const EMAIL = 'Email'
export const PASSWORD = 'Password'
export const CNF_PASSWORD = 'Confirm Password'
export const FIRST_NAME = 'First Name'
export const LAST_NAME = 'Last Name'
export const ORGANISATION = 'Organisation'
export const PHONE = 'Phone'
