import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import { ApiClient } from '../../api/client';
import FlagResponseTable from '../../components/FlagResponseTable';
import Layout from '../../components/Layout';
import './index.scss';




const FlaggedResponse = () => {

    const client = ApiClient()

    const navigate = useNavigate()
    const { projectId } = useSelector(state => state.project)

    const [flagData, setFlagData] = useState([])
    const [queryStatus, setQueryStatus] = useState('')


    useEffect(() => {
        setQueryStatus('STARTED')
        client
            .get(`/api/v1/flag-responses/${projectId}/`)
            .then(res => {
                if (res?.results) {
                    setFlagData(res.results)
                }
                setQueryStatus('COMPLETED')
            })
    }, [])


    return <Layout
        active_page={'Flagged Responses'}
        onClickNext={() => { }}
        onClickBack={() => navigate('/setup/permissions')}
        isNextDisabled={true}
        onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#FlaggedResponses','_blank')}
        isNextShown={false}
    >
        <div className="flag-response-container">
            <div className="content">
                <div className="heading">
                    <h1>Flagged responses</h1>
                    <p className='flag-response-description'>
                        These responses have been flagged for moderation. Please review and confirm responses are constructive, maintain anonymity and comply with applicable policies
                    </p>
                </div>
                <div className="flag-response-table">
                    <FlagResponseTable
                        flagData={flagData}
                        queryStatus={queryStatus}
                        setFlagData={setFlagData}
                    />
                </div>
            </div>
        </div>
    </Layout>
}


export default FlaggedResponse;