import './index.scss';
import logo from '../../assets/images/project-ai-logo-light.png';
import { Fragment, useState } from 'react';
import { CNF_PASSWORD, CnfPwdValidator, PASSWORD, PwdValidator } from '../Register/utility';
import LabeledInput from '../../Elements/LabeledInput';
import { useParams } from 'react-router-dom';
import { BaseButton } from '../../Elements/Button';
import axiosInstance from '../../api/axios';
import { InfoText } from '../../Elements/MessageBlocks';
import { Link } from '@mui/material';
import { scrollToBottom } from '../../utilities';


const PasswordResetConfirm = () => {
    const formValidator = (form_data) => {
        let validate_password = PwdValidator(form_data.password)
        if (validate_password) {
            return {
                field: PASSWORD,
                err: validate_password
            }
        }
        let validate_cnf_password = CnfPwdValidator(form_data.password, form_data.confirmPassword)
        if (validate_cnf_password) {
            return {
                field: CNF_PASSWORD,
                err: validate_cnf_password
            }
        }
    }

    const formInitialState = {
        password: '',
        confirmPassword: '',
    }
    const [showHeader, setShowHeader] = useState(true);
    const [showCircularProgress, setShowCircularProgress] = useState(false);
    const [iconType, setIconType] = useState();

    const [formData, setFormData] = useState(formInitialState)
    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })

    const { errField, errMsg } = err;
    const {password, confirmPassword } = formData;

    const handleInputChange = (e) => {
        setErr({ ...err, ...{ errField: '', errMsg: '' } })
        setFormData(prevState => (
            { ...prevState, [e.target.name]: e.target.value }
        ))
    }

    const clearFormState = () => {
        setFormData({ ...formInitialState });
    };

    let inputs_arr = [
        { type: 'password', name: 'password', label: PASSWORD, value: password },
        { type: 'password', name: 'confirmPassword', label: CNF_PASSWORD, value: confirmPassword },
    ]

    const params = useParams();

    const [pageContent, setPageContent] = useState();

    const handleSubmit = (e) => {
        let error = formValidator(formData)
        if (error &&
            'uid' in params && 'token' in params) {
            setErr({ ...err, ...{ errField: error['field'], errMsg: error['err'] } })
        }
        else {
            setShowCircularProgress(true)
            axiosInstance.post('api/v1/auth/users/reset_password_confirm/', {
                uid : params.uid,
                token : params.token,
                new_password : formData.password,
                re_password : formData.confirmPassword,
            }).then((res) => {
                setPageContent('Your password has been reset successfully. You can start using Pulse app by logging in using your credentials')
                setIconType('fa-regular fa-circle-check password-reset-status-icon password-reset-success-status-icon')
                setShowCircularProgress(false)
                setShowHeader(false)
            }).catch((error) => {
                Object.keys(error.response.data).map((key) => {
                    setPageContent(error.response.data[key])
                    return true;
                })
                setIconType('fa-regular fa-circle-xmark password-reset-status-icon password-reset-fail-status-icon')
                setShowCircularProgress(false)
                setShowHeader(false)
            })
        }
    }

    const RenderConfirmButton = (e) => {
        if(!pageContent){
            return (
            <div style={{ textAlign: 'center' }}>
                <BaseButton
                    className="submit-btn"
                    text="Reset Password"
                    variant="filled"
                    onClick={handleSubmit}
                    showCircularProgress={showCircularProgress}
                />
            </div>
            )
        } 
    }

    return <div className="register-section">
        <div className="left-section" >
            <div className="logo">
                <img src={logo} width={100} height={50} alt="logo" />
            </div>
            <div className='headline'>
                <span>Successful projects start with people</span>
            </div>
            {
                window.innerWidth < 769 && <> <div className='heading-text-sm'>
                    <h1>Collective Insights</h1>
                    <span>
                        Pulse by ProjectAI helps project managers make <br />
                        sense of people’s collective insights for better project outcomes.
                    </span>
                </div>
                    <div className='scroll-to-bottom-icon' onClick={scrollToBottom}>
                        <i className="fa-solid fa-chevron-down"></i>
                    </div>
                </>
            }
        </div>
        <div className='right-section'>
            <div>
                <div className='heading'>
                    <i className={iconType}></i>
                    {showHeader &&
                        <div>
                            <h1>Reset account password!</h1>
                            <span>Strong passwords include letters, numbers and punctuation marks.</span>
                        </div>
                    }
                </div>
                <div className='form-container'>
                    <form>
                        {!pageContent &&
                            inputs_arr.map(inp => {
                                return <Fragment key={inp.name}>
                                    <LabeledInput
                                        type={inp.type}
                                        name={inp.name}
                                        label={inp.label}
                                        value={inp.value}
                                        onChange={handleInputChange}
                                        error={errField === inp.label && errMsg}
                                    />
                                </Fragment>
                            })
                        }
                          <InfoText messageText={<b style={{fontWeight : 'normal'}}>Click <Link href="/login">here</Link> to login</b>} variant='h6' color='#000000'></InfoText>
                        <RenderConfirmButton/>
                    </form>
                </div>
                <InfoText messageText={pageContent} variant='h6' color='#000000'></InfoText>
              
            </div>
        </div>
    </div>
}

export default PasswordResetConfirm;