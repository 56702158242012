import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './index.scss'
import Layout from "../../components/Layout";
import SegmentType from '../../components/segmentType/';
import Modal from '../../components/Modal';
import Popup from './Popup';
import { ApiClient } from '../../api/client';
import { useSelector, useDispatch } from 'react-redux';
import { projectAction } from '../../redux/actions';


const Segments = () => {

    const navigate = useNavigate()

    const { state } = useLocation();
    const { projectId } = useSelector(state => state.project)
    const dispatch = useDispatch()

    const [searchParams, setSearchParams] = useSearchParams()
    const [activeSegmentType, setActiveSegmentType] = useState()
    const [atLeastOneChecked, setAtLeastOneChecked] = useState(true)

    const [segmentObj, setSegmentObj] = useState()

    const [showAddSegmentModal, setAddSegmentModal] = useState(false)
    const [editSegment, setEditSegment] = useState({
        showModal: false,
        editSegmentData: {}
    })

    const [segmentData, setSegmentData] = useState();
    const [segmentTypeIdArray, setSegmentTypeIdArray] = useState();

    useEffect(() => {
        if (!projectId) {
            if (state?.projectId) {
                dispatch(projectAction(state?.projectId))
            }
            else if (searchParams.get('projectId')) {
                dispatch(projectAction(searchParams.get('projectId')))
            }
        }
    }, [projectId])

    useEffect(() => {
        if (projectId) {
            const client = ApiClient(setSegmentData)
            client.get('/api/v1/segments/' + projectId + '/').then((response) => {
                if (response?.data) {
                    setSegmentData(response.data)
                    setActiveSegmentType(response.data?.[0].segmentTypeId)
                }
            })
        }
    }, [projectId])

    useEffect(() => {
        setSegmentObj(prevState => (
            {
                ...prevState, segmentData
            }
        ))
    }, [segmentData])

    useEffect(() => {
        if (segmentObj?.segmentData) {
            setAtLeastOneChecked(segmentObj?.segmentData.filter(segType => (
                segType.segmentTypeId === activeSegmentType
            ))[0]['segmentsList'].map(data => (
                data.isChecked)
            ).includes(true))
            setSegmentTypeIdArray(segmentObj?.segmentData.map(item => item.segmentTypeId))
        }
    }, [segmentObj, activeSegmentType])

    useEffect(() => {
        if (segmentTypeIdArray && activeSegmentType && segmentTypeIdArray.indexOf(activeSegmentType) === 0) {
            setActiveSegmentType(segmentTypeIdArray[0])
        }
    }, [segmentTypeIdArray, activeSegmentType])

    const nextBtnConditions = () => {
        const client = ApiClient()
        if (activeSegmentType) {
            saveSegmentInfo(segmentObj)
            if (segmentTypeIdArray?.[segmentTypeIdArray.indexOf(activeSegmentType) + 1]) {
                return setActiveSegmentType(segmentTypeIdArray[segmentTypeIdArray.indexOf(activeSegmentType) + 1])
            }
            client.post('api/v1/journeysteps/', {
                project: projectId,
                step_id: 2,
                step_name: 'Segments'
            }).then((res) => {

            }).catch((error) => {

            })


            return navigate({
                pathname: '/setup/drivers',
                search: '?projectId=' + projectId
            }, { state: { projectId: projectId } })
        }
    }
    const backBtnConditions = () => {
        if (activeSegmentType) {
            if (segmentTypeIdArray?.[segmentTypeIdArray.indexOf(activeSegmentType) - 1]) {
                return setActiveSegmentType(segmentTypeIdArray[segmentTypeIdArray.indexOf(activeSegmentType) - 1])
            }

            return navigate({
                pathname: '/setup/project-attributes',
                search: '?projectId=' + projectId
            }, { state: { projectId: projectId } })
        }
    }

    const handleSegmentType = (seg) => {
        if (atLeastOneChecked) {
            setActiveSegmentType(seg)
        }
    }

    const saveSegmentInfo = (segmentData, extra_params = '') => {
        const client = ApiClient(setSegmentData)
        if (projectId) {
            const apiUrl = '/api/v1/segments/' + projectId + '/' + extra_params
            client.put(apiUrl, {
                data: segmentData
            }).then((res) => {
                if (res?.data) {
                    setSegmentData(res.data)
                }
            }).catch((error) => {

            })
        }
    }

    const handleSegmentTypeChange = (updatedSegmentType) => {
        if (updatedSegmentType.segmentTypeName) {
            let changedSegmentType = segmentObj.segmentData.filter(seg => {
                return seg.segmentTypeName.toLowerCase().trim() === updatedSegmentType.segmentTypeName.toLowerCase().trim()
            })

            if (changedSegmentType.length > 0) {
                return 'DUPLICATE_ERROR'
            }
        }
            saveSegmentInfo({ 'segmentData': [updatedSegmentType] }, '?check=SEGMENT_TYPE')
            return 'Changed'
    }


    const handleSegmentChange = (segment) => {
        setEditSegment({
            showModal: true,
            editSegmentData: segment
        })
    }



    return <Layout
        active_page={'Segments'}
        onClickNext={() => nextBtnConditions()}
        onClickBack={() => backBtnConditions()}
        onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#SegmentOverview','_blank')}
        isNextDisabled={!atLeastOneChecked}
    >
        <div className='segment-section'>
            <div className='segments-container'>
                <SegmentBar
                    segmentTypeList={segmentData}
                    activeSegmentType={activeSegmentType}
                    onClick={handleSegmentType}
                />

                {(segmentObj && segmentTypeIdArray && activeSegmentType) &&
                    <SegmentType
                        active_segment_data={segmentObj.segmentData.filter(seg => seg.segmentTypeId === activeSegmentType)}
                        setSegmentChange={setSegmentObj}
                        handleAddSegmentModal={() => setAddSegmentModal(!showAddSegmentModal)}
                        handleSegmentTypeChange={handleSegmentTypeChange}
                        handleSegmentChange={handleSegmentChange}

                    />
                }
            </div>
        </div>
        {
            showAddSegmentModal && <Modal
                modalClassName={'add-segment-modal-wrapper'}
                handleModal={setAddSegmentModal}
                modal_content={
                    <Popup
                        hasDescription={activeSegmentType === segmentData?.[0].segmentTypeId}
                        activeSegmentTypeName={segmentData?.filter(seg => seg.segmentTypeId === activeSegmentType)[0].segmentTypeName}
                        projectId={projectId}
                        setSegmentData={setSegmentData}
                        setAddSegmentModal={setAddSegmentModal}
                        activeSegmentType={activeSegmentType}
                    />
                }

            />
        }
        {
            editSegment.showModal && <Modal
                modalClassName={'add-segment-modal-wrapper'}
                handleModal={() => setEditSegment({ showModal: false, editSegmentData: {} })}
                modal_content={
                    <Popup
                        hasDescription={activeSegmentType === segmentData?.[0].segmentTypeId}
                        activeSegmentTypeName={segmentData?.filter(seg => seg.segmentTypeId === activeSegmentType)[0].segmentTypeName}
                        projectId={projectId}
                        setSegmentData={setSegmentData}
                        isSegmentEdit={true}
                        setAddSegmentModal={() => setEditSegment({ showModal: false, editSegmentData: {} })}
                        activeSegmentType={activeSegmentType}
                        editSegmentData={editSegment.editSegmentData}
                    />
                }

            />
        }

    </Layout>
}

export default Segments;


const SegmentBar = ({ segmentTypeList, activeSegmentType, onClick }) => {

    if (segmentTypeList) {
        return <div className='segment-topbar'>
            {
                segmentTypeList.map(segment => {
                    return <div
                        key={segment.segmentTypeId}
                        className={`segment-topbar-box ${segment.segmentTypeId === activeSegmentType ? 'active' : ''} ${segment.segmentTypeName}`}
                        onClick={() => onClick(segment.segmentTypeId)}
                    >
                        <span>{segment.segmentTypeName}</span>
                    </div>
                })
            }

        </div>
    }

}
