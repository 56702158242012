
import './index.scss'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Tooltip } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



const LabeledInput = ({ type, name, label, value, onChange, required = true, ...args }) => {

    const [pwdVisible, setPwdVisible] = useState(false)

    return <div className="labeled-input-div">
        {
           label && <div>
            <label className="input-label" htmlFor={name}>
                <div>
                    {label}
                    {required && <span style={{ color: 'red', fontSize: '0.75rem' }}>*</span>}
                    {args.isOptional && <span style={{ fontWeight: 500, fontSize: '0.75rem' }}> (Optional)</span>}
                </div>
                {    
                    (name === 'password' && !args.isLoginPage) && <span style={{ flexGrow: 1, textAlign: 'right' }}>
                        <Tooltip
                            title="Hint: The password should be at least eight characters long. To make it stronger, use upper and lower case letters min 5, numbers, and symbols like !@#$%^&*()_+\-=\[\]{};':\|,.<>\/?"
                            placement="top-end"
                            arrows
                        >
                            <InfoOutlinedIcon color='primary' fontSize='100px' />
                        </Tooltip>
                    </span>
                }
            </label>
        </div>
        }
        
        <div className={`input-div ${args.error? "error" : ""} ${args.isUnderlined ? "underlined": ""}`}>
            {
                args.isPhone ?
                    <PhoneInput
                        className="phoneinput"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="AU"
                        value={value}
                        onChange={onChange}
                        required={false}
                    />
                    :
                    args.textarea ?
                        <textarea
                            className={args.error ? 'base-input error' : 'base-input'}
                            id={name}
                            name={name}
                            value={value}
                            onChange={onChange}
                            autoFocus={args.error}
                            required={required}
                            placeholder={args.placeholder && args.placeholder}
                        />
                        :
                        <input
                            className={args.error ? 'base-input error' : 'base-input'}
                            id={name}
                            type={pwdVisible ? 'text' : type}
                            name={name}
                            value={value}
                            onChange={onChange}
                            autoFocus={args.error}
                            disabled={args.isDisabled}
                            required={required}
                            placeholder={args.placeholder && args.placeholder}
                            autoComplete="on"
                            onKeyDown={args.onKeyDown && args.onKeyDown}
                            min={args.min}
                            max={args.max}
                        />
            }

            {type === 'password' && <span
                onClick={() => setPwdVisible(!pwdVisible)}
                className='pwd-eye'
            >
                {pwdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}

            </span>

            }
        </div>

        {args.error && <p style={{ color: 'red', fontSize: '0.75rem' }}>*{args.error}</p>}

    </div>
}

export default LabeledInput;