import { useEffect, useState } from 'react'
import './index.scss'
import { BaseButton, SelectableButton } from '../../../Elements/Button'
import { DebounceInput } from 'react-debounce-input'
import { DEBOUNCE_DELAY } from '../../../utilities/constants'

const CommentSkipOptionList = ({
    commentValue,
    commentPrompt,
    skipValue,
    onSkip,
    skipOptionsList,
    onComment
}) => {
    const [skipToggle, setSkipToggle] = useState(skipValue ? true : false)
    const [commentToggle, setCommentToggle] = useState(commentValue ? true : false)
    const [skipOption, setSkipOtion] = useState('')
    const [comment, setComment] = useState('')
    const [commentError, setCommentError] = useState(false)

    useEffect(() => {
        setSkipOtion(skipValue)
    }, [skipValue])

    useEffect(() => {
        setComment(commentValue)
    }, [commentValue])

    return (
        <div className="question-skip-comment">
            <div className="action-btns">
                <div className='comment-action-btn'>
                    <BaseButton
                        className={`toggle-btn ${commentToggle ? 'hide-comment-btn' : 'show-comment-btn'} `}
                        text={
                            commentToggle ?
                                <span>Hide Comment</span>
                                :
                                <span className='comment-text'><i className="fa-regular fa-comment" /> Comment</span>
                        }
                        onClick={() => setCommentToggle(!commentToggle)}

                    />
                </div>
                <div className='separator' />
                <div className='skip-action-btn'>
                    {
                        skipToggle ?
                            <>
                                Why are you skipping this question?{" "}
                                <BaseButton
                                    className={'toggle-btn hide-skip-btn'}
                                    text={<span>Cancel Skip</span>}
                                    onClick={() => setSkipToggle(!skipToggle)}

                                />
                            </>

                            :
                            <BaseButton
                                className={'toggle-btn show-skip-btn'}
                                text={<span>Skip</span>}
                                onClick={() => setSkipToggle(!skipToggle)}
                            />

                    }
                </div>
            </div>
            {skipToggle && skipOptionsList?.length > 0 && (
                <div className="skip-btns">
                    {skipOptionsList.map((item, index) => {
                        const selected = item.skip_option_name === skipOption;
                        return (
                            <SelectableButton
                                className="selectable-skip-btn"
                                key={item.id}
                                selected={selected}
                                onClick={() => {
                                    setSkipOtion(item.skip_option_name)
                                    onSkip(item.skip_option_name)
                                }}
                                text={item.skip_option_name}
                            />
                        );
                    })}
                </div>
            )}
            {commentToggle && (
                <div className="comment-section">
                    <DebounceInput
                        id="comment"
                        element={'input'}
                        className="answer-base-input comment-input"
                        debounceTimeout={DEBOUNCE_DELAY}
                        value={comment}
                        onChange={(e) => {
                            setCommentError(false)
                            setComment(e.target.value)
                            if ((e.target.value).length > 1000) {
                                setCommentError(true)
                            }
                            else {
                                onComment(e.target.value)
                            }
                        }}
                        placeholder={commentPrompt ? commentPrompt : "Write here why you gave this answer."}
                    />
                    {
                        commentError && <p style={{ fontSize: 12, color: '#ff0000' }}>*Comment should be less than 1000 characters long.</p>
                    }
                </div>
            )}
        </div>
    );
}

export default CommentSkipOptionList;