import './index.scss';
import Popover from '@mui/material/Popover';
import { gear_solid } from '../../assets/svgs/dashboard';
import { BaseButton } from '../../Elements/Button';
import StickyHeadTable from './TableComponent';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRounded from '@mui/icons-material/SearchRounded';

export default function SubdriverPopover({subdriverData, handleSave, ...args}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchedVal, setSearchedVal] = useState("");
  const [orderedSubdriverData, setOrderSubdriverData] = useState(subdriverData)

  useEffect(() => {
    setOrderSubdriverData(subdriverData)
  }, [subdriverData])

  const columns =  [
    { id: 'order', label: 'Reorder', minWidth: 50},
    { id: 'driver', label: 'Driver'},
    { id: 'sub_driver', label: 'Subdriver'},
    args.page === 'advisor-insights' && { id: 'bounds', label: 'Data Type' },
    { id: 'view', label: 'View'},
  ]

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePostSave = (subdriverData) => {
    handleSave(subdriverData)
    setAnchorEl(null);
    setSearchedVal('')
  };

  const handleClose = () => {
    setSearchedVal("");
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'subdriver-popover' : undefined;

  const handleOnDragEnd = (result) => {
    if (!result?.destination) return;
    let src = result.source.index
    let dest = result.destination.index
    if (src === dest) return;

    let items = Array.from(orderedSubdriverData);
    const [reorderedItem] = items.splice(src, 1);
    items.splice(dest, 0, reorderedItem);

    let OrderedItems = items.map((item, idx) => {
      item['order'] = idx
      return item
    })
    setOrderSubdriverData(OrderedItems)
  }

  return (
    <div className='subdriver-popup'>
      <BaseButton
            aria-describedby={id}
            variant='text'
            text={<img src={gear_solid} alt="Edit" width={17} />}
            className="search-btn"
            onClick={handleClick}
        />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
      >
        <div className='popover-content'>
          <div className='popover-content-header'>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Subdriver"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchedVal(e.target.value)}
              variant="standard"
            />
            <BaseButton
              variant='text'
              text='Save'
              size='small'
              className="submit-btn"
              onClick={() => {handlePostSave(orderedSubdriverData)}}
            />
          </div>
          <StickyHeadTable columns={columns} rows={orderedSubdriverData} searchedVal={searchedVal} disabledList={args?.disabledList} page={args.page} handleOnDragEnd={handleOnDragEnd}/>
        </div>
      </Popover>
    </div>
  );
}
