import './index.scss'
import { useEffect, useState } from "react";
import aboutme from "./../../assets/svgs/aboutme.svg"
import { BaseButton } from "../../Elements/Button";
import LabeledInput from "../../Elements/LabeledInput";
import SelectDropDown from "./../../Elements/SelectDropDown";
import { ApiClient } from '../../api/client';


const UserPopup = ({
    isEdit,
    handleCancel,
    userData,
    segmentsData,
    projectId,
    setUserResponse,
    activeUserTypeQueryParams
}) => {

    const [formData, setFormData] = useState({
        first_name: isEdit ? userData.first_name : '',
        last_name: isEdit ? userData.last_name : '',
        projectUserTitle: isEdit ? userData.project_user_title: '',
        email: isEdit ? userData.email : '',
    })

    const [segmentsFields, setSegmentFields] = useState([])

    const {
        first_name,
        last_name,
        projectUserTitle,
        email,
    } = formData


    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const { errField, errMsg } = err

    useEffect(() => {
        let seg_arr = []

        let has_seg_user_data = userData?.['segment_user_data'].length > 0

        segmentsData.length > 0 &&
            segmentsData?.forEach(sd => {
                let seg = userData?.['segment_user_data'].filter(item => item['segment_type'] === sd['segmentTypeId'])
                let tempObj = {
                    segment_id: (has_seg_user_data && seg.length > 0) ? seg[0]['segment_id'] : '',
                    segment_type_id: sd['segmentTypeId']
                }
                seg_arr.push(tempObj)
            });

        setSegmentFields(seg_arr)
    }, [])

    const handleForm = (e) => {
        setErr({ errField: '', errMsg: '' })
        setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }
    const handleSelectSegments = (key, value) => {
        setErr({ errField: '', errMsg: '' })
        setSegmentFields(ps => ps.map(item => {
            item.segment_id = item.segment_type_id === key ? parseInt(value) : item.segment_id
            return item
        }))
    }

    const userErrHandling = (errorData) => {
        let errors = errorData?.response?.data?.errors
        let errKeys = Object.keys(errors)
        if (errKeys.length) {
            if (errKeys.includes('first_name')) {
                setErr({ errField: 'first_name', errMsg: errors['first_name'][0] })
            }
            else if (errKeys.includes('last_name')) {
                setErr({ errField: 'last_name', errMsg: errors['last_name'][0] })
            }
            else if (errKeys.includes('email')) {
                setErr({ errField: 'email', errMsg: errors['email'][0] })
            }
            else if (errKeys.includes('non_field_errors') && errors['non_field_errors'].length) {
                if (errors['non_field_errors'].includes("The fields project, user must make a unique set.")) {
                    setErr({ errField: 'email', errMsg: 'Email address already exists in current project.' })
                }
                else {
                    // console.log(errors['non_field_errors'])
                }
            }
        }
    }

    const handleSubmit = () => {
        for (let key in formData) {
            if (formData[key] === '' && key !== 'projectUserTitle') {
                setErr({ errField: key, errMsg: 'This field is required.' })
                return;
            }
        }

        for (let obj of segmentsFields) {
            if (obj.segment_id === ''){
                setErr({ errField: obj.segment_type_id, errMsg: '' })
                return;
            }
        }

        const client = ApiClient()
        if (projectId) {
            const apiUrl = '/api/v1/project-user/' + projectId + '/?' + activeUserTypeQueryParams
            let data = {
                'project_id': projectId,
                'user_data': {
                    first_name: first_name,
                    last_name: last_name,
                    email: email.toLowerCase()
                },
                'project_user_data': {
                    project_user_title: projectUserTitle,
                },
                'segment_user_data': segmentsFields.map(item => {
                    return { 'segment': item.segment_id, 'segment_type': item.segment_type_id }
                })
            }

            if (isEdit) {
                let has_seg_user_data = userData?.['segment_user_data'].length > 0
                data['project_user_id'] = userData.id
                data['segment_user_data'] = data['segment_user_data'].map(item => {
                    let seg = userData['segment_user_data'].filter(su => su['segment_type'] === item['segment_type'])
                    item['segment_user_id'] = (has_seg_user_data && seg.length > 0) ? seg[0]['segment_user_id'] : ''
                    delete item['segment_type']
                    return item
                })
                client.put(apiUrl, data).then((response) => {
                    if (response?.data) {
                        setUserResponse(prevState => ({
                            ...prevState,
                            users: response.data,
                            nextLink: response.next,
                            prevLink: response.prev,
                            usersCount: response.count
                        }))
                        handleCancel(false)
                    }
                }).catch((error) => {
                    userErrHandling(error)
                })
            }
            else {
                data['project_user_data']['project_user_role_desc'] = ""
                data['project_user_data']['invite_sent'] = false
                data['project_user_data']['email_sent'] = false
                data['project_user_data']['project_admin'] = false
                data['project_user_data']['is_archived'] = false
                data['segment_user_data'] = data['segment_user_data'].map(item => {
                    delete item['segment_type']
                    return item
                })

                client.post(apiUrl, data).then((response) => {
                    if (response?.data) {
                        setUserResponse(prevState => ({
                            ...prevState,
                            users: response.data,
                            nextLink: response.next,
                            prevLink: response.prev,
                            usersCount: response.count
                        }))
                        handleCancel(false)
                    }
                }).catch((error) => {
                    userErrHandling(error)
                })
            }
        }
    }

    return <div className="new-user-container">
        <div className='header-div'>
            <div className='popup-icon-div'>
                <img src={aboutme} alt="about-me" />
            </div>
            <h3 className='title'>{isEdit ? 'Edit' : 'Add New'} User</h3>
        </div>


        <div className='new-user-box'>
            <div className='left-section'>
                <LabeledInput
                    type='text'
                    name='first_name'
                    label='First Name'
                    value={first_name}
                    onChange={handleForm}
                    error={errField === 'first_name' && errMsg}
                    required
                />
                <LabeledInput
                    type='text'
                    name='last_name'
                    label='Last Name'
                    value={last_name}
                    onChange={handleForm}
                    error={errField === 'last_name' && errMsg}
                    required
                />
                <LabeledInput
                    type='email'
                    name='email'
                    label='Email'
                    value={email}
                    onChange={handleForm}
                    error={errField === 'email' && errMsg}
                    required
                    isDisabled={isEdit}

                />
                <LabeledInput
                    type='text'
                    name='projectUserTitle'
                    label='Project Title'
                    value={projectUserTitle}
                    onChange={handleForm}
                    error={false}
                    required={false}
                    placeholder="E.g. Project Manager, Technical Engineer"
                />
            </div>
            <div className='right-section'>
                {
                    segmentsFields.length > 0 && segmentsData.sort((a, b) => a.segmentTypeId - b.segmentTypeId).map(
                        (sdi, idx) => {

                            return <SelectDropDown
                                key={sdi.segmentTypeId}
                                title={sdi.segmentTypeId}
                                errorTitle={errField}
                                defaultOptionText={"Select Segment"}
                                label={sdi.segmentTypeName}
                                isRequired
                                optionList={sdi.segmentsList.filter(seg => seg.isChecked === true)}
                                handleOnChange={(value) => handleSelectSegments(sdi.segmentTypeId, value)}
                                valueField='segmentId'
                                itemField='title'
                                defaultValue={isEdit && segmentsFields.filter(sf => sf.segment_type_id === sdi.segmentTypeId)[0].segment_id}
                                defaultOptionSelected={false}
                            />
                        })
                }
            </div>
        </div>

        <div className='modal-action-button-section'>
            <BaseButton
                text="Cancel"
                className='add-user-cancel-btn modal-cancel-btn'
                onClick={() => handleCancel(false)}
            />
            <BaseButton
                variant='contained'
                text={isEdit ? "Update" : "Add"}
                className='add-user-submit-btn modal-submit-btn'
                onClick={handleSubmit}
            />
        </div>
    </div>
}

export default UserPopup;