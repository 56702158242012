import './index.scss';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiClient } from '../../api/client';

const TopPositiveNegativeComponent = ({...props}) => {
    const [positiveNegativeData, setPositiveNegativeData] = useState({})
    const { projectId, projectUserId, currentProjectName } = useSelector(state => state.project)

    useEffect(() => {
        if (projectId) {
            const client = ApiClient(setPositiveNegativeData)
            client.get('/api/v1/top-positive-negative/' + projectId + '/')
            .then((response) => {
                if (response?.data) {
                    setPositiveNegativeData(response.data)
                }
            })
        }
    }, [projectId])

    return <div className='positive-negative-list'>
            {Object.keys(positiveNegativeData).length > 0 &&
                <>
                <div className='positive-list'>
                    <p>Top Positive</p>
                {
                    positiveNegativeData['top_positive'].map((value) => {
                        return <Typography component="div" fontWeight="bold" mt={1} fontSize={15} variant="p" color="text.primary">{value.sub_driver}</Typography>
                    })
                }
                </div>
                <div className='negative-list'>
                <p>Top Negative</p>
                {
                    positiveNegativeData['top_negative'].map((value) => {
                        return <Typography component="div" fontWeight="bold" mt={1} fontSize={15} variant="p" color="text.primary">{value.sub_driver}</Typography>
                    })
                }
                </div>
                </>
            }
        </div>
}

export default TopPositiveNegativeComponent