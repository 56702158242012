import './index.scss'
import { styled } from '@mui/material/styles';
import MuiButton from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';


export const Button = styled(MuiButton)(({ styles }) => ({
    border: '1px solid',
    borderColor: styles.borderColor ? styles.borderColor : '#7FCDC1',
    color: '#000',
    minWidth: styles.minWidth ? styles.minWidth : 0,
    padding: styles.padding ? styles.padding : '10px 10px',
    ...styles
}));



export const BaseButton = ({ text, className, onClick, customStyles = {}, variant = "outlined", showCircularProgress = false, size = "medium", ...args }) => {
    return <Button
        className={`mui-btn ${className}`}
        variant={variant}
        styles={customStyles}
        onClick={onClick}
        type={args.type}
        disabled={args.isDisabled}
        size={size}
        // {...args}
    >
        {text}
        {showCircularProgress &&
        <CircularProgress color="inherit" style={{ width : '30px', height: '30px', marginLeft : '5px'}}/>
        }
    </Button>
}


export const SelectableButton = ({ className, text, onClick, customStyles = {}, selected }) => {
    return <BaseButton
        className={`selectable-btn ${className} ${selected ? 'selected' : ''}`}
        variant="contained"
        text={text}
        onClick={onClick}
        customStyles={customStyles}
    />
}
