import './index.scss'
import QuestionTable from '../../components/QuestionTable'
import Modal from '../../components/Modal'

import NewQuestion from '../../components/QuestionPopup';
import { useEffect, useState } from 'react';


const AboutOthersSection = ({
    questionArr,
    segmentsData,
    handleNewQuestionSubmit,
    handleUpdateQuestionSubmit,
    handleDeleteSelected,
    handleQuestionSegmentToggle,
    addNewQuestionPopup,
    setAddNewQuestionPopup,
    editQuestionPopup,
    setEditQuestionPopup,
    questionSubmitError,
    handleOnDragEnd
}) => {
    

    const handleSubmit = (data, type) => {
        if(type){
            handleUpdateQuestionSubmit(data, questionArr.filter(item => item.id === editQuestionPopup)[0]['id'])
        }else{
            handleNewQuestionSubmit(data)
        }
    }
    return <>
        <div className="section-main-content" style={{ marginTop: '25px' }}>
            <div className="questions-section" >
                <QuestionTable
                    questionArr={questionArr}
                    segmentArr={segmentsData}
                    handleAddQuestion={setAddNewQuestionPopup}
                    handleQuestionEdit={setEditQuestionPopup}
                    handleDeleteSelected={handleDeleteSelected}
                    handleQuestionSegmentToggle={handleQuestionSegmentToggle}
                    handleOnDragEnd={handleOnDragEnd}
                />
            </div>
        </div>
        {
            addNewQuestionPopup && <Modal
                modalClassName="question-modal-wrapper"
                modal_content={
                    <>
                        <NewQuestion
                            handleSubmit={handleSubmit}
                            handleCancel={setAddNewQuestionPopup}
                            segmentsData={segmentsData}
                            questionSubmitErr={questionSubmitError}
                        />
                    </>
                }
                handleModal={setAddNewQuestionPopup}
            />
        }
        {
            editQuestionPopup && <Modal
                modalClassName="question-modal-wrapper"
                modal_content={
                    <>
                        <NewQuestion
                            isEdit={true}
                            handleSubmit={handleSubmit}
                            handleCancel={setEditQuestionPopup}
                            segmentsData={segmentsData}
                            questionData={questionArr.filter(item => item.id === editQuestionPopup)[0]}
                            questionSubmitErr={questionSubmitError}
                        />
                    </>
                }
                handleModal={setEditQuestionPopup}
            />
        }

    </>
}

export default AboutOthersSection;