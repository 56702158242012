import { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './index.scss';
import { BaseButton } from '../../Elements/Button';
import { pen, trash } from '../../assets/svgs/permissions';
import { drag } from '../../assets/svgs/questions';
import { ApiClient } from '../../api/client';
import DeleteConfirmationPopup from '../ConfirmationPopup';
import { InfoText } from '../../Elements/MessageBlocks';

const permissionOptionList = [
    {
        permissionValue: 'only_exclude',
        permissionText: 'Can View All Except',
    },
    {
        permissionValue: 'only_include',
        permissionText: 'Can View Only',
    }
]

const PermissionsTable = ({projectId, permissions, setPermissions, handleOnEdit, ...args}) => {

    const [permissionFilterText, setPermissionFilterText] = useState([{'segmentId' : 0, 'title' : 'All'}])
    const [permissionFilterSelect, setPermissionFilterSelect] = useState(0)
    const [permissionList, setPermissionList] = useState([])
    const [showFilterBody, setShowFilterBody] = useState(false)
    const [permissionReorderList, setPermissionReorderList] = useState([])
    const [confirmationPopup, setConfirmationPopup] = useState({
        show: false,
        id: '',
        action: ''
    })

    useEffect(() => {
        if(permissions){
            setPermissionList(permissions)
        }
    }, [permissions])

    useEffect(() => {
        if(args?.segmentsObj){
            setPermissionFilterText(prev => [...prev, ...args?.segmentsObj])
        }
    }, [args?.segmentsObj])

    useEffect(() => {
        if(permissionReorderList.length){
            const client = ApiClient()
            client.patch('/api/v1/permissions/' + projectId + '/', {
                data: permissionReorderList
            }).then((response) => {
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [permissionReorderList, projectId, setPermissions])

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        let items = Array.from(permissionList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let OrderedItems = items.map((item, idx) => {
            item['order'] = items.length - idx
            return item
        })
        setPermissionList(OrderedItems);
        setPermissionReorderList(OrderedItems);
    }

    const handleAction = (permissionId, action) => {
        const client = ApiClient()
        if(projectId && action === 'delete'){
            client.del('/api/v1/permissions/' + projectId + '/' + permissionId + '/'
            ).then((response) => {
                if (response?.data) {
                    if (response?.data.length) {
                        setPermissions([...response?.data]);
                    }else{
                        setPermissions([]);
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        }   
    }

    const updatePermissionFilterSelect = (index) => {
        setPermissionFilterSelect(index)
        setShowFilterBody(false)
        args?.setSegmentFilter(permissionFilterText[index].segmentId)
    }

    return <>
        <table className='permision-table'>
            <thead className='ptable-header'>
                <tr className='ptable-head-row'>
                    <th className='tablehead'>
                        SEGMENT NAME 
                        <BaseButton
                            variant="outlined"
                            className={'pview-permission'}
                            text={<> View: {permissionFilterText[permissionFilterSelect]?.title}</>}
                            onClick={() => {setShowFilterBody(!showFilterBody)}}
                        />
                        {permissionFilterText.length > 1 && showFilterBody && 
                            <ul className='filter-list'>
                                {
                                    permissionFilterText.map((value, index) => {
                                        return <li onClick={() => updatePermissionFilterSelect(index)}><p className={(index === permissionFilterSelect) ? 'active-filter' : ''}>{value.title}</p></li>
                                    })
                                }
                            </ul>
                        }
                    </th>
                    <th className='tablehead'>PERMISSION TYPE</th>
                    <th className='tablehead'>INCLUSION/EXCLUSION</th>
                </tr>
            </thead>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="permissions">
                {(provided) => (
                    <tbody className='ptable-body table-body'
                        {...provided.droppableProps} ref={provided.innerRef}
                    >
                    {
                        permissionList.length > 0 && permissionList.map((item, idx) => {
                            return (
                                <Draggable
                                    key={item.id + '-' + item.order}
                                    draggableId={item.id + '-' + item.order}
                                    index={idx}
                                >
                                    {
                                    (provided) => (
                                    <tr
                                    className="psection-main-content-row"
                                    key={item.id + '-' + item.order}
                                    ref={provided.innerRef} {...provided.draggableProps}>
                                        <td {...provided.dragHandleProps} className='drag-drop-td' colSpan={3} >
                                            <div className="psection-action psection-border">
                                                <img src={drag} alt="drag me" className='drag-me-ico'/>
                                                <table className='permision-table'>
                                                    <tbody className='ptable-body table-body'>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div className="psection-action">
                                                                    <BaseButton
                                                                        variant="outlined"
                                                                        className={'pview-list-count'}
                                                                        text={<> {idx + 1} </>}
                                                                        onClick={() => {}}
                                                                    />
                                                                    <BaseButton
                                                                        variant="text"
                                                                        text={<> <img src={pen} alt='edit'/> </>}
                                                                        onClick={() => {handleOnEdit(item)}}
                                                                        customStyles={{'borderColor': 'white', 'padding' : '5px 5px'}}
                                                                    >
                                                                    </BaseButton>
                                                                    <BaseButton
                                                                        variant="text"
                                                                        text={<> <img src={trash} alt='delete'/> </>}
                                                                        onClick={() => {setConfirmationPopup({show: true, id: item.id, action: 'delete'})}}
                                                                        customStyles={{'borderColor': 'white', 'padding' : '5px 5px'}}
                                                                    >
                                                                    </BaseButton>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="ptable-head-row">
                                                            <td className="tablehead">
                                                                <div className="psection-main-content">
                                                                {
                                                                    item.segment_group.map((segment_data, idx) => {
                                                                        return (<BaseButton
                                                                            variant="outlined"
                                                                            className={'pview-permission-segment'}
                                                                            text={<> {segment_data.segment_name}</>}
                                                                            onClick={() => {}}
                                                                        />)
                                                                    })
                                                                }
                                                                </div>
                                                            </td>
                                                            <td className="tablehead">
                                                                <div className="psection-main-content">
                                                                    <div style={{'width' : '60%', 'fontWeight' : 'bold'}}>
                                                                        <InfoText messageText={permissionOptionList.map(option => option.permissionValue === item.permission_type && option.permissionText)} variant='p' color='#000000'></InfoText>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="tablehead">
                                                                <div className="psection-main-content">
                                                                {
                                                                    item.viewed_segment_group.map((viewed_segment_data, idx) => {
                                                                        return (<BaseButton
                                                                            variant="outlined"
                                                                            className={'pview-permission-segment'}
                                                                            text={<> {viewed_segment_data.segment_name}</>}
                                                                            onClick={() => {}}
                                                                        />)
                                                                    })
                                                                }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr> 
                                )}
                                </Draggable>
                            )
                        })
                    }
                    {
                        !permissionList.length &&
                        <tr><td colSpan={3}>No records found</td></tr>
                    }
                    </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        </table>
        {
            confirmationPopup.show && <DeleteConfirmationPopup
                text="Are you sure you want to delete this permission?"
                handleCancel={() => setConfirmationPopup({show: false, id: '', action: ''})}
                handleSubmit={() => {
                    handleAction(confirmationPopup.id, confirmationPopup.action)
                    setConfirmationPopup({show: false, id: '', action: ''})
                }}
            />
        }
        </>
}


export default PermissionsTable;