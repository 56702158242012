import { useEffect, useState } from "react"
import { BaseButton } from "./../../../Elements/Button"
import LabeledInput from "./../../../Elements/LabeledInput"
import './index.scss'


import { useRef } from 'react'
import { useSelector } from "react-redux"
import { ApiClient } from '../../../api/client'
import { ADD_STAKEHOLDER, UPDATE_STAKEHOLDER, UserCard } from './utils'




const SearchSection = ({ setScreenName, setEditUserDetails, maxAvailableHeight }) => {

    const addSearchRef = useRef()
    const [addSearchDivHeight, setAddSearchDivHeight] = useState(0)

    const client = ApiClient()
    const [searchText, setSearchText] = useState('')
    const [usersList, setUsersList] = useState([])


    const { projectId } = useSelector(state => state.project)

    useEffect(() => {
        if (projectId) {
            client.get(`/api/v1/ao-project-users/${projectId}/?page_size=50`).then(res => {
                setUsersList(res.data)
            })
        }
    }, [])

    useEffect(() => {
        setAddSearchDivHeight(addSearchRef?.current?.clientHeight ? addSearchRef?.current?.clientHeight : 0)
    }, [usersList])


    const handleStakeholderSearch = (e) => {
        setSearchText(e.target.value)
        client.get(`/api/v1/ao-project-users/${projectId}/?page_size=50&search=${e.target.value}`).then(res => {
            setUsersList(res.data)
        })
    }


    return <div>
        <div ref={addSearchRef} className="">
            <BaseButton
                text={"+ Add Stakeholder"}
                className={'add-stakeholder-btn'}
                onClick={() => setScreenName(ADD_STAKEHOLDER)}
                customStyles={{}}
                variant="contained"
            />

            <LabeledInput
                type="text"
                name="searchStakeholder"
                label=""
                value={searchText}
                onChange={handleStakeholderSearch}
                placeholder="Search"
            />
        </div>

        <div className="users-list" style={{ maxHeight: maxAvailableHeight - addSearchDivHeight - 30 }}>
            {
                usersList?.length > 0 && usersList?.map(user => {
                    let segmentList = user.segment_user_data.map(item => item.segment_name).join(' / ')
                    return <UserCard
                        key={user.id}
                        fName={`${user.first_name} ${user.last_name}`}
                        segments={segmentList}
                        userTitle={user.project_user_title}
                        onClick={() => {
                            setEditUserDetails(user)
                            setScreenName(UPDATE_STAKEHOLDER)
                        }}
                    />
                })
            }

        </div>
    </div>
}

export default SearchSection;