import './index.scss';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Fragment, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { duser} from '../../../assets/svgs/sidebar'
import DriverBar from "../../../components/DriverBar";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ApiClient } from '../../../api/client';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.black,
        // boxShadow: theme.shadows[1],
        // fontSize: 12,
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme, value, height = 10, type = 'light' }) => ({
    height: height,
    borderRadius: 5,
    width: '80px',

    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],

    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        borderStyle: 'solid',
        borderWidth : 'thin',
        backgroundColor: (type === 'light') ? get_color(value)[0] : '#667085',
        borderColor: (type === 'light') ? get_color(value)[1] : '#667085',
    },

}));

const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="p" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant='determinate' {...props} />
        </Box>
      </Box>
    );
}

const get_color = (value) => {
    if (value <= 33.3) {
        return ['#FFA8A3', '#E06656'];
    }
    else if (value > 33.3 && value <= 66.6) {
        return ['#FFDCA9', '#FFAE39'];
    }
    else {
        return ['#60CF97', '#479E72'];
    }
}

const tabledatacolor = (value) => {
    if (value == null || !value) {
        return ['#FFFFFF', 'rgba(128,128,128, 0.22)']
    }
    else if (value < 2) {
        return ['#E56965', 'rgba(229, 105, 101, 0.14)']
    }
    else if (value >= 2 && value < 4) {
        return ['#A185A8', 'rgba(161, 133, 168, 0.22)']
    }
    else if (value >= 4 && value < 6) {
        return ['#8292C6', 'rgba(130, 146, 198, 0.22)']
    }
    else if (value >= 6 && value < 8) {
        return ['#4BA8ED', 'rgba(75, 168, 237, 0.13)']
    }
    else if (value >= 8) {
        return ['#07B6A9', 'rgba(7, 182, 169, 0.16)']
    }
}

const getPercent = (num1, num2) => {
    if(num1 === 0 | num2 === 0) return 0
    return Math.round((num1 / num2) * 100)
}

const DriverAnalysis = () => {
    const client = ApiClient()
    const navigate = useNavigate()
    const [_selectedSegmentIdx, _setSelectSegmentIdx] = useState(0)
    const { isAdmin } = useSelector(state => state.user)
    const [_selectSegmentType, _setSelectSegmentType] = useState()
    const [activeDriver, setActiveDriver] = useState()
    const [segmentsData, setSegmentData] = useState([])
    const [driverData, setDriverData] = useState([])
    const [subDriverData, setSubDriverData] = useState()
    const [subDriverChoiceData, setSubDriverChoiceData] = useState()
    const [questionData, setQuestionData] = useState()
    const [questionAvgData, setQuestionAvgData] = useState()
    const [choiceQuestionAvgData, setChoiceQuestionAvgData] = useState()
    const [questionList, setQuestionList] = useState()
    const [choiceQuestionData, setChoiceQuestionData] = useState()
    const [totalUserSegmentData, setTotalUserSegmentData] = useState()
    const [totalAnsweredUserSegmentData, setTotalAnsweredUserSegmentData] = useState()
    const [isLoading, setLoading] = useState(true)

    const { projectId, projectUserId, currentProjectName } = useSelector(state => state.project)

    useEffect(() => {
        if (projectId === null || projectId === undefined || projectId === '') {
            navigate('/app/project-not-found')
        }
    }, [projectId, navigate])

    useEffect(() => {
        if (projectId) {
            client
                .get(`/api/v1/project-status/${projectId}/`)
                .then(res => {
                    if (res?.data?.is_locked) {
                        navigate('/app/access-denied')
                    }
                    else {
                        if(!isAdmin){
                        client.get('/api/v1/project-anonymity-threshold/' + projectId + '/')
                            .then((response) => {
                                if (response?.data) {
                                    if (!response?.data?.criteria_met) {
                                        navigate('/app/no-dashboard', {
                                            state: {
                                                anonymity_threshold: response?.data?.anonymity_threshold,
                                                criteria_met: response?.data?.criteria_met,
                                                status_type: response?.data?.status_type,
                                            }
                                        })
                                    }
                                }
                            })
                        }
                        
                            
                        client.get('/api/v1/segments/' + projectId + '/?is_active=true')
                            .then((response) => {
                                if (response?.data) {
                                    setSegmentData(response.data)
                                }
                            })
                    
                }
                })

        }
    }, [projectId])

    useEffect(() => {
        if(segmentsData){
            _setSelectSegmentType(segmentsData[0])
        }
    }, [segmentsData])

    useEffect(() => {
        if(driverData){
            setActiveDriver(driverData[0])
        }
    }, [driverData])

    useEffect(() => {
        if(questionData && activeDriver && questionData[activeDriver['title']] !== undefined){
            setSubDriverData(Object.keys(questionData[activeDriver['title']]))
        }else{
            setSubDriverData([])
        }
    }, [activeDriver, questionData])

    useEffect(() => {
        if(choiceQuestionData && activeDriver && choiceQuestionData[activeDriver['title']] !== undefined){
            setSubDriverChoiceData(Object.keys(choiceQuestionData[activeDriver['title']]))
        }else{
            setSubDriverChoiceData([])
        }
    }, [activeDriver, choiceQuestionData])

    useEffect(() => {
        if(_selectSegmentType){
            setLoading(true)
            const client = ApiClient()
            client.get('/api/v1/driver-analysis/' + projectId + '/' + _selectSegmentType.segmentTypeId + '/').then((response) => {
                if(response?.data){
                    setDriverData(response?.data?.driverData?.sort((a, b) => a.driverOrder - b.driverOrder))
                    setTotalAnsweredUserSegmentData(response?.data?.answeredUserData)
                    setTotalUserSegmentData(response?.data?.userData)
                    setQuestionData(response?.data?.questionData)
                    setQuestionAvgData(response?.data?.sliderQuestionAvgData)
                    setChoiceQuestionAvgData(response?.data?.choiceQuestionAvgData)
                    setChoiceQuestionData(response?.data?.choiceQuestionData)
                    setQuestionList(response?.data?.questionList)
                    setLoading(false)
                }
            })
        }
    }, [_selectSegmentType, projectId])

    const handleSegmentSelection = (idx) => {
        _setSelectSegmentType(segmentsData[idx])
        _setSelectSegmentIdx(idx)
    }

    const handleDriverSelection = (val) => {
        setActiveDriver(val)
    }

    const avgCalc = (data, round = 1) => {
        let clean_arr = data.filter(a => parseFloat(a) !== 0)
        let avg = data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / clean_arr.length || 0
        return avg.toFixed(round)
    }

    const sumCalc = (data) => {
        return data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) || 0
    }

    const calcSubDriverAvg = (data, type = 'slider') => {
        if(data !== undefined){
            if(type === 'choice'){
                return avgCalc(data, 0)
            }
            return avgCalc(data)
        }
        return '0'
    }

    const NoContent = () => {
        return isLoading ?
            <Loader />
            :
            ''
    }

    return <div className="main-content" >
        <main>
            {_selectSegmentType && 
                <DriverBar driverData={driverData} handleDriverSelection={handleDriverSelection} activeDriver={activeDriver} segmentsData={segmentsData} handleSegmentSelection={handleSegmentSelection}/>
            }
            {isLoading &&
                <NoContent/>
            }
            <div className='table_section'><table className='analysis-table' >
                <thead className='table-header'>
                    <tr className='table-head-row'>
                        <th className='blank-th' style={{ width:'150px',color: '#667085', borderBottom: '2px solid #B6B6B6'}} >
                            <img src={duser} alt={'users'} width={25} style={{display:'inline', marginLeft: '6px'}} />
                            <div style={{ fontSize: '15px', marginLeft: '6px',display:'inline',color:'#667085'}}>
                                Users Response
                            </div>
                        </th>
                        {activeDriver && totalUserSegmentData && totalAnsweredUserSegmentData && totalAnsweredUserSegmentData[activeDriver['title']] !== undefined &&
                            <th className='blank-th' style={{color: '#667085', backgroundColor: '#F2F5F9', borderRight: '2px solid #B6B6B6', borderBottom: '2px solid #B6B6B6'}}>Segment Total
                                <div className='blank-th' style={{ marginTop: '20px' }}>{sumCalc(Object.values(totalAnsweredUserSegmentData[activeDriver['title']]))} out of {sumCalc(Object.values(totalUserSegmentData))}</div>
                                <div style={{ marginTop: '3px' }}>
                                    <LinearProgressWithLabel variant='determinate' value={(sumCalc(Object.values(totalAnsweredUserSegmentData[activeDriver['title']])) / sumCalc(Object.values(totalUserSegmentData))) * 100} />
                                </div>
                            </th>
                        }
                        {activeDriver && totalUserSegmentData && totalAnsweredUserSegmentData && totalAnsweredUserSegmentData[activeDriver['title']] !== undefined &&
                            segmentsData[_selectedSegmentIdx]?.segmentsList.map((item, idx) => {
                                return <th key={'segment-row-' + idx} style={{ borderBottom: '2px solid #B6B6B6' }}>{item.title}
                                    <div key={'segment-row-data-' + idx} style={{ marginTop: '20px', color: '#5A5A5A' }}>
                                        {totalAnsweredUserSegmentData[activeDriver['title']][item.title]} out of {totalUserSegmentData[item.title]}
                                    </div>
                                    <div key={'segment-row-bar-' + idx} style={{ marginTop: '3px' }}>
                                        <span>
                                            <LinearProgressWithLabel variant='determinate' value={getPercent(totalAnsweredUserSegmentData[activeDriver['title']][item.title], totalUserSegmentData[item.title])} />
                                        </span>
                                    </div>
                                </th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {subDriverData &&
                        subDriverData.map((subDriver, index) => {
                            return <tr key={'subdriver-label-row-' + index} className='table-row'>
                                <td key={'subdriver-label-cell-' + index} className='subdriver-td' >{subDriver}
                                    <span key={'subdriver-label-container-' + index} style={{ float: 'right', opacity: 0.5 }}>
                                        <LightTooltip
                                            key={'subdriver-slider-tooltip-' + index}
                                            className="this is classname"
                                            title={
                                                questionList && questionList[subDriver] !== undefined &&
                                                <>
                                                    <ol>
                                                        {
                                                            questionList[subDriver].map((questionText, qIdx) => {
                                                                return <li key={'slider-ques-' + qIdx}>{questionText}</li>
                                                            })
                                                        }
                                                    </ol>
                                                </>
                                            }
                                            placement="right"
                                            arrows
                                        >
                                            <InfoOutlinedIcon key={'subdriver-slider-tooltip-icon-' + index} color='primary' fontSize='100px' />
                                        </LightTooltip>
                                    </span>
                                </td>
                                {activeDriver && questionData[activeDriver['title']] !== undefined && questionData[activeDriver['title']][subDriver] !== undefined
                                    && questionAvgData[activeDriver['title']] !== undefined && questionAvgData[activeDriver['title']][subDriver] !== undefined && (
                                        <>
                                            {(sumCalc(Object.values(totalAnsweredUserSegmentData[activeDriver['title']])) >= 3) ?
                                                <td key={'subdriver-label-cell-progress' + index} style={{ textAlign: 'center', backgroundColor: '#F2F5F9', borderRight: '2px solid #B6B6B6' }}>
                                                     <div key={'subdriver-label-progress' + index} style={{
                                                        backgroundColor: tabledatacolor(calcSubDriverAvg(questionAvgData[activeDriver['title']][subDriver]))[1],
                                                        color: tabledatacolor(calcSubDriverAvg(questionAvgData[activeDriver['title']][subDriver]))[0],
                                                        borderRadius: '3px', width: '35px', height: '30px', textAlign: 'center', paddingTop: '6px'
                                                    }}>
                                                        {calcSubDriverAvg(questionAvgData[activeDriver['title']][subDriver])}
                                                    </div>
                                                </td> : <td key={'question-value-cell' + 1} style={{ opacity: 0.5 }}>
                                                <LightTooltip
                                                                        key={'subdriver-slider-tooltip-' + 1}
                                                                        className="this is classname"
                                                                        title={
                                                
                                                                            <>
                                                                                <ol>
                                                                                    <li >{'Anonymity threshold not met.'}</li>
                                                                                </ol>
                                                                            </>
                                                                        }
                                                                        placement="left"
                                                                        arrows
                                                                    >
                                                                <div style={{ width:'30%'}}>                                                                                                        
                                                                      <VisibilityOffIcon />  </div></LightTooltip></td>}
                                            {
                                                Object.values(questionData[activeDriver['title']][subDriver]).map((value, idx) => {
                                                    let member = Object.keys(questionData[activeDriver['title']][subDriver])[idx];
                                                    if (parseFloat(value) === 0) {
                                                        return <td key={'question-value-cell' + idx} style={{ opacity: 0.5 }}><VisibilityOffIcon /></td>
                                                    }
                                                    else {

                                                        let color1 = tabledatacolor(value)
                                                        if (totalAnsweredUserSegmentData[activeDriver['title']][member] < 3) {

                                                            return <td key={'question-value-cell' + idx} style={{ opacity: 0.5 }}>
                                                                 <LightTooltip
                                                                        key={'subdriver-slider-tooltip-' + 1}
                                                                        className="this is classname"
                                                                        title={
                                                
                                                                            <>
                                                                                <ol>
                                                                                    <li >{'Anonymity threshold not met.'}</li>
                                                                                </ol>
                                                                            </>
                                                                        }
                                                                        placement="left"
                                                                        arrows
                                                                    >
                                                                <div style={{ width:'30%'}}>                                                                                                        
                                                                      <VisibilityOffIcon />  </div></LightTooltip>
                                                            </td>

                                                        }
                                                        else {
                                                            return <td key={'question-value-cell' + idx} style={{ textAlign: 'center' }}><div style={{ backgroundColor: color1[1], color: color1[0], borderRadius: '3px', width: '35px', height: '30px', textAlign: 'center', paddingTop: '6px' }}>{value}</div></td>


                                                        }


                                                    }
                                                })
                                            }
                                        </>

                                    )}
                            </tr>
                        })
                    }
                    {subDriverChoiceData &&
                        subDriverChoiceData.map((subDriver, index) => {
                            return <Fragment key={'choice-table-modules' + index}>
                                <tr key={'subdriver-choice-label-row-' + index} className='table-row'>
                                    <td key={'subdriver-choice-label-cell-' + index} className='subdriver-choice-td' >{subDriver}
                                        <span key={'subdriver-choice-label-container-' + index} style={{ float: 'right', opacity: 0.5 }}>
                                            <LightTooltip
                                                key={'subdriver-choice-tooltip-' + index}
                                                className="this is classname"
                                                title={
                                                    questionList && questionList[subDriver] !== undefined &&
                                                    <>
                                                        <ol key={'choice-quest-list' + index}>
                                                            {
                                                                questionList[subDriver].map((questionText, qIdx) => {
                                                                    return <li key={'choice-ques-' + qIdx}>{questionText}</li>

                                                                })
                                                            }
                                                        </ol>
                                                    </>
                                                }
                                                placement="right"
                                                arrows
                                            >
                                                <InfoOutlinedIcon key={'subdriver-choice-tooltip-icon-' + index} color='primary' fontSize='100px' />
                                            </LightTooltip>
                                        </span>
                                    </td>
                                    <td key={'subdriver-choice-label-cell-progress' + index} style={{ textAlign: 'center', backgroundColor: '#F2F5F9', borderRight: '2px solid #B6B6B6' }}></td>
                                </tr>
                                {activeDriver && choiceQuestionData[activeDriver['title']] !== undefined && choiceQuestionData[activeDriver['title']][subDriver] !== undefined &&
                                    Object.keys(Object.values(choiceQuestionData[activeDriver['title']][subDriver])[0]).map((choiceText, idx) => {
                                        return <tr key={'subdriver-choice-label-row-' + idx} className='table-row'>
                                            <td key={'subdriver-choice-label-cell-' + idx} style={{ paddingLeft: '30px' }} className='multi-choice-data'>{choiceText}</td>
                                            <td key={'subdriver-choice-label-cell-progress' + idx} style={{ textAlign: 'center', backgroundColor: '#F2F5F9', borderRight: '2px solid #B6B6B6' }} className='multi-choice-data'>
                                                <div key={'segment-row-bar-' + idx} style={{ marginTop: '3px' }}>
                                                    <span key={'segment-row-bar-span-' + idx}>
                                                        <LinearProgressWithLabel key={'segment-avg-progress-row-bar-' + idx} variant='determinate' value={getPercent(calcSubDriverAvg(choiceQuestionAvgData[activeDriver['title']][subDriver][choiceText], 'choice'), 100)} type='dark' height='5' />
                                                    </span>
                                                </div>
                                            </td>
                                            {
                                                Object.values(choiceQuestionData[activeDriver['title']][subDriver]).map((choiceValues, i) => {
                                                    return <td key={'cell-' + i} className='multi-choice-data'>
                                                        <div key={'segment-row-bar-' + i} style={{ marginTop: '3px' }}>
                                                            <span key={'segment-row-bar-span-' + i}>
                                                                <LinearProgressWithLabel key={'segment-row-progress-bar-' + i} variant='determinate' value={getPercent(choiceValues[choiceText], 100)} type='dark' height='5' />
                                                            </span>
                                                        </div>
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </Fragment>
                        })
                    }
                </tbody>
            </table>
            </div>
        </main>
    </div>
}

export default DriverAnalysis;