import './index.scss'
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = ({ chartData, totalSegmentUsers, segmentTitle }) => {

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                mode: 'point',
                backgroundColor: '#000',
                color: '#fff',
                displayColors: false,
                titleAlign: 'center',
                bodyAlign: 'center',
                callbacks: {
                    title: function (context) {
                        return context[0].label;
                    },
                    label: function (context) {
                        return (context['raw'] * 100 / totalSegmentUsers).toFixed() + '%';
                    },
                }
            }
        },
        responsive: true,
        cutout: '75%',
        layout: {
            padding: 2
        },
        elements: {
            arc: {
                borderJoinStyle: 'round',
            }
        },
    }

    return <div className='donut-chart-box'>
        <div className='donut-centered-text'>{totalSegmentUsers}</div>
        <Doughnut width={'120px'} height={'120px'} data={chartData} options={options} />
        <p className='chart-title'>{segmentTitle}</p>
    </div>
}

export default DoughnutChart;