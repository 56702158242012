import './index.scss'
import { UserCard } from "../util"


const RecommendedUsers = ({ users, queryStatus }) => {

    return <div className="catch-up-users-content">
        <h2 className="catch-up-users-header">Recommended Stakeholders</h2>
        <div className="catch-up-users-list">

            {
                queryStatus === 'STARTED' ?
                    <div>Fetching....</div>
                    :
                    users.length > 0 ?
                        users
                            .map((user, idx) => {
                                let segmentList = user.segment_user_data?.map(item => item.segment_name).join(' / ')
                                return <UserCard
                                    index={idx}
                                    key={user.id}
                                    fName={`${user.first_name} ${user.last_name}`}
                                    userTitle={user.project_user_title}
                                    segments={segmentList}
                                    onClick={() => { }}
                                />
                            })
                        :
                        queryStatus === 'COMPLETED' && <div>No new user to recommend.</div>

            }
        </div>

    </div>
}

export default RecommendedUsers;