import { Fragment, useEffect, useState } from "react";
import Checkbox from "../../Elements/Checkbox";
import { BaseButton } from "../../Elements/Button";
import Modal from "../../components/Modal";
import AboutOthersPopup from "../../components/AboutOthersPopup";
import { ApiClient } from '../../api/client';
import { useSelector } from "react-redux";

const MyMapSection = ({setMappingData, handleCheck}) => {

    const [myMapObj, setMyMapObj] = useState([])
    const { projectId } = useSelector(state => state.project)
    const [showAddMyMapModal, setAddMyMapModal] = useState(false)
    const [atleastOneChecked, setAtleastOneChecked] = useState(true)

    useEffect(() => {
        if (myMapObj) {
            setMappingData({
                type: 'myMap',
                data: myMapObj
            })
        }
    }, [myMapObj, setMappingData])

    useEffect(() => {
        if (projectId) {
            const client = ApiClient()
            client.get('/api/v1/my-map/' + projectId + '/').then((response) => {
                if (response?.data) {
                    setMyMapObj(response?.data)
                }
            })
        }
    }, [projectId])

    useEffect(() => {
        setAtleastOneChecked(!myMapObj.map(item => item.isChecked).includes(true))
    }, [myMapObj])

    useEffect(() => {
        handleCheck(atleastOneChecked)
    }, [atleastOneChecked, handleCheck])

    const handleSubmit = (data) => {
        const client = ApiClient()
        client.post('/api/v1/my-map/' + projectId + '/', data = {
            title : data.mapping,
            icon: data.icon
        }).then((response) => {
            if (response?.data) {
                setMyMapObj(response?.data)
                setAddMyMapModal(false)
            }
        }).catch((error) => {
            if (error?.response?.data) {
                if(error?.response?.data?.data?.non_field_errors[0] === 'The fields project, title must make a unique set.'){
                    return 'Already exists'
                }
                console.log(error?.response?.data)
            }
        })
    }

    const handleCancel = (data) => {
        setAddMyMapModal(false)
    }

    return <>
        <div className='checkbox-option-list'>
            {
                myMapObj.map(item => {
                    return <Fragment key={item.title}>
                        <Checkbox
                            svgIcon={item.icon}
                            title={item.title}
                            subtitle={item.subtitle}
                            checked={item.isChecked}
                            onCheckboxChange={() =>
                                setMyMapObj(prevState => (
                                    [
                                        ...prevState.map(obj =>
                                            obj.title === item.title ?
                                                { ...obj, isChecked: !item.isChecked }
                                                :
                                                obj
                                        )
                                    ]
                                ))
                            }
                        />
                    </Fragment>
                })
            }

        </div>
        <div className='add-new-div'>
            <BaseButton
                text={'+ add new'}
                className="add-new-btn"
                onClick={() => setAddMyMapModal(!showAddMyMapModal)}
            />
        </div>

        {
            showAddMyMapModal && <Modal
            modalClassName="map-modal-wrapper"
                handleModal={setAddMyMapModal}
                modal_content={<AboutOthersPopup
                    title='Mapping'
                    name='mapping'
                    label='My Map Category'
                    hasDescription={false}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />}
 
            />
        }
    </>
}

export default MyMapSection;