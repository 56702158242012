import './index.scss'
import { useEffect, useState } from 'react';
import Layout from "../../components/Layout";
import AboutOthersSection from './AboutOthersSection';
import AboutOthersTextContent from './AboutOthersTextContent';
import AboutOthersTopBar from './AboutOthersTopBar';
import MyMapSection from './MyMap';
import ProjectMapSection from './ProjectMap';
import StakeholderLabelSection from './StakeholderLabel';
import DeleteConfirmationPopup from '../../components/ConfirmationPopup';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiClient } from '../../api/client';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';



const AboutOthers = () => {

    const client = ApiClient()

    const navigate = useNavigate()
    const { state } = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeDriver, setActiveDriver] = useState()
    const { projectId } = useSelector(state => state.project)
    const dispatch = useDispatch()
    const [segmentsData, setSegmentData] = useState([])
    const [driverData, setDriverData] = useState([])
    const [questionArr, setQuestionArr] = useState([])
    const [activeSection, setActiveSection] = useState('aboutOthers')
    const [mappingData, setMappingData] = useState({})
    const [atleastOneChecked, setAtleastOneChecked] = useState(false)
    const [deleteQuestionPopup, setDeleteQuestionPopup] = useState({
        show: false,
        count: 0,
        data: []
    })

    
    const [addNewQuestionPopup, setAddNewQuestionPopup] = useState(false)
    const [editQuestionPopup, setEditQuestionPopup] = useState(false)

    
    const [questionSubmitError, setQuestionSubmitError] = useState()

    useEffect(() => {
        if (projectId) {
            const client = ApiClient(setSegmentData)
            client.get('/api/v1/segments/' + projectId + '/?is_active=true')
            .then((response) => {
                if (response?.data) {
                    setSegmentData(response.data)
                }
            })
        }
    }, [projectId])

    useEffect(() => {
        if(driverData){
            setActiveDriver(driverData[0]?.driverId)
        }
    }, [driverData])

    useEffect(() => {
        setQuestionSubmitError()
    }, [addNewQuestionPopup, editQuestionPopup])
    

    useEffect(() => {
        if(projectId){
            const client = ApiClient(setQuestionArr)
            client.get('/api/v1/aboutothers/' + projectId+ '/?is_active=true')
            .then((response) => {
                if (response?.data.length) {
                    setQuestionArr(response?.data)
                }
            })
        }
    }, [projectId, setQuestionArr])

    const handleActiveSection = (opt) => {
        setMappingData({})
        setActiveSection(opt)
    }

    const handleDeleteSelected = (data) => {
        if(data.length){
            setDeleteQuestionPopup(prev => ({...prev, show: true, data: data, count: data.length}))
        }
    }

    const confirmDeleteSelected = (data) => {
        if(data.length){
            const client = ApiClient(setQuestionArr)
            client.del('/api/v1/aboutothers/' + projectId + '/?is_active=true&question_ids=' + data)
            .then((response) => {
                if (response?.data) {
                    setQuestionArr(response.data)
                }
            })
        }
    }

    const handleNewQuestionSubmit = (data) => {
        if(data){
            const client = ApiClient(setQuestionArr)
            client.post('/api/v1/aboutothers/' + projectId + '/?is_active=true', data)
            .then((response) => {
                if (response?.data) {
                    setQuestionArr(response.data)
                    setQuestionSubmitError()
                    setAddNewQuestionPopup(false)
                    setEditQuestionPopup(false)
                }
            })
            .catch((error) => {
                setQuestionSubmitError(error.response.data)
            })
        }
    }

    const handleUpdateQuestionSubmit = (data, question_id) => {
        if(data && question_id){
            client.put('/api/v1/aboutothers/' + projectId + '/' , {
                data: {
                    'question_data' : data,
                    'ao_question_id' : question_id,
                },
                action : 'UPDATE_QUESTION_DATA'
            })
            .then((response) => {
                if (response?.data) {
                    setQuestionArr(response.data)
                    setQuestionSubmitError()
                    setAddNewQuestionPopup(false)
                    setEditQuestionPopup(false)
                }
            })
            .catch((error) => {
                setQuestionSubmitError(error.response.data)
            })
        }
    }

    const nextBtnConditions = () => {

        client.post('api/v1/journeysteps/', {
            project: projectId,
            step_id: 5,
            step_name: 'About Others'
        }).then((res) => {
            // console.log(res)
        }).catch((error) => {
            console.log(error)
        })
        if(activeSection === 'aboutOthers'){
            handleActiveSection('myMap')
        }else if(activeSection === 'myMap'){
            handleActiveSection('projectMap')  
        }else if(activeSection === 'projectMap'){
            handleActiveSection('stakeholderLabel')
        }   
      

        if(Object.keys(mappingData).length){
            const API_URL = (mappingData.type === 'myMap') ? '/api/v1/my-map/' + projectId + '/' : '/api/v1/project-map/' + projectId + '/'
            client.put(API_URL, {
                data : mappingData.data
            }).then((res) => {
                
            }).catch((error) => {
                console.log(error)
            })
            
        }
        if(activeSection === 'stakeholderLabel'){
            return navigate({
                pathname: '/setup/users',
                search: '?projectId=' + projectId
            }, { state: { projectId: projectId } })
        }
    }

    const backBtnConditions = () => {
        setMappingData({})
        if(activeSection === 'stakeholderLabel'){
            handleActiveSection('projectMap')
        }else if(activeSection === 'projectMap'){
            handleActiveSection('myMap')
        }else if(activeSection === 'myMap'){
            handleActiveSection('aboutOthers')
        }else{
            navigate({
                pathname: '/setup/about-me',
                search: '?projectId=' + projectId
            }, { state: { projectId } })
        }
    }

    const handleQuestionSegmentToggle = (rowIdx, columnIdx) => {
        const newArr = [...questionArr]
        newArr[rowIdx].segments[columnIdx].is_selected = !newArr[rowIdx].segments[columnIdx].is_selected
        setQuestionArr(newArr)
        const client = ApiClient(setQuestionArr)
        client.put('/api/v1/aboutothers/' + projectId + '/' , {
            data: {
                'segment_ao_question_id' : questionArr[rowIdx].segments[columnIdx].segment_ao_question_id,
                'is_selected' : questionArr[rowIdx].segments[columnIdx].is_selected},
            action : 'UPDATE_SEGMENT_SELECTION_ONLY'
        }).then((response) => {
            
        }).catch((error) => {
            
        })
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination || result.source.index === result.destination.index) return;

        let mandatory_ques = questionArr.filter(q => q.is_mandatory === true)

        let items = Array.from(questionArr.filter(q => q.is_mandatory !== true));
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let OrderedItems = items.map((item, idx) => {
            item['aoq_order'] = idx + mandatory_ques.length
            return item
        })
        client.put(`/api/v1/aoq-order/${projectId}/`, {
            data: {
                id: parseInt(result.draggableId),
                new_order: result.destination.index + mandatory_ques.length
            }
        }).then(res => {})
        setQuestionArr([...mandatory_ques, ...OrderedItems])
        
    }


    return <Layout
    active_page={'About Others'}
        onClickNext={() => nextBtnConditions()}
        onClickBack={() => backBtnConditions()}
        onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#AboutOthersOverview','_blank')}
        isNextDisabled={atleastOneChecked}
    >
        <div className="about-others-section section">
            <div className={`about-others section-container ${activeSection}`}>
                <div className='section-content'>
                    <div>
                        <AboutOthersTopBar
                            activeSection={activeSection}
                            onClick={handleActiveSection}
                        />

                        <AboutOthersTextContent
                            activeSection={activeSection}
                        />
                    </div>
                    <div>
                        {
                            activeSection === 'aboutOthers' && <AboutOthersSection 
                                questionArr={questionArr}
                                segmentsData={segmentsData}
                                handleNewQuestionSubmit={handleNewQuestionSubmit}
                                handleUpdateQuestionSubmit={handleUpdateQuestionSubmit}
                                handleDeleteSelected={handleDeleteSelected}
                                handleQuestionSegmentToggle={handleQuestionSegmentToggle}
                                setQuestionSubmitErr={setQuestionSubmitError}
                                addNewQuestionPopup={addNewQuestionPopup}
                                setAddNewQuestionPopup={setAddNewQuestionPopup}
                                editQuestionPopup={editQuestionPopup}
                                setEditQuestionPopup={setEditQuestionPopup}
                                questionSubmitError={questionSubmitError}
                                handleOnDragEnd={handleOnDragEnd}
                            />
                        }
                        {
                            activeSection==='stakeholderLabel' && <StakeholderLabelSection/>
                    }
                        {
                            activeSection === 'myMap' && <MyMapSection setMappingData={setMappingData} handleCheck={setAtleastOneChecked} />
                        }
                        {
                            activeSection === 'projectMap' && <ProjectMapSection setMappingData={setMappingData} handleCheck={setAtleastOneChecked} />
                        }

                    </div>
                </div>
            </div>
        </div>
        {
            deleteQuestionPopup.show && <DeleteConfirmationPopup
                text={"Are you sure you want to delete " + ((deleteQuestionPopup.count > 1) ? deleteQuestionPopup.count + " questions" : "the question") + "?"}
                handleCancel={() => setDeleteQuestionPopup(prev => ({...prev, show: false}))}
                handleSubmit={() => {
                    confirmDeleteSelected(deleteQuestionPopup.data)
                    setDeleteQuestionPopup(prev => ({...prev, show: false}))
                }}
            />
        }

    </Layout>
}

export default AboutOthers;
