import './index.scss'
import { useNavigate } from "react-router-dom";
import { ApiClient } from "../../../api/client";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BaseButton } from "../../../Elements/Button";
import { MY_STAKEHOLDERS, UserCard } from "../userSection/utils";
import { controlType } from "../../../utilities/constants";
import {
    ChoiceQuestion,
    FreeTextQuestion,
    MultiTopicQuestion,
    RangeSliderQuestion
} from "../../../components/UserEndQuestions";
import Loader from "../../../components/Loader";

import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import { useRef } from 'react';



const AOQuestionsList = ({ setScreenName, user, myMaps, projectMaps, maxAvailableHeight,setView,view, setReloadMap, ...args }) => {

    const titleContentRef = useRef()
    const [titleContentHeight, setTitleContentHeight] = useState(0)

    const client = ApiClient()
    const { projectId, projectUserId, currentProjectName } = useSelector(state => state.project)
    const [aoQuestions, setAOQuestions] = useState([])
    const [isLoading, setLoading] = useState(false)

    const [mapListVisible, setMapListVisibility] = useState(false)

    const [formData, setFormData] = useState({
        myMapsIds: user?.mapping_data?.my_maps?.map(item => item?.my_map),
        projectMapsIds: user?.mapping_data?.project_maps?.map(item => item?.project_map),
    })
    const { myMapsIds, projectMapsIds } = formData
    const isLargeDevice = window.innerWidth > 820

    useEffect(() => {
        if (projectId) {
            setLoading(true)
            setAOQuestions([])
            let apiurl = `api/v1/ao-questions-list/?project_id=${projectId}&project_user_id=${projectUserId}&sub_project_user_id=${user.id}`
            client.get(apiurl)
                .then(response => {
                    if (response?.data) {
                        setAOQuestions(response.data)
                        setLoading(false)
                    }
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }, [projectId, user.id])

    useEffect(() => {
        setTitleContentHeight(titleContentRef?.current?.clientHeight ? titleContentRef?.current?.clientHeight : 0)
    }, [])

    const handleAnswer = (answer) => {
        answer['sub_project_user'] = user.id
        client
            .post('api/v1/ao-response/', {
                response: answer
            })
            .then(res => {
               //setReloadMap(true)
            })
            .catch(err => console.log(err))
    }

    const handleSelectMaps = (map_name, id) => {
        let mapArray = map_name === 'myMapsIds' ? myMapsIds : projectMapsIds
        if (mapArray.includes(id)) {
            setFormData(state => (
                {
                    ...state,
                    [map_name]: state[map_name].filter(item => item !== id)
                }
            ))
        }
        else {
            setFormData(state => (
                {
                    ...state,
                    [map_name]: [...state[map_name], id]
                }
            ))
        }
    }

    const handleUpdate = () => {
        let data = {
            'project_user_id': user.id,
            'mapping_data': { myMapsIds, projectMapsIds }
        }

        client
            .put('/api/v1/ao-project-users/' + projectId + '/', data)
            .then((response) => {
                setMapListVisibility(false)
                setReloadMap(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const NoQuestionsContent = () => {
        return isLoading ?
            <Loader />
            :
            <h2 className="no-questions">No Questions</h2>
    }

    return <div className='stakeholder-questions-container'>
        <div ref={titleContentRef}>
            <div className='header'>
                <span className='title'>About Others</span>
                <div className='edit-ao-maps-box'>
                    <span className='edit-text' onClick={() => setMapListVisibility(!mapListVisible)}>Edit</span>

                    {
                        mapListVisible && <div className='ao-maps-list'>
                            <div className='ao-map-form-btn'>
                                <BaseButton
                                    className={"ao-map-cancel-btn"}
                                    text={'Cancel'}
                                    onClick={() => {
                                        setFormData({
                                            myMapsIds: [],
                                            projectMapsIds: [],
                                        })
                                        setMapListVisibility(false)
                                    }}
                                />
                                <BaseButton
                                    className={"ao-map-save-btn"}
                                    text={'Save'}
                                    onClick={handleUpdate}
                                />
                            </div>
                            <h4>My Map:</h4>
                            <ul className='map-list'>
                                {
                                    myMaps.map(item => {
                                        return <li
                                            key={item.segment_my_map_id}
                                            className='map-list-item'
                                            value={item.segment_my_map_id}
                                        >
                                            <Checkbox
                                                id={'my-map' + item.segment_my_map_id}
                                                checked={myMapsIds.includes(item.segment_my_map_id)}
                                                onChange={() => handleSelectMaps('myMapsIds', item.segment_my_map_id)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                sx={{
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: 'white',
                                                    },
                                                }}
                                            />
                                            <label htmlFor={'my-map' + item.segment_my_map_id}>
                                                {item.segment_my_map_name}
                                            </label>
                                        </li>
                                    })
                                }
                            </ul>
                            <h4>Project Map:</h4>
                            <ul className='map-list'>
                                {
                                    projectMaps.map(item => {
                                        return <li
                                            key={item.segment_project_map_id}
                                            className='map-list-item'
                                            value={item.segment_project_map_id}
                                        >
                                            <Checkbox
                                                id={'project-map' + item.segment_project_map_id}
                                                checked={projectMapsIds.includes(item.segment_project_map_id)}
                                                onChange={() => handleSelectMaps('projectMapsIds', item.segment_project_map_id)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                sx={{
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: 'white',
                                                    },
                                                }}
                                            />
                                            <label htmlFor={'project-map' + item.segment_project_map_id}>
                                                {item.segment_project_map_name}
                                            </label>
                                        </li>
                                    })
                                }
                            </ul>

                        </div>
                    }

                </div>
            </div>

            <div className='users-list'>
                <UserCard
                    fName={`${user.first_name} ${user.last_name}`}
                    segments={user.segment_user_data.map(item => item.segment_name).join(' / ')}
                    userTitle={user.project_user_title}
                    rightArrow={false}
                />
            </div>
        </div>

        <div className="about-others-main-content" >
            {
                aoQuestions.length > 0 ?
                    <div className="questions-container" style={{ maxHeight: maxAvailableHeight - titleContentHeight - 70 }}>
                        <div id="back-to-top-anchor"></div>
                        {
                            aoQuestions?.map(question => {
                                switch (question.control_type) {
                                    case controlType.TEXT:
                                        return (
                                            <div
                                                key={`question-page-${question.id}`}
                                                className="control-container"
                                            >
                                                <FreeTextQuestion
                                                    currentUser={projectUserId}
                                                    question={question}
                                                    onAnswer={(answer) => handleAnswer(answer)}
                                                    projectTitle={currentProjectName}
                                                    surveyType="others"
                                                    otherUser={user}
                                                />
                                            </div>
                                        );
                                    case controlType.SLIDER:
                                        return (
                                            <div
                                                key={`question-page-${question.id}`}
                                                className="control-container"
                                            >
                                                <RangeSliderQuestion
                                                    currentUser={projectUserId}
                                                    question={question}
                                                    onAnswer={(answer) => handleAnswer(answer)}
                                                    projectTitle={currentProjectName}
                                                    surveyType="others"
                                                    otherUser={user}
                                                />
                                            </div>
                                        );
                                    case controlType.CHOICE:
                                        return (
                                            <div
                                                key={`question-page-${question.id}`}
                                                className="control-container"
                                            >
                                                <ChoiceQuestion
                                                    type="ao"
                                                    currentUser={projectUserId}
                                                    question={question}
                                                    onAnswer={(answer) => handleAnswer(answer)}
                                                    projectTitle={currentProjectName}
                                                    surveyType="others"
                                                    otherUser={user}
                                                />
                                            </div>
                                        );

                                    case controlType.MULTI_TOPICS:
                                        return (
                                            <div
                                                key={`question-page-${question.id}`}
                                                className="control-container"
                                            >
                                                <MultiTopicQuestion
                                                    type="am"
                                                    currentUser={projectUserId}
                                                    question={question}
                                                    onAnswer={(answer) => handleAnswer(answer)}
                                                    projectTitle={currentProjectName}
                                                    surveyType="others"
                                                    otherUser={user}
                                                />
                                            </div>
                                        );

                                    default:
                                        return null
                                }
                            })
                        }
                    </div>
                    :
                    <NoQuestionsContent />
            }


        </div>
        <div className='stakeholder-question-btn'>
            <BaseButton
                className={"stakeholder-question-back-btn"}
                text={'Back'}
                onClick={() => {
                    setReloadMap(true)
                    setScreenName(MY_STAKEHOLDERS)
                    args?.resetUserNodeClick()
                    if(!isLargeDevice){
                        setView(view=== 'MAP_VIEW' ? 'LIST_VIEW' : 'MAP_VIEW')}

                }}
            />
            
        </div>
    </div>
}


export default AOQuestionsList