import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import './index.scss'

import { UserCard } from "../util"
import { ApiClient } from "../../../../api/client"
import { BaseButton } from "../../../../Elements/Button"


const CatchupUsers = ({ users, queryStatus }) => {

    const client = ApiClient()
    const { projectId } = useSelector(state => state.project)

    const [limitUsers, setLimitUsers] = useState(true)


    return <div className="catch-up-users-content">
        <h2 className="catch-up-users-header">Catch-up with...</h2>
        <div className="catch-up-users-list">

            {
                queryStatus === 'STARTED' ?
                    <div>Fetching....</div>
                    :
                    users.length > 0 ?
                        users
                            .slice(0, limitUsers ? 3 : users.length)
                            .map((user, idx) => {
                                let segmentList = user.segment_user_data?.map(item => item.segment_name).join(' / ')
                                return <UserCard
                                    index={idx}
                                    key={user.id}
                                    fName={`${user.first_name} ${user.last_name}`}
                                    userTitle={user.project_user_title}
                                    segments={segmentList}
                                    onClick={() => { }}
                                />
                            })
                        :
                        queryStatus === 'COMPLETED' && <div>No Users Found</div>

            }

            {
                users.length > 3 && <div className="limit-users-btn-container">
                    <BaseButton
                        text={limitUsers ? 'See More' : 'See Less'}
                        className={'limit-users-btn'}
                        onClick={() => setLimitUsers(!limitUsers)}
                    />
                </div>
            }

        </div>

    </div>
}

export default CatchupUsers;