import './index.scss'
import { Typography } from '@mui/material';

const INFOTEXT_COLOR = '#78C9C4';

const VARIANT = 'subtitle1';

const COMPONENT = 'p';

export const InfoText = ({messageText, color = INFOTEXT_COLOR, variant = VARIANT, component = COMPONENT}) => {
    return (<div>
        <Typography className='typeText' variant={variant} component={component} color={color}>
        {messageText}
      </Typography> 
    </div>)
}
