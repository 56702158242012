
const AboutOthersTopBar = ({ activeSection, onClick }) => {

    let aboutOthersList = [
        { key: 'aboutOthers', value: 'About Others' }, 
        { key: 'myMap', value: 'My Map' }, 
        { key: 'projectMap', value: 'Project Map' }, 
        { key: 'stakeholderLabel', value: 'Stakeholder Label' },
    ]
    return <div className='about-others-topbar'>
        {
            aboutOthersList.map(item => {
                return <div
                    key={item.key}
                    className={`ao-topbar-box ${item.key === activeSection ? 'active' : ''} ${item.key}`}
                    onClick={() => onClick(item.key)}
                >
                    <span>{item.value}</span>
                </div>
            })
        }
    </div>

}

export default AboutOthersTopBar;