import React from "react";

import styles from "./styles.scss";

const IconHelp = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9 21C9 20.4477 9.44772 20 10 20H14C14.5523 20 15 20.4477 15 21C15 21.5523 14.5523 22 14 22H10C9.44772 22 9 21.5523 9 21Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5 15.6403C15.5 15.2834 15.6936 14.958 15.9869 14.7545C17.8078 13.4905 19 11.3845 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 11.3845 6.19225 13.4905 8.01313 14.7544C8.30638 14.958 8.5 15.2833 8.5 15.6403V16C8.5 17.3807 9.61929 18.5 11 18.5H13C14.3807 18.5 15.5 17.3807 15.5 16V15.6403Z" fill="currentColor"/>
    </svg>
    
  );
}

export default IconHelp;