import { useLayoutEffect, useRef, useState } from 'react';
import { replaceQuestionTextKeyWord } from '../../../utilities';
import Radio from '../../Radio';
import './index.scss'
import SkipQuestionOptionList from '../CommentSkipQuestion';
import { DEBOUNCE_DELAY, TOPIC_LIMIT } from '../../../utilities/constants';
import { BaseButton } from '../../../Elements/Button';
import EditableOption from './EditableOption';
import { ApiClient } from '../../../api/client'
import EditTopicModalDialog from './EditTopicModalDialog';
import TopicOptions from './TopicOptions';


const MultiTopicQuestion = ({
    question,
    currentUser,
    onAnswer,
    projectTitle,
    surveyType,
    otherUser,
    activeDriverId,
    setDriverList
}) => {

    const firstUpdate = useRef(true);

    const [answer, setAnswer] = useState({
        'project_user': currentUser,
        'project': question.project,
        ...(surveyType === 'me' && { 'am_question': parseInt(question.id) }),
        ...(surveyType === 'others' && { 'ao_question': parseInt(question.id) }),
        'control_type': question.control_type_readable,
        'option_id': null,
        'integer_value': 0,
        'topic_value': '',
        'comment_value': '',
        'skip_value': '',
        'topic_tags': '',
        'comment_tags': '',
        ...question.response_data[0]
    })

    const BASE_URL = surveyType === 'me' ? 'api/v1/am-topic-response/' : 'api/v1/ao-topic-response/'

    const [topicList, setTopicList] = useState([...question.topics_list])

    const [newTopic, setNewTopic] = useState({
        newInputVisible: false,
        newName: '',
        newComment: ''
    })
    const { newInputVisible, newName, newComment } = newTopic

    const [updatedTopic, setUpdatedTopic] = useState({
        isEditModalVisible: false,
        topicId: null,
        updatedName: '',
        updatedComment: ''
    })
    const { isEditModalVisible, topicId, updatedName, updatedComment } = updatedTopic

    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })

    const [saveTopicBtnDisable, setSaveTopicBtnDisable] = useState(false)

    const sortedTopicList = topicList.sort((a, b) => {
        const titleA = a.topic_name?.toString().toLowerCase();
        const titleB = b.topic_name?.toString().toLowerCase();
        return titleA.localeCompare(titleB);
    });

    const topicValidator = (topic_text, topic_id) => {
        if (topic_text.trim() === "") {
            setErr({ errField: topic_id ? 'updatedTopicName' : 'newTopicName', errMsg: 'Topic should not be empty.' })
            return;
        }
        let bExist = false;
        for (let i = 0; i < topicList.length; i++) {
            if (
                topicList[i].topic_name.toLowerCase().trim() === topic_text.toLowerCase().trim()
                &&
                topicList[i].id !== topic_id
            ) {
                bExist = true;
                break;
            }
        }
        if (bExist) {
            // NotificationManager.error("Topic is already existed", "Info", 2000);
            setErr({ errField: topic_id ? 'updatedTopicName' : 'newTopicName', errMsg: 'Topic already exists.' })
            return;
        }
        if (topic_text.trim().length > 255) {
            setErr({ errField: topic_id ? 'updatedTopicName' : 'newTopicName', errMsg: 'Topic length should be maximum 255 characters long.' })
            return;
        }
        return topic_text

    }
    const commentValidator = (comment_text, topic_id) => {
        if (comment_text.trim().length > 255) {
            setErr({ errField: topic_id ? 'updatedComment' : 'newComment', errMsg: 'Comment length should be maximum 255 characters long.' })
            return;
        }
        return true
    }

    const handleSaveTopic = (topic_id, topic, comment) => {
        let validatedTopic = topicValidator(topic, topic_id)
        let validatedComment = commentValidator(comment, topic_id)
        if (validatedTopic && validatedComment && !saveTopicBtnDisable) {
            setSaveTopicBtnDisable(true)
            let data = {
                ...(surveyType === 'me' && { 'am_question': question.id }),
                ...(surveyType === 'others' && { 'ao_question': question.id }),
                'response_user': currentUser,
                'topic_name': topic,
                'topic_comment': comment,
                'tags': '',
            }
            //API CALL HERE
            const client = ApiClient()
            if (topic_id === null) {
                client.post(BASE_URL, {
                    data: data
                }).then(response => {
                    if (response?.data) {
                        setDriverList(prevState => prevState.map(driver => {
                            if (parseInt(driver.driverId) === parseInt(activeDriverId)) {
                                driver.am_questions.map(amq => {
                                    if (parseInt(amq.id) === parseInt(question.id)) {
                                        amq.topics_list = [...topicList, response.data]
                                    }
                                    return amq
                                })
                            }
                            return driver
                        }))
                        setTopicList(state => [...state, response.data])

                        setNewTopic({
                            newInputVisible: false,
                            newName: '',
                            newComment: ''
                        })
                        onSelectOption(response.data.id, response.data.option_name)
                        setSaveTopicBtnDisable(false)
                    }
                })
            } else {
                client.put(BASE_URL + topic_id, {
                    data: data
                }).then(response => {
                    if (response?.data) {
                        setDriverList(prevState => prevState.map(driver => {
                            if (parseInt(driver.driverId) === parseInt(activeDriverId)) {
                                driver.am_questions.map(amq => {
                                    if (parseInt(amq.id) === parseInt(question.id)) {
                                        amq.topics_list = [...topicList.filter(item => item.id !== topic_id), response.data]
                                    }
                                    return amq
                                })
                            }
                            return driver
                        }))
                        setTopicList(state => [...state.filter(item => item.id !== topic_id), response.data])
                        setUpdatedTopic({
                            isEditModalVisible: false,
                            topicId: null,
                            updatedName: '',
                            updatedComment: ''
                        })
                        onSelectOption(response.data.id, response.data.option_name)
                        setSaveTopicBtnDisable(false)
                    }
                })
            }
        }
    }

    const handleDeleteTopic = (id) => {
        const client = ApiClient()
        client.del(BASE_URL + id).then(response => {
            if (response.data === 'Deleted') {
                setTopicList(state => state.filter(item => item.id !== id))
                setDriverList(prevState => prevState.map(driver => {
                    if (parseInt(driver.driverId) === parseInt(activeDriverId)) {
                        driver.am_questions.map(amq => {
                            if (parseInt(amq.id) === parseInt(question.id)) {
                                amq.topics_list = topicList.filter(item => item.id !== id)
                            }
                            return amq
                        })
                    }
                    return driver
                }))
            }
        })
    }

    const onSelectOption = (option_id, option_text) => {
        setAnswer(state => ({ ...state, topic_value: option_text, skip_value: "", option_id: option_id }))
    }

    const handleSkip = (skip_text) => {
        setAnswer(state => ({ ...state, skip_value: skip_text, topic_value: '', option_id: '' }))
    }

    const handleComment = (comment_text) => {
        setAnswer(state => ({ ...state, comment_value: comment_text }))
    }

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const debounce = setTimeout(() => {
            onAnswer(answer)
        }, DEBOUNCE_DELAY)

        return () => {
            clearTimeout(debounce)
        }
    }, [answer.topic_value, answer.comment_value, answer.skip_value])


    return (
        <div className="main-question multi-topic-question">
            <div>
                <h2 className="question-text">
                    {replaceQuestionTextKeyWord(question.question_text, otherUser, projectTitle)}
                </h2>
            </div>
            <div className="answer-section question-selector">
                {question.options_list.map((item, index) => {
                    const active = false //item.option_name === answer?.topic_value;
                    return (
                        <div key={item.id} className="option-item">
                            <Radio
                                checked={active}
                                onChange={() => console.warning('change this radio')} //onSelectOption(item.id, item.option_name)}
                            >
                                {item.option_name}
                            </Radio>
                        </div>
                    );
                })}
                {sortedTopicList.map((item) => {
                    let selectedValue = answer?.option_id;
                    const active = false // item?.id?.toString() === selectedValue?.toString();
                    return <TopicOptions
                        key={item.id}
                        item={item}
                        // onSelectOption={onSelectOption}
                        setUpdatedTopic={setUpdatedTopic}
                        handleDeleteTopic={handleDeleteTopic}
                        isActive={active}
                    />
                })}
                <div className="topic-wrapper">
                    {newInputVisible && (
                        <EditableOption
                            topic={newName}
                            comment={newComment}
                            changeTopic={setNewTopic}
                            topicPlaceholder={question.topic_prompt}
                            commentPlaceholder={question.comment_prompt}
                            error={err}
                            changeError={setErr}
                        />
                    )}
                    {!newInputVisible && topicList.length < TOPIC_LIMIT && (
                        <BaseButton
                            className="add-topic"
                            onClick={() => setNewTopic(state => ({ ...state, newInputVisible: true }))}
                            text="ADD NEW TOPIC"
                        />
                    )}
                    {newInputVisible && topicList.length < TOPIC_LIMIT && (
                        <BaseButton
                            className="save-topic"
                            onClick={() => handleSaveTopic(null, newName, newComment)}
                            text="SAVE NEW TOPIC"
                        />
                    )}
                </div>
            </div>
            <div className="comment-skip-section">
                <SkipQuestionOptionList
                    commentValue={answer?.comment_value}
                    commentPrompt={question.commentPrompt}
                    skipValue={answer?.skip_value}
                    onSkip={(skipAnswer) => handleSkip(skipAnswer)}
                    skipOption={question.skipOption}
                    skipOptionsList={question.skip_option_list}
                    onComment={(commentAnswer) => handleComment(commentAnswer)}
                />
            </div>

            {
                isEditModalVisible && <EditTopicModalDialog
                    topic_name={updatedName}
                    topic_comment={updatedComment}
                    topicPlaceholder={question.topic_prompt}
                    commentPlaceholder={question.comment_prompt}
                    updateTopic={setUpdatedTopic}
                    onSave={() => handleSaveTopic(topicId, updatedName, updatedComment)}
                    // onSave={() => console.log('updated:', )}
                    onCancel={() => setUpdatedTopic({
                        isEditModalVisible: false,
                        topicId: null,
                        updatedName: '',
                        updatedComment: ''
                    })}
                    error={err}
                />
            }

            {/* <NotificationContainer /> */}
        </div>
    );
}


export default MultiTopicQuestion;