import { useState } from 'react'
import './index.scss'

import CustomIconPicker from '../IconPicker'
import LabeledInput from './../../Elements/LabeledInput'
import { BaseButton } from '../../Elements/Button'
import { ApiClient } from '../../api/client'


const EditSegmentPopup = ({ segmentTypeData, setSegmentTypeModal, handleSegmentTypeChange }) => {
    const [formData, setformData] = useState({
        segmenttype: segmentTypeData.segmentTypeName,
        description: segmentTypeData.segmentTypeDescription,
        isincludedSHForm: segmentTypeData.segmentTypeShowSHForm,
        isincludedShList: segmentTypeData.segmentTypeShowSHList,
    })
    const { segmenttype, description, isincludedSHForm, isincludedShList } = formData
    const [error, setError] = useState('')

    const handleEditForm = (e) => {
        let { name, value } = e.target;
        if (formData.segmenttype) {
            setError('')
        }
        if (name === 'isincludedSHForm') {
            setformData(s => ({ ...s, [name]: !formData.isincludedSHForm }));
        }
        else if (name === 'isincludedShList') {
            setformData(s => ({ ...s, [name]: !formData.isincludedShList }));
        }
        else {
            setformData({ ...formData, [name]: value });
        }

    }
    const handleCancel = () => {
        setSegmentTypeModal(false)
    }

    const handleSubmit = () => {
        if (segmenttype.trim().length == 0) {
            setError('*This Field is Mandatory')
        }
        else {
            let obj = {
                segmentTypeId: segmentTypeData.segmentTypeId,
                ...(segmentTypeData.segmentTypeName !== segmenttype && { segmentTypeName: segmenttype }),
                ...(segmentTypeData.segmentTypeDescription !== description && { segmentTypeDescription: description }),
                ...(segmentTypeData.segmentTypeShowSHForm !== isincludedSHForm && { segmentTypeShowSHForm: isincludedSHForm }),
                ...(segmentTypeData.segmentTypeShowSHList !== isincludedShList && { segmentTypeShowSHList: isincludedShList })
            }
            let err = handleSegmentTypeChange(obj)
            if (err === 'DUPLICATE_ERROR') {
                return setError('*Segment Type already exists in the project.')
            }
            setSegmentTypeModal(false)
        }
    }

    return <div style={{ width: '100%' }}>
        <h3 className='heading'>Edit Segment Type</h3>
        <LabeledInput
            name='segmenttype'
            label='Segment Type'
            type='text'
            value={segmenttype}
            error={(segmenttype).length > 40 && 'The text content of the Segment Type can contain up to 40 characters.'}
            required={true}
            onChange={handleEditForm}
        />
        <p style={{ color: 'red', fontSize: '0.75rem' }}>{error}</p>
        <LabeledInput
            name='description'
            label='Description'
            type='text'
            value={description}
            error={description?.length > 280 && 'The text content of the Description can contain up to 280 characters.'}
            onChange={handleEditForm}
            required={false}
            textarea={true}
            isOptional={true}
        />
        <span className='input-label1'>
            Included in Add SH Form?
            <input
                type='checkbox'
                name='isincludedSHForm'
                style={{ marginLeft: '3px' }}
                onChange={handleEditForm}
                checked={isincludedSHForm}
            />
        </span>
        {/* <span className='input-label1'>
            Included in Stakeholder List?
            <input
                type='checkbox'
                name='isincludedShList'
                style={{ marginLeft: '3px' }}
                onChange={handleEditForm}
                checked={isincludedShList}
            />
        </span> */}
        <div className='modal-action-button-section'>
            <BaseButton
                className=' modal-cancel-btn'
                text='Cancel'
                variant='filled'
                onClick={handleCancel}
            />
            <BaseButton
                className=' modal-submit-btn'
                text='Update'
                variant='filled'
                onClick={handleSubmit}
            />
        </div>

    </div>
}


export default EditSegmentPopup 