import Joyride from 'react-joyride';
import TourToolTip from './tooltip';

const TourWizard = ({setShowTour,showTour,setChecked,checked}) => {
    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
     
        if(action=='skip' || action=='reset')
        {
            setShowTour(false);
            setChecked(!checked);
        
        }
    }

    const steps = [
        {
            target: '.admin-sidebar',
            content: 'Slide out menu',
            disableBeacon : true,
            placement:'right',
        },
        {
            target: '.menu-close',
            content: 'Toggle Menu',
            disableBeacon : true,
            placement:'bottom',
        
        },
        {
            target: '.Drivers-menu',
            content: 'Configuration Process',
            disableBeacon : true,
            placement:'right',
            
        },
        
        {
            target: '.bottom-bar-back',
            content: 'Back',
            disableBeacon : true,
            placement:'top',
 
        },
        
        {
            target: '.bottom-bar-next',
            content: 'Next',
            disableBeacon : true,
            placement:'top',
           

        },
        {
            target: '.bottom-bar-help',
            content: 'Help',
            disableBeacon : true,
            placement:'top',

            
        },
    ]

    return <Joyride
        steps={steps}
        continuous={true}
        disableScrollParentFix={true}
        showProgress={true}
        showSkipButton={true}
        hideCloseButton={true}
        run={showTour}
        callback={handleJoyrideCallback}
       
        locale={{
            last: "End tour",
            skip: "Close tour"
          }}
     
        styles={{
            options: {
              arrowColor: '#3F4042',
              backgroundColor: '#3F4042',
              primaryColor: '#000',
              textColor: '#fff',
              width: 'fit-content',
              zIndex: 1000,
              fontSize: '13px',
             
            }
          
          }}

          tooltipComponent={TourToolTip}
        

    />
}

export default TourWizard;