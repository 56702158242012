import './index.scss'
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ modal_content, handleModal, width, style, modalClassName }) => {

    return <div className={`modal-wrapper ${modalClassName}`}>
        <div className="modal-container" style={{ width: width, ...style }}>
            <div className="close-modal" onClick={() => handleModal(false)}>
                <span >
                    <CloseIcon sx={{ color: '#000000', fontSize: '22px' }} />
                </span>
            </div>
            {modal_content}
        </div>
    </div>
}


export default Modal