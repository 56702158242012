
import Loader from '../Loader';
import './index.scss';
import ProjectTableRow from './ProjectTableRow';

const ProjectTable = ({
    queryStatus,
    projectsArr,
    setProjects,
    projectSelection,
    updateProjectSelection,
    listType='subscriptions'
}) => {

    return <table className='project-table'>
        <thead className='prtable-header'>
            <tr className='prtable-head-row' style={{ backgroundColor: '#F7F7F7' }}>
                {listType === 'subscriptions' &&
                    <th className='select-all-rows select'>
                        <input
                            type='checkbox'
                            onChange={() => {
                                let projectIds = projectsArr.map(project => project.project_id)
                                updateProjectSelection(prevState => ({
                                    ...prevState,
                                    isAllSelected: !prevState.isAllSelected,
                                    selectedProjects: prevState.isAllSelected ?
                                        []
                                        :
                                        [...new Set([...projectSelection.selectedProjects, ...projectIds])]
                                }))
                            }}
                            checked={projectSelection.isAllSelected}
                        />
                        <span></span>
                    </th>
                }
                <th className='subdriver-th'>Project Name<span></span></th>
                <th className='tablehead'>Launch Date<span></span></th>
                <th className='tablehead'>Identified<span></span></th>
                <th className='tablehead'>Invited<span></span></th>
                <th className='tablehead'>Seats Available<span></span></th>
                <th className='project-admins-th'>Project Admin(s)<span></span></th>
                <th className='tablehead'>Status<span></span></th>
                <th className='blank-th'></th>
                {listType === 'subscriptions' &&
                    <th className='blank-th'></th>
                }

            </tr>
        </thead>

        <tbody className='table-body'
        >
            {
                queryStatus === 'STARTED' ?
                    <Loader />
                    :
                    projectsArr.length ? projectsArr.map((item, idx) => {
                        return <ProjectTableRow
                            key={item.project_id}
                            item={item}
                            setProjects={setProjects}
                            projectSelection={projectSelection}
                            updateProjectSelection={updateProjectSelection}
                            listType={listType}
                        />
                    })
                        :
                        queryStatus === 'COMPLETED' &&
                        <tr style={{}}>
                            <td colSpan={10} style={{ margin: '20px 0', padding: '20px', textAlign: 'center' }}>
                                No Project Found
                            </td>
                        </tr>
            }
        </tbody>

    </table>
}
export default ProjectTable;