
import { Fragment, useState } from 'react';
import './index.scss'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ApiClient } from '../../../api/client';
import { QUESTIONS_LIST, UserCard } from './utils';
import Loader from '../../../components/Loader';
import shSearch from '../../../assets/svgs/stakeholder-search.svg'

const MyStakeholder = ({
    setScreenName,
    setStakeholderCount,
    myMaps,
    projectMaps,
    setSelectedUser,
}) => {
    const client = ApiClient()
    const [usersList, setUsersList] = useState([])
    const [apiStatus, setApiStatus] = useState('NOT_STARTED')
    const { projectId } = useSelector(state => state.project)

    useEffect(() => {
        if (projectId) {
            setApiStatus('STARTED')
            client
                .get(`/api/v1/ao-project-users/${projectId}/?page_size=50&mapped=true`)
                .then(res => {
                    setUsersList(res.data)
                    setApiStatus('DONE')
                    setStakeholderCount(res.data.length)
                })
        }
    }, [projectId])

    if (usersList.length === 0 && apiStatus === 'STARTED') return <div style={{ position: 'relative' }}><Loader /></div>
    if (usersList.length === 0 && apiStatus === 'DONE') return <div className="no-stakeholders">
        <img src={shSearch} alt="search" />
        <span>
            <strong>No Stakeholders Selected.</strong>
        </span>
        <span>
            Click “
            <strong
                onClick={(e) => { }}
                style={{ cursor: "pointer" }}
            >
                Search
            </strong>
            ” to add your first one.
        </span>
    </div>

    return <div className='my-stakeholder-list'>

        {
            myMaps.map(item => {
                let users = usersList.filter(user => user.mapping_data.my_maps.map(k => k.my_map)?.includes(item.segment_my_map_id))

                return <Fragment key={item.segment_my_map_id}>
                    {
                        users.length ?
                            <div className='my-stakeholder-box'>
                                <div className='header'><span className='title'>My Map:</span><span className='map-type-title'> {item.segment_my_map_name}</span></div>
                                <div className='users-list'>
                                    {
                                        users.map((user) => {
                                            return <UserCard
                                                key={user.id}
                                                fName={`${user.first_name} ${user.last_name}`}
                                                segments={user?.segment_user_data?.map(item => item.segment_name).join(' / ')}
                                                rightArrow={false}
                                                userTitle={user.project_user_title}
                                                onClick={() => {
                                                    setSelectedUser(user)
                                                    setScreenName(QUESTIONS_LIST)
                                                }}
                                                value={user.response_completed}
                                                overall_sentiment_score={user.overall_sentiment_score}
                                            />
                                            }
                                        )
                                    }
                                </div>

                            </div>
                            : null

                    }
                </Fragment>
            })
        }
        {
            projectMaps.map(item => {
                let users = usersList.filter(user => user.mapping_data.project_maps.map(item => item.project_map)?.includes(item.segment_project_map_id))

                return <Fragment key={item.segment_project_map_id}>
                    {
                        users.length ?
                            <div className='my-stakeholder-box'>
                                <div className='header'><span className='title'>Project Map:</span><span className='map-type-title'> {item.segment_project_map_name}</span></div>
                                <div className='users-list'>
                                    {
                                        users.map((user) => {
                                            return <UserCard
                                                key={user.id}
                                                fName={`${user.first_name} ${user.last_name}`}
                                                segments={user.segment_user_data.map(item => item.segment_name).join(' / ')}
                                                rightArrow={false}
                                                userTitle={user.project_user_title}
                                                onClick={() => {
                                                    setSelectedUser(user)
                                                    setScreenName(QUESTIONS_LIST)
                                                }}
                                                value={user.response_completed}
                                                overall_sentiment_score={user.overall_sentiment_score}
                                            />
                                            }
                                        )
                                    }
                                </div>

                            </div>
                            :
                            null
                    }
                </Fragment>
            })
        }

    </div>
}

export default MyStakeholder;

