import { useRef, useLayoutEffect, useEffect, useState, } from 'react';
import './index.scss';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import WebFont from 'webfontloader';
import { ApiClient } from '../../api/client';
//import { getGradientColorFromValue, mapsColors } from './userSection/utils';
import { useSelector } from 'react-redux';

const palette = {
  brown: 'rgb(166, 86, 40)',
  blue: 'rgb(55, 126, 184)',
  orange: 'rgb(255, 127, 0)',
  green: 'rgb(77, 175, 74)',
  red: 'rgb(228, 26, 28)',
  purple: 'rgb(152, 78, 163)',
  grey: 'rgb(153, 153, 153)',
  darkgrey: 'rgb(50, 50, 50)',
  fadeblue: 'rgb(217,228,238)',
};


function Amcharts({ mapType = 'my-map', groupData, graphLayout, handleUserNodeClick, ...args }) {
  const chartRef = useRef(null);
  const { projectId, currentProjectName } = useSelector(state => state.project);
  
  const [chartDataSet, setChartDataSet] = useState();
  const [mapDataSet, setMapDataSet] = useState();
  const [newExpandData, setNewExpandData] = useState([])
  const [chartInstance, setChartInstance] = useState()
  const [comboIds, setComboIds] = useState([])
  const [comboData, setComboData] = useState({})
  const [baseLabel, setBaseLabel] = useState('ME')
  const [baseIcon, setBaseIcon] = useState('fa-user')
  const [segmentData, setSegmentData] = useState();
  const [baseIconColor, setBaseIconColor] = useState('#5b5b5b')
  const [baseColor, setBaseColor] = useState(palette.fadeblue)


  const baseData = {
    value: 0,
    children: [{
      name: baseLabel,
      value: 30,
      children:[]
      
    },]
  };
  const [dataSet, setDataSet] = useState(baseData)
  function geticon_map(segment_name) {
    let icon_value = ''
  
    for (let i = 0; i < segmentData?.length; i++) {
      segmentData[i].segmentsList.forEach(element => {
        if (element['title'] == segment_name) {
          
          icon_value = element['icon']
        }
      });


    }
    return icon_value

  }
  const getChartData = (projectId) => {
    const client = ApiClient()

    if (projectId) {
      client.get('/api/v1/ao-graph-data/' + projectId + '/').then(
        (response) => {
          if (response?.data) {
           
            setChartDataSet(response.data)
            //args?.setReloadMap(false)
          }
        })
    }
  }
  useEffect(() => {

    //if(args?.reloadMap){
    if (true) {
  
      getChartData(projectId)
    }
  }, [projectId, args?.reloadMap])

  useEffect(() => {

    
    if (chartDataSet && Object.keys(chartDataSet).length > 0) {
      //resetInstance(chartInstance)
      if (mapType === 'my-map') {
        setBaseIcon('fa-user')
        setBaseColor(palette.fadeblue)
        setBaseIconColor('#5b5b5b')
        setBaseLabel('ME')
        setMapDataSet(chartDataSet?.my_map)
      } else if (mapType === 'project-map') {
        setBaseIcon('fa-sitemap')
        setBaseIconColor('#FFFFFF')
        setBaseColor('#7030A0')
        setBaseLabel(currentProjectName)
        setMapDataSet(chartDataSet?.project_map)
      }
    }
  }, [mapType, chartDataSet, chartInstance, currentProjectName])
  useEffect(() => {
   
    if(mapDataSet && Object.keys(mapDataSet).length > 0){
        setDataSet(baseData)
        setNewExpandData([])
        Object.keys(mapDataSet).map((map_label, idx) => {
          let child_dic=[]
          for (let i=0;i<mapDataSet[map_label].data.length;i++)
          {
          child_dic.push({
            name : mapDataSet[map_label].data[i].project_user_full_name,
            value:mapDataSet[map_label].data[i].overall_sentiment_score

          }
          )
        
        }

          return setNewExpandData(prev => [...prev, {
            name: map_label,
            value: 15,
            children:child_dic,
                
        },
    ])
         
        })  
    }
   
   
    setDataSet(prev =>{
      
      prev.children[0].children=newExpandData
      return { ...prev}
    }
  
    )

}, [mapDataSet])

  useEffect(()=>{

  },[dataSet])
    // Creates the chart, this code only runs one time
    useEffect(() => {
      var root = am5.Root.new("chartdiv");

  // Set themes
  // https://www.amcharts.com/docs/v5/concepts/themes/
  root.setThemes([
    am5themes_Animated.new(root)
  ]);

  
  // Create wrapper container
  var container = root.container.children.push(am5.Container.new(root, {
    width: am5.percent(100),
    height: am5.percent(100),
    layout: root.verticalLayout
  }));

  // Create series
  // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
  var series = container.children.push(am5hierarchy.ForceDirected.new(root, {
    singleBranchOnly: false,
    downDepth: 2,
    topDepth: 1,
    initialDepth: 1,
    valueField: "value",
    categoryField: "name",
    childDataField: "children",
    idField: "name",
    linkWithField: "linkWith",
    manyBodyStrength: -100,
    centerStrength: 0.8
  }));

  series.labels.template.setAll({
    fontSize: 14,
    oversizedBehavior: "none",
    paddingLeft: 0,
    paddingRight: 0
  });
  console.log(series)
  //series.labels.template.html="<h2>Chart title</h2><h4>And a smaller sub-title</h4>"
  series.labels.template.adapters.add("centerX", function(centerX, target) {
    var circle = target.dataItem.get("circle");
    var radius = circle.get("radius") + 10;
    var width = target.width();
    
    if (width > (radius)) {
      target.set("y", radius +10)
      target.set("fill", am5.color(0x000000));
    }
    else {
      target.set("y", 0);
      target.set("fill", am5.color(0xffffff));
    }
    return centerX;
  });
  series.children.unshift(am5.Label.new(root, {
    html: "<h2>Chart title</h2><h4>And a smaller sub-title</h4>",
    x: am5.percent(50),
    centerX: am5.percent(50),
    paddingTop: 0,
    paddingBottom: 0
  }));
  series.get("colors").setAll({
    step: 2
  });

  series.links.template.set("strength", 0.5);

  
  series.data.setAll([dataSet]);

  series.set("selectedDataItem", series.dataItems[0]);


  // Make stuff animate on load
  series.appear(1000, 100);
      //chartRef.current = container;

      return () => {
        root.dispose();
      };
    }, [dataSet]);

  //When the paddingRight prop changes it will update the chart
 
    
    

  return (
    <div id="chartdiv" ref={chartRef} ></div>
  );
}
export default Amcharts;