import './index.scss'
import { BaseButton } from '../../Elements/Button';
import {
    trash,
    openBook,
    pen
} from './../../assets/svgs/questions'
import { useEffect, useState } from 'react';
import Table from './Table';
import SelectDropDown from '../../Elements/SelectDropDown'
import { ApiClient } from '../../api/client';
import { useSelector } from 'react-redux';




const TableQuickActions = ({ handleAddQuestion, handleDeleteSelected, segmentsData, setSelectedSegmentTypeId, selectedQuestions }) => {

    useEffect(() => {
        if(segmentsData.length){
            setSelectedSegmentTypeId(segmentsData[0]['segmentTypeId'])
        }
    }, [segmentsData, setSelectedSegmentTypeId])

    return (segmentsData.length > 0 &&
        <>
        <p className='table-title'>Questions</p>
        <div className='question-quick-actions'>
            <span className='action segmenttype-dropdown-title'>Segment Type</span>
            <span className='segmenttype-dropdown action'>
                <SelectDropDown
                    optionList={segmentsData}
                    valueField='segmentTypeId'
                    itemField='segmentTypeName'
                    handleOnChange={setSelectedSegmentTypeId}
                    defaultValue={segmentsData[0]['segmentTypeId']}
                />
            </span>
            <span className='delete-ques action'>
                <BaseButton
                    variant='text'
                    className={'delete delete-question-btn'}
                    text={<>
                        <img src={trash} alt='delete' />
                        <span>Delete</span>
                    </>}
                    onClick={() => handleDeleteSelected(selectedQuestions)}
                    isDisabled={!selectedQuestions.length}
                />
            </span>
            <span className='question-library action' onClick={ () => window.open('https://projectai.com/pulse-admin-help-question-library','_blank')}>
                <img src={openBook} alt="library" />
                <span> Question Library</span>
            </span>
            <span className='add-new-ques action'>
                <BaseButton
                    variant="outlined"
                    className={'add-new-question-btn'}
                    text={<> + Add New</>}
                    onClick={() => handleAddQuestion(true)}
                />
            </span>
        </div>
    </>
    )
}


const QuestionTable = ({ questionArr, handleAddQuestion, handleDeleteSelected, handleQuestionEdit, segmentArr, handleQuestionSegmentToggle, handleOnDragEnd}) => {

    const [segmentsData, setSegmentData] = useState([])
    const [selectedSegmentTypeId, setSelectedSegmentTypeId] = useState()
    const [selectedQuestions, setSelectedQuestions] = useState([])
    
    useEffect(() => {
        if(segmentArr){
            setSegmentData(segmentArr)
        }
        return () => {
        }
    }, [segmentArr])

    
    return <div className='question-table-container'>
        <div className='container-header'>
            <TableQuickActions handleAddQuestion={handleAddQuestion} handleDeleteSelected={handleDeleteSelected} segmentsData={segmentsData} setSelectedSegmentTypeId={setSelectedSegmentTypeId} selectedQuestions={selectedQuestions}/>
        </div>

        <div className='table-box'>
            <Table
                questionsArr={questionArr}
                handleOnDragEnd={handleOnDragEnd}
                handleQuestionEdit={handleQuestionEdit}
                segmentArr={segmentsData}
                selectedSegmentTypeId={selectedSegmentTypeId}
                setSelectedQuestions={setSelectedQuestions}
                handleQuestionSegmentToggle={handleQuestionSegmentToggle}
            />
        </div>
    </div>
}


export default QuestionTable;




