
import { Fragment, useEffect, useState } from "react";
import Checkbox from "../../Elements/Checkbox";
import { BaseButton } from "../../Elements/Button";
import Modal from "../../components/Modal";
import AboutOthersPopup from "../../components/AboutOthersPopup";
import { ApiClient } from '../../api/client';
import { useSelector } from "react-redux";

const ProjectMapSection = ({setMappingData, handleCheck}) => {

    const [projectMapObj, setProjectMapObj] = useState([])

    const [showAddProjectMapModal, setAddProjectMapModal] = useState(false)
    const { projectId } = useSelector(state => state.project)
    const [atleastOneChecked, setAtleastOneChecked] = useState(true)
    
    useEffect(() => {
        if (projectMapObj) {
            setMappingData({
                type: 'projectMap',
                data: projectMapObj
            })
        }
    }, [projectMapObj, setMappingData])
    
    useEffect(() => {
        if (projectId) {
            const client = ApiClient()
            client.get('/api/v1/project-map/' + projectId + '/').then((response) => {
                if (response?.data) {
                    setProjectMapObj(response?.data)
                }
            })
        }
    }, [projectId])

    useEffect(() => {
        setAtleastOneChecked(!projectMapObj.map(item => item.isChecked).includes(true))
    }, [projectMapObj])

    useEffect(() => {
        setAtleastOneChecked(!projectMapObj.map(item => item.isChecked).includes(true))
    }, [projectMapObj])

    useEffect(() => {
        handleCheck(atleastOneChecked)
    }, [atleastOneChecked, handleCheck])

    const handleSubmit = (data) => {
        const client = ApiClient()
        client.post('/api/v1/project-map/' + projectId + '/', data = {
            title : data.mapping,
            icon: data.icon
        }).then((response) => {
            if (response?.data) {
                setProjectMapObj(response?.data)
                setAddProjectMapModal(false)
            }
        }).catch((error) => {
            if (error?.response?.data) {
                if(error?.response?.data?.data?.non_field_errors[0] === 'The fields project, title must make a unique set.'){
                    return 'Already exists'
                }
                console.log(error?.response?.data)
            }
        })
    }

    const handleCancel = (data) => {
        setAddProjectMapModal(false)
    }

    return <>
        <div className='checkbox-option-list'>

            {
                projectMapObj.map(item => {
                    return <Fragment key={item.title}>
                        <Checkbox
                            svgIcon={item.icon}
                            title={item.title}
                            subtitle={item.subtitle}
                            checked={item.isChecked}
                            onCheckboxChange={() =>
                                setProjectMapObj(prevState => (
                                    [
                                        ...prevState.map(obj =>
                                            obj.title === item.title ?
                                                { ...obj, isChecked: !item.isChecked }
                                                :
                                                obj
                                        )
                                    ]
                                ))
                            }
                        />
                    </Fragment>
                })
            }

        </div>
        <div className='add-new-div'>
            <BaseButton
                text={'+ add new'}
                className="add-new-btn"
                onClick={() => setAddProjectMapModal(!showAddProjectMapModal)}
            />
        </div>

        {
            showAddProjectMapModal && <Modal
            modalClassName="map-modal-wrapper"
                handleModal={setAddProjectMapModal}
                modal_content={<AboutOthersPopup
                    title='Project Map'
                    name='mapping'
                    label='Project Mapping Category'
                    hasDescription={false} 
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    />}

            />
        }
    </>
}

export default ProjectMapSection;