import './index.scss'
import Layout from "../../components/Layout";
import UsersTable from "../../components/UsersTable";
import { useEffect, useState } from "react";
import UserPopup from "../../components/UserPopup";
import Modal from "../../components/Modal";
import ReactPaginate from 'react-paginate';
import { ApiClient } from '../../api/client';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { projectAction } from '../../redux/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BulkDeleteConfirmationPopup from '../../components/ConfirmationPopup'

const RESEND_INVITE = 1
const INVITE_NEW = 2

const UserTypeArr = [
    {
        id: RESEND_INVITE,
        name: 'Resend Invite'
    },
    {
        id: INVITE_NEW,
        name: 'Invite New Users'
    }
]

const Users = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const pageSize = 50
    const { state } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const { projectId, isEdit, isDraft } = useSelector(state => state.project)
    let IS_PROJECT_LAUNCHED = isEdit && !isDraft
    const [segmentsData, setSegmentData] = useState([])
    const [activeUserType, setActiveUserType] = useState(1)
    const [activeUserTypeQueryParams, setActiveUserTypeQueryParams] = useState('&is_invited=')
    const [atLeastOneChecked, setAtLeastOneChecked] = useState(true)
    const [userResponse, setUserResponse] = useState({
        users: [],
        nextLink: '',
        prevLink: '',
        usersCount: 1,
        page: 1,
    })
    const [segmentsFilters, setSegmentFilters] = useState({
        showFiltersList: '',
        checkedSegments: [],
        filteredSegmentTypes: [],
        filteredObj: {}
    })

    let BASE_PREFIX = `/api/v1/project-user/${projectId}/?`


    const [userSelect, setUserSelect] = useState({
        isAllSelected: false,
        selectedUsers: []
    })
    const { isAllSelected, selectedUsers } = userSelect
    const updateSelectedUser = (id) => {
        let updatedUsers = selectedUsers.includes(id) ? selectedUsers.filter(k => k !== id) : [...new Set([...selectedUsers, id])]
        setUserSelect(prevState => ({ ...prevState, selectedUsers: updatedUsers }))
    }

    const [bulkDeletePopup, setBulkDeletePopup] = useState(false)
    const [errMsg, setErrMsg] = useState('')

    useEffect(() => {
        if (IS_PROJECT_LAUNCHED) setActiveUserTypeQueryParams('&is_invited=true')
    }, [isEdit])

    useEffect(() => {
        if (IS_PROJECT_LAUNCHED) {
            if (activeUserType === INVITE_NEW) {
                setActiveUserTypeQueryParams('&is_invited=false')
            } else {
                setActiveUserTypeQueryParams('&is_invited=true')
            }
        }
    }, [activeUserType])

    useEffect(() => {
        if (!projectId) {
            if (state?.projectId) {
                dispatch(projectAction(state?.projectId))
            }
            else if (searchParams.get('projectId')) {
                dispatch(projectAction(searchParams.get('projectId')))
            }
        }
    }, [projectId, searchParams])

    useEffect(() => {
        if (projectId) {
            const client = ApiClient(setSegmentData)
            client.get('/api/v1/segments/' + projectId + '/')
                .then((response) => {
                    if (response?.data) {
                        setSegmentData(response.data)
                    }
                })
        }
    }, [projectId])

    const handleUserType = (userType) => {
        if (atLeastOneChecked) {
            setActiveUserType(userType)
            setUserSelect({ isAllSelected: false, selectedUsers: [] })
        }
    }

    const getUsers = (API_URL, updateUserResponse) => {
        updateUserResponse(prevState => ({
            ...prevState,
            users: [],
            usersCount: 0,
        }))
        const client = ApiClient()
        client.get(API_URL)
            .then((response) => {
                if (response?.data) {
                    updateUserResponse(prevState => ({
                        ...prevState,
                        users: response.data,
                        nextLink: response.next,
                        prevLink: response.prev,
                        usersCount: response.count
                    }))
                    return response.data
                }
            })
    }
    useEffect(() => {
        if (projectId) {
            let API_URL = BASE_PREFIX + activeUserTypeQueryParams + '&page_size=' + pageSize
            getUsers(API_URL, setUserResponse)
        }
    }, [projectId, activeUserTypeQueryParams])

    const [searchUser, setSearchUser] = useState('')
    const [addNewUserPopup, setAddNewUserPopup] = useState(false)
    const [editUserPopup, setEditUserPopup] = useState(false)

    const handleSearchUser = (e) => {
        setSearchUser(e.target.value)
        if (projectId) {
            let API_URL = BASE_PREFIX + activeUserTypeQueryParams + '&search=' + e.target.value + '&page_size=' + pageSize
            getUsers(API_URL, setUserResponse)
        }
        setUserSelect({ isAllSelected: false, selectedUsers: [] })
    }

    const [sort, setSort] = useState({
        by: '',
        order: 'asc'
    })

    const handleSorting = (property) => {
        setUserSelect({ isAllSelected: false, selectedUsers: [] })
        let order = 'asc'
        if (sort.by === property) {
            order = sort.order !== 'desc' ? 'desc' : 'asc'
            setSort(prevState => ({ ...prevState, order: order }))
        }
        else {
            setSort(prevState => ({ ...prevState, by: property, order: 'asc' }))
        }
        if (projectId) {
            let API_URL = BASE_PREFIX + activeUserTypeQueryParams + '&sort_by=' + property + '&order=' + order + '&page_size=' + pageSize
            getUsers(API_URL, setUserResponse)
        }
    }
    const pageCount = Math.ceil(userResponse.usersCount / pageSize);

    const handlePageClick = (event) => {
        if (projectId) {
            let API_URL = BASE_PREFIX + activeUserTypeQueryParams +
                '&sort_by=' + sort.by + '&order=' + sort.order +
                '&search=' + searchUser +
                '&page=' + (event.selected + 1) + '&page_size=' + pageSize
            getUsers(API_URL, setUserResponse)
            setUserResponse(prevState => ({
                ...prevState,
                page: event.selected + 1
            }))

        }
    };
    useEffect(() => {
        if (isAllSelected) {
            let ids = userResponse.users.map(item => item.id)
            setUserSelect(prevState => ({ ...prevState, selectedUsers: [...new Set([...selectedUsers, ...ids])] }))
        }
    }, [userResponse.users, userResponse.page])

    const handleDeleteUserBulk = () => {
        const client = ApiClient()
        let API_URL = BASE_PREFIX + activeUserTypeQueryParams
        if (isAllSelected) {
            API_URL = API_URL + '&all_selected=' + isAllSelected
            if (segmentsFilters.checkedSegments.length > 0) {
                let segments_list_string = segmentsFilters.checkedSegments.join(",")
                let segmentsTypes_list_string = segmentsFilters.filteredSegmentTypes.join(",")
                API_URL = API_URL + '&segments=' + segments_list_string +
                    '&segmentTypes=' + segmentsTypes_list_string +
                    activeUserTypeQueryParams
            }
        }
        else {
            API_URL = BASE_PREFIX + activeUserTypeQueryParams + '&project_user_ids=' + selectedUsers
        }
        client.del(API_URL)
            .then((response) => {
                if (response?.data) {
                    setUserResponse(prevState => ({
                        ...prevState,
                        users: response.data,
                        nextLink: response.next,
                        prevLink: response.prev,
                        usersCount: response.count
                    }))
                }
                setBulkDeletePopup(false)
            })
    }
    let deleteCount = isAllSelected ? 'all ' + userResponse.usersCount : selectedUsers.length

    const nextBtnConditions = () => {
        const client = ApiClient()
        client.post('api/v1/journeysteps/', {
            project: projectId,
            step_id: 6,
            step_name: 'Users'
        }).then((res) => {
        }).catch((error) => {
            console.log(error)
        })

        return navigate({
            pathname: '/setup/permissions',
            search: '?projectId=' + projectId
        }, { state: state })

    }

    return <Layout
        active_page={'Users'}
        onClickNext={() => nextBtnConditions()}
        onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#UsersOverview','_blank')}
        onClickBack={() => navigate({
            pathname: '/setup/about-others',
            search: '?projectId=' + projectId
        }, { state: { projectId } })}
    >
        <div className="users-section section">
            <div className='users section-container'>
                <div className='section-content'>
                    <div>
                        <h1 className='section-title'>Users</h1>
                        {IS_PROJECT_LAUNCHED &&
                            <UserTypeBar userTypeList={UserTypeArr} activeUserType={activeUserType} onClick={handleUserType} />
                        }
                        {((IS_PROJECT_LAUNCHED && activeUserType === INVITE_NEW) || !IS_PROJECT_LAUNCHED) &&
                            <p className="section-description">
                                Tell us about the people you'd like to invite to Pulse. Add user information individually by entering data directly into the table below or use the bulk upload option. To use the bulk upload option, download the .csv template and enter all details for your users. When completed, select the Upload Users button to upload the file.
                            </p>
                        }
                        {errMsg.length > 0 &&
                            <p className="err-txt">
                                {errMsg}
                            </p>
                        }
                    </div>
                    <div className="section-main-content" style={{ marginTop: '5px' }}>
                        <div className="users-table-section">
                            <UsersTable
                                userResponse={userResponse}
                                handleAddUser={setAddNewUserPopup}
                                handleUserEdit={setEditUserPopup}
                                searchedUserValue={searchUser}
                                handleSearchUser={handleSearchUser}
                                segmentsData={segmentsData}
                                handleSorting={handleSorting}
                                sort={sort}
                                // handleDeleteUser={handleDeleteUser}
                                setDeleteBulkPopup={setBulkDeletePopup}
                                isAllSelected={isAllSelected}
                                selectedUsers={selectedUsers}
                                setUserSelect={setUserSelect}
                                updateSelectedUser={updateSelectedUser}
                                projectId={projectId}
                                allowQuickAction={activeUserType === INVITE_NEW}
                                editMode={IS_PROJECT_LAUNCHED}
                                setUserResponse={setUserResponse}
                                activeUserTypeQueryParams={activeUserTypeQueryParams}
                                segmentsFilters={segmentsFilters}
                                setSegmentFilters={setSegmentFilters}
                                setErrMsg={setErrMsg}
                            />
                        </div>

                        <div>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={10}
                                pageCount={pageCount > 1 ? pageCount : 0}
                                renderOnZeroPageCount={null}
                                breakClassName='paginate-li'
                                breakLinkClassName='paginate-anchor'
                                containerClassName='paginate-container'
                                className="pagination"
                                pageClassName="paginate-li"
                                previousClassName="prev"
                                nextClassName="next"
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
        {
            addNewUserPopup && <Modal
                modalClassName="users-modal-wrapper"
                modal_content={
                    <UserPopup
                        handleCancel={setAddNewUserPopup}
                        segmentsData={segmentsData}
                        projectId={projectId}
                        setUserResponse={setUserResponse}
                        activeUserTypeQueryParams={activeUserTypeQueryParams}
                    />
                }
                handleModal={setAddNewUserPopup}
            />
        }

        {
            editUserPopup !== false && <Modal
                modalClassName="users-modal-wrapper"
                modal_content={
                    <>
                        <UserPopup
                            isEdit={true}
                            handleCancel={setEditUserPopup}
                            userData={userResponse.users.filter(item => item.id === editUserPopup)[0]}
                            segmentsData={segmentsData}
                            projectId={projectId}
                            setUserResponse={setUserResponse}
                            activeUserTypeQueryParams={activeUserTypeQueryParams}
                        />
                    </>
                }
                handleModal={setEditUserPopup}
            />
        }
        {
            bulkDeletePopup && <BulkDeleteConfirmationPopup
                modalClassName={"confirmation-modal-wrapper"}
                handleCancel={setBulkDeletePopup}
                text={
                    `Are you sure you want to remove ${deleteCount} ${(selectedUsers.length > 1) ? 'users' : 'user'}?`
                }
                handleSubmit={handleDeleteUserBulk}
            />
        }


    </Layout>
}

const UserTypeBar = ({ userTypeList, activeUserType, onClick }) => {

    if (userTypeList) {
        return <div className='usertype-topbar'>
            {
                userTypeList.map((usertype) => {
                    return <div
                        key={usertype.id}
                        className={`usertype-topbar-box ${usertype.id === activeUserType ? 'active' : ''} ${usertype.name}`}
                        onClick={() => onClick(usertype.id)}
                    >
                        <span>{usertype.name}</span>
                    </div>
                })
            }

        </div>
    }

}

export default Users;

