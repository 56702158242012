import React, { Fragment } from "react";

import styles from "./styles.scss";
import { noAmresponse, noDashboard, noConfigured } from '../../assets/images/report';
import { useLocation } from "react-router-dom";
/**
 * code
 * 228: amreponse not completed
 * 227: not enough threshcnt
 * 400: no data
 */
const NoDashboard = () => {
  const {state} = useLocation();
  const { status_type, criteria_met, anonymity_threshold } = state; // Read values passed on state

  return (
    <Fragment>
      {(!criteria_met && status_type === "WAIT_OTHERS") && (
        <div className="no-dashboard-root">
          <img
            src={noDashboard}
            className="no-dashboard-image"
            alt="No Dashboard"
          />
          <p className="no-dashboard-title">Waiting for other users</p>
          <p className="no-dashboard-text">
            Not enough users have responded to ensure<br />
            anonymity. The dashboards will be visible<br />
            when at least {anonymity_threshold} users have provided their responses.
          </p>
        </div>
      )}
      {(!criteria_met && status_type === "WAIT_SELF") && (
        <div className="no-dashboard-root">
          <img
            src={noAmresponse}
            className="no-dashboard-image"
            alt="No AM Response"
          />
          <p className="no-dashboard-title">Tell us what you think!</p>
          <p className="no-dashboard-text">
            To view the dashboards, please complete the questions<br />
            in the About Me section!<br /><br />
            If you can't answer a question, please select "Skip".
          </p>
        </div>
      )}
      {/* {code.toString() === "404" && (
        <div className={styles["no-dashboard-root"]}>
          <img
            src={noConfigured}
            className={styles["no-dashboard-image"]}
          />
          <p className={styles["no-dashboard-title"]}>Coming Soon</p>
          <p className={styles["no-dashboard-text"]}>
            Pulse is being configured for this project.  Stay tuned!
          </p>
        </div>
      )} */}
    </Fragment>
  );
};

export default NoDashboard;
