import editPen from '../../assets/svgs/editPencil.svg'
import { BaseButton } from "../../Elements/Button"
import { useEffect, useState } from 'react'
import DeleteConfirmationPopup from '../ConfirmationPopup'
import { PulseSwitch } from '../../Elements/Switch'
import { useSelector } from 'react-redux';
import Modal from '../Modal'
import { ApiClient } from '../../api/client'

const UsersTableRow = ({
    item,
    openUserId,
    onClickChevron,
    onClickEdit,
    onClickInvite,
    selectedUsers,
    setUserSelect,
    segmentsData,
    showInviteHeader,
    editMode,
    setUserResponse
}) => {
    const client = ApiClient()
    const { isDraft, projectId, projectUserId } = useSelector(state => state.project)
    const [isInvited, setIsInvited] = useState(item.invite_sent)
    const [invitationConfirmationPopup, setInvitationConfirmationPopup] = useState({
        showPopup: false,
        popupMessage: ''
    })

    const [projectAdmin, setProjectAdmin] = useState(item.project_admin)
    const [adminConfirmationPopup, setAdminConfirmationPopup] = useState({
        showPopup: false,
        popupTitle: '',
        popupContent: '',
        popupErr: ''
    })
    useEffect(() => {
        setIsInvited(item.invite_sent)
    }, [item])

    useEffect(() => {

    }, [selectedUsers])

    let openUserCondtion = openUserId === item.id
    var currentdate = new Date();
    var datetime = currentdate.getDate() + "/"
        + (currentdate.getMonth() + 1) + "/"
        + currentdate.getFullYear() % 2000 + "  "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes()


    const handleCancelAdminConfirmPopup = () => {
        setProjectAdmin(!projectAdmin)
        setAdminConfirmationPopup(false)
    }

    const handleSubmit = () => {
        client
            .put(`/api/v1/project-user-access/${projectId}/${item.id}/`, {
                data: {
                    isAdmin: projectAdmin
                }
            })
            .then(response => {
                if (response.message === 'SUCCESS') {
                    setAdminConfirmationPopup(false)
                    setUserResponse({
                        users: response.data,
                        nextLink: response.next,
                        prevLink: response.prev,
                        usersCount: response.count
                    })
                }
            })
            .catch(err => {
                if (err.response.data.error === 'LIMIT_ERROR') {
                    setAdminConfirmationPopup(prevState => (
                        { ...prevState, popupErr: err.response.data.message }
                    ))
                }
            })
    }



    return <>
        <tr
            key={item.id}
            className={`table-row ${openUserCondtion ? 'active-row' : ''}`}
        >
            <td className='edit-btn-td'>
                <BaseButton
                    variant='text'
                    text={<img src={editPen} alt="Edit" width={17} />}
                    className="edit-btn"
                    onClick={() => onClickEdit(item.id)}

                />
            </td>
            <td className='select-row select-td'>
                <input type='checkbox' onChange={() => {
                    setUserSelect(prevState => {
                        return {
                            ...prevState,
                            isAllSelected: prevState.selectedUsers.includes(item.id) ? false : prevState.isAllSelected,
                            selectedUsers: prevState.selectedUsers.includes(item.id) ?
                                prevState.selectedUsers.filter(k => k !== item.id)
                                :
                                [...new Set([...prevState.selectedUsers, item.id])]
                        }
                    })
                }} checked={selectedUsers.includes(item.id)} />
            </td>
            <td className='fullname-td'>{item.first_name} {item.last_name}<span></span></td>
            <td className='project-title-td'>{item.project_user_title}<span></span></td>
            <td className='email-td'>{item.email}<span></span></td>

            {
                segmentsData.map(st => {
                    let elem = <td key={st.segmentTypeId} className='segments-td'><span></span></td>
                    if (item.segment_user_data.length > 0) {
                        let seg = item.segment_user_data.filter(seg => seg.segment_type === st.segmentTypeId)
                        if (seg.length > 0) {
                            elem = <td key={seg[0].segment_id} className='segments-td'>{seg[0].segment_name}<span></span></td>
                        }
                    }
                    return elem
                })
            }

            <td className='invite-td'>
                <PulseSwitch
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="success"
                    checked={isInvited}
                    onChange={(e) => {
                        setIsInvited(!isInvited)
                        if (!isDraft) {
                            if (e.target.checked) {
                                setInvitationConfirmationPopup({
                                    showPopup: true,
                                    popupMessage: 'Do you want to invite this user?'
                                })
                            } else if (item.project_admin) {
                                setInvitationConfirmationPopup({
                                    showPopup: true,
                                    popupMessage: <p style={{ textAlign: 'center', width: '100%' }}>
                                        Revoke the project admin rights before unassigning the seat.
                                    </p>
                                })
                            } else {
                                setInvitationConfirmationPopup({
                                    showPopup: true,
                                    popupMessage: 'Do you want to revoke the invite for this user?'
                                })
                            }
                        } else {
                            if (item.project_admin) {
                                setInvitationConfirmationPopup({
                                    showPopup: true,
                                    popupMessage: <p style={{ textAlign: 'center', width: '100%' }}>
                                        Revoke the project admin rights before unassigning the seat.
                                    </p>
                                })
                            } else {
                                onClickInvite(item.id)
                            }
                        }
                    }}
                />
            </td>
            {
                (editMode && !showInviteHeader) && <td className='invite-td'>
                    {(item.is_subscriber && item.id !== projectUserId) &&
                        <PulseSwitch
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="success"
                            checked={projectAdmin}
                            onChange={(e) => {
                                setProjectAdmin(!projectAdmin)
                                if (e.target.checked) {
                                    setAdminConfirmationPopup({
                                        showPopup: true,
                                        popupTitle: 'Grant Access',
                                        popupContent: <>
                                            <p className='content-msg'>
                                                The user you are granting the project admin rights will now have access to the following:
                                            </p>
                                            <ul className='access-list'>
                                                <li>Project Management</li>
                                                <li>User Management</li>
                                                <li>Dashboard Settings</li>
                                            </ul>
                                            <p className='content-confirm-msg'>
                                                Click proceed to confirm.
                                            </p>
                                        </>
                                    })
                                } else {
                                    setAdminConfirmationPopup({
                                        showPopup: true,
                                        popupTitle: 'Revoke Access',
                                        popupContent: <p className='content-confirm-msg'>
                                            The user will no longer have the project admin privileges. Confirm?
                                        </p>

                                    })
                                }

                            }}
                        />
                    }
                    {(!item.is_subscriber || (item.is_subscriber && item.id === projectUserId)) &&
                        <PulseSwitch
                            color="success"
                            checked={projectAdmin}
                            disabled
                        />
                    }
                </td>
            }

        </tr>
        {
            openUserCondtion && <tr className={`table-row ${openUserCondtion ? 'active-row' : ''}`}>
                <td className='blank-td'></td>
                <td colSpan={11}>
                    <div>
                        Last Login: {datetime}
                    </div>
                </td>
            </tr>
        }
        {
            (invitationConfirmationPopup.showPopup && item.project_admin) && <Modal
                modalClassName={"make-project-admin-confirmation-popup"}
                handleModal={() => {
                    setIsInvited(!isInvited)
                    setInvitationConfirmationPopup(false)
                }}
                modal_content={
                    <div className="confirmation-modal-content">
                        <div className="content-div">
                            {invitationConfirmationPopup.popupMessage}
                        </div>
                        <div className='modal-action-button-section'>
                            <BaseButton
                                text="Okay"
                                className='cancel-btn modal-cancel-btn'
                                onClick={() => {
                                    setIsInvited(!isInvited)
                                    setInvitationConfirmationPopup(false)
                                }}
                            />
                        </div>

                    </div>
                }
            />
        }
        {
            (invitationConfirmationPopup.showPopup && !item.project_admin) && <DeleteConfirmationPopup
                text={invitationConfirmationPopup.popupMessage}
                handleCancel={() => {
                    setIsInvited(!isInvited)
                    setInvitationConfirmationPopup(false)
                }}
                handleSubmit={() => {
                    onClickInvite(item.id, isInvited ? 'send' : '')
                    setInvitationConfirmationPopup(false)
                }}
                deleteText='Yes'
            />
        }

        {
            adminConfirmationPopup.popupContent && <Modal
                modalClassName={"make-project-admin-confirmation-popup"}
                handleModal={handleCancelAdminConfirmPopup}
                modal_content={
                    <div className="confirmation-modal-content">
                        <div className='header-div'>
                            <h3 className='title'>{adminConfirmationPopup.popupTitle}</h3>
                        </div>

                        <div className="content-div">
                            {adminConfirmationPopup.popupContent}
                        </div>
                        {
                            adminConfirmationPopup.popupErr &&
                            <p style={{ fontSize: '12px', color: '#ff0000', textAlign: 'center' }}>
                                *{adminConfirmationPopup.popupErr}
                            </p>
                        }
                        {
                            !adminConfirmationPopup.popupErr
                            &&
                            <div className='modal-action-button-section'>
                                <BaseButton
                                    text="Cancel"
                                    className='cancel-btn modal-cancel-btn'
                                    onClick={handleCancelAdminConfirmPopup}
                                />

                                <BaseButton
                                    variant='contained'
                                    text={'Proceed'}
                                    className='proceed-btn modal-submit-btn'
                                    onClick={handleSubmit}
                                />
                            </div>
                        }
                    </div>
                }
            />
        }

    </>
}

export default UsersTableRow;