import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox, styled } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { drag } from '../../assets/svgs/questions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function StickyHeadTable({ columns, rows, ...args }) {
  const [tableData, setTableData] = React.useState([...rows])

  const handleCheckboxChange = (e, row) => {
    setTableData(prev => {
      // const newArr = [...prev]
      // newArr[idx].view = e.target.checked
      // return newArr
      return prev.map(item => {
        if (
          (item.driver === row.driver && item.sub_driver === row.sub_driver)
          &&
          (args.page === 'advisor-insights' ? item.bounds === row.bounds : true)
        ) {
          item.view = e.target.checked
        }
        return item
      })
    })
  }
  let checkedLength = args.page === 'advisor-insights' ? tableData.filter(item => item.view === true).length : tableData.length
  return (
    <Paper>
      <TableContainer sx={{ maxHeight: 400 }} className='subdriver-list-table'>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={(column?.align) ? column?.align : 'left'}
                  style={{ minWidth: (column?.minWidth) ? column.minWidth : 170 }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={args.handleOnDragEnd}>
            <Droppable droppableId="permissions">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {tableData
                    .reduce((tbd, obj, index) => {
                      if ((!args?.searchedVal?.length || obj.sub_driver.toString().toLowerCase().includes(args?.searchedVal.toString().toLowerCase()))
                        && !(args?.disabledList !== undefined && args?.disabledList.includes(obj.sub_driver))) {
                        tbd[index] = obj
                      }
                      return tbd
                    }, [])
                    .sort((a, b) => a.order - b.order)
                    .map((row, idx) => {
                      return (
                        <Draggable
                          key={idx + '-' + row?.order}
                          draggableId={idx + '-' + row?.order}
                          index={idx}
                        >
                          {(provided) => (
                            <TableRow key={idx + '-' + row?.order}
                              ref={provided.innerRef} {...provided.draggableProps} hover role="checkbox" tabIndex={-1}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id === 'order') {
                                  return <TableCell {...provided.dragHandleProps} key={column.id} align={column.align}>
                                    <img src={drag} alt="drag me" className='drag-me-ico' />
                                  </TableCell>
                                }
                                return (
                                  <TableCell  {...provided.dragHandleProps} key={column.id} align={column.align}>
                                    {
                                      column.format && typeof value === 'number' ?
                                        column.format(value)
                                        : (
                                          typeof value === 'boolean' ?
                                            <Checkbox
                                              sx={{ padding: 0 }}
                                              checked={value}
                                              disabled={args.page === 'advisor-insights' && value === false && checkedLength >= 10}
                                              onChange={(e) => handleCheckboxChange(e, row)}
                                            />
                                            :
                                            value
                                        )
                                    }
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </Paper>
  );
}