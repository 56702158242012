
import './index.scss'
import {
    trash
} from '../../assets/svgs/questions'
import editPencil from '../../assets/svgs/editPencil.svg'

import { BaseButton } from "../../Elements/Button"
import { Switch, ThemeProvider, createTheme } from '@mui/material'
import { ApiClient } from '../../api/client'
import { useNavigate } from 'react-router-dom'
import { MY_PROJECTS_PATH_NAME } from './../../utilities/constants'
import { useDispatch } from 'react-redux'
import {
    projectAction,
    projectEditAction,
    projectDraftStatusAction,
    projectNameAction,
    projectUserIdAction,
    isProjectAdminAction
} from '../../redux/actions'
import { useState } from 'react'
import DeleteConfirmationPopup from '../ConfirmationPopup'


const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {

        neutral: {
            main: '#7FCDC1',
            contrastText: '#fff',
        },
    },
});


const ProjectTableRow = ({
    item,
    setProjects,
    projectSelection,
    updateProjectSelection,
    listType
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false)

    const handleProjectStatus = (newStatus) => {
        const client = ApiClient()
        let API_URL = '/api/v1/billing-projects/' + item.project_id + '/'
        client.put(API_URL, {
            data: { status: newStatus }
        }).then(response => {
            if (response?.data) {
                setProjects(response.data)
            }
        })
    }

    const handleProjectDelete = () => {
        const client = ApiClient()
        let API_URL = '/api/v1/billing-projects/' + item.project_id + '/'
        client.del(API_URL).then(response => {
            if (response?.data) {
                setProjects(response.data)
            }
            setDeleteConfirmationPopup(false)
        })
    }

    const handleEditProject = () => {

        dispatch(projectAction(item.project_id))
        dispatch(projectEditAction(true))
        dispatch(projectNameAction(item.project_name))
        dispatch(projectUserIdAction(item.project_user_id))
        dispatch(isProjectAdminAction(true))

        if (item.status === 'draft') {
            dispatch(projectDraftStatusAction(true))
        } else {
            dispatch(projectDraftStatusAction(false))
        }

        navigate(
            {
                pathname: '/setup/project-attributes',
                search: '?projectId=' + item.project_id
            },
            {
                state: {
                    isNewProject: false,
                    from: MY_PROJECTS_PATH_NAME,
                    projectId: item.project_id
                }
            }
        )
    }

    return <><tr className={'prtable-row'}>
        {listType === 'subscriptions' &&
            <td className='select-row select'>
                <input
                    type='checkbox'
                    onChange={() => {
                        updateProjectSelection(prevState => {
                            return {
                                isAllSelected: prevState.selectedProjects.includes(item.project_id) ? false : prevState.isAllSelected,
                                selectedProjects: prevState.selectedProjects.includes(item.project_id) ?
                                    prevState.selectedProjects.filter(k => k !== item.project_id)
                                    :
                                    [...new Set([...prevState.selectedProjects, item.project_id])]
                            }
                        })
                    }}

                    checked={projectSelection.selectedProjects.includes(item.project_id)}

                />
            </td>
        }
        <td className='project-list-td'>{item.project_name}</td>
        <td className='project-list-td'>{item.launch_date}</td>
        <td className='project-list-td'>{item.identified_users}</td>
        <td className='project-list-td'>{item.status === 'draft' ? '-' : item.invited_users}</td>
        <td className='project-list-td'>{item.seats_purchased}</td>
        <td className='project-list-td'>{item.project_admins?.join(', ')}</td>
        <td className='toggle-td'>
            {
                item.status === 'draft' ?
                    <span className='toggle toggle-draft'>{item.status}</span>
                    :
                    <div className='' >
                        <span className={item.status === 'active' ? 'toggle toggle-active' : 'toggle toggle-inactive'}>
                            {item.status === 'active' ? 'Active' : 'Inactive'}
                        </span>
                        {listType === 'subscriptions' &&
                            <ThemeProvider theme={theme}>
                                <Switch checked={item.status === 'active'}
                                    onChange={() => handleProjectStatus(item.status === 'active' ? 'inactive' : 'active')}
                                    color="neutral"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </ThemeProvider>
                        }
                    </div>
            }
        </td>
        <td className='edit-btn-td'>
            <BaseButton
                variant='text'
                text={<img src={editPencil} alt="Edit" width={17} />}
                className="edit-btn"
                onClick={handleEditProject}
            /></td>
        {listType === 'subscriptions' &&
            <td className='delete-btn-td'>
                <BaseButton
                    variant='text'
                    text={<img src={trash} alt="Delete" width={17} />}
                    className="delete-btn"
                    onClick={() => setDeleteConfirmationPopup(true)}
                />
            </td>
        }
    </tr>
        {
            deleteConfirmationPopup && <DeleteConfirmationPopup
                modalClassName="project-delete-confirmation-wrapper"
                text={
                    <>
                        <p >Are you sure you want to delete the <b>{item.project_name}</b>?</p>
                        <span className='delete-warning'> Warning: By deleting the project you will loose all the responses and users PERMANENTLY!</span>
                    </>
                }
                handleCancel={() => setDeleteConfirmationPopup(false)}
                handleSubmit={handleProjectDelete}
            />
        }
    </>
}

export default ProjectTableRow;