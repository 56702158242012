
import { useState, useRef, useEffect } from 'react';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import lock from '../../assets/svgs/lock.svg'
import './index.scss'


const ProjectLocked = () => {
    const divRef = useRef()

    const [offsetTop, setOffsetTop] = useState()

    useEffect(() => {

        setOffsetTop(divRef.current.getBoundingClientRect().top)

    }, [])

    return <div
        ref={divRef}
        className="project-locked-div"
        style={{ height: `calc(100vh - ${offsetTop}px)` }}>
        <div>
            <div>
                <img src={lock} alt="" />
                {/* <LockOutlinedIcon sx={{fontSize: '5rem'}}/> */}
            </div>
            <div>
                <h5>This project is not active.</h5>
                <h2>Reach out to your admin to access this.</h2>
            </div>
        </div>
    </div>
}

export default ProjectLocked;