import {
    CURRENT_PROJECT,
    ADMIN_MENU_ITEMS,
    IS_EDIT,
    IS_DRAFT,
    PROJECT_USER_ID,
    CURRENT_PROJECT_NAME,
    RESET_PROJECT_STATE,
    IS_PROJECT_ADMIN
} from "../actionTypes"


const projectAction = (payload) => {
    return {
        type: CURRENT_PROJECT,
        payload: payload
    }
}

const projectEditAction = (payload) => {
    return {
        type: IS_EDIT,
        payload: payload
    }
}

const projectDraftStatusAction = (payload) => {
    return {
        type: IS_DRAFT,
        payload: payload
    }
}

const adminMenuItemAction = (payload) => {
    return {
        type: ADMIN_MENU_ITEMS,
        payload: payload
    }
}



const projectUserIdAction = (payload) => {
    return {
        type: PROJECT_USER_ID,
        payload: payload
    }
}


const projectNameAction = (payload) => {
    return {
        type: CURRENT_PROJECT_NAME,
        payload: payload
    }
}

const projectResetAction = () => {
    return {
        type: RESET_PROJECT_STATE
    }
}


const isProjectAdminAction = (payload) => {
    return {
        type: IS_PROJECT_ADMIN,
        payload: payload
    }
}


export {
    projectAction,
    projectEditAction,
    projectDraftStatusAction,
    adminMenuItemAction,
    projectUserIdAction,
    projectNameAction,
    projectResetAction,
    isProjectAdminAction
}