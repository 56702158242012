import React from "react";
import "./styles.scss"

const Radio = ({ name, styles, value, children, onChange, checked }) => {
	const checkedCls = checked ? "checked-radio-input" : "";
	return (
		<label className={`main-radio-input ${checkedCls}`}>
			<span className="checkmark-bullet"></span>
			<span className="label">{children}</span>
			<input
				className="radio-input"
				name={name}
				value={value}
				type="radio"
				checked={checked}
				onChange={onChange}
			/>
		</label>
	);
}

export default Radio;
