


const AboutOthersTextContent = ({ activeSection }) => {

    let content = {
        'aboutOthers': {
            'title': 'About Others',
            'description': `Pulse tracks people’s perception of how others think/feel. We’ve provided a few suggestions to get started. Check out the Questions Library for more ideas. We recommend keeping this list of questions as short as possible.`,
            'instructions': ''
        },
        'myMap': {
            'title': 'Mapping - My Map',
            'description': `My Map allows your team members to identify the people they interact with frequently on the project and to track how these team members feel.
                            Create any new categories your team members might want to use, the team member will see a map like the one below and have the opportunity to select from the user list to add users to each category.`,
            'instructions': ''
        },
        'projectMap': {
            'title': 'Mapping - Project Map',
            'description': `Project Map allows your team members to identify the people they don't interact with frequently but think are important to your project, and to track their own perception of how others feel.
                            Create any new categories relevant to your project, the team member will see a map like the one below and have the opportunity to select from the user list to add users to each category.`,
            'instructions': ''
        },
        'stakeholderLabel': {
            'title': '',
            'description':'',
            'instructions': ''
        },


    }

    return <>
        <h1 className='section-title'> {content[activeSection]?.title}</h1>
        <p className="section-description">
            {content[activeSection]?.description}
        </p>
        {
            content[activeSection]?.instructions && <p className="section-instructions">
                {content[activeSection]?.instructions}
            </p>
        }

    </>
}

export default AboutOthersTextContent;