import {
    RESET_USER_STATE,
    USER_EMAIL,
    USER_ID,
    USER_NAME,
    IS_ADMIN,
    HAS_SUBSCRIPTION,
    IS_BILLING_ADMIN
} from "../actionTypes"


const initialState = {
    username: '',
    userId: '',
    useremail: '',
    isAdmin: '',
    isBillingAdmin: ''
}


const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case USER_NAME:
            return {
                ...state,
                username: action.payload
            }
        case USER_ID:
            return {
                ...state,
                userId: action.payload
            }
        case USER_EMAIL:
            return {
                ...state,
                useremail: action.payload
            }
        case IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload
            }
        case HAS_SUBSCRIPTION:
            return {
                ...state,
                hasSubscription: action.payload
            }
        case IS_BILLING_ADMIN:
            return {
                ...state,
                isBillingAdmin: action.payload
            }
        case RESET_USER_STATE:
            return initialState
        default:
            return state
    }

}

export default userReducer;