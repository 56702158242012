import { useEffect, useState } from 'react'
import './index.scss'

import CustomIconPicker from '../IconPicker'
import LabeledInput from './../../Elements/LabeledInput'
import { BaseButton } from '../../Elements/Button'


const AboutOthersPopup = ({ title, hasDescription, label, name, handleSubmit, handleCancel }) => {
    const [formData, setformData] = useState({
        field: '',
        desc: '',
        mapping: '',
        icon: '',
    })

    const [errField, setErrField] = useState({})

    const [iconValue, setIconValue] = useState()
    const [iconError, setIconError] = useState()

    useEffect(() => {
        setformData(prevState => (
            { ...prevState, icon: iconValue }
        ))
    }, [iconValue])

    const handleIconChange = (value) => {
        setIconValue(value);
    }

    const handleChange = (e) => {
        if(e.target.required){
            if(e.target.value.trim().length === 0){
                setErrField(prevState => (
                    { ...prevState, [e.target.name]: 'Field is required' }
                ))
            }else{
                setErrField(prevState => {
                    const newState = {...prevState}
                    delete newState[e.target.name]
                    return newState
                })
            }
        }
        setformData(prevState => (
            { ...prevState, [e.target.name]: e.target.value }
        ))

    }
    const handleConfirm = (e) => {
        if(!iconValue){
            setIconError('*Field is required')
        }else{
            setIconError('')
        }
        if(formData.hasOwnProperty(name) && formData[name].trim().length === 0){
            setErrField(prevState => (
                { ...prevState, [name]: 'Field is required' }
            ))
        }else{
            if(!Object.keys(errField).length && iconValue){
                handleSubmit(formData)
            }
        }
    }
    return <div style={{ width: '100%' }}>

        <h3 className="heading">Add New {title}</h3>
        <LabeledInput
            name={name}
            label={label}
            type='text'
            value={formData[name]}
            error={errField.hasOwnProperty(name) ? errField[name] : false}
            required={true}
            onChange={handleChange}
        />
        {
            hasDescription && <LabeledInput
                name='desc'
                label='Description'
                type='text'
                value={formData.desc}
                error={false}
                required={false}
                onChange={handleChange}
                textarea={true}
                isOptional={true}
            />}
        <div>
            <span className="icon_heading">Select Icon Category:</span>
            <CustomIconPicker hasCategory handleIconChange={handleIconChange} />
            {iconError && <p style={{ color: 'red', fontSize: '0.75rem' }}>{iconError}</p>}
        </div>
        <div className='modal-action-button-section'>
            <BaseButton
                className="modal-cancel-btn"
                text="Cancel"
                variant="filled"
                onClick={handleCancel}
            />

            <BaseButton
                className="modal-submit-btn"
                text="Confirm"
                variant="filled"
                onClick={handleConfirm}
            />

        </div>

    </div>
}


export default AboutOthersPopup