import './index.scss'
import { useSelector } from 'react-redux';
import React, { useState, useEffect, Fragment } from "react";
import Modal from '../Modal';
import { BaseButton } from '../../Elements/Button';
import SlideNavigator from '../SlideNavigator';
import { ApiClient } from '../../api/client';


const NikelTour = ({ handleModal }) => {
  const { projectId,currentProjectName } = useSelector(state => state.project);

  const [nikelContent, setNikelContent] = useState([]);
  const [activestep, setActiveStep] = useState(0);
  const [tour, setTour] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (projectId) {
      const client = ApiClient()
      const url = `api/v1/nikel-tour/?projectId=${projectId}`
      client.get(url).then((response) => {
        setNikelContent(response.data)

      })


    }
  }, [projectId])
  const onNext = () => {
    if (activestep === nikelContent.length - 1) {
      handleModal(false)

    }
    else {
      setActiveStep(activestep + 1);
    }
  };

  const onBack = () => {
    if (activestep > 0) {
      setActiveStep(activestep - 1);
    }
  };

  const onSelect = (position) => {
    setActiveStep(position);
  };



  return <>
  {nikelContent?.length>0 && 
  <Modal
    modalClassName="nikelpage_popup"
    modal_content={
      <div className='maindiv'>
         <img src={nikelContent[activestep]['img'] } className='nikelimg'></img>
        <div className='page_name'>
          <h1>{nikelContent[activestep]['page_name'].replace("{{PROJECT}}", currentProjectName)}</h1>
        </div>
        <div className='page_content'>
          {nikelContent[activestep]['page_text']}
       
        </div>
        <div className='btngrp'>
          <BaseButton
            className="back-btn"
            text="Back"
            variant="filled"
            onClick={onBack}
          />
          <BaseButton
            className="next-btn"
            text="Next"
            variant="filled"
            onClick={onNext}
          />



        </div>
        <div className="navigator">
              <SlideNavigator
                onSelect={(activestep) => onSelect(activestep)}
                cnt={nikelContent.length}
                position={activestep}
              />
            </div>

      </div>
    }
    handleModal={() => { handleModal(false) }}
  />}
  </>
}

export default NikelTour;