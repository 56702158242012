import './index.scss';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { FormControl, FormHelperText, Input, TextField, Typography } from '@mui/material';
import SubdriverPopover from '../SubdriverSelector';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ApiClient } from '../../api/client';
import { BaseButton } from '../../Elements/Button';
import { pen, save } from '../../assets/svgs/permissions';

const BorderLinearProgress = styled(LinearProgress)(({ theme, value, height = 6, type = 'light' }) => ({
    height: height,
    borderRadius: 5,
    width: '90%',

    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#ebecee',

    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        borderStyle: 'solid',
        borderWidth : 'thin',
        backgroundColor: (type === 'light') ? tabledatacolor(value)[0] : '#667085',
        borderColor: (type === 'light') ? tabledatacolor(value)[1] : '#667085',
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        borderWidth: 0
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const tabledatacolor = (value) => {
    if (value <= 10) {
      return ['#e56965', 'rgba(229, 105, 101, 0.6)'];
    }else if (value <= 20) {
      return ['#c0788a', 'rgba(192, 120, 138, 0.6)'];
    }else if (value <= 30) {
      return ['#a185a8', 'rgba(161, 133, 168, 0.6)'];
    }else if (value <= 40) {
      return ['#8296c6', 'rgba(130, 150, 198, 0.6)'];
    }else if (value <= 50) {
      return ['#659fe2', 'rgba(101, 159, 226, 0.6)'];
    }else if (value <= 60) {
      return ['#4ba8ed', 'rgba(75, 168, 237, 0.6)'];
    }else if (value <= 70) {
      return ['#3bacdd', 'rgba(59, 172, 221, 0.6)'];
    }else if (value <= 80) {
      return ['#2aaecc', 'rgba(42, 174, 204, 0.6)'];
    }else if (value <= 90) {
      return ['#19b2bb', 'rgba(25, 178, 187, 0.6)'];
    }else if (value <= 100) {
      return ['#24beb3', 'rgba(36, 190, 179, 0.6)'];
    }
    return ['#24beb3', 'rgba(36, 190, 179, 0.6)'];
}

const getRandom = (min, max) => {
    return parseFloat(Math.random() * (max - min) + min).toFixed(1)
}

const CultureComponent = ({ feedbackHeight }) => {
  const [cultureResultData, setCultureResultData] = useState([])
  const [cultureResultFormattedData, setCultureResultFormattedData] = useState([])
  const [reportTitle, setReportTitle] = useState('Culture Results')
  const [err, setErr] = useState('')
  const [subdriverFilterData, setSubdriverFilterData] = useState([])
  const [isReportTitleEdit, setIsReportTitleEdit] = useState(false)
  const { projectId, projectUserId, currentProjectName, isProjectAdmin } = useSelector(state => state.project)

  useEffect(() => {
      if (projectId) {
          const client = ApiClient(setCultureResultData)
          client.get('/api/v1/culture-results/' + projectId + '/')
          .then((response) => {
              if (response?.data) {
                  setSubdriverFilterData(response.data?.filter_data?.sub_driver)
                  let ordered_data = []
                  response.data?.filter_data?.sub_driver.sort((a,b) => a.order-b.order).forEach(item => {
                      let k = response.data?.response_data?.filter(res => {
                          return res.sub_driver === item.sub_driver
                      })
                      if (k.length > 0){
                          ordered_data.push(...k)
                      }
                  })
                  setCultureResultData(ordered_data)

                  if(response.data?.filter_data?.display_name) setReportTitle(response.data?.filter_data?.display_name)
              }
          })
      }
  }, [projectId])

  useEffect(() => {
      if(cultureResultData.length > 0){
          setCultureResultFormattedData(cultureResultData.reduce((acc, curr, index) => {
              if (index % 2 === 0) {
                acc.push([curr]);
              } else {
                acc[acc.length - 1].push(curr);
              }
              return acc;
            }, []))
      }
  }, [cultureResultData])

  const handleReportSettingSave = (data) => {
    if(data){
        const client = ApiClient(setCultureResultData)
        client.post('/api/v1/culture-results/' + projectId + '/', {
            data: data,
            report_name: reportTitle,
        })
        .then((response) => {
            if (response?.data) {
                // setCultureResultData(response.data?.response_data)
                setSubdriverFilterData(response.data?.filter_data?.sub_driver)
                let ordered_data = []
                  response.data?.filter_data?.sub_driver.sort((a,b) => a.order-b.order).forEach(item => {
                      let k = response.data?.response_data?.filter(res => {
                          return res.sub_driver === item.sub_driver
                      })
                      if (k.length > 0){
                          ordered_data.push(...k)
                      }
                  })
                  setCultureResultData(ordered_data)
            }
        })
    }
  }

  const handleReportTitleChange = () => {
    if(reportTitle.trim().length > 0 && reportTitle.trim().length < 56){
      const client = ApiClient(setCultureResultData)
      client.put('/api/v1/culture-results/' + projectId + '/', {
        report_name: reportTitle,
      }).then((response) => {
        setErr('')
        setIsReportTitleEdit(false)
      }).catch((error) => {
        
      })
    }else{
      if(reportTitle.trim().length > 56){
        setErr('Title has to be less than 56 characters')
      }else{
        setErr('Title cannot be empty')
      }
    }
  }

  return <TableContainer sx={{ maxHeight: feedbackHeight }} className='culture-summary-container'>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell colSpan={2}>
                            <div className='culture-summary-panel'>
                                <h2>
                                  {!isReportTitleEdit &&
                                    <>
                                    {reportTitle}
                                    <BaseButton
                                      variant='text'
                                      text={<img src={pen} alt="Edit" width={17} />}
                                      className="edit-btn"
                                      onClick={() => {setIsReportTitleEdit(true)}}/>
                                    </>
                                  }
                                  {isReportTitleEdit &&
                                    <>
                                    <FormControl 
                                      value={reportTitle}
                                      onChange={(e) => {setReportTitle(e.target.value)}}
                                      variant="standard"
                                      inputProps={{style: {padding: 0, fontSize: '1.5rem', fontWeight: 'bold'}}}
                                      error={err ? true : false}
                                      focused>
                                      <Input
                                        id="component-error"
                                        defaultValue={reportTitle}
                                        inputProps={{style: {padding: 0, fontSize: '1.5rem', fontWeight: 'bold'}}}
                                        aria-describedby="component-error-text"
                                      />
                                      {err &&
                                        <FormHelperText id="component-error-text">{err}</FormHelperText>
                                      }
                                    </FormControl>
                                    <BaseButton
                                      variant='text'
                                      text={<img src={save} alt="Edit" width={17} />}
                                      className="edit-btn"
                                      onClick={() => {handleReportTitleChange()}}
                                    />
                                    </>
                                  }
                                </h2>
                              {
                                isProjectAdmin && <SubdriverPopover subdriverData={subdriverFilterData} handleSave={handleReportSettingSave} />
                              }
                            </div>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cultureResultFormattedData.length > 0 &&
                        cultureResultFormattedData.map((row, index) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            {row.map((cell, index) => (
                                <TableCell key={index} sx={{border: 0}}>
                                    <div className='cell-content'>
                                        <Typography variant="p" color="text.primary">{cell.sub_driver}</Typography>
                                        <BorderLinearProgress variant='determinate' value={cell.score}/>
                                    </div>
                                </TableCell>
                            ))}
                            </TableRow>

                        ))
                    }
                </TableBody>
                </Table>
            </TableContainer>
}

export default CultureComponent