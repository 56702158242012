import { useLayoutEffect, useRef, useState } from 'react'
import { replaceQuestionTextKeyWord } from '../../../utilities'
import { DEBOUNCE_DELAY } from '../../../utilities/constants'
import SliderInput from '../../Slider'
import SkipQuestionOptionList from '../CommentSkipQuestion'
import './index.scss'


const RangeSliderQuestion = ({
    question,
    currentUser,
    onAnswer,
    projectTitle,
    surveyType,
    otherUser
}) => {

    const firstUpdate = useRef(true);
    const [answer, setAnswer] = useState({
        'project_user': currentUser,
        'project': question.project,
        ...(surveyType === 'me' && {'am_question': parseInt(question.id)}),
        ...(surveyType === 'others' && {'ao_question': parseInt(question.id)}),
        'control_type': question.control_type_readable,
        'integer_value': 0,
        'topic_value': '',
        'comment_value': '',
        'skip_value': '',
        'topic_tags': '',
        'comment_tags': '',
        ...question.response_data[0]
    })

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        // // debounced answer: currently disabled
        // const debounce = setTimeout(() => {
            onAnswer(answer)
        // }, DEBOUNCE_DELAY)

        // return () => {
        //     clearTimeout(debounce)
        // }
    }, [answer.integer_value, answer.comment_value, answer.skip_value])

    const onChangeSlide = (value) => {
        setAnswer(state => ({ ...state, integer_value: value, skip_value: "" }))
    };

    const handleSkip = (skip_text) => {
        setAnswer(state => ({ ...state, skip_value: skip_text, integer_value: 0 }))
    }

    const handleComment = (comment_text) => {
        setAnswer(state => ({ ...state, comment_value: comment_text }))
    }

    return <div className="main-question slider-question">
        <h2 className="question-text">
            {replaceQuestionTextKeyWord(question.question_text, otherUser, projectTitle)}
        </h2>
        <div className="answer-section slider-section">
            <SliderInput
                className="slider"
                percent={answer.integer_value}
                onChange={onChangeSlide}
            />
        </div>
        <div className="slider-text-section">
            <div className="slider-text-left">
                {question.slider_text_left}
            </div>
            <div className="slider-text-right">
                {question.slider_text_right}
            </div>
        </div>
        <div className="comment-skip-section">
            <SkipQuestionOptionList
                answer={answer?.integer_value}
                commentValue={answer?.comment_value}
                commentPrompt={question.comment_prompt}
                skipValue={answer?.skip_value}
                skipOptionsList={question.skip_option_list}
                skipOption={question.skipOption}
                onSkip={(skipAnswer) => handleSkip(skipAnswer)}
                onComment={(commentAnswer) => handleComment(commentAnswer)}
            />
        </div>
    </div>
}


export default RangeSliderQuestion