

import logo1 from '../../assets/images/Pulselogo_Admin.svg'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import { BaseButton } from '../../Elements/Button';
import './index.scss'
import Switch from '@mui/material/Switch';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ApiClient } from '../../api/client';
import {
	projectAttr,
	segments,
	drivers,
	aboutme,
	aboutothers,
	users,
	permissions,
	launch,
	preview,
} from './../../assets/svgs/sidebar'
import { useSelector, useDispatch } from 'react-redux';
import { adminMenuItemAction, projectAction } from '../../redux/actions';

const menuItemSet = [
	{ page: 'Project Attributes', path: 'project-attributes', icon: projectAttr },
	{ page: 'Segments', path: 'segments', icon: segments, prevPage: 'Project Attributes' },
	{ page: 'Drivers', path: 'drivers', icon: drivers, prevPage: 'Segments' },
	{ page: 'About Me', path: 'about-me', icon: aboutme, prevPage: 'Drivers' },
	{ page: 'About Others', path: 'about-others', icon: aboutothers, prevPage: 'About Me' },
	{ page: 'Users', path: 'users', icon: users, prevPage: 'About Others' },
	{ page: 'Permissions', path: 'permissions', icon: permissions, prevPage: 'Users' },
	// { page: 'Preview', path: 'preview', icon: preview, prevPage: 'Permissions' },
	{ page: 'Launch', path: 'launch', icon: launch, prevPage: 'Permissions' },
	{ page: 'Flagged Response', path: 'flagged-response', icon: launch, prevPage: 'Permissions' },
]

let menuItems = [...menuItemSet]

const theme = createTheme({
	status: {
		danger: '#e53e3e',
	},
	palette: {

		neutral: {
			main: '#7FCDC1',
			contrastText: '#fff',
		},
	},
});

const AdminSideBar = ({ isSidebar, toggleSidebar, isWelcomepage, setShowTour, checked, setChecked, active_page }) => {


	const dispatch = useDispatch()
	const [projectId, setProjectId] = useState('')
	const [searchParams, setSearchParams] = useSearchParams();

	const { isEdit, isDraft, menuItemsCompletedList } = useSelector(state => state.project)
	const { isBillingAdmin } = useSelector(state => state.user)

	let projectState = useSelector(state => state.project)


	useEffect(() => {
		let newProjectId;
		if (projectState?.projectId) {
			newProjectId = projectState?.projectId
		} else {
			newProjectId = searchParams.get('projectId')
			dispatch(projectAction(newProjectId))
		}
		setProjectId(newProjectId)

	}, [])


	useEffect(() => {
		if (isDraft) {
			menuItems = menuItemSet.filter(obj => (!['Flagged Response'].includes(obj.page)) && obj)
		}
		else if (isEdit && !isDraft) {
			menuItems = menuItemSet.filter(obj => (!['Preview', 'Launch'].includes(obj.page)) && obj)
		} else {
			menuItems = [...menuItemSet]
		}
	}, [isEdit, isDraft])

	useEffect(() => {
		if (projectId) {
			const client = ApiClient()
			const url = `api/v1/completedsteps/?project_id=${projectId}`
			client.get(url).then((response) => {
				dispatch(adminMenuItemAction(response))
			})
		}

	}, [projectId])

	const handleChange = () => {
		if (!checked) {
			setShowTour(true)
		}
		setChecked(!checked)
	}


	const navLinkPath = (path) => {
		let params = projectId ? '?projectId=' + projectId : ''
		let k = {
			pathname: '/setup/' + path,
			state: { projectId: projectId }
		}
		if (searchParams) {
			k['search'] = params
		}
		return k
	}

	return <>
		<div className={isSidebar ? 'admin-sidebar' : 'admin-sidebar sidebar-open'}>
			<div className='project-logos'>

				<img src={logo1} width={130} alt='Pulse' style={{ marginLeft: '0px' }} />
				<div className='menu-close'>
					<BaseButton customStyles={{ padding: '4px' }}
						text={<ChevronLeftIcon />}
						onClick={toggleSidebar}
					/>
				</div>

			</div>
			<div className='menu-items'>
				<ul>
					<li className='menu-item' key={`menu-item-${menuItems[0].page}`}>
						<NavLink to={navLinkPath(menuItems[0].path)} className='menu-link'>
							<div className='menu-item-icon'>
								<img src={menuItems[0].icon} alt={menuItems[0].page} width={20} />
							</div>
							<div className={`menu-item-content ${menuItems[0].page}-menu`}>
								<span className='heading'>{menuItems[0].page}</span>
								{
									menuItemsCompletedList.length && menuItemsCompletedList?.includes(menuItems[0].page) ?
										<span className='sub-heading completed' >Done</span>
										:
										<span className='sub-heading'>Not started</span>
								}
							</div>
						</NavLink>
					</li>
					{
						menuItems.slice(1).map(item => {
							return <li className='menu-item' key={`menu-item-${item.page}`}>
								{
									(menuItemsCompletedList.length && menuItemsCompletedList?.includes(item.prevPage)) ?
										<NavLink to={navLinkPath(item.path)} className='menu-link'>
											<div className='menu-item-icon'>
												<img src={item.icon} alt={item.page} width={20} />
											</div>
											<div className={`menu-item-content ${item.page}-menu`}>
												<span className='heading'>{item.page}</span>
												{
													item.page !== 'Flagged Response'
														?
														menuItemsCompletedList?.includes(item.page) ?
															<span className='sub-heading completed' >Done</span>
															:
															menuItemsCompletedList?.includes(item.prevPage) ?
																<span className='sub-heading'>In Progress</span>
																:
																<span className='sub-heading'>Not Started</span>
														:
														null
												}
											</div>
										</NavLink>
										:
										<div className='menu-link disabled'>
											<div className='menu-item-icon'>
												<img src={item.icon} alt={item.page} width={20} />
											</div>
											<div className={`menu-item-content ${item.page}-menu`}>
												<span className='heading'>{item.page}</span>
												<span className='sub-heading'>Not started</span>
											</div>
										</div>
								}
							</li>
						})
					}
					<br />
					{
						isWelcomepage && <li style={{ marginLeft: '7px' }} >
							<div className='' style={{ border: 'none' }}>
								<div className={`menu-item-content`}>
									<span className='heading'><strong>Turn On Guide Mode</strong></span></div>
								<ThemeProvider theme={theme}><Switch checked={checked}
									onChange={handleChange}
									color="neutral"
									inputProps={{ 'aria-label': 'controlled' }}
								/>
								</ThemeProvider>
							</div>
						</li>
					}

				</ul>
			</div>
			{
				(isBillingAdmin || isEdit) && <div className='admin-menu'>
					<NavLink to={'/app/my-projects'} className='admin-menu-link'>
						<FirstPageOutlinedIcon/>
						<span>Project List</span>
					</NavLink>
				</div>
			}
		</div>
		{/* {
			!isSidebar && <div className='admin-sidebar-menu-open' style={{ paddingTop: 24 }}>
				<BaseButton customStyles={{ padding: '4px' }}
					text={<MenuIcon />}
					onClick={toggleSidebar}
				/>
			</div>
		} */}
	</>
}

export default AdminSideBar;