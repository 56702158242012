import { 
    USER_EMAIL, 
    USER_ID, 
    USER_NAME,
    IS_ADMIN,
    HAS_SUBSCRIPTION,
    RESET_USER_STATE,
    IS_BILLING_ADMIN
} from "../actionTypes"


const resetUserStateAction = () => {
    return {
        type: RESET_USER_STATE
    }
}

const usernameAction = (payload) => {
    return {
        type: USER_NAME,
        payload: payload
    }
}


const userIdAction = (payload) => {
    return {
        type: USER_ID,
        payload: payload
    }
}


const user_emailAction = (payload) => {
    return {
        type: USER_EMAIL,
        payload: payload
    }
}

const userIsAdminAction = (payload) => {
    return {
        type: IS_ADMIN,
        payload: payload
    }
}

const userHasSubscription = (payload) => {
    return {
        type: HAS_SUBSCRIPTION,
        payload: payload
    }
}

const userIsBillingAdminAction = (payload) => {
    return {
        type: IS_BILLING_ADMIN,
        payload: payload
    }
}

export {
    resetUserStateAction,
    usernameAction,
    userIdAction,
    user_emailAction,
    userIsAdminAction,
    userHasSubscription,
    userIsBillingAdminAction
}