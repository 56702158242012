import './index.scss'
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Layout from "../../components/Layout";
import Checkbox from '../../Elements/Checkbox';
import { BaseButton } from '../../Elements/Button';
import Modal from '../../components/Modal';
import DriverPopup from '../../components/DriverPopup';
import { ApiClient } from '../../api/client';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { projectAction } from '../../redux/actions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Drivers = () => {

    const client = ApiClient()

    const navigate = useNavigate()
    const { state } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const { projectId } = useSelector(state => state.project)
    const dispatch = useDispatch()

    const [driverObj, setDriverObj] = useState([])
    const [driverData, setDriverData] = useState();

    const [showAddDriverModal, setAddDriverModal] = useState(false)
    const [atleastOneChecked, setAtleastOneChecked] = useState(true)
    const [showMandateStatement, setMandateStatement] = useState(false)

    useEffect(() => {
        if (state?.projectId) {
            dispatch(projectAction(state?.projectId))
        } else if (searchParams.get('projectId')) {
            dispatch(projectAction(searchParams.get('projectId')))
        }
    }, [])

    useEffect(() => {
        if (projectId) {
            const client = ApiClient(setDriverData)
            client.get('/api/v1/survey/drivers/' + projectId + '/').then((response) => {
                if (response?.data) {
                    setDriverData(response.data.driversList)
                }
            })
        }
    }, [projectId])

    useEffect(() => {
        if (driverData) {
            setDriverObj(driverData)
        }
    }, [driverData])

    useEffect(() => {
        setAtleastOneChecked(!driverObj.map(item => item.isChecked).includes(true))
    }, [driverObj])

    const nextBtnConditions = () => {
        if (driverObj) {
            const client = ApiClient(setDriverData)
            if (projectId) {
                const apiUrl = '/api/v1/survey/drivers/' + projectId + '/'
                client.put(apiUrl, {
                    data: {
                        'projectId': projectId,
                        'driversList': driverObj
                    }
                }).then((response) => {
                    setDriverData(response.data.driversList)
                    navigate({
                        pathname: '/setup/about-me',
                        search: '?projectId=' + projectId
                    }, { state: { projectId: projectId } })
                }).catch((error) => {

                })
            }
            client.post('api/v1/journeysteps/', {
                project: projectId,
                step_id: 3,
                step_name: 'Drivers'
            }).then((res) => {
                navigate({
                    pathname: '/setup/about-me',
                    search: '?projectId=' + projectId
                }, { state: { projectId: projectId } })
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const handleOnDragEnd = (result) => {
        if (!result?.destination) return;
        let src = result.source.index
        let dest = result.destination.index
        if (src === dest || dest === 0 || src === 0) return;

        let items = Array.from(driverObj);
        const [reorderedItem] = items.splice(src, 1);
        items.splice(dest, 0, reorderedItem);

        let OrderedItems = items.map((item, idx) => {
            item['driverOrder'] = idx + 1
            return item
        })
        client.put(`/api/v1/drivers-order/${projectId}/${result.draggableId}/`, {
            data: {
                new_order: dest + 1
            }
        })
        setDriverObj(OrderedItems);
    }


    return <Layout
        active_page={'Drivers'}
        onClickNext={() => nextBtnConditions()}
        onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#DriversOverview','_blank')}
        onClickBack ={() => navigate({
            pathname: '/setup/segments',
            search: '?projectId=' + projectId
        }, { state: { projectId } })}
        isNextDisabled={atleastOneChecked}
    >
        <div className="drivers-section section">
            <div className='section-container'>
                <div className='drivers-content'>
                    <h1 className='drivers-title'>Drivers</h1>
                    <p className='drivers-description'>What would you like Pulse to help you discover?<br />
                        These are your Drivers that group questions into meaningful categories.<br />
                        You can add any other drivers that will tell you what else people care about on your project.
                    </p>
                    <p className='drivers-instructions'>Deselect any drivers that do not apply or click on '+' to add another driver.</p>

                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <div className='drivers-option-list'>
                            {
                                driverObj
                                    .sort((a, b) => a.driverOrder - b.driverOrder)
                                    .map((item, idx) => {
                                        return (
                                            <Droppable key={"drivers" + item.driverId} droppableId={"drivers" + item.driverId}>
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        <Draggable
                                                            key={item.driverId}
                                                            draggableId={item.driverId.toString()}
                                                            index={idx}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    key={item.title}
                                                                    index={idx}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}

                                                                >
                                                                    <Checkbox
                                                                        isDraggable={true}
                                                                        provided={provided}
                                                                        svgIcon={item.icon}
                                                                        title={item.title}
                                                                        subtitle={item.subtitle}
                                                                        checked={item.isChecked}
                                                                        isMandatory={item.isMandatory}
                                                                        onCheckboxChange={() =>
                                                                            item.isMandatory ?
                                                                                setMandateStatement(true)
                                                                                :
                                                                                setDriverObj(prevState => (
                                                                                    [
                                                                                        ...prevState.map(obj =>
                                                                                            obj.title === item.title ?
                                                                                                { ...obj, isChecked: !item.isChecked }
                                                                                                :
                                                                                                obj
                                                                                        )
                                                                                    ]
                                                                                ))
                                                                        }
                                                                    />
                                                                    {
                                                                        (item.isMandatory && showMandateStatement)
                                                                        &&
                                                                        <span style={{ color: '#ff0000', fontSize: 10 }}>
                                                                            *This is a mandatory driver
                                                                        </span>
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        )
                                    })
                            }
                        </div>
                    </DragDropContext>
                    <div className='add-new-driver-div'>
                        <BaseButton
                            text={'+Add New Driver'}
                            className="add-new-driver-btn"
                            onClick={() => setAddDriverModal(!showAddDriverModal)}
                        />
                    </div>

                </div>
            </div>


        </div>
        {
            showAddDriverModal && <Modal
                modalClassName="add-driver-modal-wrapper"
                handleModal={setAddDriverModal}
                modal_content={<DriverPopup projectId={projectId} hasDescription setDriverData={setDriverData} setAddDriverModal={setAddDriverModal} />}

            />
        }
    </Layout>
}

export default Drivers;