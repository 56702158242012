import React from "react";

import  "./styles.scss";

const IconDashboard = (props) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect x="-1" y="-1" width="22" height="22" fill="none" />
      </g>
      <g stroke="null">
        <path
          d="m16.751 4.0538c0.52871 0 0.98809 0.19068 1.3695 0.58072s0.58072 0.84074 0.58072 1.3695v9.7509c0 0.52871-0.19068 0.98809-0.58072 1.3695s-0.84074 0.58072-1.3695 0.58072h-13.651c-0.52871 0-0.98809-0.19068-1.3695-0.58072s-0.58072-0.84074-0.58072-1.3695v-9.7509c0-0.52871 0.19068-0.98809 0.58072-1.3695s0.84074-0.58072 1.3695-0.58072h4.3857v-0.97942c0-0.26002 0.095342-0.49404 0.28603-0.68473s0.4247-0.28603 0.68473-0.28603h2.9296c0.26002 0 0.49404 0.095342 0.68473 0.28603s0.28603 0.4247 0.28603 0.68473v0.97942h4.3944zm0.48538 11.701v-9.7509c0-0.13868-0.043337-0.26002-0.13868-0.3467s-0.20802-0.13868-0.3467-0.13868h-4.3857v1.4648h-4.8798v-1.4648h-4.3857c-0.13868 0-0.26002 0.043337-0.3467 0.13868s-0.13868 0.20802-0.13868 0.3467v9.7509c0 0.13868 0.043337 0.26002 0.13868 0.3467s0.20802 0.13868 0.3467 0.13868h3.441l-0.03467-0.095342v-0.58072c0-0.48538 0.19935-0.90142 0.59805-1.2481s0.87541-0.52005 1.4475-0.52005c0.10401 0 0.23402 0.03467 0.3987 0.095342 0.32936 0.10401 0.65006 0.15601 0.97942 0.15601s0.65006-0.052005 0.97942-0.15601c0.18202-0.060672 0.31203-0.095342 0.3987-0.095342 0.57205 0 1.0488 0.17335 1.4475 0.52005s0.59805 0.76274 0.59805 1.2481v0.58072l-0.03467 0.095342h3.441c0.13868 0 0.26002-0.043337 0.3467-0.13868s0.13001-0.20802 0.13001-0.3467zm-7.3153-6.83c0.52871 0 0.98809 0.19068 1.3695 0.58072s0.58072 0.84074 0.58072 1.3695-0.19068 0.98809-0.58072 1.3695-0.84074 0.58072-1.3695 0.58072-0.98809-0.19068-1.3695-0.58072-0.58072-0.84074-0.58072-1.3695 0.19068-0.98809 0.58072-1.3695 0.84074-0.58072 1.3695-0.58072zm-0.97075-5.3565v1.9502h1.9502v-1.9502h-1.9502z"
          stroke="null"
        />
      </g>
    </svg>
  );
}

export default IconDashboard;