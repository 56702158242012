
import './index.scss'


const Progressbar = ({ progress }) => {
    return (
        <div className='progress-bar-box'>
            <div className='progress-bar' style={{ width: `${progress}%`, }}></div>
            <div className='completed-text'>{`${progress}% done`}</div>
        </div>
    )
}


export default Progressbar;