
import './index.scss';
import { trash } from "../../assets/svgs/questions";
import { BaseButton } from '../../Elements/Button';
import ProjectTable from '../../components/ProjectTable';
import LabeledInput from "../../Elements/LabeledInput";
import { useEffect, useState } from "react";
import { ApiClient } from "../../api/client";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";
import { MY_PROJECTS_PATH_NAME } from './../../utilities/constants'
import DeleteConfirmationPopup from "../../components/ConfirmationPopup";
import { projectResetAction } from '../../redux/actions';
import { useDispatch } from "react-redux";
import { Link } from '@mui/material';
import { useSelector } from 'react-redux';
import SeatsSubscriptionPopupModal from '../../components/SubscriptionPopup';
import checkmark from '../../assets/images/checkmark.gif';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {},
}));

const MyProject = () => {
    const pageSize = 10
    const navigate = useNavigate()
    const client = ApiClient()
    const { isBillingAdmin } = useSelector(state => state.user)

    const [subscriptionStatus, setSubscriptionStatus] = useState({
        hasActiveSubscription: false,
        isTrialExpired: null,
        daysLeft: null,
    })
    
    const { hasActiveSubscription, isTrialExpired, daysLeft } = subscriptionStatus

    const [queryStatus, setQueryStatus] = useState('NOT_STARTED')
    const [projects, setProjects] = useState([])
    const [projectsCreated, setProjectsCreated] = useState([])
    const [projectsCount, setProjectsCount] = useState(1)
    const [projectsCreatedCount, setProjectsCreatedCount] = useState(1)
    const [projectSelection, setProjectSelection] = useState({
        selectedProjects: [],
        isAllSelected: false
    })
    const [ deleteConfirmationPopup, setDeleteConfirmationPopup ] = useState(false)
    const [ seatPurchasePopup, setSeatPurchasePopup ] = useState(false)
    const [ searchProjects, setSearchProjects ] = useState('')
    const [ searchProjectsCreated, setSearchProjectsCreated ] = useState('')
    const [ seatsAssigned, setSeatsAssigned ] = useState(0)
    const [ seatsPurchased, setSeatsPurchased ] = useState(0)
    const [ seatsRequired, setSeatsRequired ] = useState(0)
    const [ seatReqErrStatus, setSeatReqErrStatus ] = useState(false)
    const [ submitDisabled, setSubmitDisabled ] = useState(true)
    const { selectedProjects, isAllSelected } = projectSelection
    const { hasSubscription } = useSelector(state => state.user)
    const [ showCircularProgress, setShowCircularProgress ] = useState(false);
    const [ updateSubscription, setUpdateSubscription ] = useState(false);
    const [ estimatedBilling, setEstimatedBilling ] = useState('')
    const [ currentBilling, setCurrentBilling ] = useState('')
    const [ billCalcError, setBillCalcError ] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        setQueryStatus('STARTED')
        client.get('/api/v1/billing-projects/').then((response) => {
            setProjectsCreated(response.data)
            setProjectsCreatedCount(response.count)
            setQueryStatus('COMPLETED')
        })
        client.get('/api/v1/seats/').then((response) => {
            if(response?.data){
                setSeatsAssigned(response?.data?.seats_assigned)
                setSeatsPurchased(response?.data?.seats_purchased)
            }
        })
        client.get('/api/v1/projects-list/?page_size=' + pageSize).then((response) => {
                setProjects(response.data)
                setProjectsCount(response.count)
                setQueryStatus('COMPLETED')
            })
        if (isBillingAdmin){
            getBillingProjects()
            client
                .get(`/api/v1/free-trial-check/`)
                .then(response => {
                    setSubscriptionStatus({isTrialExpired: response?.is_trial_expired, daysLeft: response?.days_left, hasActiveSubscription: response.has_active_subscription})
                })
        
        }
    }, [])

    useEffect(() => {
      getBillingProjects()
    }, [isBillingAdmin])
    

    const getBillingProjects = () => {
        setQueryStatus('STARTED')
        client.get('/api/v1/billing-projects/?page_size=' + pageSize).then((response) => {
            setProjectsCreated(response.data)
            setProjectsCreatedCount(response.count)
            setQueryStatus('COMPLETED')
        })
    }

    const handlePageClick = (event) => {
        let API_URL = '/api/v1/projects-list/?page=' + (event.selected + 1) + '&page_size=' + pageSize
        
        client.get(API_URL).then((response) => {
            setProjects(response.data)
        })
    };

    const handlePageProjectCreatedClick = (event) => {
        let API_URL = '/api/v1/billing-projects/?page=' + (event.selected + 1) + '&page_size=' + pageSize
        
        client.get(API_URL).then((response) => {
            setProjectsCreated(response.data)
        })
    };

    const handleDeleteSelected = () => {
        let API_URL = '/api/v1/billing-projects/selected/'
        if (isAllSelected) {
            API_URL = API_URL + '?all_selected=' + isAllSelected
        }
        else {
            API_URL = API_URL + '?project_ids=' + [...selectedProjects]
        }
        client.del(API_URL).then(response => {
            if (response?.data) {
                setProjectsCreated(response.data)
                setProjectsCreatedCount(response.count)
                setDeleteConfirmationPopup(false)
            }

        })
    }

    const handleProjectSearch = (e) => {
        setSearchProjects(e.target.value)
        
        let API_URL = '/api/v1/projects-list/?search=' + e.target.value + '&page_size=' + pageSize
        client.get(API_URL).then((response) => {
            setProjects(response.data)
            setProjectsCount(response.count)
        })
    }

    const handleProjectsCreatedSearch = (e) => {
        setSearchProjectsCreated(e.target.value)
        
        let API_URL = '/api/v1/billing-projects/?search=' + e.target.value + '&page_size=' + pageSize
        client.get(API_URL).then((response) => {
            setProjectsCreated(response.data)
            setProjectsCreatedCount(response.count)
        })
    }

    const handleNewProject = () => {
        dispatch(projectResetAction())
        navigate('/setup/project-attributes/', { state: { isNewProject: true, from: MY_PROJECTS_PATH_NAME } })
    }

    const handleSubscriptionDetails = () => {
        setShowCircularProgress(true)
        const API_URL = '/api/v1/stripe-session/'
        client.get(API_URL)
        .then((response) => {
            if(response?.data?.session_url){
                setShowCircularProgress(false)
                window.location.assign(response.data.session_url)
            }
        })
    }

    const handlSeatsRequired = (e) => {
        setSeatsRequired(e.target.value)
        if(e.target.value < seatsAssigned){
            setSubmitDisabled(true)
            setSeatReqErrStatus('Number of seats cannot be less that seats assigned')
            return false
        }
        setSeatReqErrStatus(false)
        setSubmitDisabled(false)
    }

    const handleSeatsPurchased = () => {
        setUpdateSubscription(false)
        setShowCircularProgress(true)
        const API_URL = '/api/v1/seats/'
        client.post(API_URL, {
            'seatsRequired' : seatsRequired
        }).then((response) => {
            if(response?.data?.session_url){
                window.location.assign(response.data.session_url)
            }
            if(response?.msg === 'Successful'){
                setUpdateSubscription(true)
                setShowCircularProgress(false)
            }
        })
    }

    const calculateEstimatedBilling = (seatCount) => {
        setBillCalcError('')
        const API_URL = '/api/v1/calculate-billing/'
        client.post(API_URL, {
            'seatsRequired' : seatsRequired
        }).then((response) => {
            if(response?.data?.upcoming_invoice_due_amount){
                setEstimatedBilling(response?.data?.upcoming_invoice_due_amount)
            }
            if(response?.data?.current_invoice_amount){
                setCurrentBilling(response?.data?.current_invoice_amount)
            }
        }).catch((err) => {
            setBillCalcError(err?.response?.data?.msg)
        })
    }

    const pageCount = Math.ceil(projectsCount / pageSize);
    const pageprojectsCreatedCount = Math.ceil(projectsCreatedCount / pageSize);

    return <>
        <div className="projects-list-section section">
            {isBillingAdmin && <>
                    <div className="projects-list-topbar">
                        <div className="title">
                            <div className="topbar-title">
                                <h1>My Subscription</h1>
                            </div>
                            {
                                (!hasActiveSubscription && isTrialExpired !== null)

                                &&

                                <div className='free-trial-banner'>
                                    <p>
                                        <span>Free Trial:&nbsp;</span> <b> {daysLeft === null ? ' Expired. ' : daysLeft === 0 ? ' Expiring Today. ' : (` ${daysLeft} ${daysLeft < 2 ? 'day': 'days'} left. `)} </b>
                                        <span>&nbsp;Click on Add/Remove seats to continue Pulse services.</span>
                                    </p>
                                </div>

                            }
                        </div>
                    </div>
                    <div className="seats-count-section">
                        <div className="seats-count-section-title">
                            <p>
                                <span className='seats-used-count'>{seatsAssigned}</span> of <span className='seats-available-count'>{seatsPurchased}</span> seats assigned - 
                                <Link
                                className='seats-count-add-remove'
                                onClick={() => {
                                    setSeatPurchasePopup(true)
                                }}>
                                    Add/Remove Seats
                                </Link>
                            </p>
                            {hasSubscription &&
                                <p>
                                    <Link
                                    className='subscription-details'
                                    onClick={() => {
                                        handleSubscriptionDetails()
                                    }}>
                                        Subscription details
                                    </Link>
                                </p>
                            }
                        </div>
                    </div>
                    <div className='projects-list-container'>
                        <div className="projects-list-content">
                            <div className="projects-list-actions">
                                <div className="left-actions" style={{display: 'flex'}}>
                                    {projectsCreated.length} {projectsCreated.length > 1 ? 'projects' : 'project'}
                                </div>
                                <div className="right-actions">
                                    <div className="search-input-action">
                                        <LabeledInput
                                            type='text'
                                            name='search'
                                            value={searchProjectsCreated}
                                            onChange={handleProjectsCreatedSearch}
                                            error={false}
                                            required={false}
                                            placeholder='Search for a Project Name'
                                        />
                                    </div>
                                    <BaseButton
                                        variant='text'
                                        className={'delete-action'}
                                        text={<>
                                            <img src={trash} alt='delete' />
                                            <span>Delete</span>
                                        </>}
                                        onClick={() => setDeleteConfirmationPopup(true)}
                                        isDisabled={!selectedProjects.length}
                                    />
                                    <div className='new-project-action'>
                                        <BaseButton
                                            variant="outlined"
                                            className={'new-project-btn'}
                                            text={<> + New Project</>}
                                            onClick={handleNewProject}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project-table-content">
                            <ProjectTable
                                queryStatus={queryStatus}
                                projectsArr={projectsCreated}
                                setProjects={setProjectsCreated}
                                projectSelection={projectSelection}
                                updateProjectSelection={setProjectSelection}
                            />

                            <div>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    previousLabel="<"
                                    onPageChange={handlePageProjectCreatedClick}
                                    pageRangeDisplayed={10}
                                    pageCount={pageprojectsCreatedCount > 1 ? pageprojectsCreatedCount : 0}
                                    renderOnZeroPageCount={null}
                                    breakClassName='paginate-li'
                                    breakLinkClassName='paginate-anchor'
                                    containerClassName='paginate-container'
                                    className="pagination"
                                    pageClassName="paginate-li"
                                    previousClassName="prev"
                                    nextClassName="next"
                                />
                            </div>
                        </div>

                    </div>
                </>
            }
           
        </div>


        <div className="projects-list-section section">
            {
                isBillingAdmin && <div className="projects-list-topbar">
                    <div className="title">
                        <div className="topbar-title">
                            <h1>Other Projects</h1>
                        </div>
                    </div>
                </div>
            }
            <div className='projects-list-container'>
                <div className="projects-list-content">
                    <div className="projects-list-actions">
                        <div className="left-actions">
                            {projects.length} {projects.length > 1 ? 'projects' : 'project'}
                        </div>
                        <div className="right-actions">
                            <div className="search-input-action">
                                <LabeledInput
                                    type='text'
                                    name='search'
                                    value={searchProjects}
                                    onChange={handleProjectSearch}
                                    error={false}
                                    required={false}
                                    placeholder='Search for a Project Name'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-table-content">
                    <ProjectTable
                        queryStatus={queryStatus}
                        projectsArr={projects}
                        setProjects={setProjects}
                        listType='projects'
                    />
                    <div>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            previousLabel="<"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={10}
                            pageCount={pageCount > 1 ? pageCount : 0}
                            renderOnZeroPageCount={null}
                            breakClassName='paginate-li'
                            breakLinkClassName='paginate-anchor'
                            containerClassName='paginate-container'
                            className="pagination"
                            pageClassName="paginate-li"
                            previousClassName="prev"
                            nextClassName="next"
                        />
                    </div>
                </div>

            </div>
        </div>
        {
            seatPurchasePopup && <SeatsSubscriptionPopupModal
                modalClassName="seat-update-wrapper"
                text={
                    <>
                    {updateSubscription &&
                        <>
                            <div style={{ textAlign: 'center',  padding: '1px' }}>
                                <img src={checkmark} alt='checkmark' style={{ borderRadius: '50%', marginTop: '6px', width: '150px', height: '150px' }}></img>
                            </div>
                            <h2>
                                Success! 
                            </h2>
                            <p>
                                Seats count updated. Please check your active subscriptions.
                            </p>
                        </>
                    }
                    {!updateSubscription &&
                        <>
                            {hasSubscription &&
                                <h1>Update Seats</h1>
                            }
                            {!hasSubscription &&
                                <h1>Buy Seats</h1>
                            }
                            <p>You have seats assigned to {seatsAssigned} unique {seatsAssigned > 1 ? 'users' : 'user' }</p>
                            <p>
                                Enter Number of Seats for Your Subscription : 
                            </p>
                            <div className={hasSubscription ? 'seat-count-50' : 'seat-count'}>
                                <LabeledInput
                                    type='number'
                                    name='seat-req'
                                    value={seatsRequired}
                                    onChange={(e) => handlSeatsRequired(e)}
                                    error={seatReqErrStatus}
                                    required={true}
                                    placeholder='Add Seat Count'
                                    min={seatsAssigned + 1}
                                />
                                {hasSubscription &&
                                    <Link
                                    className='bill-estimate'
                                    onClick={() => {
                                        calculateEstimatedBilling(seatsRequired)
                                    }}>
                                        Calculate
                                    </Link>
                                }
                            </div>
                            <p className='seat-count-rule'>
                                (Min {seatsAssigned})
                                <LightTooltip
                                    className="seat-note"
                                    title="To reduce the seat count, you'll need to unassign the seats from user list"
                                    placement="top"
                                    arrows
                                >
                                <InfoIcon fontSize='100px' />
                                </LightTooltip>
                            </p>
                            {currentBilling &&
                                <p className='billing-values'>
                                    (Current Bill: <b>{currentBilling}</b>)
                                </p>
                            }
                            {estimatedBilling &&
                                <p className='billing-values'>
                                    (Estimated Bill: <b>{estimatedBilling}</b>)
                                </p>
                            }
                            {billCalcError &&
                                <p className='billing-calc-error'>
                                    ({billCalcError})
                                </p>
                            }
                        </>
                    }
                    </>
                }
                handleCancel={() => {
                    window.location.reload()
                }}
                handleSubmit={handleSeatsPurchased}
                submitDisabled={submitDisabled}
                showCircularProgress={showCircularProgress}
                updateSubscription={updateSubscription}
                footerText={!updateSubscription && hasSubscription && 
                    <p>
                        You'll be immediately charged a prorated price
                        <br></br>
                        for additional seats for the rest of the billing cycle.
                        <br></br>
                        When a new billing cycle comes, they'll be charged 
                        <br></br>
                        according to their full price. There are no refunds.
                    </p>
                }
            />
        }
        {
            deleteConfirmationPopup && <DeleteConfirmationPopup
                modalClassName="project-delete-confirmation-wrapper"
                text={
                    <>
                        <p >Are you sure you want to delete {selectedProjects.length > 1 ? 'the projects' : <b>{projectsCreated.filter(item => item.project_id === selectedProjects[0])?.[0]?.['project_name']}</b>}?</p>
                        <span className='delete-warning'> Warning: By deleting the project you will loose all the responses and users PERMANENTLY!</span>
                    </>
                }
                handleCancel={() => setDeleteConfirmationPopup(false)}
                handleSubmit={handleDeleteSelected}
            />
        }
    </>

}

export default MyProject;