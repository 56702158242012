import './index.scss'
import { useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ApiClient } from '../../api/client';


const StakeholderLabelSection = ({ img = "" }) => {

    const client = ApiClient()
    const { projectId } = useSelector(state => state.project)

    const [preview_data, setPreview_data] = useState(
        {
            firstName: 'John',
            lastName: 'Doe',
            projectTitle: 'Pulse',
            segment_type_list: [],
        }

    )
    const [segmentString, setSegmentString] = useState('Pulse/Internal/External')
    const [statedata, setStatedata] = useState({
        lastNameVisible: true,
        userTitleVisible: true,
        segmentTypesVisible: [],
    })

    const handleClick = (value) => {

        let data = {}

        if (value === 'lastNameVisible') {
            data = { 'lastNameVisible': !statedata['lastNameVisible'] }
            setStatedata((state) => (
                {
                    ...state, lastNameVisible: !state['lastNameVisible']
                }
            ))
        }
        else if (value === 'userTitleVisible') {
            data = { 'userTitleVisible': !statedata['userTitleVisible'] }
            setStatedata((state) => (
                {
                    ...state, userTitleVisible: !state['userTitleVisible']
                }
            ))
        }
        else {
            if (statedata?.segmentTypesVisible?.includes(value)) {
                data = { segmentTypesVisible: statedata.segmentTypesVisible.filter(item => item !== value) }

                setStatedata((state) => (
                    {

                        ...state, segmentTypesVisible: state.segmentTypesVisible.filter(item => item !== value)

                    }

                ))
            }
            else {

                data = { segmentTypesVisible: [...statedata.segmentTypesVisible, value] }

                setStatedata((state) => (
                    {

                        ...state, segmentTypesVisible: [...state.segmentTypesVisible, value]

                    }
                ))
            }
        }
        client.put(`/api/v1/sh-visibility/${projectId}/`, { 'data': data })
            .then((response) => {
            })
    }


    const constructString = () => {
        let finalString = ''
        Object.keys(preview_data.segment_type_list).map(key => {

            if (statedata['segmentTypesVisible'].indexOf((preview_data.segment_type_list[key].segmentTypeId)) >= 0) {

                if (finalString === '') {
                    finalString = preview_data.segment_type_list[key].title
                }
                else {
                    finalString = finalString + ' / ' + preview_data.segment_type_list[key].title

                }
            }

        })
        setSegmentString(finalString)
    }

    useEffect(() => {
        let demo = {}
        if (projectId) {
            client.get('/api/v1/segments/' + projectId + '/?is_active=true')
                .then((response) => {
                    if (response?.data) {
                        response.data.map(item => {
                            demo[item.segmentTypeName] = {
                                'segemntId': item.segmentsList[0].segmentId,
                                'title': item.segmentsList[0].title,
                                'segmentTypeId': item.segmentTypeId
                            }
                        })
                        setPreview_data(state => (
                            {
                                ...state, segment_type_list: demo
                            }
                        ))

                    }
                })
        }

    }, [])

    useEffect(() => {
        if (projectId) {
            client.get(`/api/v1/sh-visibility/${projectId}/`)
                .then((response) => {
                    if (response?.data) {
                        setStatedata(response.data)
                    }
                })
        }
    }, [preview_data])

    useEffect(() => {
        constructString();
    }, [statedata])


    return <>
        <div className='wrapper'>
            <div className='stakeholder'>
                <div className='stak_title'> Click on the Labels to toggle On/Off the view for the defined categories :</div>
                <div className='stak_preview'>Sample Preview</div>
                <div className="user-card1" >
                    <div className={`profile-pic-div1 red`}>
                        <div className={`pic-box1 red`}>
                            {
                                !img && <span className={`initials1 red`}>
                                    {preview_data.firstName.split('')[0]}
                                    {statedata?.lastNameVisible && preview_data.lastName.split('')[0]}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="user-detail1">
                        <span className="full-name1">
                            {preview_data.firstName} {statedata?.lastNameVisible && preview_data.lastName}
                        </span>
                        <span className="user-tijtle1">
                            {statedata.userTitleVisible && preview_data.projectTitle}
                        </span>
                        <span className="segments1">{segmentString}</span>
                    </div>
                </div>

                <div className='user-name'>
                    <div className='btnbox disabled' >
                        <div>First Name </div>
                        <div className='eyediv' > <VisibilityIcon /> </div>
                    </div>
                    <div className={(statedata?.lastNameVisible) ? 'btn_on btnbox' : 'btn_off btnbox'} onClick={() => { handleClick('lastNameVisible') }}>
                        <div>Last Name </div>
                        <div className='eyediv' >{((statedata?.lastNameVisible)) ? <VisibilityIcon /> : <VisibilityOffIcon />}</div>
                    </div>
                </div>
                <div className='user-name'>
                    <div className={(statedata?.userTitleVisible) ? 'btn_on btnbox' : 'btn_off btnbox'} onClick={() => { handleClick('userTitleVisible') }}>
                        <div>Project title </div>
                        <div className='eyediv' >{((statedata?.userTitleVisible)) ? <VisibilityIcon /> : <VisibilityOffIcon />}</div>
                    </div>
                </div>

                <div className='user-name'>
                    {

                        Object.keys(preview_data.segment_type_list).map(key => {
                            return <div
                                className={(statedata?.segmentTypesVisible?.includes(preview_data.segment_type_list[key].segmentTypeId)) ? 'btn_on btnbox' : 'btn_off btnbox'}
                                onClick={() => { handleClick(preview_data.segment_type_list[key].segmentTypeId) }}
                            >
                                <div >{key}</div>
                                <div className='eyediv' >
                                    {(statedata?.segmentTypesVisible?.includes(preview_data.segment_type_list[key].segmentTypeId)) ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

    </>
}

export default StakeholderLabelSection;