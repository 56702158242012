
import { BaseButton } from '../../Elements/Button';
import Modal from '../Modal';
import './index.scss';


const SeatsSubscriptionPopupModal = ({
    modalClassName = '',
    text,
    handleCancel,
    handleSubmit,
    buttonLabel = 'Submit',
    submitDisabled = true,
    showCircularProgress,
    updateSubscription,
    footerText
}) => {
    return <Modal
        modalClassName={modalClassName}
        handleModal={handleCancel}
        modal_content={
            <div className="confirmation-modal-content">
                <div className="content-div">
                    <p>{text}</p>
                </div>
                {!updateSubscription &&
                    <div className='modal-action-button-section'>
                        <BaseButton
                            text="Cancel"
                            className='cancel-btn modal-cancel-btn'
                            onClick={() => handleCancel(false)}
                        />

                        <BaseButton
                            variant='contained'
                            text={buttonLabel}
                            className='modal-submit-btn'
                            onClick={handleSubmit}
                            isDisabled={submitDisabled}
                            showCircularProgress={showCircularProgress}
                        />

                    </div>
                }
                <div className="footer-content-div">
                    {footerText}
                </div>
            </div>
        }
    />
}

export default SeatsSubscriptionPopupModal;