import { BaseButton } from '../Button';
import './index.scss'

import CloseIcon from '@mui/icons-material/Close';

const Chips = ({ text, onCloseClick, isClosable = true }) => {
    
    return <BaseButton
            variant="outlined"
            className={'pview-permission-segment'}
            text={<> {text} {
                isClosable && <CloseIcon
                    className='close-btn'
                    sx={{ color: '#000000', fontSize: 13 }}
                    onClick={onCloseClick}
                />
            }</>}
            onClick={() => {}}
        />
}

export default Chips