import './index.scss'
import { useEffect, useState } from "react"
import { BaseButton } from "../../../Elements/Button"
import LabeledInput from "../../../Elements/LabeledInput"
import { ApiClient } from "../../../api/client"
import SelectDropDown from "../../../Elements/SelectDropDown"
import { useSelector } from "react-redux"
import { MY_STAKEHOLDERS, MappingIcon, PROJECT_USERS_LIST, mapsColors } from './utils'
import { UserCard } from './utils'




const AddStakeHolderForm = ({ setScreenName, myMaps, projectMaps, isEdit, user, maxAvailableHeight, ...args }) => {

    const client = ApiClient()
    const { projectId } = useSelector(state => state.project)

    const [segmentTypes, setSegmentTypes] = useState([])
    const [segmentsFields, setSegmentFields] = useState({})

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        organisation: '',
        projectUserTitle: '',
        projectUserRoleDesc: '',
        myMapsIds: isEdit ? user?.mapping_data?.my_maps?.map(item => item?.my_map) : [],
        projectMapsIds: isEdit ? user?.mapping_data?.project_maps?.map(item => item?.project_map) : [],
    })
    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })

    const {
        firstName,
        lastName,
        email,
        organisation,
        projectUserTitle,
        projectUserRoleDesc,
        myMapsIds,
        projectMapsIds,
    } = formData
    const { errField, errMsg } = err

    const resetErrState = () => {
        setErr({
            errField: '',
            errMsg: ''
        })
    }

    const resetFormState = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            organisation: '',
            projectUserTitle: '',
            projectUserRoleDesc: '',
            myMapsIds: [],
            projectMapsIds: [],
        })
    }


    useEffect(() => {
        if (projectId) {
            client
                .get('/api/v1/segments/' + projectId + '/?is_active=true&shForm=true')
                .then((response) => {
                    if (response?.data) {
                        setSegmentTypes(response.data)
                    }
                })
        }
    }, [])

    useEffect(() => {
        let tempObj = {}

        segmentTypes.length > 0 &&
            segmentTypes?.forEach((elem, idx) => {
                tempObj[`segment_type_${idx}`] = ''
            });

        setSegmentFields(tempObj)
        setFormData(state => ({ ...state, ...tempObj }))
    }, [segmentTypes])

    const handleForm = (e) => {
        setErr({ errField: '', errMsg: '' })
        setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }

    const handleSelectSegments = (key, value) => {
        setErr({ errField: '', errMsg: '' })
        setFormData(prevState => ({ ...prevState, [key]: parseInt(value) }))
    }

    const handleSelectMaps = (map_name, id) => {
        setErr({ errField: '', errMsg: '' })
        let mapArray = map_name === 'myMapsIds' ? myMapsIds : projectMapsIds
        if (mapArray.includes(id)) {
            setFormData(state => (
                {
                    ...state,
                    [map_name]: state[map_name].filter(item => item !== id)
                }
            ))
        }
        else {
            setFormData(state => (
                {
                    ...state,
                    [map_name]: [...state[map_name], id]
                }
            ))
        }
    }

    const userErrHandling = (errorData) => {
        let errors = errorData?.response?.data?.errors
        let errKeys = Object.keys(errors)
        if (errKeys.length) {
            if (errKeys.includes('first_name')) {
                setErr({ errField: 'first_name', errMsg: errors['first_name'][0] })
            }
            else if (errKeys.includes('last_name')) {
                setErr({ errField: 'last_name', errMsg: errors['last_name'][0] })
            }
            else if (errKeys.includes('email')) {
                setErr({ errField: 'email', errMsg: errors['email'][0] })
            }
            else if (errKeys.includes('non_field_errors') && errors['non_field_errors'].length) {
                if (errors['non_field_errors'].includes("The fields project, user must make a unique set.")) {
                    setErr({ errField: 'email', errMsg: 'Email address already exists in current project.' })
                }
                else {
                    // console.log(errors['non_field_errors'])
                }
            }
        }
    }



    const handleSubmit = () => {
        for (let key in formData) {
            if (formData[key] === '') {
                if (key === 'email') {
                    setErr({ errField: 'email', errMsg: 'This field is required.' })
                    return;
                }
                if (key === 'firstName') {
                    setErr({ errField: 'first_name', errMsg: 'This field is required.' })
                    return;
                }
                if (key === 'lastName') {
                    setErr({ errField: 'last_name', errMsg: 'This field is required.' })
                    return;
                }

            }
        }
        // for (let key in segmentsFields) {
        //     if (formData[key] === null || formData[key] === undefined || !formData[key]) {
        //         setErr({ errField: key, errMsg: '' })
        //         return;
        //     }
        // }

        if (myMapsIds.length === 0 && projectMapsIds.length === 0){
            setErr({ errField: 'mapping', errMsg: '*Assign atleast one Mapping' })
            return;
        }

        let data = {
            'project_id': projectId,
            'user_data': {
                first_name: firstName,
                last_name: lastName,
                email: email.toLowerCase()
            },
            'project_user_data': {
                project_user_title: projectUserTitle,
                project_user_role_desc: projectUserRoleDesc,
                invite_sent: false,
                email_sent: false,
                project_admin: false,
                is_archived: false,
            },
            'segment_user_data': Object.keys(segmentsFields).map(item => {
                return { 'segment': formData[item] }
            }),
            'mapping_data': { myMapsIds, projectMapsIds }
        }

        client
            .post('/api/v1/ao-project-users/' + projectId + '/', data)
            .then((response) => {
                resetFormState()
                resetErrState()
                args?.setReloadMap(true)
                setScreenName(MY_STAKEHOLDERS)

            }).catch((error) => {
                userErrHandling(error)
            })

    }

    const handleUpdate = () => {
        if (myMapsIds.length === 0 && projectMapsIds.length === 0){
            setErr({ errField: 'mapping', errMsg: '*Assign atleast one Mapping' })
            return;
        }
        let data = {
            'project_user_id': user.id,
            'mapping_data': { myMapsIds, projectMapsIds }
        }

        client.put('/api/v1/ao-project-users/' + projectId + '/', data).then((response) => {
            args?.setReloadMap(true)
            setScreenName(MY_STAKEHOLDERS)
        }).catch((error) => {
            console.log(error)
            // userErrHandling(error)
        })
    }

    return <div className="about-others-user-form-container">
        <h3>{isEdit ? 'Update Stakeolder' : 'Add New Stakeholder'}</h3>

        <div className="about-others-user-form" style={{ maxHeight: maxAvailableHeight - 40 }}>
            {
                isEdit ?
                    <div className='users-list'>
                        <UserCard
                            fName={`${user.first_name} ${user.last_name}`}
                            segments={user.segment_user_data.map(item => item.segment_name).join(' / ')}
                            userTitle={user.project_user_title}
                            rightArrow={false}
                        />
                    </div>
                    :
                    <div>
                        <LabeledInput
                            type='text'
                            name='firstName'
                            value={firstName}
                            onChange={handleForm}
                            placeholder='First Name*'
                            error={errField === 'first_name' && errMsg}
                            required
                            isUnderlined={true}
                        />
                        <LabeledInput
                            type='text'
                            name='lastName'
                            value={lastName}
                            onChange={handleForm}
                            placeholder='Last Name*'
                            error={errField === 'last_name' && errMsg}
                            required
                            isUnderlined={true}
                        />
                        <LabeledInput
                            type='email'
                            name='email'
                            value={email}
                            onChange={handleForm}
                            placeholder='Email*'
                            error={errField === 'email' && errMsg}
                            required
                            isUnderlined={true}

                        />
                        <LabeledInput
                            type='text'
                            name='organisation'
                            value={organisation}
                            onChange={handleForm}
                            placeholder='Organisation'
                            error={false}
                            required={false}
                            isUnderlined={true}
                        />
                        <LabeledInput
                            type='text'
                            name='projectUserTitle'
                            value={projectUserTitle}
                            onChange={handleForm}
                            placeholder='Project Title'
                            error={false}
                            required={false}
                            isUnderlined={true}
                        />

                        <LabeledInput
                            type='text'
                            placeholder="How would you describe this person's role on the project?"
                            name='projectUserRoleDesc'
                            value={projectUserRoleDesc}
                            onChange={handleForm}
                            error={false}
                            required={false}
                            isUnderlined={true}
                        />


                        {
                            segmentTypes.sort((a, b) => a.segmentTypeId - b.segmentTypeId).map(
                                (segment_item, idx) => {

                                    return <SelectDropDown
                                        title={Object.keys(segmentsFields)[idx]}
                                        errorTitle={errField}
                                        defaultOptionText={"Select Segment"}
                                        label={segment_item.segmentTypeName}
                                        isRequired={false}
                                        optionList={segment_item.segmentsList.filter(seg => seg.isChecked === true)}
                                        handleOnChange={handleSelectSegments}
                                        valueField='segmentId'
                                        itemField='title'
                                        defaultValue={formData?.[`segment_type_${idx}`]}
                                        keys={Object.keys(segmentsFields)[idx]}
                                        defaultOptionSelected={false}
                                    />
                                })
                        }

                    </div>
            }

          
            <div className='ao-maps'>
                <span><b>My Map:</b> How does this person relate to you?</span>
                <div className='ao-maps-options-list'>
                    {
                        myMaps.map((item, idx) => {
                            let isSelected = myMapsIds.includes(item.segment_my_map_id)
                            return <MappingIcon
                                key={item.segment_my_map_id}
                                icon={item.icon_path}
                                title={item.segment_my_map_name}
                                onClick={() => handleSelectMaps('myMapsIds', item.segment_my_map_id)}
                                isSelected={isSelected}
                                styles={isSelected ? { backgroundColor: mapsColors[idx] } : {}}
                            />
                        })
                    }
                </div>
            </div>
            <div className='ao-maps'>
                <span><b>Project Map:</b> How does this person relate to the project?</span>
                <div className='ao-maps-options-list'>
                    {
                        projectMaps.map((item, idx) => {
                            let isSelected = projectMapsIds.includes(item.segment_project_map_id)
                            return <MappingIcon
                                key={item.segment_project_map_id}
                                icon={item.icon_path}
                                title={item.segment_project_map_name}
                                onClick={() => handleSelectMaps('projectMapsIds', item.segment_project_map_id)}
                                isSelected={isSelected}
                                styles={isSelected ? { backgroundColor: mapsColors[idx] } : {}}
                            />
                        })
                    }
                </div>
            </div>
            {
                errField === 'mapping' && <p style={{ color: '#ff0000', fontSize: '12px' }}>{errMsg}</p>
            }

            <div className='add-stakeholder-form-btn'>
                <BaseButton
                    className={"add-stakeholder-cancel-btn"}
                    text={'Back'}
                    onClick={() => {
                        resetFormState()
                        setScreenName(PROJECT_USERS_LIST)
                    }}
                />
                {
                    isEdit ?
                        <BaseButton
                            className={"add-stakeholder-submit-btn"}
                            text='Update Stakeholder'
                            onClick={handleUpdate}
                        />
                        :
                        <BaseButton
                            className={"add-stakeholder-submit-btn"}
                            text={'Add  Stakeholder'}
                            onClick={handleSubmit}
                        />
                }
            </div>


        </div>

    </div>
}


export default AddStakeHolderForm;


