import { BaseButton } from "../../../Elements/Button"




const TopicOptions = ({
    item,
    // onSelectOption,
    setUpdatedTopic,
    handleDeleteTopic,
    isActive
}) => {

    return <div
        key={`topic-${item.id}`}
        className={`multi-topic-option-item ${isActive ? 'active-topic' : ''}`}
    >
        <div
            // onClick={(e) => onSelectOption(item.id, item.topic_name)}
            className={`main ${isActive ? 'editable' : ''}`}
        >
            <div className="bullet"></div>
            <div className="data">
                <div className="topic">{item.topic_name}</div>
                <div className="comment">{item.topic_comment}</div>
            </div>
        </div>

        <div className="space">
            <BaseButton
                className={`topic-btn edit-topic ${isActive ? 'active-btn' : 'inactive-btn'}`}
                variant={isActive ? "contained" : "outlined"}
                color="primary"
                size="small"
                onClick={() => setUpdatedTopic((state) => (
                    {
                        ...state,
                        isEditModalVisible: true,
                        topicId: item.id,
                        updatedName: item.topic_name,
                        updatedComment: item.topic_comment
                    }
                ))}
                text="Edit"
            />
            <BaseButton
                className={`topic-btn delete-topic ${isActive ? 'active-btn' : 'inactive-btn'}`}
                variant={isActive ? "contained" : "outlined"}
                color="secondary"
                size="small"
                // isDisabled={isActive}
                onClick={(e) => handleDeleteTopic(item.id)}
                text="Delete"
            />
        </div>
    </div>
}

export default TopicOptions