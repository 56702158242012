import "./layout.scss"
import AdminSideBar from "../AdminSidebar"
import { useState } from "react";
import BottomBar from "../BottomBar";
import AdminTopBar from "../AdminTopBar";



import { useSelector } from "react-redux";




const Layout = ({ children, ...args }) => {
    const [isSidebar, setIsSidebar] = useState(true)

    const { currentProjectName } = useSelector(state => state.project)

    const toggleSidebar = () => setIsSidebar(!isSidebar)

    return <div className="layout" style={{ display: 'flex' }}>

        {
            (args.showSideBar ?? true) &&
            <div className="sidebar" >
                <AdminSideBar isSidebar={isSidebar} toggleSidebar={toggleSidebar} isWelcomepage={args.isWelcomepage} setShowTour={args.setShowTour} checked={args.checked} setChecked={args.setChecked} active_page={args.active_page} />
            </div>
        }
        <div className={isSidebar ? "main-content sidebar-open" : "main-content"} >
            <AdminTopBar isSidebar={isSidebar} toggleSidebar={toggleSidebar} topBarTitle={args.topBarTitle} setUserInfo={args.setUserInfo} projectName={currentProjectName} />
            <main>
                {children}
            </main>

        </div>
        <div className="bottom-bar-section">
            <BottomBar
                onClickBack={args.onClickBack}
                onClickHelp={args.onClickHelp}
                onClickNext={args.onClickNext}
                isNextDisabled={args.isNextDisabled}
                isNextShown={args.isNextShown}
                isHelpShown={args.isHelpShown}
                isLaunch={args.isLaunch}
                isBackShown={args.isBackShown}
            />
        </div>
    </div>
}

export default Layout;