import { BaseButton } from '../../Elements/Button'
import Checkbox from '../../Elements/Checkbox'
import editPencil from '../../assets/svgs/editPencil.svg'
import { Fragment, useEffect, useState } from 'react';
import Modal from '../Modal'
import './index.scss'
import EditSegmentPopup from '../EditSegmentPopup';

const SegmentTypeContent = ({ segmentTypeData, handleSegmentTypeChange,flag }) => {

    const [showSegmentTypeModal, setSegmentTypeModal] = useState(false)
    function handle_add() {
        setSegmentTypeModal(true);
    }
    return <>
        <div>
            <h1 className='segment-title' >
                {segmentTypeData.segmentTypeName} &nbsp;
              
                <img src={editPencil} width={15} onClick={() => handle_add()} />
            </h1>
        </div>
        {
            showSegmentTypeModal && <Modal
            modalClassName={'edit-segment-modal-wrapper'}
                handleModal={setSegmentTypeModal}
                modal_content={
                    <EditSegmentPopup
                        segmentTypeData={segmentTypeData}
                        setSegmentTypeModal={setSegmentTypeModal}
                        handleSegmentTypeChange={handleSegmentTypeChange}
                    />
                }
            />
        }
        {segmentTypeData.segmentTypeDescription && <p className='segment-description'>{segmentTypeData.segmentTypeDescription}</p>}
        <p className='segment-instructions'>Select all that apply and 'Add New' segments if required.</p>
    </>
}



const SegmentType = ({ active_segment_data, setSegmentChange, handleAddSegmentModal, handleSegmentTypeChange, handleSegmentChange }) => {
    let segments_list = active_segment_data[0]['segmentsList']
    return <div className='segment-content'>
        <div>
            <SegmentTypeContent
                segmentTypeData={active_segment_data[0]}
                handleSegmentTypeChange={handleSegmentTypeChange}
            />
        </div>
        <div className='segments-option-list' >
            { 
                segments_list?.sort((a, b) => a.segmentId - b.segmentId).map(item => {
                    
                    return <Fragment key={item.title}>
                        <Checkbox
                            svgIcon={item.icon}
                            title={item.title}
                            subtitle={item.subtitle}
                            checked={item.isChecked}
                            isEditable={true}
                            onEdit={() => handleSegmentChange(item)}
                            onCheckboxChange={() => {
                                setSegmentChange(prevState => (
                                    {
                                        ...prevState,
                                        segmentData: prevState?.segmentData?.map(obj => (
                                            {
                                                ...obj, segmentsList: obj['segmentsList'].map(data => (
                                                    data.segmentId === item.segmentId ?
                                                        { ...data, isChecked: !item.isChecked }
                                                        :
                                                        data
                                                ))
                                            }
                                        ))
                                    }
                                ))
                            }}
                        />
                    </Fragment>
                
                })
            }
        </div>
      
        <div className='add-new-segment-div'>
            <BaseButton
                text={'+ add new '+active_segment_data[0].segmentTypeName}
                className="add-new-segment-btn"
                onClick={handleAddSegmentModal}
            />
        </div>
    </div>
}


export default SegmentType;