import './index.scss';
import logo from '../../assets/images/project-ai-logo-light.png';
import { Fragment, useEffect, useState } from 'react';
import { EMAIL, PASSWORD } from '../Register/utility';
import isEmail from 'validator/lib/isEmail';
import LabeledInput from '../../Elements/LabeledInput';
import { Link, useNavigate } from 'react-router-dom';
import { BaseButton } from '../../Elements/Button';
import { ApiClient } from '../../api/client';
import Modal from '../../components/Modal';
import { InfoText } from '../../Elements/MessageBlocks';
import mail from '../../assets/svgs/mail.svg'
import OtpInput from 'react-otp-input';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { projectResetAction, resetUserStateAction, userIdAction, userIsAdminAction, userIsBillingAdminAction, user_emailAction } from '../../redux/actions';
import { scrollToBottom } from '../../utilities';


const LoginForm = () => {
    const formValidator = (form_data) => {
        if (!isEmail(form_data.username)) {
            return {
                field: EMAIL,
                err: 'Invalid Email.'
            }
        }
    }

    const formInitialState = {
        username: '',
        password: '',
    }

    const [formData, setFormData] = useState(formInitialState)
    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })

    const { errField, errMsg } = err;
    const { username, password } = formData;
    const [mfaActivationCode, setMfaActivationCode] = useState('');
    const [showModal, setModalVisibility] = useState(false);
    const [showCircularProgress, setShowCircularProgress] = useState(false);

    const handleInputChange = (e) => {
        setErr({ ...err, ...{ errField: '', errMsg: '' } })
        setFormData(prevState => (
            { ...prevState, [e.target.name]: e.target.value }
        ))
    }

    let inputs_arr = [
        { type: 'username', name: 'username', label: EMAIL, value: username },
        { type: 'password', name: 'password', label: PASSWORD, value: password },
    ]

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loginResponse, setLoginResponse] = useState()
    const [navigateDestination, setNavigateDestination] = useState({
        loginSuccess : false,
        navigateTo : '',
        responseData : '',
        isAdmin: '',
        userId: '',
        isBillingAdmin: ''
    })
    const client = ApiClient(setNavigateDestination)

    const handleSubmit = (e) => {
        let error = formValidator(formData)
        if (error) {
            setErr({ ...err, ...{ errField: error['field'], errMsg: error['err'] } })
        }
        else {
            resetUserStateAction()
            projectResetAction()
            client.login(formData.username, formData.password)
            dispatch(user_emailAction(username))
        }
    }

    const handleKeyDown = (e) => {
        if(e.key === 'Enter'){
            handleSubmit()
        }
    }

    const handleMfaSubmit = (e) => {
        setShowCircularProgress(true)
        if(mfaActivationCode?.length === 6){
            const client = ApiClient(setModalVisibility(true))
            setErr({ ...err, ...{ errField: 'mfa_activation_code', errMsg: '' } })
            client.post('api/v1/auth/login/code/', {
                code : mfaActivationCode,
                ephemeral_token : navigateDestination?.responseData
            }).then((response) => {
                setShowCircularProgress(false)
                setModalVisibility(false)
                // Store the JWT and refresh tokens in session storage
                localStorage.setItem('access_token', response.access);
                localStorage.setItem('refresh_token', response.refresh);
                client.get('/api/v1/auth/users/me/').then((results) => {
                    let navigate_path;
                    if (results['is_admin_user'] === true || results['is_biling_admin'] === true) {
                        navigate_path = '/app/my-projects'
                    }
                    else if (results['is_invited_user'] === true) {
                        navigate_path = '/app/welcome'
                    }
                    else {
                        navigate_path = '/setup/welcome'
                    }
                    navigate(navigate_path);

                })
            }).catch((error) => {
                setShowCircularProgress(false)
                setErr({ ...err, ...{ errField: 'mfa_activation_code', errMsg: error.response.data.error } })
            })
        }else{
            setShowCircularProgress(false)
            setErr({ ...err, ...{ errField: 'mfa_activation_code', errMsg: 'Verification code should be 6 digits long' } })
        }
    }

    useEffect(() => {
        console.log(navigateDestination)
        if(navigateDestination.responseData){
            if(navigateDestination?.loginSuccess && navigateDestination?.navigateTo !== ''){
                setLoginResponse(navigateDestination?.responseData)
                dispatch(userIsAdminAction(navigateDestination?.isAdmin))
                dispatch(userIsBillingAdminAction(navigateDestination?.isBillingAdmin))
                dispatch(userIdAction(navigateDestination?.userId))
                navigate(navigateDestination?.navigateTo);
            }else if (navigateDestination?.responseData){
                if(navigateDestination?.additionalStep){
                    setModalVisibility(true)
                    setMfaActivationCode('')
                    setErr({ ...err, ...{ errField: 'mfa_activation_code', errMsg: '' } })
                }else{
                    navigateDestination.responseData='The username or password you entered is incorrect.'
                    setLoginResponse(navigateDestination?.responseData)
                }
            }
        }
    }, [navigateDestination, navigate])

    return <div className="register-section">
        <div className="left-section" >
            <div className="logo">
                <img src={logo} width={100} height={50} alt="logo" />
            </div>
            <div className='headline'>
                <span>Successful projects start with people</span>
            </div>
            {
                window.innerWidth < 769 && <> <div className='heading-text-sm'>
                    <h1>Collective Insights</h1>
                    <span>
                        Pulse by ProjectAI helps project managers make <br />
                        sense of people’s collective insights for better project outcomes.
                    </span>
                </div>
                    <div className='scroll-to-bottom-icon' onClick={scrollToBottom}>
                        <i className="fa-solid fa-chevron-down"></i>
                    </div>
                </>
            }
        </div>
        <div className='right-section'>
            <div>
                <div className='heading'>
                    <h1>Welcome!</h1>
                    <span>Welcome to <strong>Pulse</strong>, Login to continue.</span>
                </div>
                <InfoText messageText={loginResponse} color="#FF0000"></InfoText>
                <div className='form-container'>
                    <form>
                        {
                            inputs_arr.map(inp => {
                                return <Fragment key={inp.name}>
                                    <LabeledInput
                                        type={inp.type}
                                        name={inp.name}
                                        label={inp.label}
                                        value={inp.value}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        error={errField === inp.label && errMsg}
                                        isLoginPage={true}
                                    />
                                </Fragment>
                            })
                        }
                        <p style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}><Link to='/forgot-password'> Forgot Password? </Link></p>
                        <p>Want to create a new account? <Link to='/register'> Click here </Link></p>
                        <div style={{ textAlign: 'center' }}>
                            <BaseButton
                                className="submit-btn"
                                text="Login"
                                variant="filled"
                                onClick={handleSubmit}
                            />
                        </div>
                    </form>
                </div>
            </div>
            {showModal && 
                <Modal
                    handleModal={() => setModalVisibility(!showModal)} 
                    width="20%"
                    modal_content={
                    <div style={{ width: '100%' , flex: '1' }}>
                        <div style={{ textAlign: 'center' , marginTop: '20px'}}>
                            <img src={mail} alt="mail"/>
                            <InfoText messageText="Enter OTP" color='#00000' variant='h6' component='h6'></InfoText>
                            <InfoText messageText="Check your email for the OTP" color='' variant='subtitle1'></InfoText>
                            <OtpInput
                                value={mfaActivationCode}
                                onChange={setMfaActivationCode}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                inputStyle="otp-input-div"
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus={true}
                                containerStyle={{'justifyContent' : 'center', 'marginTop' : '1rem', 'marginBottom' : '1rem'}}
                                />
                            <InfoText messageText={errField === 'mfa_activation_code' && errMsg} color='red' variant='subtitle1'></InfoText>
                            <div>
                                <BaseButton
                                    className="submit-btn"
                                    text="Submit"
                                    variant="filled"
                                    onClick={handleMfaSubmit}
                                    showCircularProgress={showCircularProgress}
                                />
                            </div>
                        </div>
                    </div>
                    }
                />
            }
        </div>
    </div>
}

export default LoginForm;