
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from "@mui/material";
import { Fragment, useState } from "react";
import { useSelector } from 'react-redux';

import './index.scss';

import Loader from "../Loader";
import Modal from '../Modal';

import { ApiClient } from '../../api/client';
import {
    chevronDown,
    chevronUp,
} from './../../assets/svgs/questions';
import { BaseButton } from '../../Elements/Button';


const PENDING = 'PENDING'
const SHOW_CURRENT = 'SHOW_CURRENT'
const SHOW_FLAGGED = 'SHOW_FLAGGED'
const LEAVE_HIDDEN = 'LEAVE_HIDDEN'


const FlagResponseTable = ({ flagData, queryStatus, setFlagData }) => {

    const client = ApiClient()
    const { projectId } = useSelector(state => state.project)

    const [flagsVisible, updateVisibleFlags] = useState([])
    const [defaultOption, setDefaultOption] = useState(PENDING)

    const [actionData, setActionData] = useState({
        action: '',
        flagItem: {}
    })
    const [confirmationVisible, setConfirmationVisibility] = useState(false)

    const handleFlagVisibility = (flagId) => {
        if (flagsVisible.includes(flagId)) {
            updateVisibleFlags(state => state.filter(item => item !== flagId))
        }
        else {
            updateVisibleFlags(state => [...state, flagId])
        }
    }

    const handleSelectOptionChange = (e, flag) => {
        if (e.target.value !== PENDING) {
            setConfirmationVisibility(true)
            setActionData({ action: e.target.value, flagItem: flag })
        }
        else {
            setActionData({ action: e.target.value, flagItem: {} })
        }

    }

    const handleSubmit = () => {
        let data = {
            flag_id: actionData.flagItem.id,
            flag_response_id: actionData.flagItem.am_response_id,
            latest_response_id: actionData.flagItem.current_response_id
        }
        client
            .post(`/api/v1/flag-responses/${projectId}/?action=${actionData.action}`, {
                data: data
            }).then(res => {
                setFlagData(flagData.filter(item => item.id !== actionData.flagItem.id))
                setConfirmationVisibility(false)
            })

    }

    const handleCancel = () => {
        setActionData({
            action: '',
            flagItem: {}
        })
        setDefaultOption(PENDING)
        setConfirmationVisibility(false)
    }

    return <Fragment><table className="flag-response-table">
        <thead className="flag-response-thead">
            <tr className="table-head-row">
                <th className="head-item"><span>Subdrivers</span></th>
                <th className="head-item"><span>Flagged Response</span></th>
                <th className="head-item"><span>Current Response</span></th>
                <th className="head-item"><span>Flagged By</span></th>
                <th className="head-item"><span>Date</span></th>
                <th className="head-item"><span>Status</span></th>
            </tr>
        </thead>
        <tbody className="flag-response-tbody">
            {
                queryStatus === 'STARTED' ?
                    <Loader />
                    :
                    flagData.length > 0 ?
                        flagData.map((item, idx) => {
                            return <tr key={item.id} className="table-row">
                                <td className="row-item subdriver-td">
                                    <span className='text'>{item.sub_driver}</span>
                                    <span className='info-icon'>
                                        <Tooltip
                                            title={item.am_question_text}
                                            placement="top-start"
                                            arrows
                                        >
                                            <InfoOutlinedIcon color='primary' fontSize='100px' />
                                        </Tooltip>
                                    </span>
                                </td>
                                <td className="row-item flag-res-td">
                                    <span className="res-text">{item.flag_answer_text}</span>
                                    <div className="flag-type">
                                        <div className="title" onClick={() => handleFlagVisibility(item.id)}>
                                            <span>Flag Type: </span>
                                            <img
                                                src={flagsVisible.includes(item.id) ? chevronUp : chevronDown}
                                                alt={flagsVisible.includes(item.id) ? 'chevronUp' : 'chevronDown'}
                                            />
                                        </div>
                                        {flagsVisible.includes(item.id) && <span className="flag">{item.flag_type}</span>}
                                    </div>
                                </td>
                                <td className="row-item current-res-td">{item.current_answer_text}</td>
                                <td className="row-item flag-by-td">{item.flag_by}</td>
                                <td className="row-item flag-date-td">{item.date}</td>
                                <td className="row-item flag-status-td">

                                    <select className='select-option' value={defaultOption} defaultValue={defaultOption} onChange={(e) => handleSelectOptionChange(e, item)}>
                                        <option value={PENDING}>Pending</option>
                                        {item.current_answer_text && <option value={SHOW_CURRENT}>Show Current</option>}
                                        <option value={SHOW_FLAGGED}>Show Flagged</option>
                                        <option value={LEAVE_HIDDEN}>Leave Hidden</option>
                                    </select>

                                </td>
                            </tr>
                        })

                        :
                        <tr className="table-row"><td colSpan={6}>No Flagged Responses found</td></tr>
            }

        </tbody>
    </table>

        {
            confirmationVisible && <Modal
                modalClassName="flag-action-popup"
                modal_content={
                    <div className="confirmation-modal-content">

                        <div className='header-div'>
                            <h3 className='title'></h3>
                        </div>

                        <div className="content-div">
                            {
                                actionData.action === SHOW_CURRENT && <p>You are approving the <b>Current response</b> to be displayed.</p>
                            }
                            {
                                actionData.action === SHOW_FLAGGED && <p>You are approving the <b>Flagged response</b> to be posted back.</p>
                            }
                            {
                                actionData.action === LEAVE_HIDDEN && <p>The response will remain flagged and removed from the list.</p>
                            }
                        </div>
                        <div className='modal-action-button-section'>
                            <BaseButton
                                text={"No"}
                                className='cancel-btn modal-cancel-btn'
                                onClick={handleCancel}
                            />

                            <BaseButton
                                variant='contained'
                                text={'Yes'}
                                className='modal-submit-btn'
                                onClick={handleSubmit}
                            />

                        </div>
                    </div>

                }
                handleModal={handleCancel}
                width={'30vw'}
                style={{ minHeight: 'unset' }}
            />
        }

    </Fragment>
}

export default FlagResponseTable;