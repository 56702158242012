import {
    CURRENT_PROJECT,
    ADMIN_MENU_ITEMS,
    IS_EDIT,
    IS_DRAFT,
    PROJECT_USER_ID,
    CURRENT_PROJECT_NAME,
    RESET_PROJECT_STATE,
    IS_PROJECT_ADMIN
} from "../actionTypes"


const initialState = {
    projectId: '',
    isEdit: false,
    isDraft: true,
    menuItemsCompletedList: [],
    projectUserId: '',
    currentProjectName: '',
    isProjectAdmin: ''
}


const projectReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADMIN_MENU_ITEMS:
            return {
                ...state,
                menuItemsCompletedList: action.payload
            }
        case CURRENT_PROJECT:
            return {
                ...state,
                projectId: action.payload
            }
        case IS_EDIT:
            return {
                ...state,
                isEdit: action.payload
            }
        case IS_DRAFT:
            return {
                ...state,
                isDraft: action.payload
            }
        case PROJECT_USER_ID:
            return {
                ...state,
                projectUserId: action.payload
            }
        case CURRENT_PROJECT_NAME:
            return {
                ...state,
                currentProjectName: action.payload
            }
        case IS_PROJECT_ADMIN:
            return {
                ...state,
                isProjectAdmin: action.payload
            }
        case RESET_PROJECT_STATE:
            return initialState
        default:
            return state
    }

}

export default projectReducer;