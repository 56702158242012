import './index.scss';
import { Box, Typography } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Emoji from '../Emoji'
import { Fragment, useEffect, useRef, useState } from 'react';
import { BaseButton } from '../../Elements/Button';
import { gear_solid } from './../../assets/svgs/dashboard';
import SubdriverPopover from '../SubdriverSelector';
import { ApiClient } from '../../api/client';
import { useSelector } from 'react-redux';

const tabledatacolor = (value) => {
    if (value == null || !value) {
        return ['#FFFFFF', 'rgba(128,128,128, 0.6)']
    }
    else if (value < 2) {
        return ['#FBEAE9', 'rgba(229, 105, 101, 0.6)']
    }
    else if (value >= 2 && value < 4) {
        return ['#EAE4EC', 'rgba(161, 133, 168, 0.6)']
    }
    else if (value >= 4 && value < 6) {
        return ['#E4E7F3', 'rgba(130, 146, 198, 0.6)']
    }
    else if (value >= 6 && value < 8) {
        return ['#E8F4FD', 'rgba(75, 168, 237, 0.6)']
    }
    else if (value >= 8) {
        return ['#D7F3F1', 'rgba(7, 182, 169, 0.6)']
    }
}

const CircularProgressWithLabel = (props) => {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
            variant="determinate"
            sx={{
            color: (theme) =>
                theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
            }}
            size={60}
            thickness={4}
            {...props}
            value={100}
        />
        <CircularProgress
            variant="determinate"
            disableShrink
            sx={{
            color: (theme) => (theme.palette.mode === 'light' ? tabledatacolor(props.value)[1] : tabledatacolor(props.value)[1]),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
            },
            }}
            size={60}
            thickness={4}
            {...props}
            value={Math.round(props.value * 10)}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
        <Typography 
          variant="caption"
          component="div"
          sx={{
            color: (theme) => tabledatacolor(props.value)[1],
            fontWeight: 'bold',
            fontSize: '1.5rem'
            }}>
            {props.value}
        </Typography>
        </Box>
      </Box>
    );
}

const SentimentBar = ({...props}) => {
    const [overallSentimentScoreData, setOverallSentimentScoreData] = useState({})
    const [subdriverFilterData, setSubdriverFilterData] = useState([])
    const { projectId, projectUserId, currentProjectName, isProjectAdmin } = useSelector(state => state.project)

    useEffect(() => {
        if (projectId) {
            const client = ApiClient(setOverallSentimentScoreData)
            client.get('/api/v1/overall-sentiment-score/' + projectId + '/')
            .then((response) => {
                if (response?.data) {
                    let subdrivers = response.data?.filter_data?.sub_driver
                    setSubdriverFilterData([...subdrivers.filter(item => item.sub_driver !== "Overall Sentiment"), ...subdrivers.filter(item => item.sub_driver === "Overall Sentiment")])
                    let ordered_data = response.data?.response_data?.filter(item => item.sub_driver === "Overall Sentiment")
                    subdrivers.sort((a,b) => a.order-b.order).forEach(item => {
                        let k = response.data?.response_data?.filter(res => {
                            return res.sub_driver === item.sub_driver
                        })
                        if (k.length > 0 && k[0].sub_driver !== "Overall Sentiment"){
                            ordered_data.push(...k)
                        }
                    })
                    setOverallSentimentScoreData(ordered_data)
                }
            })
        }
    }, [projectId])
    
    const [leftArrowVisible, setLeftArrowVisible] = useState(false);
    const [rightArrowVisible, setRightArrowVisible] = useState(false);

    const root = useRef(null);
    const container = useRef(null);
    const dragRef = useRef(null);
    
    const [dragData] = useState({
        dragStart: null,
        dragEnd: null,
        oldScroll: null,
        dragStartTimestamp: null,
    });

    const [chevronScrollInfo] = useState({
        chevronScrollAction: null,
        scrollAmount: 0,
    });

    // debounced scroll
    const scrollPanel = (direction) => {
        if (chevronScrollInfo.chevronScrollAction) {
            clearTimeout(chevronScrollInfo.chevronScrollAction);
        }
        const sgn = direction === "left" ? -1 : 1;
        chevronScrollInfo.scrollAmount =
            2 * chevronScrollInfo.scrollAmount + root.current.clientWidth * 0.3 * sgn;
        chevronScrollInfo.chevronScrollAction = setTimeout(() => {
            root.current.scrollBy({
                left: chevronScrollInfo.scrollAmount,
                behavior: "smooth",
            });
            Object.assign(chevronScrollInfo, {
                chevronScrollAction: null,
                scrollAmount: 0,
            });
        }, 200);
    };
    const updateArrowsVisibility = () => {
        setLeftArrowVisible(root.current.scrollLeft > 0);
        setRightArrowVisible(
            container.current.clientWidth - root.current.scrollLeft >
            root.current.clientWidth
        );
    };

    const handleFilterSave = (data) => {
        if(data){
            const client = ApiClient(setOverallSentimentScoreData)
            client.post('/api/v1/overall-sentiment-score/' + projectId + '/', data = {
                data: data,
            })
            .then((response) => {
                if (response?.data) {
                    let subdrivers = response.data?.filter_data?.sub_driver
                    setSubdriverFilterData([...subdrivers.filter(item => item.sub_driver !== "Overall Sentiment"), ...subdrivers.filter(item => item.sub_driver === "Overall Sentiment")])
                    let ordered_data = response.data?.response_data?.filter(item => item.sub_driver === "Overall Sentiment")
                    subdrivers.sort((a,b) => a.order-b.order).forEach(item => {
                        let k = response.data?.response_data?.filter(res => {
                            return res.sub_driver === item.sub_driver
                        })
                        if (k.length > 0  && k[0].sub_driver !== "Overall Sentiment"){
                            ordered_data.push(...k)
                        }
                    })
                    setOverallSentimentScoreData(ordered_data)

                }
            })
        }
    }

    return <div className='sentiment-container'>
        {
            isProjectAdmin && <div className='sentiment-panel-filter'>
                {subdriverFilterData &&
                    <SubdriverPopover subdriverData={subdriverFilterData} handleSave={handleFilterSave} disabledList={['Overall Sentiment']} />
                }
            </div>
        }
            <div className='sentiment-list'>
                
            {/* <div
                className={`sentiment-panel-arrow arrowleft ${leftArrowVisible ? 'visible' : null}`}
                onClick={() => scrollPanel("left")}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
            <div
                className={`sentiment-panel-arrow arrowright ${rightArrowVisible ? 'visible' : null}`}
                onClick={() => scrollPanel("right")}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
            <div
                ref={root}
                draggable
                className="subdriver-panel"
                onScroll={updateArrowsVisibility}
                onDragStart={(e) => {
                    e.dataTransfer.setDragImage(dragRef.current, 0, 0);
                    dragData.dragStart = e.pageX;
                    dragData.dragStartTimestamp = Date.now();
                    dragData.oldScroll = root.current.scrollLeft;
                }}
                onDragOver={(e) => {
                    const dragEnd = e.pageX;
                    const distance = dragEnd - dragData.dragStart;
                    root.current.scrollLeft = dragData.oldScroll - distance;
                }}
                onDragEnd={(e) => {
                    const dragEnd = e.pageX;
                    const distance = dragEnd - dragData.dragStart;
                    const dragEndTimestamp = Date.now();
                    const timeDelta = dragEndTimestamp - dragData.dragStartTimestamp;
                    const factor = timeDelta > 800 ? 1 : Math.min(2000 / timeDelta, 10);
                    const finalScroll = dragData.oldScroll - distance * factor;
                    root.current.scrollTo({
                        left: finalScroll,
                        behavior: "smooth",
                    });
                }}
            >
                <div
                    ref={dragRef}
                    style={{ position: "fixed", width: "1px", height: "1px" }}
                />
                <div ref={container} className="subdriver-main-container"> */}
                    {Object.keys(overallSentimentScoreData).length > 0 &&
                        overallSentimentScoreData.map((value, idx) => {
                            if(value.sub_driver==='Overall Sentiment') {
                                return <div className='subdriver-container'>
                                    <Emoji satisfaction={value.score}></Emoji>
                                    <span className='sub-driver-text'>{value.sub_driver}</span>
                                </div>
                            }else{
                                return <div className='subdriver-container'>
                                    <CircularProgressWithLabel value={(value.score / 10).toFixed(1)} />
                                    <span className='sub-driver-text'>{value.sub_driver}</span>
                                </div>
                    }
                })
            }
            {/* </div>
            </div> */}
        </div>
    </div>
}

export default SentimentBar