import React, { useRef, useState, useEffect } from "react";
import './styles.scss'
import { Slider, styled } from "@mui/material";



const fillstart = 'rgb(230, 104, 100)'
const fillmid = 'rgb(83, 167, 245)'
const fillend = 'rgb(0, 183, 162)'


const SliderComponent = styled(Slider)(({ }) => ({
	color: '#d9dada',
	height: 8,
	padding: '13px 0 !important',
	'& .MuiSlider-rail': {
		opacity: 1,
		background: `linear-gradient(90deg, ${fillstart}, ${fillmid}, ${fillend})`,
		zIndex: 1
	},
	'& .MuiSlider-track': {
		border: 'none',
		background: 'transparent',
		zIndex: 5
	},
	'& .MuiSlider-thumb': {
		height: 18,
		width: 18,
		backgroundColor: '#fff',
		borderRadius: '50%',
		borderColor: 'rgb(71, 169, 233)',
		border: '5px solid #000',
		zIndex: 10,
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: '50% 50% 50% 0',
		backgroundColor: '#52af77',
		transformOrigin: 'bottom left',
		transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
})
)

const SliderInput = ({ percent, onChange }) => {

	const coverRef = useRef()
	const sliderRef = useRef()

	const handleCoverClick = (event) => {
		let elem = coverRef.current.getBoundingClientRect()
		let sliderWidth = sliderRef.current.getBoundingClientRect().width

		let k = Math.round(((event.clientX - elem.left) * 100 / sliderWidth))

		onChange(percent + k)
	}

	return <div style={{ zIndex: 0, position: 'relative' }}>
		<SliderComponent
			ref={sliderRef}
			className="slider-question-input"
			defaultValue={1}
			value={percent}
			valueLabelDisplay="off"
			onChange={(e) => onChange(e.target.value)}
			min={0}
			max={100}
		/>
		<span
			ref={coverRef}
			onClick={handleCoverClick}
			style={
				{
					position: 'absolute',
					top: '13px',
					right: 0,
					height: '8px',
					backgroundColor: '#D9DADA',
					borderRadius: '12px',
					zIndex: 7,
					width: `calc(100% - ${percent}%)`,
					cursor: 'pointer'
				}
			}
		/>
	</div>
}


export default SliderInput;
