const CURRENT_PROJECT = 'CURRENT_PROJECT'
const IS_EDIT = 'IS_EDIT'
const IS_DRAFT = 'IS_DRAFT'
const ADMIN_MENU_ITEMS = 'ADMIN_MENU_ITEMS'
const PROJECT_USER_ID = 'PROJECT_USER_ID'
const CURRENT_PROJECT_NAME = 'CURRENT_PROJECT_NAME'
const RESET_PROJECT_STATE = 'RESET_PROJECT_STATE'
const IS_PROJECT_ADMIN = 'IS_PROJECT_ADMIN'

export {
    CURRENT_PROJECT,
    IS_EDIT,
    IS_DRAFT,
    ADMIN_MENU_ITEMS,
    PROJECT_USER_ID,
    CURRENT_PROJECT_NAME,
    RESET_PROJECT_STATE,
    IS_PROJECT_ADMIN
}