import "./index.scss"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import SelectDropDown from '../../../Elements/SelectDropDown'
import { ApiClient } from '../../../api/client'


const barColors = [
    'rgba(0, 189, 174, 1)',
    'rgba(64, 64, 65, 1)',
    'rgba(53, 124, 210, 1)',
    'rgba(229, 101, 144, 1)',
    'rgba(248, 184, 131, 1)',
    'rgba(112, 173, 71, 1)',
    'rgba(221, 138, 189, 1)',
    'rgba(127, 132, 232, 1)',
    'rgba(123, 180, 235, 1)',
    'rgba(234, 122, 87, 1)',

];

const borderColors = [
    'rgba(0, 189, 174, 0.2)',
    'rgba(64, 64, 65, 0.2)',
    'rgba(53, 124, 210, 0.2)',
    'rgba(229, 101, 144, 0.2)',
    'rgba(248, 184, 131, 0.2)',
    'rgba(112, 173, 71, 0.2)',
    'rgba(221, 138, 189, 0.2)',
    'rgba(127, 132, 232, 0.2)',
    'rgba(123, 180, 235, 0.2)',
    'rgba(234, 122, 87, 0.2)',
]



const FeedbackSummary = ({ segmentTypesList }) => {

    const chartRef = useRef()

    const { projectId } = useSelector(state => state.project)

    const [defaultSegmentType, setDefaultSegmentType] = useState('')
    const [feedbackData, setFeedbackData] = useState([])


    useEffect(() => {
        if (segmentTypesList.length > 0) {
            setDefaultSegmentType(segmentTypesList?.[0]?.['segmentTypeId'])
        }
    }, [segmentTypesList])

    useEffect(() => {
        if (defaultSegmentType) {
            const client = ApiClient()
            client.get(`/api/v1/feedback-summary/${projectId}/?segment_type=${defaultSegmentType}`)
                .then((response) => {
                    if (response?.data) {
                        setFeedbackData(response.data)
                    }
                })
        }
    }, [defaultSegmentType])

    var graphData = {
        labels: [], // X-AXIS labels (Driver Names),
        datasets: []
    }

    if (feedbackData?.answers_data?.length > 0) {
        for (let dat of feedbackData?.answers_data) {
            for (let item of dat['driver_scores']) {
                if (!graphData['labels'].includes(item.driver_name)) {
                    graphData['labels'].push(item.driver_name)
                }
            }
        }
    }

    if (feedbackData?.answers_data?.length > 0) {
        for (const [idx, seg] of feedbackData?.answers_data.entries()) {
            if (seg['driver_scores'].length > 0) {
                let obj = {
                    label: seg['segment'],
                    backgroundColor: barColors[idx],
                    data: graphData['labels'].map(driver => {
                        let driver_score = seg['driver_scores'].filter(item => item.driver_name === driver)
                        return driver_score.length > 0 ? driver_score[0].score : 0

                    }),
                    maxBarThickness: 50,
                }
                if (obj['data'].length > 0) {
                    graphData['datasets'].push(obj)
                }
            }
        }
    }

    // BAR CHART OPTIONS
    const options = {
        indexAxis: 'x',
        plugins: {
            barRoundness: 1,
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'bottom',
                font: function (context) {
                    var l = context.chart._metasets?.[0].data?.[0]?.width
                    var size = l > 16 ? 12 : l <= 16 && l > 10 ? l - 3.5 : l - 2
                    return {
                        size: size,
                    };
                },
                color: function (context) {
                    return '#fff' // context.dataset.backgroundColor;
                },
            },
            title: {
                display: false,
            },
            htmlLegend: {
                // ID of the container to put the legend in
                containerID: 'bar-legend',
            },
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rect'
                }
            },
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.5,
        // interaction: {
        //     // mode: "index",
        //     intersect: false
        // },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: '10px',
                    }
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
                min: 0,
                max:10,
                /*
                max: graphData?.datasets?.length ? Math.max(...graphData?.['datasets']?.map(item => {
                    var nums = item?.['data'].filter(Number)
                    return Math.max(...nums)
                }
                )) + 0.5 : 10*/
            }
        }
    };

    const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.classList.add('legend-list-container')
            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            const ul = getOrCreateLegendList(chart, options.containerID);

            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            items.forEach((item, idx) => {
                const li = document.createElement('li');
                li.classList.add('legend-list-item');

                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };

                // Colored Bar Icon
                const boxIcon = document.createElement('i');
                boxIcon.classList.add('fa-solid')
                boxIcon.classList.add('fa-chart-simple')
                boxIcon.style.color = item.fillStyle;

                // Text
                const text = document.createTextNode(item.text);

                const textContainer = document.createElement('span');
                textContainer.classList.add('legend-text')
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                textContainer.appendChild(text);

                li.appendChild(boxIcon);
                li.appendChild(textContainer);
                ul.appendChild(li);
            })
        }
    };
    

    return <div className='feedback-summary-content'>
        <div className='feedback-summary-header'>
            <span className='title'>Feedback Summary</span>
            <SelectDropDown
                optionList={segmentTypesList}
                handleOnChange={setDefaultSegmentType}
                valueField='segmentTypeId'
                itemField='segmentTypeName'
                defaultValue={defaultSegmentType}
            />
        </div>
        <div>
            <Bar
                height={'500px'}
                type="bar"
                options={options}
                data={graphData}
                ref={chartRef}
                plugins={[ChartDataLabels, htmlLegendPlugin]}
            />
            <div id='bar-legend' className='whatever you wanna add'></div>
        </div>
    </div>
}

export default FeedbackSummary;