import React from "react";
import comingsoon from '../../assets/images/comingsoon.png'
import { NavLink } from "react-router-dom";
import './index.scss';
import { useNavigate } from 'react-router-dom';
const Coming_Soon = () => {
    const navigate = useNavigate()
  return <div className='com_main'>
      <div className="com_content-wrapper">
        <img src={comingsoon} alt="comming soon"></img>
        <h1 className="com_title">Coming Soon</h1>
        <h2 className="com_sub-title">STAY TUNED!</h2>
        <div className="com_content">We are currently working on a super exciting feature
      <br />
      <div  className="com_link" onClick={()=>{navigate('/')}}>
        Go to Homepage
      </div>
      </div>
      </div>
    </div>
  
}

export default Coming_Soon;
