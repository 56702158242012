
import './index.scss';
import { BaseButton } from "../../Elements/Button";
import main_1 from '../../assets/images/main_1.png';
import main_2 from '../../assets/images/main_2.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Tour_Wizard from '../../components/enduser_tourWizard';
import { useEffect, useRef, useState } from 'react';


const UsersWelcome = () => {
	const divRef = useRef()

    const [offsetTop, setOffsetTop] = useState()

    useEffect(() => {

        setOffsetTop(divRef.current.getBoundingClientRect().top)

    }, [])
	
	function createCookie(value) {
        var date = new Date();
        date.setTime(date.getTime() + (31 * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
        document.cookie = value + "=" + 'tourguide2' + expires + "; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }


  const navigate = useNavigate()
  const [tourGuide, setTourGuide] = useState(false)
const [tourSwitch,settourSwitch]=useState(false)
const { projectId, projectUserId, currentProjectName } = useSelector(state => state.project)
const { useremail, userId } = useSelector(state => state.user)

useEffect(() => {
		if (readCookie(userId+'tour2')=='tourguide2') {
			createCookie(userId+'tour2')
		}
		else {
			if (!tourSwitch) {
				setTourGuide(true)
			}
			settourSwitch(!tourSwitch)
			createCookie(userId+'tour2')
		}

	

}, [])

  
  const handleContinue = () => {
    if (projectId === null || projectId === undefined || projectId === '') {
      if (!tourSwitch) {
        setTourGuide(true)
      }
      
  
          settourSwitch(!tourSwitch)
  }
  else
  {
    navigate('/app/about-me') 
  }
  }
	
	

	return <div ref={divRef} className="welcome"  style={{ minHeight: `calc(100vh - ${offsetTop}px)` }}>

			<div className="welcome__content" style={{ paddingBottom: '20px' }} >

				<h1 className="welcome__content--title">
					Pulse is more than a survey.
				</h1>

				<p className="welcome__content--description">
					This platform has been taught many of the important concepts
					involved in this project. This questionnaire is not designed to
					point fingers or find people to blame - but only to vimprove how we
					communicate and collaborate. ​Your responses will be anonymised and
					combined with others to guide managers on new focus areas to improve
					team/stakeholder engagement and project performance.
				</p>

				<div className="welcome__content__about">
					<div className="welcome__content__about__section">
						<img
							className="welcome__content__about__section--img"
							src={main_1}
							alt=""
						/>
						<h2 className="welcome__content__about__section--title">
							About Me
						</h2>
						<p className="welcome__content__about__section--description">
							What you think / feel about the project.
						</p>

					</div>
					<div className="welcome__content__about__section">
						<img
							className="welcome__content__about__section--img"
							src={main_2}
							alt=""
						/>
						<h2 className="welcome__content__about__section--title">
							About Others
						</h2>
						<p className="welcome__content__about__section--description">
							What you assume other people are thinking​ or feeling about the
							project.
						</p>

					</div>
				</div>
				<div className='continue-btn'>
					<BaseButton
							className='welcome__content__about__section--continue edge-btn green'
							onClick={handleContinue}
							text={'Continue'}
						/>
						{tourGuide && <Tour_Wizard setTourGuide={setTourGuide} tourGuide={tourGuide} settourSwitch={settourSwitch} tourSwitch={tourSwitch} />}
					
					</div>
			</div>

		</div>
	

}

export default UsersWelcome;