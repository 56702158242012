import { BaseButton } from '../../Elements/Button'
import './index.scss'
import CloseIcon from '@mui/icons-material/Close';

const TourToolTip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    skipProps,

  
}) => {
  
    return <div className="tour-tooltip" {...tooltipProps}>

          
        {
            step.content && <div className='tooltip-content'>
                <span className='step-num'>{index + 1}</span>
                <span className='step-content'>{step.content}</span>
               
                
                {/* {index > 0 && (
                    <button {...backProps}>
                        back
                    </button>
                )} */}
                {/* {continuous && (
                    <button {...primaryProps}>
                        next
                    </button>
                )} */}
                 <BaseButton
                className="tooltip-next-btn"
                {...skipProps}
                text="Skip"
            />
                 
                <BaseButton
                className="tooltip-next-btn"
                {...primaryProps}
                text={continuous ? "Next" : "Close"}
            />
           

            </div>
        }



    </div>

}

export default TourToolTip