import { BaseButton } from '../../Elements/Button'
import './index.scss'
import CloseIcon from '@mui/icons-material/Close';

const TourToolTip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    skipProps,

  
}) => {
  
    return <div className='body' {...tooltipProps}>
      <center>
      <div className='guide__icon'>
        <img
          src={step.img}
          className='guide__icon__image'
          alt=""
        />
      </div>
    {step.title &&  <h3 className='title'>{step.title}</h3>}
    {step.content && <div className='content'>{step.content}</div>}
    <div className='footer1'>
      {!step.isLastStep && (
        <input
          type="button"
          className='button off'
          {...skipProps}
          value="Turn Off"
          
        />
      )}
      <input
        type="button"
        className='button'
        {...primaryProps}
        value={continuous ? "Next" : "Close"}
      />
    </div>
    </center>
  </div>
}

export default TourToolTip