import axios from "axios";


export const baseUrl = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 10000
})

export default axiosInstance