import { useEffect, useState } from 'react'
import './index.scss'


const LabeledCheckbox = ({ index, item, label, isChecked, handleData }) => {

    const [checked, setChecked] = useState(true)

    const handleChange = (event) => {
        setChecked(event.target.checked)
        handleData(index, event.target.checked)
    }

    useEffect(() => {
        setChecked(isChecked)
    }, [isChecked])

    return <div className="labeled-checkbox-div">
        <input type='checkbox' id={label} className="checkbox-input" onChange={handleChange}  checked={checked} />
        <label className="checkbox-label" htmlFor={label}>{label}</label>
    </div>
}


export default LabeledCheckbox;