

import { useState } from 'react';
import { tabledatacolor } from '../../../../utilities';
import './index.scss'
import Loader from '../../../../components/Loader';


const PercentBar = ({ value }) => {
    return (
        <div className="keytakeaway-percent-container">
            <div
                style={
                    {
                        width: `${value * 10}%`,
                        backgroundColor: tabledatacolor(value)[0]
                    }
                }
                className="keytakeaway-table-percent-bar"
            ></div>
            <span className='value'>{value}</span>
        </div>
    );
};


const KeyTakeAways = ({ isLoading, keyTakeAwayData, subdriversList }) => {

    const [visibleSegments, setVisibleSegments] = useState([])

    const handleLoadMore = (key) => {

        if (visibleSegments.includes(key)) {
            setVisibleSegments(visibleSegments.filter(item => item !== key))
        }
        else {
            setVisibleSegments(state => [...state, key])
        }
    }
    return <div className='key-takeaways-content'>
        <h2 className='key-takeaway-header'>What are the key takeaways from this analysis?</h2>
        <div className='key-takeaway-box'>
            {
                isLoading ?
                    <Loader />
                    :
                    Object.keys(keyTakeAwayData).length ?
                        subdriversList.map(sd => {

                            let hgs = keyTakeAwayData?.[sd]?.['highest']
                            let lws = keyTakeAwayData?.[sd]?.['lowest']
                            return <div className='subdriver-box' key={sd}>
                                {
                                    hgs && hgs.length > 0 && <div className='segment-box'>
                                        <p className='segment-statement'>
                                            The segments rated <span className='high'><strong>Highest</strong></span> in <strong>{sd}</strong> includes:
                                        </p>
                                        <ul className='segments-score-list'>
                                            {
                                                hgs
                                                    .slice(0, visibleSegments.includes(sd + '-h') ? 5 : 3)
                                                    .map(item => {
                                                        return <li className='segments-score-item' key={item.segment_name}>
                                                            <span className='segment-name'>{item.segment_name}</span>
                                                            <PercentBar value={item.score} />
                                                        </li>
                                                    })
                                            }
                                            {
                                                hgs.length > 3 && <span className='load-more' onClick={() => handleLoadMore(sd + '-h')}>
                                                    {`${visibleSegments.includes(sd + '-h') ? 'Less' : 'Load more...'}`}
                                                </span>
                                            }

                                        </ul>
                                    </div>
                                }
                                {
                                    lws && lws.length > 0 && <div className='segment-box'>
                                        <p className='segment-statement'>
                                            The segments rated <span className='low'><strong>Lowest</strong></span> in <strong>{sd}</strong> includes:
                                        </p>
                                        <ul className='segments-score-list'>
                                            {
                                                lws
                                                    .slice(0, visibleSegments.includes(sd + '-l') ? 5 : 3)
                                                    .map(item => {
                                                        return <li className='segments-score-item' key={item.segment_name}>
                                                            <span className='segment-name'>{item.segment_name}</span>
                                                            <PercentBar value={item.score} />
                                                        </li>
                                                    })
                                            }
                                            {
                                                lws.length > 3 && <span className='load-more' onClick={() => handleLoadMore(sd + '-l')}>
                                                    {`${visibleSegments.includes(sd + '-l') ? 'Less' : 'Load more...'}`}
                                                </span>
                                            }
                                        </ul>
                                    </div>
                                }

                            </div>
                        })

                        :
                        <div>No Data Found</div>
            }

        </div>
    </div>
}


export default KeyTakeAways