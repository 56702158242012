import './index.scss'
import PasswordStrengthBar from 'react-password-strength-bar';
import logo from '../../assets/images/project-ai-logo-light.png'
import LabeledInput from '../../Elements/LabeledInput'
import { Link } from 'react-router-dom'
import { BaseButton } from '../../Elements/Button'
import { Fragment, useState } from 'react'
import isEmail from 'validator/lib/isEmail';
import { EMAIL, FIRST_NAME, LAST_NAME, NameValidator, ORGANISATION, PASSWORD, CNF_PASSWORD, PwdValidator, CnfPwdValidator, EmptyStringValidator } from './utility';
import axiosInstance from '../../api/axios';
import Modal from '../../components/Modal';
import { InfoText } from '../../Elements/MessageBlocks';
import checkmark from '../../assets/images/checkmark.gif';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { scrollToBottom } from '../../utilities';



const formValidator = (form_data) => {
    if (!isEmail(form_data.email)) {
        return {
            field: EMAIL,
            err: 'Invalid Email.'
        }
    }
    let validate_password = PwdValidator(form_data.password)
    if (validate_password) {
        return {
            field: PASSWORD,
            err: validate_password
        }
    }
    let validate_cnf_password = CnfPwdValidator(form_data.password, form_data.confirmPassword)
    if (validate_cnf_password) {
        return {
            field: CNF_PASSWORD,
            err: validate_cnf_password
        }
    }
    let fNameValid = NameValidator(form_data.firstName)
    if (fNameValid) {
        return {
            field: FIRST_NAME,
            err: fNameValid
        }
    }
    let lNameValid = NameValidator(form_data.lastName)
    if (lNameValid) {
        return {
            field: LAST_NAME,
            err: lNameValid
        }
    }
    let orgValid = EmptyStringValidator(form_data.organisation)
    if (orgValid) {
        return {
            field: ORGANISATION,
            err: orgValid
        }
    }
}


const Register = () => {
    const navigate = useNavigate();
    const formInitialState = {
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        organisation: '',
    }
    const [formData, setFormData] = useState(formInitialState)
    const [phoneNumber, setPhone] = useState('')
    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const [showModal, setModalVisibility] = useState(false);
    const [showCircularProgress, setShowCircularProgress] = useState(false);
    const [userEmail, setUserEmail] = useState();
    const { errField, errMsg } = err;
    const { email, password, confirmPassword, firstName, lastName, organisation } = formData;
    const resendActivationEmailName = 'resend-activation-mail';
    const handleInputChange = (e) => {
        setErr({ ...err, ...{ errField: '', errMsg: '' } })
        setFormData(prevState => (
            { ...prevState, [e.target.name]: e.target.value }
        ))
    }
    const changePhone = (newPhone) => {
        setPhone(newPhone)
    }

    const clearFormState = () => {
        setFormData({ ...formInitialState });
    };

    const handleSubmit = (name) => {
        if (name === resendActivationEmailName) {
            setShowCircularProgress(true)
            axiosInstance.post('api/v1/auth/users/resend_activation/', {
                email: userEmail.toLowerCase()
            }).then((res) => {
                setModalVisibility(true)
                setShowCircularProgress(false)

            }).catch((error) => {
                console.log(error.response.data)
                setShowCircularProgress(false)
            })
        } else {
            let error = formValidator(formData)
            if (error) {
                setErr({ ...err, ...{ errField: error['field'], errMsg: error['err'] } })

            }
            else {
                setShowCircularProgress(true)
                axiosInstance.post('api/v1/auth/users/', {
                    email: formData.email.toLowerCase(),
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    organization_name: formData.organisation,
                    password: formData.password,
                    re_password: formData.confirmPassword,
                    phone_number: phoneNumber
                }).then((res) => {
                    setUserEmail(res.data.email.toLowerCase());
                    setModalVisibility(true);
                    clearFormState();
                    setShowCircularProgress(false)
                }).catch((error) => {
                    Object.keys(error.response.data).map((key) => {
                        setErr({ ...err, ...{ errField: key, errMsg: error.response.data[key] } })

                        return true;
                    })
                    setShowCircularProgress(false)
                })
            }
        }
    }

    let inputs_arr = [
        { type: 'email', name: 'email', label: EMAIL, value: email },
        { type: 'password', name: 'password', label: PASSWORD, value: password },
        { type: 'password', name: 'confirmPassword', label: CNF_PASSWORD, value: confirmPassword },
        { type: 'text', name: 'firstName', label: FIRST_NAME, value: firstName },
        { type: 'text', name: 'lastName', label: LAST_NAME, value: lastName },
        { type: 'text', name: 'organisation', label: ORGANISATION, value: organisation },
    ]
    const handleClose=()=>{
        navigate('/login');
        return (setModalVisibility(!showModal))
    }

    return <div className="register-section">
        <div className="left-section" >
            <div className="logo">
                <img src={logo} width={100} height={50} alt="logo" />
            </div>
            <div className='headline'>
                <span>Successful projects start with people</span>
            </div>
            {
                window.innerWidth < 769 && <> <div className='heading-text-sm'>
                    <h1>Collective Insights</h1>
                    <span>
                        Pulse by ProjectAI helps project managers make <br />
                        sense of people’s collective insights for better project outcomes.
                    </span>
                </div>
                    <div className='scroll-to-bottom-icon' onClick={scrollToBottom}>
                        <i className="fa-solid fa-chevron-down"></i>
                    </div>
                </>
            }
        </div>
        <div className='right-section'>
            <div>
                <div className='heading'>
                    <h1>Welcome!</h1>
                    <span>Welcome to <strong>Pulse</strong>, create your free account to continue.</span>
                </div>
                <div className='form-container'>
                    <form>
                        {
                            inputs_arr.map(inp => {
                                return <Fragment key={inp.name}>
                                    <LabeledInput
                                        type={inp.type}
                                        name={inp.name}
                                        label={inp.label}
                                        value={inp.value}
                                        onChange={handleInputChange}
                                        error={(errField === inp.label || errField === inp.label.toLowerCase()) && (errMsg)}

                                    />
                                    {
                                        inp.name === 'password' && inp.value.length > 0 && <PasswordStrengthBar
                                            className='pwd-strength-bar'
                                            minLength={8}
                                            password={inp.value}
                                        />
                                    }
                                    {
                                        inp.em
                                    }
                                </Fragment>
                            })

                        }
                        <LabeledInput
                            name='phone_number'
                            label='Phone Number'
                            value={phoneNumber}
                            onChange={changePhone}
                            isPhone={true}
                            required={false}
                            isOptional={true}
                            error={errField === 'phone_number' && errMsg}
                        />

                        <p>Already have an account? <Link to='/login'> Click here </Link></p>

                        <div style={{ textAlign: 'center' }}>
                            <BaseButton
                                className="submit-btn"
                                text="Create Account"
                                variant="filled"
                                onClick={() => handleSubmit('create-account')}
                                showCircularProgress={showCircularProgress}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        {showModal &&
            <Modal
                handleModal={handleClose}
              
                modal_content={
                    <div style={{ width: '100%', flex: '1' }}>
                        <div style={{ textAlign: 'center',  padding: '1px' }}>
                            <img src={checkmark} style={{ borderRadius: '50%', marginTop: '6px', width: '150px', height: '150px' }}></img>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '15px' }}>
                            <h1>Great!</h1>
                            <InfoText messageText="You have been successfully registered on Pulse!" color='#00000'></InfoText>
                            <InfoText messageText="Check your email to complete the verification process" color='#00000'></InfoText>
                            <BaseButton
                                className="submit-btn"
                                text="Resend Activation Mail"
                                variant="filled"
                                onClick={() => handleSubmit(resendActivationEmailName)}
                                name={resendActivationEmailName}
                                showCircularProgress={showCircularProgress}
                            />
                        </div>
                    </div>
                }
            />
        }
    </div>
}

export default Register