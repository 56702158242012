import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactTags } from 'react-tag-autocomplete';
import './index.scss';

import { ApiClient } from '../../../../api/client';
import { dislikeOff, dislikeOn, likeOff, likeOn } from '../../../../assets/images/report';
import Modal from '../../../../components/Modal';
import tag from './../../../../assets/svgs/report/tag.svg';
import { BaseButton } from '../../../../Elements/Button';
import { capitalizeWords } from '../../../../utilities';


const PercentBar = ({ value }) => {
    return (
        <div className="keythemes-table-percent-container">
            <div
                style={{ width: `${value}%` }}
                className="keythemes-table-percent-bar"
            ></div>
        </div>
    );
};


const TagTable = ({ tags_data, getSubdriverResponse }) => {

    const client = ApiClient()
    const reactTagsRef = useRef()
    const { projectId, projectUserId, isProjectAdmin } = useSelector(state => state.project)
    const [totalFreq, setTotalFreq] = useState(0)
    const [tagsVisible, setTagsVisibility] = useState(false)
    const [activeTagData, setActiveTagData] = useState()

    const [tagSuggestions, setTagsSuggestion] = useState([])

    useEffect(() => {
        client
            .get(`/api/v1/keytags?project_id=${projectId}`)
            .then(res => {
                if (res.data) {
                    let suggestions = res.data.map(item => { return { value: item, label: item } })
                    setTagsSuggestion(suggestions)
                }
            })
    }, [tags_data])

    useEffect(() => {
        let total_freq = 0
        for (let tag of tags_data) {
            total_freq = total_freq + tag.freq
        }
        setTotalFreq(total_freq)
    }, [tags_data])


    const handleUpvoteDownvote = (key, id, voteValue, oldVoteValue) => {
        let data = {
            "tag": key,
            "project": projectId,
            "projectUser": projectUserId,
            "voteValue": voteValue === oldVoteValue ? 0 : voteValue
        }
        if (id === null) {
            client
                .post(`/api/v1/key-theme-vote/`, { data })
                .then(res => getSubdriverResponse(false))
        }
        else {
            client
                .put(`/api/v1/key-theme-vote/${id}`, { data })
                .then(res => getSubdriverResponse(false))
        }

    }

    const onDelete = useCallback((tagIndex, idx) => {
        let temp = [...activeTagData]

        temp = temp.map(d => {
            if (d.topic_name === temp[idx].topic_name) {
                return {
                    ...d,
                    tags: d.tags.filter((_, i) => i !== tagIndex)
                }
            } else {
                return d
            }
        })
        setActiveTagData(temp)
    }, [activeTagData])

    const onAddition = useCallback((newTag, idx) => {
        let temp = [...activeTagData]
        newTag['label'] = capitalizeWords(newTag['label'])
        temp = temp.map(d => {
            if (d.topic_name === temp[idx].topic_name) {
                return {
                    ...d,
                    tags: d.tags === null || d.tags === undefined ? [newTag] : [...d.tags, newTag]
                }
            } else {
                return d
            }
        })
        setActiveTagData(temp)
    }, [activeTagData])


    const handleSaveTags = () => {

        client
            .post(`/api/v1/keytags`, {
                data: activeTagData
            })
            .then(res => {
                getSubdriverResponse()
                setTagsVisibility(false)
                setActiveTagData(null)
            })
    }

    return <div className="key-themes-tag-table-container">
        <table className="tag-table">
            <thead>
                <tr className="tag-table-head-row">
                    <th className="head-item frequency">Frequency</th>
                    <th className="head-item topic-name" >Theme</th>
                    <th className="head-item vote">Like(upvote)</th>
                    <th className="head-item vote">Dislike(downvote)</th>
                    {
                        isProjectAdmin && <th className="head-item tag">Tags</th>
                    }
                </tr>
            </thead>
            <tbody>
                {
                    tags_data
                        .map((item, idx) => {
                            const voteValue = item.myStatus && item.myStatus.length > 0 ? item.myStatus[0].voteValue : 0;
                            const id = item.myStatus && item.myStatus.length > 0 ? item.myStatus[0].id : null;
                            return <tr className="tag-table-row" key={'tags-' + idx}>
                                <td className="row-item frequency">
                                    <div>
                                        {(item.freq / totalFreq * 100).toFixed()}%
                                    </div>
                                    <PercentBar
                                        value={(item.freq / totalFreq * 100).toFixed()}
                                    />
                                </td>
                                <td className="row-item topic-name">{item.key}</td>
                                <td className="row-item vote">
                                    <span className='vote-upvote' onClick={() => handleUpvoteDownvote(item.key, id, 1, voteValue)}>
                                        <img src={voteValue === 1 ? likeOn : likeOff} />
                                        {item.upvoteCnt}
                                    </span>
                                </td>
                                <td className="row-item vote">
                                    <span className='vote-downvote' onClick={() => handleUpvoteDownvote(item.key, id, -1, voteValue)}>
                                        <img src={voteValue === -1 ? dislikeOn : dislikeOff} />
                                        {item.downvoteCnt}
                                    </span>
                                </td>
                                {
                                    isProjectAdmin &&
                                    <td className="row-item tag">
                                        <TagSpan
                                            tags={item.tags}
                                            onClick={() => {
                                                setTagsVisibility(true)
                                                setActiveTagData(item.tags)
                                            }}
                                        />
                                    </td>
                                }
                            </tr>
                        })
                }

            </tbody>
        </table>
        {
            tagsVisible && <Modal
                modal_content={
                    <div className='tag-popup-content'>

                        <div className='tag-header'>
                            {
                                activeTagData.length > 0 ?
                                    <>
                                        <h5 className='tag-name-title'>Response</h5>
                                        <h5 className='tag-tags-title'>Tags</h5>
                                    </>
                                    :
                                    <h5 className='no-tags'>No Tags Found</h5>

                            }
                        </div>

                        <div className='tags-list-box'>
                            {
                                activeTagData.map((tag, idx) =>
                                    <div className='tag-list-row' key={tag.topic_name + '-' + idx}>
                                        <span className='tag-name'>
                                            {tag.topic_name}
                                        </span>
                                        <span className='tag-tags'>
                                            <ReactTags
                                                ref={reactTagsRef}
                                                labelText=''
                                                selected={(tag.tags || [])}
                                                suggestions={tagSuggestions}
                                                onDelete={(tagIndex) => onDelete(tagIndex, idx)}
                                                onAdd={(newTag) => onAddition(newTag, idx)}
                                                allowNew={true}
                                                deleteButtonText='Click To Remove Tag'
                                                collapseOnSelect
                                            />
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                        <div className='tag-popup-action-btn'>
                            <BaseButton
                                text="Cancel"
                                className='add-tag-cancel-btn modal-cancel-btn'
                                onClick={() => {
                                    setTagsVisibility(false)
                                    setActiveTagData(null)
                                }}
                            />
                            {
                                activeTagData.length > 0 && <BaseButton
                                    variant='contained'
                                    text={"Save"}
                                    className='add-tag-submit-btn modal-submit-btn'
                                    onClick={handleSaveTags}
                                />
                            }

                        </div>
                    </div>
                }
                handleModal={() => setTagsVisibility(false)}
                modalClassName="tags-modal-container"

            />
        }

    </div>
}


export default TagTable;


const TagSpan = ({ tags, onClick }) => {
    let hasTags = false
    for (let item of tags) {
        if (item?.tags?.length > 0) {
            hasTags = true
            break;
        }
    }
    return <span
        className={`tag-span ${hasTags ? 'has-tags' : ''}`}
        onClick={onClick}
    >
        {
            hasTags ?
                <i className="fa-solid fa-tags"></i>
                :
                <img src={tag} alt="" />
        }
    </span>
}