import './index.scss'
import PasswordStrengthBar from 'react-password-strength-bar';
import LabeledInput from '../../Elements/LabeledInput'
import { useNavigate } from 'react-router-dom'
import { BaseButton } from '../../Elements/Button'
import { Fragment, useEffect, useState } from 'react'
import isEmail from 'validator/lib/isEmail';
import checkmark from '../../assets/images/checkmark.gif'
import { AlphaNumericValidator, EMAIL, FIRST_NAME, LAST_NAME, NameValidator, ORGANISATION } from './utility';
import Layout from '../../components/Layout';
import { ApiClient } from '../../api/client';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { InfoText } from '../../Elements/MessageBlocks';
import { PulseSwitch } from '../../Elements/Switch';
import Modal from '../../components/Modal';
import mail from '../../assets/svgs/mail.svg';
import OtpInput from 'react-otp-input';
import { Button } from '@mui/material';
import ConfirmationPopupModal from '../../components/ConfirmationPopup';


const formValidator = (form_data) => {
    if (!isEmail(form_data.email)) {
        return {
            field: EMAIL,
            err: 'Invalid Email.'
        }
    }
    let fNameValid = NameValidator(form_data.first_name)
    if (fNameValid) {
        return {
            field: FIRST_NAME,
            err: fNameValid
        }
    }
    let lNameValid = NameValidator(form_data.last_name)
    if (lNameValid) {
        return {
            field: LAST_NAME,
            err: lNameValid
        }
    }
    // if (form_data.organization_name.length) {
    //     let orgValid = AlphaNumericValidator(form_data.organization_name)
    //     if (orgValid) {
    //         return {
    //             field: ORGANISATION,
    //             err: orgValid
    //         }
    //     }
    // }
}


const UserProfile = () => {
    const navigate = useNavigate();

    const formInitialState = {
        email: '',
        first_name: '',
        last_name: '',
        organization_name: '',
    };
    const [formData, setFormData] = useState(formInitialState);
    const [phoneNumber, setPhone] = useState('');
    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    });
    const { errField, errMsg } = err;
    const { email, first_name, last_name, organization_name } = formData;
    const [userData, setUserData] = useState();
    const [mfaChecked, setMfaChecked] = useState(false);
    const [isMfaGettingEnabled, setIsMfaGettingEnabled] = useState(true);
    const [mfaActivationCode, setMfaActivationCode] = useState('');
    const [showModal, setModalVisibility] = useState(false);
    const [showCircularProgress, setShowCircularProgress] = useState(false);
    const [showSuccessModal, setSuccessShowModal] = useState(false);
    const [showDisableMFAPopup, setShowDisableMFAPopup] = useState(false);
    
    const handleInputChange = (e) => {
        setErr({ ...err, ...{ errField: '', errMsg: '' } })
        setFormData(prevState => (
            { ...prevState, [e.target.name]: e.target.value }
        ))
    }
    const handleClose = () => {
        navigate(-1)
        return setSuccessShowModal
    }
    const changePhone = (newPhone) => {
        setPhone(newPhone)
    }

    const handleSubmit = (e) => {
        let error = formValidator(formData)
        if (error) {
            setErr({ ...err, ...{ errField: error['field'], errMsg: error['err'] } })
        }
        else {
            const client = ApiClient(setUserData)
            client.patch('api/v1/auth/users/me/', {
                first_name: formData.first_name,
                last_name: formData.last_name,
                organization_name: formData.organization_name,
                phone_number: phoneNumber
            }).then((response) => {
                setUserData(response)
                setSuccessShowModal(true)
            }).catch((error) => {
                Object.keys(error.response.data).map((key) => {
                    setErr({ ...err, ...{ errField: key, errMsg: error.response.data[key] } })
                    return true;
                })
            })
        }
    }

    const handleMFAChange = (e) => {
        setShowDisableMFAPopup(false)
        const client = ApiClient(setModalVisibility)
        if (e.target.checked) {
            client.post('api/v1/auth/email/activate/', {
                email: '4lcviwuae3p7n7v4@gmail.com', // Redundant email (Do not remove)
            }).then((response) => {
                setIsMfaGettingEnabled(true)
                setModalVisibility(true)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            // client.post('api/v1/auth/code/request/', {
            //     email: '4lcviwuae3p7n7v4@gmail.com', // Redundant email (Do not remove)
            // }).then((response) => {
            //     setIsMfaGettingEnabled(false)
            //     setModalVisibility(true)
            // }).catch((error) => {
            //     console.log(error)
            // })
            setShowDisableMFAPopup(true)
        }
    }

    const handleMfaSubmit = (e) => {
        setShowCircularProgress(true)
        if (mfaActivationCode?.length === 6) {
            const client = ApiClient(setModalVisibility)
            const URL = 'api/v1/auth/email/activate/confirm/'
            setErr({ ...err, ...{ errField: 'mfa_activation_code', errMsg: '' } })
            client.post(URL, {
                code: mfaActivationCode
            }).then((response) => {
                setMfaActivationCode('')
                setModalVisibility(false)
            }).catch((error) => {
                setErr({ ...err, ...{ errField: 'mfa_activation_code', errMsg: error.response.data.code[0] } })
            }).finally((data) => {
                setMfaChecked(!mfaChecked)
                setShowCircularProgress(false)
            })
        } else {
            setShowCircularProgress(false)
            setErr({ ...err, ...{ errField: 'mfa_activation_code', errMsg: 'Verification code should be 6 digits long' } })
        }
    }

    const handleMFADisable = () => {
        setShowCircularProgress(true)
        const client = ApiClient()
        const DEACTIVATION_URL = 'api/v1/deactivate-mfa/'
        client.post(DEACTIVATION_URL).then((response) => {
            setShowCircularProgress(false)
            setMfaChecked(false)
            setShowDisableMFAPopup(false)
        }).catch((error) => {

        }).finally((data) => {
            console.log(data)
        })
    }

    useEffect(() => {
        const client = ApiClient(setUserData)
        client.get('/api/v1/auth/users/me/').then((response) => {
            setUserData(response)
        }).catch((error) => {

        })
        client.get('/api/v1/auth/mfa/user-active-methods/').then((response) => {
            if(response){
                if(response[0]?.name === 'email' && response[0]?.is_primary === true){
                    setMfaChecked(true)
                }
            }
        })
    }, [])

    useEffect(() => {
        setFormData(prevState => (
            { ...prevState, ...userData }
        ))
        changePhone(userData?.phone_number)
    }, [userData])

    useEffect(() => {
        if(!showModal){
            setMfaActivationCode('')
            setErr({ ...err, ...{ errField: 'mfa_activation_code', errMsg: '' } })
        }
    }, [showModal])


    const resendMFACode = () => {
        const client = ApiClient(setModalVisibility)
        client.post('api/v1/auth/email/activate/', {
            email: '4lcviwuae3p7n7v4@gmail.com', // Redundant email (Do not remove)
        }).then((response) => {
            setIsMfaGettingEnabled(true)
            setModalVisibility(true)
        }).catch((error) => {
            console.log(error)
        })
    }

    let inputs_arr = [
        { type: 'email', name: 'email', label: EMAIL, value: email, isDisabled: true },
        { type: 'text', name: 'first_name', label: FIRST_NAME, value: first_name, isDisabled: false },
        { type: 'text', name: 'last_name', label: LAST_NAME, value: last_name, isDisabled: false },
        { type: 'text', name: 'organization_name', label: ORGANISATION, value: organization_name, isDisabled: false },
    ]

    return <Layout 
    onClickBack={() => {  }} 
    topBarTitle='My Account' 
    showSideBar={false} 
    isNextShown={false}
    isHelpShown={false}
    isBackShown={false}
    >
        <div className="profile-section">
            <div>
                <h1>Personal Information</h1>
            </div>
            <div className='form-container'>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <form>
                            {
                                inputs_arr.map(inp => {
                                    return <Fragment key={inp.name}>
                                        <LabeledInput
                                            type={inp.type}
                                            name={inp.name}
                                            label={inp.label}
                                            value={inp.value}
                                            isDisabled={inp.isDisabled}
                                            onChange={handleInputChange}
                                            error={errField === inp.label && errMsg}
                                            required={inp.name !== 'organization_name'}
                                            isOptional={inp.name === 'organization_name'}
                                        />
                                        {
                                            inp.name === 'password' && inp.value.length > 0 && <PasswordStrengthBar
                                                className='pwd-strength-bar'
                                                minLength={8}
                                                password={inp.value}
                                            />
                                        }
                                    </Fragment>
                                })

                            }
                            <LabeledInput
                                name='phone_number'
                                label='Phone Number'
                                value={phoneNumber}
                                onChange={changePhone}
                                isPhone={true}
                                required={false}
                                isOptional={true}
                                error={errField === 'phone_number' && errMsg}
                            />
                            <div className='mfa-container'>
                                <br></br>
                                <InfoText messageText={"Enable Multi-Factor Authentication (Email)"} variant='p' component='p' color=''></InfoText>
                                <PulseSwitch
                                    checked={mfaChecked}
                                    onChange={handleMFAChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success"
                                />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                            <BaseButton
                                    className="profile-back-btn"
                                    text="Back"
                                    variant="contained"
                                    onClick={() => navigate(-1)}
                                />
                                <BaseButton
                                    className="profile-submit-btn"
                                    text="Save"
                                    variant="filled"
                                    onClick={handleSubmit}
                                />
                                {showSuccessModal &&
                                    <Modal
                                        handleModal={handleClose}
                                        modal_content={
                                            <div style={{ width: '100%', flex: '1' }}>
                                                <div style={{ textAlign: 'center', marginTop: '16px', padding: '1px' }}>
                                                <img src={checkmark} style={{ borderRadius: '50%', marginTop: '6px', width: '150px', height: '150px' }}></img>
                                                </div>
                                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                    <h1>Success!</h1>

                                                    <InfoText messageText="Your changes have been successfully saved!" color='#00000'></InfoText>

                                                </div>
                                            </div>
                                        }
                                    />
                                }
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
            <div>
                {showModal &&
                    <Modal
                        modalClassName={"profile-modal-wrapper"}
                        handleModal={() => setModalVisibility(!showModal)}

                        modal_content={
                            <div style={{ width: '100%', flex: '1' }}>
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <img src={mail} alt="mail" />
                                    <InfoText messageText="Two Factor Authentication (Email)" color='#00000' variant='h6' component='h6'></InfoText>
                                    <InfoText messageText="A verification code has been sent to your email. This code will be valid for 15 minutes" color='' variant='subtitle1'></InfoText>
                                    <OtpInput
                                        value={mfaActivationCode}
                                        onChange={setMfaActivationCode}
                                        numInputs={6}
                                        renderSeparator={<span>-</span>}
                                        inputStyle="otp-input-div"
                                        renderInput={(props) => <input {...props} />}
                                        shouldAutoFocus={true}
                                        containerStyle={{'justifyContent' : 'center', 'marginTop' : '1rem', 'marginBottom' : '1rem'}}
                                        />
                                    <InfoText messageText={errField === 'mfa_activation_code' && errMsg} color='red' variant='subtitle1'></InfoText>
                                    <Button href="#text-buttons"  onClick={resendMFACode}>Resend Code</Button>
                                    <div className='modal-action-button-section'>
                                        <BaseButton
                                            className="modal-submit-btn"
                                            text="Verify"
                                            variant="filled"
                                            onClick={handleMfaSubmit}
                                            showCircularProgress={showCircularProgress}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    />
                }
                {showDisableMFAPopup && <ConfirmationPopupModal
                        modalClassName="project-delete-confirmation-wrapper"
                        text={
                            <>
                                <p>Are you sure you want to disable 2FA?</p>
                            </>
                        }
                        handleCancel={() => setShowDisableMFAPopup(false)}
                        handleSubmit={handleMFADisable}
                        deleteText='Disable'
                    />
                }
            </div>
        </div>
    </Layout>
}

export default UserProfile