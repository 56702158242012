import { BaseButton } from "../../../Elements/Button";
import LabeledInput from "../../../Elements/LabeledInput";
import { capitalizeWords } from "../../../utilities";
import Modal from "../../Modal";



const EditTopicModalDialog = ({
    topic_name,
    topic_comment,
    topicPlaceholder,
    commentPlaceholder,
    updateTopic,
    onSave,
    onCancel,
    error
}) => {
    return <Modal
        modalClassName="edit-topic-dialog"
        handleModal={onCancel}
        modal_content={
            <div className="edit-topic-form">
                <div><h3>Edit Topic</h3></div>
                <LabeledInput
                    type="text"
                    name="topicName"
                    label="Topic Name"
                    value={topic_name}
                    onChange={(e) => updateTopic(state => ({ ...state, updatedName: capitalizeWords(e.target.value) }))}
                    error={error.errField === 'updatedTopicName' && error.errMsg}
                    placeholder={topicPlaceholder === "" ? "Specify your topic" : topicPlaceholder}
                />
                <LabeledInput
                    textarea
                    type="text"
                    name="topicComment"
                    label="Topic Comment"
                    value={topic_comment}
                    onChange={(e) => updateTopic(state => ({ ...state, updatedComment: e.target.value }))}
                    placeholder={commentPlaceholder === "" ? "Specify your comment" : commentPlaceholder}
                    error={error.errField === 'updatedComment' && error.errMsg}
                    required={false}
                />
                <div className='modal-action-button-section'>
                    <BaseButton
                        text="Cancel"
                        className='modal-cancel-btn'
                        onClick={onCancel}
                    />

                    <BaseButton
                        variant='contained'
                        text={"Update"}
                        className='modal-submit-btn'
                        onClick={onSave}
                    />
                </div>
            </div>
        }
    />
}
export default EditTopicModalDialog;