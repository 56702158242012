import { useEffect, useState } from "react";
import { BaseButton } from "../../Elements/Button";
import LabeledInput from "../../Elements/LabeledInput"
import SkipOptions from "./SkipOption";
import Modal from "./../../components/Modal"
import plus from "./../../assets/svgs/plus.svg"
import CloseIcon from '@mui/icons-material/Close';




const MultiChoiceQuestion = ({handleChange, ...args}) => {

    const [optionPopup, setOptionPopup] = useState(false)
    const [multiOptionArr, setMultiOptionArr] = useState((args?.isEdit) ? [...args?.questionData?.option.map(opt => opt?.option_name || opt?.option)]: [])
    const [inputVal, setInputVal] = useState((args?.isEdit) ? args?.questionData?.comment_prompt: 'Write a comment to explain why you gave that score')

    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const { errField, errMsg } = err;

    const addOption = () => {
        if (multiOptionArr.length < 4) {
            setMultiOptionArr(prevState => [...prevState, ''])
        }
    }
    const removeOption = (index) => {
        if (multiOptionArr.length > 0) {
            setMultiOptionArr(prevState => prevState.filter((_, i) => i !== index))
        }
    }

    const handleOnChange = (index, value) => {
        setMultiOptionArr(prevState  => {
            const newState = [...prevState]
            newState[index] = value
            return newState
        })
    }

    const handleInputOnChange = (e) => {
        setInputVal(e.target.value)
        args.handleForm(e)
    }

    useEffect(() => {
        setErr(args?.err)
    }, [args?.err])

    useEffect(() => {
        if(multiOptionArr){
            handleChange(multiOptionArr)
        }
    }, [multiOptionArr])

    return <div>

        <div className="add-option-btn"  onClick={addOption}>
            <span>+ Add Option</span>
            <div>
            {errField === 'noOptionError' && <p style={{ color: 'red', fontSize: '0.75rem' }}>*{errMsg}</p>}
            </div>
        </div>

        <div >
            {
                multiOptionArr.map((value, index) => <div className="option-div" key={"option-div" + index}>
                    <LabeledInput
                        key={"option" + index}
                        type='text'
                        name={'option' + (index + 1)}
                        label={'Option ' + (index + 1)}
                        value={value}
                        onChange={e => handleOnChange(index, e.target.value)}
                        error={errField === 'option' + (index + 1) && (errMsg)}
                        required
                    />
                    <span key={"delete-option" + index} onClick={() => removeOption(index)} className="delete-option"><CloseIcon /></span>
                </div>
                )
            }
        </div>
        <LabeledInput
            type='text'
            name='commentPrompt'
            label='Comment Prompt'
            value={inputVal}
            onChange={handleInputOnChange}
            error={errField === 'commentPrompt' && (errMsg)}
            placeholder='Write a comment here to explain why you gave that score.'
            textarea
        />
        <div >
            <SkipOptions handleOptionChange={args?.handleSkipOptionType} isEdit={args.isEdit} questionData={args.questionData}/>
        </div>

        {
            optionPopup && <Modal
                modal_content={<>Add option</>}
                handleModal={setOptionPopup}
            />
        }
    </div>
}

export default MultiChoiceQuestion;