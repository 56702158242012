

import './index.scss'
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { useState } from 'react';
import {
    drag,
} from './../../assets/svgs/questions'

import editPencil from '../../assets/svgs/editPencil.svg'


const formatTitleToId = (title) => {
    return title && title.split(' ').join('-').toLowerCase()
}


const Checkbox = ({ checked, onCheckboxChange, svgIcon, title, subtitle, isMandatory, ...args }) => {

    const [isChecked, setIsChecked] = useState(checked);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
        onCheckboxChange()
    };

    return <div className={`checkbox-container ${isChecked ? 'checked' : ''} ${isMandatory ? 'mandatory' : ''}`}>

        <label className='checkbox-label' htmlFor={`${formatTitleToId(title)}-checkbox`}>
            {/* <div className='checkbox-content'> */}
            {
                !isMandatory && args.isDraggable && <span className='drag-handle' {...args.provided.dragHandleProps}>
                    <img src={drag} />
                </span>
            }
            <span className='check-icon'>
                {
                    isMandatory ?
                        <CircleCheckedFilled style={{ color: "#D1D1D1" }} />
                        :

                        isChecked ?
                            <CircleCheckedFilled style={{ color: "#657DE1" }} />
                            :
                            <CircleUnchecked style={{ color: "#EDEAEA" }} />
                }
            </span>
            <div className='checkbox-img'>
                {/* <img src={svgIcon} alt={title} /> */}
                <i className={svgIcon} />
            </div>
            <h4 className='checkbox-title'>{title}</h4>
            {subtitle && <p className='checkbox-subtitle'>{subtitle}</p>}
            {/* </div> */}
        </label>
        <input
            className='checkbox-input'
            id={`${formatTitleToId(title)}-checkbox`}
            type='checkbox'
            checked={isChecked}
            onChange={handleOnChange}
        />
        {
            args.isEditable && <div className='edit-btn' onClick={args.onEdit} >
                <img src={editPencil}/>
            </div>
        }
    </div>
}


export default Checkbox;