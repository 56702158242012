import { useEffect, useState } from "react"
import SelectDropDown from "../../Elements/SelectDropDown"
import CloseIcon from '@mui/icons-material/Close';
import { ApiClient } from "../../api/client";

const SkipOptions = ({handleOptionChange, ...args}) => {
    const [count, setCount] = useState(0)
    const [skipOptionChoices, setSkipOptionChoices] = useState([])
    const [selectSkipOptionChoices, setSelectSkipOptionChoices] = useState([])
    const [selectedSkipChoices, setSelectedSkipChoices] = useState([])

    useEffect(() => {
        const client = ApiClient(setSkipOptionChoices)
        client.get('/api/v1/skipoption/')
            .then((response) => {
                if (response?.data) {
                    setSkipOptionChoices(response?.data)
                }
            })
    }, [])

    useEffect(() => {
        if(args?.isEdit){
            setSelectedSkipChoices(args?.questionData?.skip_option)
            setCount(args?.questionData?.skip_option.length)
        }
    }, [args?.isEdit, args?.questionData])

    useEffect(() => {
        if(selectSkipOptionChoices && handleOptionChange){
            handleOptionChange(selectSkipOptionChoices)
        }
    }, [selectSkipOptionChoices])

    const addSkipOption = () => {
        if (count < 5) {
            setCount(prevCount => (prevCount + 1))
        }
    }
    const removeSkipOption = () => {
        if (count > 0) {
            setSelectSkipOptionChoices(prevState  => {
                const newState = [...prevState]
                newState.splice(count - 1, 1);
                return newState
            })
            setCount(prevCount => (prevCount - 1))
        }
    }

    const handleOnChange = (index, value) => {
        setSelectSkipOptionChoices(prevState  => {
            const newState = [...prevState]
            newState[index] = {...newState[index], id : parseInt(value)}
            return newState
        })
    }

    return <div >
          <div className="add-skipoption-btn"  onClick={addSkipOption}>
            <span>+ Add Skip Option</span>
        </div>
        <div >
            {
                [...Array(count)].map((_, i) => {
                    let selected_option = skipOptionChoices?.filter(so => {
                        return so.option_name === selectedSkipChoices[i]?.skip_option_name
                    })
                    return <div className="skip-option-div">
                            <SelectDropDown key={i}
                                defaultValue={selectedSkipChoices.length > 0 ? selected_option[0]?.id : skipOptionChoices[i]?.id}
                                index={i}
                                label={'Skip Option ' + (i + 1)}
                                optionList={skipOptionChoices}
                                valueField='id'
                                itemField='option_name'
                                isRequired
                                handleOnChange={handleOnChange}
                                selectedValues={selectSkipOptionChoices}
                            />
                            <span key={'close' + i} onClick={removeSkipOption} className="delete-option"><CloseIcon /></span>
                        </div>
                }
                )
            }
        </div>
    </div>
}

export default SkipOptions;