import { useEffect, useState, } from 'react'
import { ApiClient } from '../../../api/client'
import Carousel from './Carousel.js'
import './index.scss'
import { useSelector } from 'react-redux'
import TagTable from './tagsTable'
import Loader from '../../../components/Loader'
import SubdriverPopover from '../../../components/SubdriverSelector'
import SelectDropDown from '../../../Elements/SelectDropDown'
import { useNavigate } from 'react-router-dom'



const FREQUENCY = 'Most Frequent'
const MOST_RECENT = 'Most Recent'
const MOST_LIKED = 'Most Liked'
const MOST_DISLIKED = 'Most Disliked'



const KeyThemes = () => {

    const client = ApiClient()
    const { isAdmin } = useSelector(state => state.user)
    const navigate = useNavigate()
    const { projectId, projectUserId, isProjectAdmin } = useSelector(state => state.project)

    const [isLoading, setLoading] = useState(false)
    const [subdriver, setSubdriver] = useState([])
    const [subdriverFilterData, setSubdriverFilterData] = useState([])
    const [activeSubdriver, setActiveSubdriver] = useState()
    const [flagPanelOpen, setFlagPanelOpen] = useState(false);
    const [ackPanelOpen, setAckPanelOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [questionDetails, setQuestionDetails] = useState({
        questionType: '',
        questionText: '',
        tags_response: [],
    })
    const { questionType, questionText, tags_response } = questionDetails
    const [sort, setSort] = useState({
        sortOptions: [{ label: MOST_RECENT }, { label: MOST_LIKED }],
        sortBy: MOST_RECENT
    })
    const { sortOptions, sortBy } = sort

    useEffect(() => {
        if (projectId === null || projectId === undefined || projectId === '') {
            navigate('/app/project-not-found')
        }
    }, [projectId, navigate, client])

    useEffect(() => {
        if (projectId) {
            client
                .get(`/api/v1/project-status/${projectId}/`)
                .then(res => {
                    if (res?.data?.is_locked) {
                        navigate('/app/access-denied')
                    }
                    else {
                        if(!isAdmin){
                        client.get('/api/v1/project-anonymity-threshold/' + projectId + '/')
                            .then((response) => {
                                if (response?.data) {
                                    if (!response?.data?.criteria_met) {
                                        navigate('/app/no-dashboard', {
                                            state: {
                                                anonymity_threshold: response?.data?.anonymity_threshold,
                                                criteria_met: response?.data?.criteria_met,
                                                status_type: response?.data?.status_type,
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
        }
    }, [])


    useEffect(() => {
        if (projectId) {
            setLoading(true)
            client
                .get(`api/v1/subdriver/${projectId}/`)
                .then(res => {
                    if (res?.data) {
                        setState(res.data.sub_driver.sort((a,b) => a.order - b.order))
                        setLoading(false)
                    }
                })
        }
    }, [])

    const setState = (data) => {
        setSubdriverFilterData(data)
        let filtered_subdrivers = data?.filter(item => item.view === true)
        setSubdriver(filtered_subdrivers?.map(item => item.sub_driver))
        setActiveSubdriver(filtered_subdrivers?.length > 0 ? filtered_subdrivers[0].sub_driver : null)
    }


    const handleFlag = (d, value) => { };

    const handleLike = (d, value) => {
        const data = {
            'like_status': value,
            'am_response': d['am_response_id'],
            'project_user': projectUserId
        }

        client
            .post('/api/v1/key-theme-acknowledgement/',
                { 'data': data }
            )
            .then(response => { })
            .catch(err => {
                console.log(err)
            })

    };

    const handleToggleFlag = (id) => {
        setAckPanelOpen(false);
        if (id !== selectedId) {
            setSelectedId(id);
        }
        setFlagPanelOpen(!flagPanelOpen);

    }

    const handleToggleAck = (id) => {
        setFlagPanelOpen(false);
        if (id !== selectedId) {
            setSelectedId(id);
        }
        setAckPanelOpen(!ackPanelOpen);
    }

    const handleFilterSave = (data) => {
        if (data) {
            setLoading(true)
            client
                .post(`api/v1/subdriver/${projectId}/`, data = {
                    data: data,
                })
                .then((res) => {
                    if (res?.data) {
                        setState(res.data.sub_driver)
                        setLoading(false)
                    }
                })
        }
    }


    useEffect(() => {
        if (projectId) {
            getSubdriverResponse(true)
        }
    }, [activeSubdriver])

    const getSubdriverResponse = (loading) => {
        if (activeSubdriver) {
            setLoading(loading)
            client
                .get(`api/v1/key-themes/${projectId}/${activeSubdriver}`)
                .then(res => {
                    if (res?.data) {
                        setQuestionDetails({
                            questionType: res.data.question_type,
                            questionText: res.data.question_text,
                        })
                        setSort(state => (
                            {
                                sortBy: loading ? res.data.question_type === 'MULTI_TOPICS' ? FREQUENCY : MOST_RECENT : state.sortBy,
                                sortOptions: res.data.question_type === 'MULTI_TOPICS' ?
                                    [{ label: FREQUENCY }, { label: MOST_RECENT }, { label: MOST_LIKED }, { label: MOST_DISLIKED }]
                                    :
                                    [{ label: MOST_RECENT }, { label: MOST_LIKED }]
                            }
                        ))
                        onSort(res.data.tags_response)
                    }
                    setLoading(false)
                })
        }
    }

    const handleSelect = (sortBy) => {
        setSort(state => ({ ...state, sortBy: sortBy }))
    }
    useEffect(() => {
        onSort(tags_response)
    }, [sortBy])

    const onSort = (data) => {
        let response;

        if (sortBy === FREQUENCY) {
            response = data.sort((a, b) => b.freq - a.freq)
        }
        else if (sortBy === MOST_RECENT) {
            response = data
                .sort((a, b) => new Date(b.date) - new Date(a.date))
        }
        else if (sortBy === MOST_LIKED) {
            if (questionType === 'MULTI_TOPICS') {
                response = data
                    .sort((a, b) => b.freq - a.freq)
                    // .sort((a, b) => a.downvoteCnt - b.downvoteCnt)
                    .sort((a, b) => b.upvoteCnt - a.upvoteCnt)
            }
            else {
                response = data.sort((a, b) => b?.['my_status']?.['like_count'] - a?.['my_status']?.['like_count'])
            }

        }
        else if (sortBy === MOST_DISLIKED) {
            response = data
                .sort((a, b) => a.freq - b.freq)
                // .sort((a, b) => a.upvoteCnt - b.upvoteCnt)
                .sort((a, b) => b.downvoteCnt - a.downvoteCnt)
        }
        setQuestionDetails(state => (
            {
                ...state,
                tags_response: response
            }
        ))
    }


    return <div className='key-themes-container'>
        <div className='key-themes-header'>

            <div className='key-themes-subdrivers'>
                {
                    subdriver?.length > 0 && subdriver?.map((item, idx) => {
                        return <div
                            key={item + idx}
                            className={`head-item ${item === activeSubdriver ? 'active' : ''}`}
                            onClick={() => setActiveSubdriver(item)}
                        >
                            <span className='head-item-title'>{item}</span>
                        </div>
                    })
                }

            </div>

            {
                isProjectAdmin && subdriverFilterData.length > 0 && <div className='key-themes-subdriver-filter'>

                    <SubdriverPopover subdriverData={subdriverFilterData} handleSave={handleFilterSave} disabledList={[]} />

                </div>
            }

        </div>

        <div>
            <div className='question-div'>
                <h3 className='key-themes-question-text'>{questionText}</h3>
                <div className='keythemes-filters'>
                    <SelectDropDown
                        optionList={sortOptions}
                        handleOnChange={handleSelect}
                        defaultValue={sortBy}
                        itemField='label'
                        valueField='label'
                    />
                </div>
            </div>

            <div>
                {
                    isLoading ?
                        <Loader />
                        :
                        (!isLoading && tags_response.length === 0) ?
                            <h3 style={{ textAlign: 'center' }}>No Response Found</h3>
                            :

                            questionType === 'MULTI_TOPICS' ?
                                <div>
                                    {
                                        tags_response.length > 0 && <TagTable
                                            tags_data={tags_response}
                                            getSubdriverResponse={getSubdriverResponse}
                                        />
                                    }
                                </div>
                                :
                                questionType === 'TEXT' &&

                                <div className="keythemes-content">
                                    {tags_response.length > 0 ? (

                                        tags_response.map((d) => {

                                            if (d.topicValue === "") return null;

                                            return (
                                                <Carousel
                                                    data={d}
                                                    responseId={d.am_response_id}
                                                    key={`keytheme_carousel_${d.am_response_id}`}
                                                    text={d.topic_value}
                                                    value={d.integer_value}
                                                    callback={() => { getSubdriverResponse(false) }}
                                                    //onAck={(value) => handleAck(d, value)}
                                                    onFlag={(value) => handleFlag(d, value)}
                                                    ack_id={d.my_status.acknowledge_id}
                                                    like_id={d.my_status.like_id}
                                                    onLike={(value) => handleLike(d, value)}
                                                    acknowledge_status={d.my_status?.acknowledge_status}
                                                    like_status={d.my_status?.like_status}
                                                    like_count={d.my_status?.like_count}
                                                    flagPanelOpen={flagPanelOpen}
                                                    ackPanelOpen={ackPanelOpen}
                                                    onToggleFlag={() => handleToggleFlag(d.am_response_id)}
                                                    onToggleAck={() => handleToggleAck(d.am_response_id)}
                                                    selectedId={selectedId}
                                                />
                                            );
                                        })
                                    ) : (
                                        <h2 className="keythemes-content-nodata">No Data</h2>
                                    )}
                                </div>
                }


            </div>

        </div>
    </div>
}


export default KeyThemes;