import './index.scss'
import { Fragment, useEffect, useState } from 'react';
import LabeledInput from '../../Elements/LabeledInput';
import SelectDropDown from '../../Elements/SelectDropDown';
import TextQuestion from './TextQuestion';
import SliderQuestion from './SliderQuestion';
import MultiTopicQuestion from './MultiTopicQuestion';
import MultiChoiceQuestion from './MultiChoiceQuestion';
import { BaseButton } from '../../Elements/Button';
import LabeledCheckbox from '../../Elements/Checkbox/LabeledCheckbox';
import aboutme from "./../../assets/svgs/aboutme.svg"
import { ApiClient } from '../../api/client';
import { controlType } from '../../utilities/constants';


const NewQuestionPopup = ({ sectionType, isEdit, handleCancel, handleSubmit, questionData, segmentsData, questionSubmitErr }) => {
    
    const isAboutMe = sectionType === 'ABOUT_ME'

    const [formData, setFormData] = useState({
        subdriver: isEdit ? questionData.subdriver : '',
        question: isEdit ? questionData.question : '',
        questionType: isEdit ? questionData.type_readable : 'Text',
        segmentType: '',
        commentPrompt: isEdit ? questionData.comment_prompt : 'Write a comment here to explain why you gave that score.',
        topicPrompt: isEdit ? questionData.topic_prompt : 'Your response will be anonymously shared.  Do not identify yourself or others in your response.',
        sliderRight: isEdit ? questionData.slider_text_left : '',
        sliderLeft: isEdit ? questionData.slider_text_right : '',
        multiOption: isEdit ? questionData.option : [],
        skipOption: isEdit ? questionData.skip_option : [],
        segment: isEdit ? questionData.segments : [],
        ...(isAboutMe && {
            isIncludedOnFeedbackSummary: isEdit ?
            [controlType.TEXT, controlType.SLIDER].includes(questionData.control_type) && questionData.is_included_on_feedback_summary
            :
            true
        })
    })

    const [intialData, setIntialData] = useState([])
    const [segmentTypeOptions, setSegmentTypeOptions] = useState([])
    const [segmentDataOptions, setSegmentDataOptions] = useState([])
    const [selectedSegments, setSelectedSegments] = useState([])
    const [controlTypes, setControlTypes] = useState([])
    const [segmentTypeDefaultValue, setSegmentTypeDefaultValue] = useState()
    const [segmentTypeDefaultIdx, setSegmentTypeDefaultIdx] = useState()
    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const { errField, errMsg } = err;
    const { subdriver, question, questionType, segmentType } = formData

    const handleQuestionType = (value) => {
        setFormData(prevState => ({
            ...prevState, questionType: value,
            skipOption: [], multiOption: [], sliderLeft: '', sliderRight: '', topicPrompt: '',
             commentPrompt: isEdit ? questionData.comment_prompt : 'Write a comment here to explain why you gave that score.',
            ...(isAboutMe && {
                isIncludedOnFeedbackSummary: ["Text", "Slider"].includes(value) ? true : null
            })
        }))
    }

    const handleSegmentDataOptions = (index, data) => {
        setSegmentDataOptions(prevState => {
            const newState = [...prevState]
            newState[segmentTypeDefaultIdx][index]['isChecked'] = data
            return newState
        })
    }

    const handleSegmentType = (value) => {
        const index = segmentTypeOptions.findIndex(object => {
            return parseInt(object.segmentTypeId) === parseInt(value);
        })
        setSegmentTypeDefaultIdx(index)
        setFormData(prevState => ({ ...prevState, segmentType: value }))
    }

    const handleSkipOptionType = (value) => {
        setFormData(prevState => ({ ...prevState, skipOption: value }))
    }

    const handleMultiChoice = (value) => {
        setFormData(prevState => ({ ...prevState, multiOption: value }))
    }

    const handleForm = (e) => {
        setFormData(prevState => (
            {
                ...prevState,
                [e.target.name]: e.target.name === 'isIncludedOnFeedbackSummary' ?
                    e.target.checked : e.target.value
            }
        ))
    }

    const formValidator = (form_data) => {
        if (form_data?.subdriver.trim().length < 1) {
            return {
                field: 'subdriver',
                err: 'Subdriver cannot be empty'
            }
        }
        if (form_data?.question.trim().length < 1) {
            return {
                field: 'question',
                err: 'Question cannot be empty'
            }
        }
        if (form_data?.questionType === 'Slider' && form_data?.sliderLeft.trim().length < 1) {
            return {
                field: 'sliderLeft',
                err: 'Slider left cannot be empty'
            }
        }
        if (form_data?.questionType === 'Slider' && form_data?.sliderRight.trim().length < 1) {
            return {
                field: 'sliderRight',
                err: 'Slider right cannot be empty'
            }
        }
        if (form_data?.questionType === 'Choice' && form_data?.multiOption.length < 1) {
            return {
                field: 'noOptionError',
                err: 'Need to add atleast one option'
            }
        }
        if (form_data?.questionType === 'Choice') {
            let multiOptionValues = form_data?.multiOption.map((value, index) => value.trim().length < 1 ? index : undefined).filter(index => index !== undefined)
            if(multiOptionValues.length > 0){
                return {
                    field: 'option' + (parseInt(multiOptionValues[0]) + 1),
                    err: 'Option cannot be empty'
                }
            }
        }
        if (form_data?.questionType === 'Multi Topics' && form_data?.topicPrompt.length < 1) {
            return {
                field: 'topicPrompt',
                err: 'Topic Prompt cannot be empty'
            }
        }
        if (form_data?.commentPrompt.trim().length < 1) {
            return {
                field: 'commentPrompt',
                err: 'Comment Prompt cannot be empty'
            }
        }
    }

    const handleQuestionSubmit = () => {
        let error = formValidator(formData)
        if (error) {
            setErr({ ...err, ...{ errField: error['field'], errMsg: error['err'] } })
        }else{
            setErr({ ...err, ...{ errField: '', errMsg: '' } })
            handleSubmit(formData, isEdit)
        }
    }

    useEffect(() => {
        const client = ApiClient(setControlTypes)
        client.get('/api/v1/controltype/')
            .then((response) => {
                if (response?.data) {
                    setControlTypes(response?.data)
                }
            })
    }, [])

    useEffect(() => {
        if (questionSubmitErr?.data) {
            let errCondition = questionSubmitErr.data.includes('The sub-driver already exists in the project.')
            if (errCondition) {
                setErr({ errField: 'subdriver', errMsg: 'The sub-driver already exists in the project.' })
            }
        }
    }, [questionSubmitErr])

    useEffect(() => {
        if(segmentsData){
            setIntialData([...segmentsData])
        }
    }, [segmentsData])

    useEffect(() => {

        if(intialData.length){
            setSegmentTypeOptions([...intialData])
            setSegmentTypeDefaultValue(intialData[0]['segmentTypeId'])
            setSegmentTypeDefaultIdx(0)
            setSegmentDataOptions([...intialData.map((data) => data.segmentsList)])
        }
    }, [intialData])

    useEffect(() => {
        if(questionData){
            setSelectedSegments([...questionData.segments.map(item => ({'id' : item.segment.segment_id, 'is_selected' : item.is_selected}))])
        }
    }, [questionData])

    useEffect(() => {
        if(segmentDataOptions){
            setFormData(prevState => ({ ...prevState, segment: segmentDataOptions }))
        }
    }, [segmentDataOptions, intialData])

    return <div className='new-question-container'>

        <div className='header-div'>
            <div className='popup-icon-div'>
                <img src={aboutme} alt="about-me" />
            </div>
            <h3 className='title'>{isEdit ? 'Edit' : 'Add New'} Question</h3>
        </div>


        <div className='new-question-box'>
            <div className='left-section'>
                <LabeledInput
                    type='text'
                    name='subdriver'
                    label='Subdriver Name'
                    value={subdriver}
                    onChange={handleForm}
                    error={errField === 'subdriver' && (errMsg)}
                    required

                />
                <LabeledInput
                    type='text'
                    name='question'
                    label='Question'
                    value={question}
                    onChange={handleForm}
                    error={errField === 'question' && (errMsg)}
                    textarea
                    required
                />
                <SelectDropDown
                    label='type'
                    isRequired
                    optionList={controlTypes}
                    handleOnChange={handleQuestionType}
                    valueField='control_type_display_name'
                    itemField='control_type_display_name'
                    defaultValue={questionType}
                    hasToolTip={true}
                    toolTipTitle={<Fragment>
                        <ol>
                            <li><strong>Slider</strong> - Slider Questions allow users to quickly provide responses on a <b>subjective scale</b>. The left(red) is typically negative, and the right(green) is typically positive. For example, bad/good, never/frequently, etc.</li>
                            <li><b>Choice</b> - Choice questions provide users with a list of <b>options from a list of answers that you define.</b></li>
                            <li><b>Text</b> - Text questions provide users with a way to respond in more detail, <b>in their own words.</b></li>
                            <li><b>Multi Topic</b> - Multi Topic questions provide users with a simple way to identify key topics in just a few words - this is helpful when trying to consolidate the feedback of many different people. An administrator can meaningfully group this feedback into common categories to better understand what people are saying.</li>
                        </ol>
                    </Fragment>}
                />
                <div>
                    <SelectDropDown
                        label='Which stakeholder should be asked this question?'
                        isRequired
                        optionList={segmentTypeOptions}
                        handleOnChange={handleSegmentType}
                        valueField='segmentTypeId'
                        itemField='segmentTypeName'
                        defaultValue={segmentTypeDefaultValue}
                        hasToolTip={true}
                        toolTipTitle={"Select the segments to whom the questions will be exposed."}
                    />
                    <div className='segment-type-options'>
                        <div className='left-checkboxes'>
                            {segmentDataOptions.length > 0 &&
                                segmentDataOptions[segmentTypeDefaultIdx].map((item, idx) => {
                                    return <LabeledCheckbox
                                        key={item.segmentId}
                                        index={idx}
                                        item={item}
                                        label={item.title}
                                        isChecked={(isEdit) ? selectedSegments.find(segment => parseInt(segment.id) === parseInt(item.segmentId)).is_selected : item.isChecked}
                                        handleData={handleSegmentDataOptions}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
            <div className='right-section'>
                {
                    questionType === 'Text' && <TextQuestion isAboutMe={isAboutMe} isEdit={isEdit} questionData={questionData} err={err} handleChange={handleForm} handleSkipOptionType={handleSkipOptionType} />
                }
                {
                    questionType === 'Slider' && <SliderQuestion isAboutMe={isAboutMe} isEdit={isEdit} questionData={questionData} err={err} handleChange={handleForm} handleSkipOptionType={handleSkipOptionType} />
                }
                {
                    questionType === 'Multi Topics' && <MultiTopicQuestion isEdit={isEdit} questionData={questionData} err={err} handleChange={handleForm} handleSkipOptionType={handleSkipOptionType} />
                }
                {
                    questionType === 'Choice' && <MultiChoiceQuestion isEdit={isEdit} questionData={questionData} err={err} handleChange={handleMultiChoice} handleForm={handleForm} handleSkipOptionType={handleSkipOptionType} />
                }
            </div>
        </div>
        <div className='modal-action-button-section'>

            <BaseButton
                text="Cancel"
                className='add-question-cancel-btn modal-cancel-btn'
                onClick={() => {
                    handleCancel(false)
                }}
            />

            <BaseButton
                variant='contained'
                text={isEdit ? "Update" : "Add"}
                className='add-question-submit-btn modal-submit-btn'
                onClick={handleQuestionSubmit}
            />

        </div>

    </div>
}


export default NewQuestionPopup;