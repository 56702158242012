import './index.scss'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { BaseButton } from '../../Elements/Button';


const BottomBar = ({ onClickBack, onClickHelp, onClickNext, ...args }) => {
    return <div className='bottom-bar'>
        {(args.isBackShown ?? true) &&
            <div className='bottom-bar-back'>
                <BaseButton
                    className='back-btn'
                    text={
                        <>
                            <KeyboardBackspaceIcon />
                            <span>Back</span>
                        </>
                    }
                    onClick={onClickBack}
                />
            </div>
        }
        {(args.isHelpShown ?? true) &&
        <div className='bottom-bar-help'>
            <BaseButton
                className='help-btn'
                text={
                    <>
                        <HelpOutlineIcon />
                        <span>Help</span>
                    </>
                }
                onClick={onClickHelp}
            />
        </div>
        }
        {(args.isNextShown ?? true) &&
        <div className='bottom-bar-next'>
            <BaseButton
                className='next-btn'
                text={
                    <>
                        <ArrowRightAltIcon />
                        <span>{(args?.isLaunch !== undefined) ? 'Launch' : 'Next'}</span>
                    </>
                }
                onClick={onClickNext}

                isDisabled={args.isNextDisabled}

            />
        </div>
        }
    </div>
}

export default BottomBar;