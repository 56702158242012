import { useState } from 'react'
import './index.scss'

import CustomIconPicker from '../IconPicker'
import LabeledInput from './../../Elements/LabeledInput'
import { BaseButton } from '../../Elements/Button'
import { ApiClient } from '../../api/client'


const DriverPopup = ({ projectId, hasDescription = true, setDriverData, setAddDriverModal }) => {
    const [formData, setformData] = useState({
        title: '',
        subtitle: '',
    })


    const [iconValue, setIconValue] = useState()
    const [icon_error,setIcon_error]=useState()
    const [title_error,setTitle_error]=useState()


    const handleIconChange = (value) => {
        setIconValue(value);
        setIcon_error('');
    }

    const handleChange = (e) => {
       
        setformData(prevState => (
            { ...prevState, [e.target.name]: e.target.value }
        ))
        if(formData.title)
        {
            setTitle_error('')
        }
    }
    const handleCancel = () =>{
        setAddDriverModal(false)

    }
    const handleConfirm = (e) => {
        let flag_error=false
        if(!iconValue)
        {
            setIcon_error("*Icon must be selected")
            flag_error=true
        }
    
         if((formData.title).trim().length===0)
        {
           setTitle_error('*This field is mandatory')
           flag_error=true
        }
       
        if(formData && !flag_error){
            const client = ApiClient(setDriverData)
            if(projectId){
                const apiUrl = '/api/v1/survey/drivers/' + projectId + '/'
                client.post(apiUrl, {
                    data : {...formData, 'icon' : iconValue, 'project' : projectId}
                }).then((response) => {
                    setDriverData(response.data.driversList)
                    setAddDriverModal(false)
                }).catch((error) => {
                    if (error?.response?.data?.message === 'Driver already exists') {
                        setTitle_error('*The driver name already exists in the project.')
                    }
                })
            }
        
    }
    }

    return <div style={{ width: '100%' }}>
        
        <h3 className='heading'>Add New Driver</h3>
        <LabeledInput
            name='title'
            label='Driver'
            type='text'
            value={formData.title}
            error={(formData.title).length>40 && 'The text content of the Driver can contain up to 40 characters.'}
            required={true}
            onChange={handleChange}
        />
         <p style={{ color: 'red', fontSize: '0.75rem' }}>{title_error}</p>
        
        {
            hasDescription && <LabeledInput
                name='subtitle'
                label='Description'
                type='text'
                value={formData.subtitle}
                error={(formData.subtitle).length>280 && 'The text content of the Discription can contain up to 280 characters.'}
                required={false}
                onChange={handleChange}
                textarea={true}
                isOptional={true}
            />}
        <div>
        <span className='icon_heading'>Select Icon Category:</span>
            <CustomIconPicker hasCategory handleIconChange={handleIconChange} />
            <p style={{ color: 'red', fontSize: '0.75rem' ,marginTop:'0.3rem'}}>{icon_error}</p>
        </div>
        <div className='modal-action-button-section'>
            <BaseButton
                className='modal-cancel-btn'
                text='Cancel'
                variant='filled'
                onClick={handleCancel}
            />

            <BaseButton
                className='modal-submit-btn'
                text='Add'
                variant='filled'
                onClick={handleConfirm}
            />
        </div>

    </div>
}


export default DriverPopup