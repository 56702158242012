import { Route, Routes, useLocation } from "react-router";
import ProtectedRoutes from "./ProtectedRoutes";
import AboutMeUser from "../userpages/AboutMe";
import MyProject from "../pages/MyProject";
import EndUserLayout from "../components/EndUserLayout";
import ProjectNotFound from "../userpages/ProjectNotFound";
import PeopleGalaxy from "../userpages/PeopleGalaxy";
import AboutOthersUser from "../userpages/AboutOthers/Index";
import DriverAnalysis from "../userpages/DashBoard/DriverAnalysis";
import UsersWelcome from "../userpages/UsersWelcome";
import ProjectSummary from "../userpages/DashBoard/Summary";
import KeyThemes from "../userpages/DashBoard/keythemes";
import AdvisorInsights from "../userpages/DashBoard/advisorInsights/Index";
import { useSelector } from "react-redux";
import NoDashboard from "../userpages/NoDashboard";
import ProjectLocked from "../userpages/ProjectLocked";
import Amcharts from "../userpages/Amcharts";
import PeopleGalaxy2 from "../userpages/PeopleGalaxy/index2";



const EndUserRoutes = () => {

    const location = useLocation()
    const { isAdmin } = useSelector(state => state.user)

    const TOPBAR_TITLE = {
        '/app/': 'Select Project',
        '/app/how-to-use-pulse': 'Help: How to use Pulse.',
        '/app/project-not-found': 'Project Not Found',
        '/app/no-dashboard': 'No Dashboard',
        '/app/my-projects': isAdmin ? 'Projects' : '',
        '/app/about-me': 'About Me',
        '/app/about-others': 'My Map',
        '/app/driver-analysis': 'Driver Analysis',
        '/app/project-summary': 'Summary',
        '/app/welcome': 'Help: How to use Pulse',
        '/app/key-themes': 'Key Themes',
        '/app/advisor-insights': 'Advisor Insights',
        '/app/people-galaxy': 'People Galaxy',
        '/app/people-galaxy-2': 'People Galaxy',
        '/app/access-denied': '',
    }

    return <EndUserLayout
        topBarTitle={TOPBAR_TITLE[location.pathname]}
    >
        <Routes>
            <Route path="project-not-found" element={<ProtectedRoutes Component={ProjectNotFound} />} />
            <Route path="no-dashboard" element={<ProtectedRoutes Component={NoDashboard} />} />
            {
                isAdmin && 
                <>
                <Route path="my-projects" element={<ProtectedRoutes Component={MyProject} />} />
                </>
            }
            <Route path="about-me" element={<ProtectedRoutes Component={AboutMeUser} />} />
            <Route path="about-others" element={<ProtectedRoutes Component={AboutOthersUser} />} />
            <Route path="amcharts" element={<ProtectedRoutes Component={Amcharts} />} />
            <Route path="driver-analysis" element={<ProtectedRoutes Component={DriverAnalysis} />} />
            <Route path="project-summary" element={<ProtectedRoutes Component={ProjectSummary} />} />
            <Route path="key-themes" element={<ProtectedRoutes Component={KeyThemes} />} />
            <Route path="people-galaxy" element={<ProtectedRoutes Component={PeopleGalaxy} />} />
            <Route path="people-galaxy-2" element={<ProtectedRoutes Component={PeopleGalaxy2} />} />
            <Route path="advisor-insights" element={<ProtectedRoutes Component={AdvisorInsights} />} />
            <Route path="welcome" element={<ProtectedRoutes Component={UsersWelcome} />} />
            <Route path="how-to-use-pulse" element={<ProtectedRoutes Component={UsersWelcome} />} />
            <Route path="access-denied" element={<ProtectedRoutes Component={ProjectLocked} />} />
            {/* <Route path="*" element={<ProtectedRoutes Component={() => <h1>404 Page not found</h1>} />} /> */}
        </Routes>
    </EndUserLayout>
}

export default EndUserRoutes;


