import React from "react";

import Emoticon1 from '../../assets/images/emoji/Emoticon1.png'
import Emoticon2 from '../../assets/images/emoji/Emoticon2.png'
import Emoticon3 from '../../assets/images/emoji/Emoticon3.png'
import Emoticon4 from '../../assets/images/emoji/Emoticon4.png'
import Emoticon5 from '../../assets/images/emoji/Emoticon5.png'
import Emoticon6 from '../../assets/images/emoji/Emoticon6.png'
import Emoticon7 from '../../assets/images/emoji/Emoticon7.png'
import Emoticon8 from '../../assets/images/emoji/Emoticon8.png'
import Emoticon9 from '../../assets/images/emoji/Emoticon9.png'
import Emoticon10 from '../../assets/images/emoji/Emoticon10.png'

import styles from "./styles.scss";

const Emoji = ({ satisfaction,keytheme=false }) => {
  let emoji = Emoticon1

  if (satisfaction < 10) {
    emoji = Emoticon10
  } else if (satisfaction < 20) {
    emoji = Emoticon9
  } else if (satisfaction < 30) {
    emoji = Emoticon8
  } else if (satisfaction < 40) {
    emoji = Emoticon7
  } else if (satisfaction < 50) {
    emoji = Emoticon6
  } else if (satisfaction < 60) {
    emoji = Emoticon5
  } else if (satisfaction < 70) {
    emoji = Emoticon4
  } else if (satisfaction < 80) {
    emoji = Emoticon3
  } else if (satisfaction < 90) {
    emoji = Emoticon2
  } else if (satisfaction < 100) {
  }

  const rootStyle = {
    background: `url(${emoji}) center center / 100% 100% no-repeat`,
  };

  return (
    <div className='emoji-root' style={rootStyle}>
      {!keytheme && satisfaction !== -1 && (
        <div className='percentage'>{(satisfaction / 10).toFixed(1)}</div>
      )}
    </div>
  );
};

export default Emoji;
