

export const UserCard = ({ index, fName, userTitle, segments, onClick, img = "" }) => {
    let color = !img ?
        index % 3 === 0 ?
            'red'
            :
            index % 2 === 0 ?
                'green'
                :
                'blue'
        :
        null
    let split_name = fName.trim().split(' ')
    let ini = split_name[0].split('')[0] + (split_name.length > 1 ? split_name[split_name.length - 1]?.split('')?.[0] : '')


    return <div className="user-card" onClick={onClick}>
        <div className={`profile-pic-div ${color}`}>
            <div className={`pic-box ${color}`}>
                {
                    !img && <span className={`initials ${color}`}>{ini}</span>
                }
            </div>

        </div>
        <div className="user-detail">
            <span className="full-name">{fName}</span>
            <span className="user-title">{userTitle}</span>
            <span className="segments">{segments}</span>
        </div>
    </div>
}