import React from "react";
import './styles.scss';


const SlideNavigator = ({ cnt, position, onSelect, className }) => {
  return (
    <div className={`root ${className}`}>
      {[...new Array(cnt)].map((item, index) => (
        <span
          key={index}
          className= {( index == position)? "dot active":"dot"}
          onClick={(e) => onSelect(index)}
        ></span>
      ))}
    </div>
  );
};

export default SlideNavigator;
