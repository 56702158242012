import './index.scss'
import ReactLoading from "react-loading";

const Loader = () => {

    return <div className='loader-div'>
        <ReactLoading type={"bars"} color={"#7FCDC1"} />
    </div>
}

export default Loader;