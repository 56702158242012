import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './index.scss';

import { ApiClient } from '../../../api/client';
import SubdriverPopover from '../../../components/SubdriverSelector';
import KeyTakeAways from './keyTakeaways';
import CatchupUsers from './catchUsers';
import RecommendedUsers from './recommendedUsers';
import { useNavigate } from 'react-router-dom';
import { BaseButton } from '../../../Elements/Button';



const AdvisorInsights = () => {


    const client = ApiClient()
    const navigate = useNavigate()
    const { isAdmin } = useSelector(state => state.user)
    const { projectId, isProjectAdmin } = useSelector(state => state.project)


    const [isLoading, setLoading] = useState(false)
    const [subdriverFilterData, setSubdriverFilterData] = useState([])
    const [subdriverDTypeList, setSubdriverDTypeList] = useState({
        highest: [],
        lowest: []
    })
    const { highest, lowest } = subdriverDTypeList
    const [keyTakeAwayData, setKeyTakeAwayData] = useState([])

    const [recommendedUsers, setRecommendedUsers] = useState([])
    const [recoQueryStatus, setRecoQueryStatus] = useState('')
    const [view, setView] = useState('Stakeholder')
    const [catchUsers, setCatchUsers] = useState([])
    const [catchupQueryStatus, setCatchupQueryStatus] = useState('')

    useEffect(() => {
        if (projectId === null || projectId === undefined || projectId === '') {
            navigate('/app/project-not-found')
        }
    }, [projectId, navigate, client])

    useEffect(() => {
        if (projectId) {
            client
                .get(`/api/v1/project-status/${projectId}/`)
                .then(res => {
                    if (res?.data?.is_locked) {
                        navigate('/app/access-denied')
                    } 
                    else {
                        if(!isAdmin){
                        client.get('/api/v1/project-anonymity-threshold/' + projectId + '/')
                            .then((response) => {
                                if (response?.data) {
                                    if (!response?.data?.criteria_met) {
                                        navigate('/app/no-dashboard', {
                                            state: {
                                                anonymity_threshold: response?.data?.anonymity_threshold,
                                                criteria_met: response?.data?.criteria_met,
                                                status_type: response?.data?.status_type,
                                            }
                                        })
                                    }
                                }
                            })
                        }
                        }
                })
        }
    }, [projectId])

    const setState = (data) => {
        setSubdriverFilterData(data)
        setSubdriverDTypeList({
            highest: data.filter(d => d.view && d.bounds === 'Highest').map(s => s.sub_driver),
            lowest: data.filter(d => d.view && d.bounds === 'Lowest').map(s => s.sub_driver),
        })
    }

    useEffect(() => {
        if (projectId){
        setRecoQueryStatus('STARTED')
        client
            .get(`api/v1/insights-recommended-users/${projectId}/`)
            .then(res => {
                if (res?.results) {
                    setRecommendedUsers(res.results)
                    setRecoQueryStatus('COMPLETED')
                }
            })
        }
    }, [])

    useEffect(() => {
        if (projectId){
        setCatchupQueryStatus('STARTED')
        client
            .get(`api/v1/insights-catchup/${projectId}/`)
            .then(res => {
                if (res?.results) {
                    setCatchUsers(res.results)
                    setCatchupQueryStatus('COMPLETED')
                }
            })
        }
    }, [])


    useEffect(() => {
        if (projectId){
        setLoading(true)
        client
            .get(`api/v1/subdriver/${projectId}/?report_type=4_ai&control_types=2`)
            .then(res => {
                if (res?.data) {
                    setState(res.data.sub_driver)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (highest.length && lowest.length && projectId) {
            setLoading(true)
            let h = highest.join(',')
            let l = lowest.join(',')
            client
                .get(`api/v1/advisor-insights/${projectId}/?highest=${h}&lowest=${l}`)
                .then(res => {
                    if (res?.results) {
                        setKeyTakeAwayData(res.results)
                    }
                    setLoading(false)
                })
        }
    }, [subdriverDTypeList])


    const handleFilterSave = (data) => {
        if (data && isProjectAdmin) {
            setLoading(true)
            client
                .post(`api/v1/subdriver/${projectId}/?report_type=4_ai&control_types=2`, data = {
                    data: data,
                })
                .then((res) => {
                    if (res?.data) {
                        setState(res.data.sub_driver)
                        setLoading(false)
                    }
                })
        }
    }
    const handletoggle = () =>{
        if (view=='Stakeholder')
        {
            setView('Key Takeaways')
        }
        else{
            setView('Stakeholder')

        }
    }
    const isLargeDevice = window.innerWidth > 820

    return <div className={!isLargeDevice ? 'advisor-insights-container':'advisor-insights-container mobile'}>
        
        {
                
                !isLargeDevice && <div className="toggle-container" >
                    <BaseButton className="toggle-btn" onClick={() => {
                       handletoggle();
                    }}
                    text={view=='Key Takeaways'? 'Key Takeaways':'Stakeholders'}
                    />

                </div>
          
          
        }
       
 
        < div className={isLargeDevice ? 'key-takeaways-container':view=='Stakeholder'?'key-takeaways-container mobile ':'key-takeaways-container mobile viewchangetakeaway'}>
        
       
            {

             isProjectAdmin && subdriverFilterData.length > 0 && <div className='advisor-insights-subdriver-filter'>

                    <SubdriverPopover
                        subdriverData={subdriverFilterData}
                        handleSave={handleFilterSave}
                        disabledList={[]}
                        page='advisor-insights'
                    />
                    

                </div>
                

            }
           



            <KeyTakeAways
                isLoading={isLoading}
                keyTakeAwayData={keyTakeAwayData}
                subdriversList={subdriverFilterData.filter(d => d.view === true).map(d => d.sub_driver).filter((value, index, array) => array.indexOf(value) === index)}
            />
             

        </div>
        
       
        <div  className={isLargeDevice ? 'users-container': view=='Key Takeaways'?'users-container mobile':'users-container mobile viewchangeuser'}>

            <div className='to-catchup-users'>
                <CatchupUsers users={catchUsers} queryStatus={catchupQueryStatus} />
            </div>

            <div className='recommended-users'>
                <RecommendedUsers users={recommendedUsers} queryStatus={recoQueryStatus} />
            </div>

        </div>
        

    </div>
}

export default AdvisorInsights;