import './kgraphnav.scss'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { BaseButton } from '../../Elements/Button'
import SelectDropDown from '../../Elements/SelectDropDown'
import { ApiClient } from '../../api/client'
import { Popover } from '@mui/material'

const KGraphNavControls = ({handleOnChange, groupData, setGroupData}) => {
    const [level1_list, setLevel1_list] =useState();
    const [level2_list, setLevel2_list] = useState();
    const { projectId } = useSelector(state => state.project)
    const [level_1_selected, setLevel1selected] = useState('')
    const [level_2_selected, setLevel2selected] = useState('')
    const [showerror, setShowerror] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (projectId) {
            setLevel1_list([{
                'segmentTypeId' : 0,
                'segmentTypeName' : "None"
            }])
            setLevel2_list([{
                'segmentTypeId' : 0,
                'segmentTypeName' : "None"
            }])
            const client = ApiClient(setLevel1_list)
            client.get('/api/v1/segments/' + projectId + '/?is_active=true&shLabel=true')
            .then((response) => {
                if (response?.data) {
                    setLevel1_list(prev => ([...prev, ...response.data]))
                    setLevel2_list(prev => ([...prev, ...response.data]))
                }
            })
        }
    }, [projectId])

    useEffect(() => {
        if (groupData) {
            setLevelData(prev => ({
                ...prev,
                level1: groupData.level1,
                level2: groupData.level2,        
            }))
        }
    }, [groupData])
    
    const [levelData, setLevelData] = useState({
        level1: groupData.level1,
        level2: groupData.level2,
    })
    
    const level1Change =(value)=>{
        setLevel1selected(value)
        if((level_2_selected !== '' || level_2_selected !== 'None') && level_1_selected === 'None'){
            setLevel2selected('None')
        }
        setShowerror(false)
    }

    const level2Change =(value)=>{
        if((level_1_selected==='None' || level_1_selected==='') && (value==='None' || value===''))
        {
            setShowerror(false)
            setLevel2selected(value)
            return
        }
        if(level_1_selected === '' || level_1_selected === 'None'){
            setShowerror('Set Level 1 before Level 2')
            return
        }
        setShowerror(false)
        setLevel2selected(value)
    }
    
    const handleSubmit = () =>{
        if ((level_1_selected === 'None' || level_1_selected==='' ) && ( level_2_selected === 'None' || level_2_selected===''))
        {
            setGroupData(prev => ({...prev, level1: '', level2: ''}))
            handlePostSave()  
            return 
        }

        if(level_1_selected === '' || level_1_selected === 'None'){
            setShowerror('Level 1 required')
            return
        }
        
        if(level_2_selected == level_1_selected ){
            setShowerror('*Please Select Different Levels')
            return
        }
        setGroupData(prev => ({...prev, level1: level_1_selected, level2: level_2_selected}))
        handlePostSave()
    }
    
    const layoutlist= [
        { key: 'organic', title: 'Radial', },
        { key: 'sequential', title: 'Hierarchy', },
    ]

    const handleClick = (event) => {
        setShowerror(false)
        setAnchorEl(event.currentTarget);
    };

    const handlePostSave = () => {
        setShowerror(false)
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'group-popover' : undefined;
   
    return <>
        <div className='k-graph-nav'>
            <div className='save-map'>
                <BaseButton
                    className="save_btn"
                    text="Save Map"
                    variant="filled"
                />
            </div>
            <div className='group-popup'>
                <BaseButton
                    aria-describedby={id}
                    variant='text'
                    text='Group'
                    className="grouping_btn"
                    onClick={handleClick}
                />
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className='popover-content'>
                        <div className='popover-content-data'>
                            <SelectDropDown
                                label='Level 1'
                                isRequired
                                optionList={level1_list}
                                handleOnChange={level1Change}
                                valueField='segmentTypeName'
                                itemField='segmentTypeName'
                                defaultValue={levelData.level1}
                            />
                            <SelectDropDown
                                label='Level 2'
                                optionList={level2_list}
                                handleOnChange={level2Change}
                                valueField='segmentTypeName'
                                itemField='segmentTypeName'
                                defaultValue={levelData.level2}
                            />
                            <BaseButton
                                className="ok_btn"
                                text="Ok"
                                variant="filled"
                                onClick={() => {handleSubmit()}}
                            />
                            {showerror &&  <p style={{ color: '#ff0000', fontSize: '10px', marginTop: '10px' }}>{showerror}</p>}
                        </div>
                    </div>
                </Popover>
            </div>
            <div className='graph-layout-dropdown'>
                <SelectDropDown
                    isRequired
                    optionList={layoutlist}
                    itemField='title'
                    valueField='key'
                    handleOnChange={handleOnChange}
                />
            </div>
        </div>
    </>
}

export default KGraphNavControls;