import './index.scss';
import logo from '../../assets/images/project-ai-logo-light.png';
import { Fragment, useState } from 'react';
import { EMAIL } from '../Register/utility';
import isEmail from 'validator/lib/isEmail';
import LabeledInput from '../../Elements/LabeledInput';
import { Link } from 'react-router-dom';
import { BaseButton } from '../../Elements/Button';
import Modal from '../../components/Modal';
import { InfoText } from '../../Elements/MessageBlocks';
import axiosInstance from '../../api/axios';
import checkmark from '../../assets/images/checkmark.gif';
import {useNavigate} from 'react-router-dom';
import { scrollToBottom } from '../../utilities';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const formValidator = (form_data) => {
        if (!isEmail(form_data.username)) {
            return {
                field: EMAIL,
                err: 'Invalid Email.'
            }
        }
    }

    const formInitialState = {
        username: '',
    }

    const [formData, setFormData] = useState(formInitialState)
    const [showModal, setModalVisibility] = useState(false);
    const [showCircularProgress, setShowCircularProgress] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [emailModal, setemailModal] = useState(false);

    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const [foregt_error, setForget_error] = useState('')

    const { errField, errMsg } = err;

    const { username } = formData;


    const handleInputChange = (e) => {
        setErr({ ...err, ...{ errField: '', errMsg: '' } })
        setFormData(prevState => (
            { ...prevState, [e.target.name]: e.target.value }

        ))
        if (formData.username.length > 0) {
            setForget_error('')
        }
    }

    const clearFormState = () => {
        setFormData({ ...formInitialState });
    };

    let inputs_arr = [
        { type: 'username', name: 'username', label: EMAIL, value: username },
    ]

    const handleSubmit = (e) => {
        let error = formValidator(formData)
        if (error) {
            setErr({ ...err, ...{ errField: error['field'], errMsg: error['err'] } })
        }
        else {
            axiosInstance.post('api/v1/validate-email/', { email: formData.username, }).then((res) => {
                if (res.data === 'success') {
                    setShowCircularProgress(true)
                    axiosInstance.post('api/v1/auth/users/reset_password/', {
                        email: formData.username,
                    }).then((res) => {
                        clearFormState();
                        setModalVisibility(true);
                        setShowCircularProgress(false)
                        setShowHint(true)
                    }).catch((error) => {
                        Object.keys(error.response.data).map((key) => {
                            setErr({ ...err, ...{ errField: key, errMsg: error.response.data[key] } })
                            return true;
                        })
                        setShowCircularProgress(false)
                    })
                }
                else {

                    setForget_error('*User is not registered')
                }

            })


        }
    }

    const handleClose = () =>{
        navigate('/login');
        return (setShowHint(!showModal))
    }


    return <div className="register-section">
        <div className="left-section" >
            <div className="logo">
                <img src={logo} width={100} height={50} alt="logo" />
            </div>
            <div className='headline'>
                <span>Successful projects start with people</span>
            </div>
            {
                window.innerWidth < 769 && <> <div className='heading-text-sm'>
                    <h1>Collective Insights</h1>
                    <span>
                        Pulse by ProjectAI helps project managers make <br />
                        sense of people’s collective insights for better project outcomes.
                    </span>
                </div>
                    <div className='scroll-to-bottom-icon' onClick={scrollToBottom}>
                        <i className="fa-solid fa-chevron-down"></i>
                    </div>
                </>
            }
        </div>
        <div className='right-section'>
            <div>
                <div className='heading'>
                    <h1>Forgot your password?</h1>
                    <div>Please enter the email address for your account,</div>
                    <div>a password reset link will be sent to that email</div>
                </div>
                <div className='form-container'>
                    <form>
                        {
                            inputs_arr.map(inp => {
                                return <Fragment key={inp.name}>
                                    <LabeledInput
                                        type={inp.type}
                                        name={inp.name}
                                        label={inp.label}
                                        value={inp.value}
                                        onChange={handleInputChange}
                                        error={errField === inp.label && errMsg}
                                    />
                                </Fragment>
                            })
                        }
                        <p style={{ color: 'red', fontSize: '0.75rem' }}>{foregt_error}</p>
                        <p><Link to='/login'> Click here </Link> to login</p>

                        <div style={{ textAlign: 'center' }}>
                            <BaseButton
                                className="submit-btn"
                                text="Send Password Reset Link"
                                variant="filled"
                                onClick={handleSubmit}
                                showCircularProgress={showCircularProgress}
                            />
                            {showHint &&
                                <Modal
                                    handleModal={handleClose}
                                    modal_content={
                                        <div style={{ width: '100%', flex: '1' }}>
                                            <div style={{ textAlign: 'center', marginTop: '16px', padding: '1px' }}>
                                                <img alt='checkmark' src={checkmark} style={{ borderRadius: '50%', marginTop: '6px', width: '150px', height: '150px' }}></img>
                                            </div>
                                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                                <h1>Success!</h1>
                                                <InfoText messageText="An email has been sent to your registered email ID." color='#00000'></InfoText>
                                            </div>
                                        </div>
                                    }
                                />
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}

export default ForgotPassword;