import './index.scss';
import './Launch_TableRow';
import { useEffect, useState } from 'react';

const LaunchTable = ({data, selectedUser, handleSelected,setSelectedUsers, usersArr,isAllSelected,setIsAllSelected,handleAllSelected}) => {
    
   

    return <table className='launch-table'>
        <thead className='ltable-header'>
            <tr className='ltable-head-row'>
            <th className='select-all-rows select'><input type='checkbox' checked={isAllSelected} onChange={(e) => handleAllSelected(e)} /><span></span></th>
                <th className='tablehead'>First Name<span></span></th>
                <th className='tablehead'><span>Project Title</span></th>
                <th className='tablehead'><span>Email</span></th>
                <th className='tablehead'><span>Date Identified</span></th>
            </tr>
        </thead>
        <tbody className='table-body'>
        {
        data?.users?.length > 0 && data.users.map((item, idx) => {
            return <tr key={idx} className={'ltable-row'}>
                    <td><input type='checkbox' checked={selectedUser.includes(item.id)} onChange={(e) => handleSelected(e, item.id)}/></td>
                    <td>{item?.first_name + ' ' + item?.last_name}</td>    
                    <td>{item?.project_user_title}</td>    
                    <td>{item?.email}</td>  
                    <td>{item?.created_at}</td>
                </tr>
        })
        }
        </tbody>
    </table>
}


export default LaunchTable;