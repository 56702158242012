import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TableRow from './TableRow';
import { ApiClient } from '../../api/client';


const Table = ({ questionsArr, handleOnDragEnd, handleQuestionEdit, segmentArr, handleQuestionSegmentToggle, ...args }) => {

    const [openQuestion, setOpenQuestion] = useState()
    const [segmentsData, setSegmentData] = useState([])
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const [isAllSelected, setIsAllSelected] = useState(false)

    useEffect(() => {
        if (questionsArr) {
            setIsAllSelected(false)
            setSelectedQuestions([])
        }
        return () => {
        }
    }, [questionsArr])

    useEffect(() => {
        if (segmentArr) {
            setSegmentData(segmentArr)
        }
        return () => {
        }
    }, [segmentArr])

    const handleQuestionSelection = (event, questionId) => {
        if (event.target.checked) {
            setSelectedQuestions(prev => [...prev, questionId])
        } else {
            setIsAllSelected(false)
            setSelectedQuestions(prev => prev.filter((item) => item !== questionId))
        }
    }

    const handleAllSelected = (event) => {
        if (event.target.checked) {
            setSelectedQuestions(prev => [...new Set([...prev, ...questionsArr.filter(item => item.is_mandatory !== true).map((item) => item.id)])])
        } else {
            setSelectedQuestions([])
        }
        setIsAllSelected(!isAllSelected)
    }

    useEffect(() => {
        if (selectedQuestions.length && questionsArr) {
            if ([...questionsArr.map((item) => item.id)].length === selectedQuestions.length) setIsAllSelected(true)
        }
    }, [selectedQuestions, questionsArr])

    useEffect(() => {
        args?.setSelectedQuestions(selectedQuestions)
    }, [selectedQuestions, args, args?.setSelectedQuestions])

    return <table className='questions-table'>
        <thead className='table-header'>
            <tr className='table-head-row'>
                <th className='blank-th'></th>
                <th className='blank-th'></th>
                <th className='select-all-rows select'><input type='checkbox' checked={isAllSelected} onChange={(e) => handleAllSelected(e)} /><span></span></th>
                <th className='subdriver-th'>Subdriver<span></span></th>
                <th className='question-th'>Question<span></span></th>
                <th className='type-th'>Type<span></span></th>
                {args?.selectedSegmentTypeId &&
                    segmentsData.length > 0 && segmentsData.map(segType => {
                        return (parseInt(segType.segmentTypeId) === parseInt(args.selectedSegmentTypeId)) &&
                            segType.segmentsList.map(seg => {
                                return <th className='segments-th' key={seg.segmentId}>{seg.title}<span></span></th>
                            })
                    })
                }
                <th></th>
            </tr>
        </thead>
        <tbody className='table-body'>
            {
                questionsArr.filter(q => q.is_mandatory === true).length > 0
                && questionsArr.filter(q => q.is_mandatory === true).map((question, idx) => {
                    return <TableRow
                        index={idx}
                        item={question}
                        provided={null}
                        openQuestionId={openQuestion}
                        onClickChevron={() => {
                            if (openQuestion === question.id) {
                                setOpenQuestion()
                            }
                            else {
                                setOpenQuestion(question.id)
                            }
                        }}
                        onClickEdit={handleQuestionEdit}
                        onChange={handleQuestionSegmentToggle}
                        selectedSegmentTypeId={args.selectedSegmentTypeId}
                        handleQuestionSelection={handleQuestionSelection}
                        selectedQuestions={selectedQuestions}
                    />
                })
            }

        </tbody>
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="questions">
                {(provided) => (
                    <tbody className='table-body'
                        {...provided.droppableProps} ref={provided.innerRef}
                    >
                        {
                            questionsArr
                                .filter(q => q.is_mandatory === false)
                                .length > 0
                            &&
                            questionsArr
                                .filter(q => q.is_mandatory === false)
                                .map((question, idx) => {
                                    return (
                                        <Draggable
                                            key={question.id + '-' + question.subdriver}
                                            draggableId={question.id}
                                            index={idx}
                                        >
                                            {
                                                (provided) => (
                                                    <TableRow
                                                        index={idx + questionsArr?.filter(q => q?.is_mandatory === true)?.length}
                                                        item={question}
                                                        provided={provided}
                                                        openQuestionId={openQuestion}
                                                        onClickChevron={() => {
                                                            if (openQuestion === question.id) {
                                                                setOpenQuestion()
                                                            }
                                                            else {
                                                                setOpenQuestion(question.id)
                                                            }
                                                        }}
                                                        onClickEdit={handleQuestionEdit}
                                                        onChange={handleQuestionSegmentToggle}
                                                        selectedSegmentTypeId={args.selectedSegmentTypeId}
                                                        handleQuestionSelection={handleQuestionSelection}
                                                        selectedQuestions={selectedQuestions}
                                                    />
                                                )
                                            }
                                        </Draggable>
                                    )
                                })
                        }
                        {provided.placeholder}
                    </tbody>
                )}
            </Droppable>
        </DragDropContext>
    </table>
}


export default Table;