import { NameValidator } from "../Register/utility"


const alpha_numeric_regex = /^[\w '.-]+$/
let optionalField = ['projectCode', 'projectDescription']

const fieldValidator = (string, name) => {
    if (!string.trim().length && !optionalField.includes(name)) {
        return "Field can't be empty."
    }
    ////TODO: Accepting all Characters for now
    // if (string.length && !string.match(alpha_numeric_regex)) {
    //     return "Field should be AlphaNumeric."
    // }
    if (name === 'organisationName' && string.length > 150) {
        return "Field should not be more than 350 characters long."
    }
    if (name === 'projectName' && string.length > 350) {
        return "Field should not be more than 350 characters long."
    }
    if (name === 'projectManager' && string.length > 250) {
        return "Field should not be more than 250 characters long."
    }
    if (name === 'projectCode' && string.length > 20) {
        return "Field should not be more than 20 characters long."
    }
    if (name === 'projectDescription' && string.length > 1250) {
        return "Field should not be more than 1250 characters long."
    }
}



export const projectAttrFormValidator = (form_data, maxSeats) =>{
    let orgValid = fieldValidator(form_data.organisationName, 'organisationName')
    if(orgValid){
        return {
            field: 'Organisation Name',
            err: orgValid
        }
    }
    let projectNameValid = fieldValidator(form_data.projectName, 'projectName')
    if(projectNameValid){
        return {
            field: 'Project Name',
            err: projectNameValid
        }
    }
    let projectManagerValid = fieldValidator(form_data.projectManager, 'projectManager')
    if(projectManagerValid){
        return {
            field: 'Project Manager',
            err: projectManagerValid
        }
    }
    let projectCodeValid = fieldValidator(form_data.projectCode, 'projectCode')
    if(projectCodeValid){
        return {
            field: 'Project Code',
            err: projectCodeValid
        }
    }
    let projectDescriptionValid = fieldValidator(form_data.projectDescription, 'projectDescription')
    if(projectDescriptionValid){
        return {
            field: 'Project Description',
            err: projectDescriptionValid
        }
    }
    if (form_data.projectSeats < 10 || form_data.projectSeats > maxSeats){
        return {
            field: 'Project Seats',
            err: `Project Seats should be minimum '10' and maximum '${maxSeats}'.`
        }
    }
    
}
