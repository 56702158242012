
import Layout from "../../components/Layout";
import './index.scss';
import { useEffect, useState } from "react";
import { BaseButton } from '../../Elements/Button';
import LaunchTable from '../../components/LaunchTable';
import {  useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { ApiClient } from "../../api/client";
import ReactPaginate from "react-paginate";
import { top, invitation} from '../../assets/svgs/launch';
import { useDispatch } from "react-redux";
import { projectDraftStatusAction, userIsAdminAction } from "../../redux/actions";

const Launch = () => {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [usersArr, setUsersArr] = useState([])
    const [selectedUser, setSelectedUsers] = useState([])
    const [errorTxt, setErrorTxt] = useState('')
    const [isAllSelected, setIsAllSelected] = useState(false)
    const { projectId, currentProjectName } = useSelector(state => state.project)
    const { username } = useSelector(state => state.user)
    
    const pageSize = 15
    const BASE_PREFIX = `/api/v1/project-user/${projectId}?&is_invited=true`
    const [userResponse, setUserResponse] = useState({
        users: [],
        nextLink: '',
        prevLink: '',
        usersCount: 1,
        page: 1,
    })
    const pageCount = Math.ceil(userResponse.usersCount / pageSize);

    const getUsers = (API_URL, setState) => {
        const client = ApiClient()
        client.get(API_URL)
        .then((response) => {
            if (response?.data) {
                setState(prevState => ({
                    ...prevState,
                    users: response.data,
                    nextLink: response.next,
                    prevLink: response.prev,
                    usersCount: response.count
                }))
                setUsersArr(response.data)
                
                return response.data
            }
        })
    }

    useEffect(() => {
        if (projectId) {
            let API_URL = BASE_PREFIX + '&page_size=' + pageSize
            getUsers(API_URL, setUserResponse)
        }
    }, [projectId, BASE_PREFIX])

    const handlePageClick = (event) => {
        if (projectId) {
            let API_URL = BASE_PREFIX + '&page=' + (event.selected + 1) + '&page_size=' + pageSize
            getUsers(API_URL, setUserResponse)
            setUserResponse(prevState => ({
                ...prevState,
                page: event.selected + 1
            }))

        }
    };
    
    const performPrecheckandLaunch = () => {
        const client = ApiClient()
        client.put('/api/v1/project-launch/' + projectId + '/', {
            selectedUserList : selectedUser
        })
        .then((response) => {
            if (response?.data) {
                dispatch(userIsAdminAction(true))
                dispatch(projectDraftStatusAction(false))
                navigate({pathname: '/app/my-projects'})
            }
        })
        .catch((error) => {
            setErrorTxt(error?.response?.data?.message)
        })
    }

    const handleSelected = (event, id) => {
        if(event.target.checked){
            setSelectedUsers(prev => [...prev, id])
            if(usersArr.length==selectedUser.length+1){
                setIsAllSelected(true)    
            }
        }else{
            setSelectedUsers(prev => prev.filter(item => item !== id))
            setIsAllSelected(false)

            
        }

      
    }
    const handleAllSelected = (event) => {
        if (event.target.checked) {
            
            setSelectedUsers(prev => [...new Set([...prev, ...usersArr.filter(item => item.is_mandatory !== true).map((item) => item.id)])])
        } else {
            setSelectedUsers([])
        }
        setIsAllSelected(!isAllSelected)
    }
    

    return <Layout
    active_page={'Launch'}
    isLaunch={true}
    onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#Launch','_blank')}
    onClickNext={() => performPrecheckandLaunch()}
    onClickBack={() => navigate({
            pathname: '/setup/permissions',
            search: '?projectId=' + projectId
        }, { state: { projectId } })}>

        <div style={{ overflowX: 'scroll'}}>
            <h1 className='lsection-title'>Launch</h1>
            <p className="lsection-description">
                Here is the preview of the Invitation Email we will send to your user list. Click Launch Invitations and get started!
            </p>
            {errorTxt &&
                <p className="lsection-description" style={{ color: 'red' }}>
                    {errorTxt}
                </p>
            }
            <div className="flex-container" style={{backgroundColor:'#EAF0F3'}}>
                <div className="flex-item-left">
                    <div className="top-header">
                        <div style={{ fontSize: '14px', textAlign: 'center', paddingBottom: '10px',}}>
                            <img src={top} alt="top" />
                        </div>
                        <div style={{ fontSize: '14px', textAlign: 'center', padding: '5px 25px 25px 25px',}}>
                            Welcome <b>USER FIRST NAME</b>,<br></br>
                            You have been nominated as a stakeholder <br></br>on the
                            <b> {currentProjectName}</b> project by
                            <b> {username}</b>
                        </div>
                    </div>
                    <div className="mock-mail-body" style={{ fontSize: '14px', textAlign: 'center', padding: '25px 100px', fontFamily: 'Poppins', backgroundColor:'#FFFFFF'}}>
                        <p style={{ marginBottom: '20px'}}>
                            <img src={invitation} alt="invitation" />
                        </p>
                        <p style={{ marginBottom: '20px'}}>
                            <b>You have been invited to share your perspective on the project using Pulse by ProjectAI</b>
                        </p>
                        <p style={{ marginBottom: '20px'}}>
                            We want to create the kind of project where everyone can freely share their thoughts, feelings and ideas. We
                            want this project to succeed - and to do that, we need your honest input.
                        </p>
                        <p style={{ marginBottom: '20px'}}>
                            We are using Pulse to anonymously gather the views of our stakeholders. This is more than survey. There are no 
                            wrong answers and you can change your responses at any time. If your opinion changes, we want to know. So log back in as 
                            often as you'd like.
                        </p>
                        <hr style={{ marginBottom: '20px', opacity: '0.3'}}/>
                        <p style={{ marginBottom: '20px'}}>
                            Let me know if you have any questions about Pulse or if you get stuck,
                            email <a href="mailto:support@projectai.com">support@projectai.com</a>
                            -So, let's get started.
                        </p>
                        <div className='center'>
                            <p className='center-btn'>
                                Login
                            </p>
                        </div>
                    </div>
                    <div className="footer">
                        <p className="apple-link">&copy; All Rights Reserved</p>
                        <b>Pulse by ProjectAI</b>
                    </div>
                </div>
                <div className="flex-item-right">
                    <LaunchTable
                        data={userResponse}
                        selectedUser={selectedUser}
                        setSelectedUsers={setSelectedUsers}
                        usersArr={usersArr}
                        handleSelected={handleSelected}
                        isAllSelected={isAllSelected}
                        setIsAllSelected={setIsAllSelected}
                        handleAllSelected={handleAllSelected}
                        
                    />
                    <div>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            previousLabel="<"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={10}
                            pageCount={pageCount}
                            renderOnZeroPageCount={null}
                            breakClassName='paginate-li'
                            breakLinkClassName='paginate-anchor'
                            containerClassName='paginate-container'
                            className="pagination"
                            pageClassName="paginate-li"
                            previousClassName="prev"
                            nextClassName="next"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
}

export default Launch;