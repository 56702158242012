import LabeledInput from "../../../Elements/LabeledInput"
import { capitalizeWords } from "../../../utilities"


const EditableOption = ({
    topic,
    comment,
    changeTopic,
    topicPlaceholder,
    commentPlaceholder,
    error,
    changeError
}) => {

    return <div className="main editable">
        <div className="bullet"></div>
        <div className="data">
            <LabeledInput
                type="text"
                className="topic"
                onChange={(e) => {
                    changeTopic(state => ({ ...state, newName: capitalizeWords(e.target.value) }))
                    changeError({ errField: '', errMsg: '' })
                }}
                placeholder={topicPlaceholder === "" ? "Specify your topic" : topicPlaceholder}
                value={topic}
                isUnderlined={true}
                error={error.errField === 'newTopicName' && error.errMsg}
            />
            <LabeledInput
                type="text"
                className="comment"
                onChange={(e) => {
                    changeTopic(state => ({ ...state, newComment: e.target.value }))
                    changeError({ errField: '', errMsg: '' })
                }}
                placeholder={commentPlaceholder === "" ? "Specify your comment" : commentPlaceholder}
                value={comment}
                isUnderlined={true}
                error={error.errField === 'newComment' && error.errMsg}
                
            />
        </div>
    </div>
}


export default EditableOption;