import './index.scss'
import TourWizard from "../../components/tourWizard"
import Layout from "../../components/Layout"
import { useNavigate } from "react-router"
import { Fragment } from "react"
import { BaseButton } from '../../Elements/Button'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const welcome_cards_data = [
    { title: 'Slide out menu', desc: 'Displays a list of sections to be configured, click on a title to jump to that section.' },
    { title: 'Toggle menu', desc: 'Click on the arrow to toggle the menu on and off.' },
    { title: 'Configuration progress', desc: 'You will see each step with their completion status.' },
    { title: 'Back', desc: 'Return to previous screen.' },
    { title: 'Next', desc: "Saves your selections and moves to the next screen. You can come back and change your selections at any time. <br/><strong>Note</strong>: Most screens will have a default set of pre-selected options that you can change, add to or simply accept by clicking the 'Next' button to move on to the next section." },
    { title: 'Help', desc: 'The help icon will display for any section that has important information to help you understand the concept of a section and how to complete it. You will be prompted to select this icon before completing the section.' },

]


const WelcomeCards = ({ num, title, desc }) => {
   

    return <div className="welcome-card">
        <div className="welcome-card-head">
            <div className="welcome-card-num">
                {num}
            </div>
            <div className="card-title">
                <span>{title}</span>
            </div>
        </div>
        <div className="welcome-card-desc" dangerouslySetInnerHTML={{ __html: desc }} />
    </div>
}


const Welcome = () => {
    function createCookie(value) {
        var date = new Date();
        date.setTime(date.getTime() + (31 * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
        document.cookie = value + "=" + 'tourguide1' + expires + "; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useState()
    const [showTour,setShowTour]=useState(false)
    const [checked,setChecked]=useState(false)
    const { useremail , userId} = useSelector(state => state.user)
    
  
    useEffect(() => {
		if (readCookie(userId+'tour1')=='tourguide1') {
			createCookie( userId+'tour1')
           
		}
		else {
			if (!checked) {
				setShowTour(true)

			}
			setChecked(!checked)
			createCookie(userId+'tour1')
		}

	

}, [])

    return <Layout
            onClickNext={() => navigate('/setup/project-attributes')} 
            setUserInfo={setUserInfo}
            isWelcomepage={true}
            setShowTour={setShowTour}
            checked={checked}
            setChecked={setChecked}
            >
              
            <div className="header-section">
                <h1>Welcome {userInfo?.first_name},</h1>
                <span >
                    The Pulse Wizard will walk you through how to configure your Project.<br />
                    Before you get started here are a few things you need to know about using the Wizard.<br/>
        
                    </span>
            </div>
            
            <div className="welcome-content">
                {
                    welcome_cards_data.map((item, idx) => {
                        return <Fragment key={item.title}>
                            <WelcomeCards
                                num={idx + 1}
                                title={item.title}
                                desc={item.desc}
                            />
                        </Fragment>
                    })
                }
            </div>
             { showTour && <TourWizard setShowTour={setShowTour} showTour={showTour} setChecked={setChecked} checked={checked}/>}
         
          
    </Layout>
}

export default Welcome;