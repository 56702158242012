import './index.scss';
import logo from '../../assets/images/project-ai-logo-light.png';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosInstance from '../../api/axios';
import { InfoText } from '../../Elements/MessageBlocks';
import { fontWeight } from '@mui/system';
import { Link } from '@mui/material';
import { scrollToBottom } from '../../utilities';


const Activation = () => {

    const params = useParams();

    const [pageContent, setPageContent] = useState();
    const [iconType, setIconType] = useState();
    const [status, setStatus] = useState();

    useEffect(() => {
        if('uid' in params && 'token' in params){
            axiosInstance.post('api/v1/auth/users/activation/', {
                uid : params.uid,
                token : params.token
            }).then((res) => {
                setPageContent('Your email has been verified successfully. You can start using Pulse app by logging in using your credentials.')
                setIconType('fa-regular fa-circle-check activation-status-icon activation-success-status-icon')
                setStatus('Great!')
            }).catch((error) => {
                Object.keys(error.response.data).map((key) => {
                    setPageContent(error.response.data[key])
                    setIconType('fa-regular fa-circle-xmark activation-status-icon activation-fail-status-icon')
                    setStatus('Sorry!')
                    return true;
                })
            })
        }    
    }, [params]);

    return <div className="register-section">
        <div className="left-section" >
            <div className="logo">
                <img src={logo} width={100} height={50} alt="logo" />
            </div>
            <div className='headline'>
                <span>Successful projects start with people</span>
            </div>
            {
                window.innerWidth < 769 && <> <div className='heading-text-sm'>
                    <h1>Collective Insights</h1>
                    <span>
                        Pulse by ProjectAI helps project managers make <br />
                        sense of people’s collective insights for better project outcomes.
                    </span>
                </div>
                    <div className='scroll-to-bottom-icon' onClick={scrollToBottom}>
                        <i className="fa-solid fa-chevron-down"></i>
                    </div>
                </>
            }
        </div>
        <div className='right-section'>
            <div>
                <div className='heading'>
                    <div style={{ textAlign: 'center'}}>
                        <i className={iconType}></i>
                    </div>
                    <h1>{status}</h1>
                    <span>
                        <InfoText messageText={pageContent} variant='h6' color='#000000'></InfoText>
                    </span>
                    <span>
                        <InfoText messageText={<b style={{fontWeight : 'normal'}}>Click <Link href="/login">here</Link> to login</b>} variant='h6' color='#000000'></InfoText>
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export default Activation;