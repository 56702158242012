import './index.scss'
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Layout from "../../components/Layout";
import LabeledInput from "../../Elements/LabeledInput";
import { projectAttrFormValidator } from "./utility";
import { ApiClient } from '../../api/client';
import { useSelector, useDispatch } from 'react-redux';
import { projectAction, projectNameAction } from '../../redux/actions';
import { useSearchParams } from 'react-router-dom';


const ProjectAttributes = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const projectState = useSelector(state => state.project)
    const { isBillingAdmin } = useSelector(state => state.user)
    const [isFirstTimeUser, setFirstTimeUser] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [userType,setUserType]=useState()
    const [maxSeats, setMaxSeats] = useState(false)
    const [showSeats, setShowSeats] = useState(false)
    const [isTrial, setIsTrial] = useState(false)

    let dispatch = useDispatch()
    let apiUrl = '';
    const [defaultProjectAttributes, setDefaultProjectAttributes] = useState();
    const [userOrg, setUserOrg] = useState('')
    const [projectId, setProjectId] = useState();
    const [formData, setFormData] = useState({
        organisationName: '',
        projectName: '',
        projectManager: '',
        projectCode: '',
        projectDescription: '',
        projectSeats: 0
    })
    const [isMaxSeats, setIsMaxSeats] = useState(false)

    const {
        organisationName,
        projectName,
        projectManager,
        projectCode,
        projectDescription,
        projectSeats
    } = formData

    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const { errField, errMsg } = err
    const handleInputChange = (e) => {
        setErr({ ...err, ...{ errField: '', errMsg: '' } })
        setFormData(prevState => (
            { ...prevState, [e.target.name]: e.target.value }
        ))
        if (e.target.name === 'organisationName') {
            setUserOrg(e.target.value)
        }
        if (e.target.name === 'projectSeats') {
            setIsMaxSeats(false)
            if (e.target.value == maxSeats) {
                setIsMaxSeats(true)
            }
        }
    }

    const handleSubmit = (e) => {
        if (!projectId) formData['organisationName'] = userOrg
        let error = projectAttrFormValidator(formData, maxSeats)
        if (error) {
            setErr({ errField: error['field'], errMsg: error['err'] })
        }
        else {
            const client = ApiClient()
            let postData = {
                project_organization: formData.organisationName,
                project_name: formData.projectName,
                project_manager: formData.projectManager,
                project_code: formData.projectCode,
                project_description: formData.projectDescription,
            }
            if (projectId) {
                apiUrl = 'api/v1/survey/' + projectId + '/'
                if (isBillingAdmin){
                    postData['seats_purchased'] = formData.projectSeats
                }
                client.put(apiUrl, postData).then((res) => {
                    navigate({
                        pathname: '/setup/segments',
                        search: '?projectId=' + projectId
                    }, { state: { projectId } })
                }).catch((error) => {
                    Object.keys(error?.response?.data?.data).map((key) => {
                        setErr({ ...err, ...{ errField: key, errMsg: error.response.data.data[key] } })
                        return true;
                    })
                })
            } else {
                apiUrl = 'api/v1/survey/'
                postData['seats_purchased'] = formData.projectSeats
                client.post(apiUrl, postData).then((res) => {
                    let id = res.data.projectId
                    dispatch(projectAction(id))
                    dispatch(projectNameAction(res.data.projectName))
                    client.post('api/v1/journeysteps/', {
                        project: id,
                        step_id: 1,
                        step_name: 'Project Attributes'
                    })
                    navigate({
                        pathname: '/setup/segments',
                        search: '?projectId=' + id
                    }, { state: { projectId: id } })
                }).catch((error) => {
                    Object.keys(error?.response?.data?.data).map((key) => {
                        setErr({ ...err, ...{ errField: key, errMsg: error.response.data.data[key] } })
                        return true;
                    })
                })
            }
        }

    }
   useEffect(()=>{
        const client = ApiClient()
        client
        .get(`/api/v1/billing-user-detail/`)
        .then(response => {
            
            if(response.data==0)
            {
                setUserType('newuser')
            }
            else if(response.data==2)
            {
                setUserType('stripe_enterprise')
            }
            else{
                setUserType('trail')
            }
           
        })
    },[])

    
    

    useEffect(() => {
        const client = ApiClient()
        client.get('/api/v1/auth/users/me/')
            .then((res) => {
                let is_first_user = (!res.is_billing_admin && !res.is_admin_user && !res.is_invited_user)
                setFirstTimeUser(is_first_user)
                // setDefaultProjectAttributes(res)
                setUserOrg(res?.organization_name)
                setShowSeats(is_first_user || res.is_billing_admin)

                setMaxSeats(res?.max_seats)

                if (is_first_user || res.is_billing_admin) {
                    setFormData(state => ({
                        ...state,
                        projectSeats: res?.max_seats
                    }))
                }
                if (res.is_billing_admin) {
                    client
                        .get(`/api/v1/free-trial-check/`)
                        .then(response => {
                            setIsTrial(is_first_user || !res?.has_subscription)
                        })
                }


                let API_URL = '/api/v1/survey/'
                let newProjectId = searchParams.get('projectId')
                setProjectId(newProjectId)
                if (!location?.state?.isNewProject && newProjectId) {
                    API_URL = API_URL + newProjectId + '/'
                    client.get(API_URL).then((response) => {
                        if (response?.data) {
                            setProjectId(response.data?.projectId)
                            setFormData(prevState => (
                                { ...prevState, ...response.data }
                            ))
                            if (response.data?.projectSeats) {
                                setIsMaxSeats(response.data?.projectSeats === res?.max_seats)
                            }
                        }
                    })
                }

            }).catch((error) => {

            })



    }, [])

    let form_fields = [
        { type: 'text', name: 'organisationName', label: 'Organisation Name', value: projectId ? organisationName : userOrg },
        { type: 'text', name: 'projectName', label: 'Project Name', value: projectName },
        { type: 'text', name: 'projectManager', label: 'Project Manager', value: projectManager },
        { type: 'text', name: 'projectCode', label: 'Project Code', value: projectCode },
        { type: 'text', name: 'projectDescription', label: 'Project Description', value: projectDescription },
    ]
    let optionalField = ['projectCode', 'projectDescription']

    return <Layout
        active_page={'Project Attributes'}
        onClickNext={handleSubmit}
        onClickBack={() => navigate('/app/my-projects')}
        onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#ProjectAttributes','_blank')}
        isNextDisabled={(projectId ? !organisationName.length : !userOrg.length) || !projectName.length || !projectManager}
        isBackShown={false}
    >
        <div className="project-attr-container">
            <div className="content">
                <div className="heading">
                    <h1>Let's start with a few details about the project</h1>
                </div>
                <div className="project-attr-form-container">
                    <form>
                        {
                            form_fields.map(inp => {
                                return <Fragment key={inp.name}>
                                    <LabeledInput
                                        type={inp.type}
                                        name={inp.name}
                                        label={inp.label}
                                        value={inp.value}
                                        onChange={handleInputChange}
                                        error={errField === inp.label && errMsg}
                                        required={!optionalField.includes(inp.name)}
                                        isOptional={optionalField.includes(inp.name)}
                                        textarea={inp.name === 'projectDescription'}
                                    />
                                </Fragment>
                            })
                        }
                        {
                             (userType=='trail' || userType=='stripe_enterprise') && <>
                                <div className='slider-limit-input-label' style={{justifyContent:'unset'}}>
                                <label htmlFor={'project-seats-input'} className='no_of_seat' >Set seat limit for this project ? </label>
                                <div className='slider-limit-input-label' >
                                   
                                   <input
                                       style={{margin:'0px 15px 0 10px'}}
                                       id="project-seats-input"
                                       type='checkbox'
                                       name='ProjectSeatsLimit'
                                       onChange={() => {
                                           setIsMaxSeats(!isMaxSeats)
                                           if (!isMaxSeats) {
                                               setFormData(prevState => (
                                                   {
                                                       ...prevState,
                                                       projectSeats: maxSeats
                                                   }
                                               ))
                                           }
                                       }}
                                       checked={isMaxSeats}
                                   />
                               </div>
                               {isMaxSeats &&  <div ><LabeledInput
                                    type={'number'}
                                    name={'projectSeats'}
                                    //label={'No. of Seats'}
                                    value={projectSeats}
                                    onChange={handleInputChange}
                                    error={errField === 'Project Seats' && errMsg}
                                    min={10}
                                    max={maxSeats}
                                />
                                </div>}
                                </div>

                              
                            </>
                        }
                        {
                            (!(userType=='stripe_enterprise') && !(userType=='newuser') ) && <p style={{ fontSize: 10, color: '#ff0000' }}>*You can only assign {maxSeats} seats during trial period</p>
                        }
                    </form>
                </div>
            </div>
        </div>
    </Layout>
}

export default ProjectAttributes;