import './index.scss'
import { useEffect, useRef, useState } from 'react';
import UserLogo from './../../assets/svgs/user.svg'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { useNavigate } from 'react-router-dom';
import { ApiClient } from '../../api/client';
import { BaseButton } from '../../Elements/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector, useDispatch } from 'react-redux';
import { userHasSubscription } from '../../redux/actions/userActions';
import { usernameAction, projectResetAction, resetUserStateAction, userIsBillingAdminAction, userIsAdminAction } from '../../redux/actions';


const AdminTopBar = ({ isSidebar, toggleSidebar, topBarTitle = 'Administration', setUserInfo, projectName='' }) => {

    const userOptionsRef = useRef(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [userOptionsVisible, setUserOptionsVisible] = useState(false)
    const { username } = useSelector(state => state.user)

    const [userData, setUserData] = useState();

    const handleClickOutside = (event) => {
        if (userOptionsRef.current && !userOptionsRef.current.contains(event.target)) {
            setUserOptionsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        // if (!username) {
            const client = ApiClient()
            client.get('/api/v1/auth/users/me/').then((response) => {
                setUserData(response)
                dispatch(userIsBillingAdminAction(response.is_billing_admin))
                dispatch(userIsAdminAction(response.is_admin_user))
                if (typeof (setUserInfo) === 'function') {
                    setUserInfo(response)
                }
            }).catch((error) => {

            })
        // }
    }, [])
    
    useEffect(() => {
        if(userData?.first_name) {
            dispatch(usernameAction(userData?.first_name + ' ' + userData?.last_name))
        }
        dispatch(userHasSubscription(userData?.has_subscription))
    }, [userData, dispatch])

    const handleLogout = () => {
        const client = ApiClient()
        client.post('/api/v1/auth/logout/', {
            refresh_token: localStorage.getItem('refresh_token')
        })
            .then(response => {
                localStorage.clear()
                navigate('/login')
                dispatch(projectResetAction())
                dispatch(resetUserStateAction())

            })
            .catch(err => {

                console.log(err)

                console.log('error occured')

                localStorage.clear()
                navigate('/login')
                dispatch(projectResetAction())
                dispatch(resetUserStateAction())
            })
    }

    return <div className='topbar'>
        <div className='title'>
            {
                !isSidebar && <div className='menu-open-top-bar'>
                    <BaseButton customStyles={{ padding: '4px' }}
                        text={<MenuIcon />}
                        onClick={toggleSidebar}
                    />
                </div>
            }
            <div className='topbar-title'>
                <h1>{topBarTitle}</h1>
                {projectName && <h2 className='project-name'>{projectName}</h2>}
            </div>

        </div>
        <div className='user-profile'>
            <div
                className='user-profile-div'
                onClick={() => setUserOptionsVisible(!userOptionsVisible)}
            >
                <div className='profile-pic'>
                    <img src={UserLogo} alt='user-profile' />
                </div>
                <div className='profile-title'>
                    <span>{username}</span>
                </div>

                {userOptionsVisible ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}

            </div>
            {
                userOptionsVisible && <div ref={userOptionsRef} className='user-options'>
                    <ul>
                        <li onClick={() => navigate('/setup/profile')}>
                            Edit Profile
                        </li>
                        <li onClick={handleLogout}>
                            Logout
                        </li>
                    </ul>
                </div>
            }
        </div>
    </div>
}


export default AdminTopBar;