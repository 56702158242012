import { useEffect, useState } from 'react'
import './index.scss'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.black,
        // boxShadow: theme.shadows[1],
        // fontSize: 12,
    },
}));



const SelectDropDown = ({
    title,
    errorTitle,
    defaultOptionText,
    defaultValue,
    label,
    isRequired,
    optionList,
    valueField,
    itemField,
    handleOnChange,
    keys,
    selectedValues,
    index,
    defaultOptionSelected = true,
    hasToolTip,
    toolTipTitle,
    className,
}) => {

    const [value, setValue] = useState(defaultValue)

    const handleChange = (e) => {
        setValue(e.target.value)
        if (keys) {
            handleOnChange(keys, e.target.value)
        } else if (selectedValues) {
            handleOnChange(index, e.target.value)
        } else {
            handleOnChange(e.target.value)
        }
    }

    useEffect(() => {
        setValue(defaultValue)
        if (typeof (index) !== 'undefined') {
            handleOnChange(index, defaultValue)
        }
    }, [defaultValue])

    let hasError = errorTitle && title === errorTitle
    return <div className='select-option-div' >
        {
            label && <label className='select-option-label' >
                <div >
                    {label}
                    {isRequired && <span style={{ color: 'red', fontSize: '0.75rem' }}>*</span>}
                </div>
                {
                    hasToolTip && <span style={{ flexGrow: 1, textAlign: 'right' }}>
                        <LightTooltip
                            className="this is classname"
                            title={toolTipTitle}
                            placement="right"
                            arrows
                        >
                            <InfoOutlinedIcon color='primary' fontSize='100px' />
                        </LightTooltip>
                    </span>
                }
            </label>
        }
        <select
            style={{marginTop:'-3px',height:'30px'}}
            className={`select-option ${className? className :''} ${hasError ? 'select-option-error' : ''}`}
            onChange={handleChange}
            value={value}
            autoFocus={hasError}
        >
            {
                !defaultOptionSelected && <option
                    className='option'
                    value={''}
                >
                    {defaultOptionText}
                </option>
            }
            {
                optionList.map((item, index) => {
                    return <option
                        key={(valueField) ? item[valueField] : index}
                        className='option'
                        value={(valueField) ? item[valueField] : index}
                    >
                        {item[itemField]}
                    </option>
                })
            }

        </select>

        {
            hasError && <p style={{ color: '#ff0000', fontSize: '10px' }}>*This field is required.</p>
        }

    </div>
}

export default SelectDropDown;