import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { ApiClient } from "../../../api/client";
import AOQuestionsList from "../AOQuestionsList";
import AddStakeHolderForm from "./AddStakeholderForm";
import "./index.scss";
import MyStakeholder from "./myStakeholders";
import SearchSection from "./searchSection";
import {
    ADD_STAKEHOLDER,
    MY_STAKEHOLDERS,
    MappingIcon,
    PROJECT_USERS_LIST,
    QUESTIONS_LIST,
    UPDATE_STAKEHOLDER,
    mapsColors
} from "./utils";




const UserSection = ({ maxParentHeight,setView,view, ...args }) => {

    const aoSectionContentRef = useRef()
    const [aoSectionHeight, setAOSectionHeight] = useState()

    const client = ApiClient()
    const { projectId } = useSelector(state => state.project)

    const [screenName, setScreenName] = useState(MY_STAKEHOLDERS)
    const [myMaps, setMyMaps] = useState([])
    const [projectMaps, setProjectMaps] = useState([])
    const [editUserDetails, setEditUserDetails] = useState()
    const [stakeholderCount, setStakeholderCount] = useState(0)

    const [selectedUser, setSelectedUser] = useState()

    const [filterVisible, setFilterVisibility] = useState(false)

    const [filterOptions, setFilterOptions] = useState({
        myMapsIds: [],
        projectMapsIds: [],
    })
    const { myMapsIds, projectMapsIds } = filterOptions

    useEffect(() => {
        if (projectId) {
            client
                .get('/api/v1/ao-mapping/' + projectId + '/')
                .then((response) => {
                    if (response?.data) {
                        setMyMaps(response.data['my_maps'])
                        setProjectMaps(response.data['project_maps'])
                        setFilterOptions({
                            myMapsIds: response.data['my_maps']?.map(item => item.segment_my_map_id),
                            projectMapsIds: response.data['project_maps']?.map(item => item.segment_project_map_id),
                        })
                    }
                })
        }
    }, [])

    useEffect(() => {
        if(args?.mapProjectUserId){
            setSelectedUser(args?.mapProjectUserId)
            setScreenName(QUESTIONS_LIST)
        }
    }, [args?.mapProjectUserId])
    
    useEffect(() => {
        setAOSectionHeight(aoSectionContentRef?.current?.clientHeight ? aoSectionContentRef?.current?.clientHeight : 0)
    }, [filterVisible, screenName,])

    const handleScreenChange = (screen) => {
        setScreenName(screen)
    }

    const handleSelectMaps = (map_name, id) => {
        let mapArray = map_name === 'myMapsIds' ? myMapsIds : projectMapsIds
        if (mapArray.includes(id)) {
            setFilterOptions(state => (
                {
                    ...state,
                    [map_name]: state[map_name].filter(item => item !== id)
                }
            ))
        }
        else {
            setFilterOptions(state => (
                {
                    ...state,
                    [map_name]: [...state[map_name], id]
                }
            ))
        }
    }


    return <div className="users-section-container">
        {
            (
                screenName === MY_STAKEHOLDERS
                ||
                screenName === PROJECT_USERS_LIST
            ) && <div ref={aoSectionContentRef} className="ao-section-content">
                <div className="user-section-header">
                    <span className="title">{screenName === PROJECT_USERS_LIST ? "Search Stakeholders" : "My Stakeholders"}</span>
                    <div className="action-container">
                        {
                            screenName === MY_STAKEHOLDERS && <span
                                className="filter-action"
                                onClick={() => setFilterVisibility(!filterVisible)}
                            >
                                <FilterListIcon /> Filter
                            </span>
                        }
                        <span className="search-list-action" onClick={() => {
                            setFilterVisibility(false)
                            handleScreenChange(screenName === PROJECT_USERS_LIST ? MY_STAKEHOLDERS : PROJECT_USERS_LIST)
                        }}>
                            {screenName === PROJECT_USERS_LIST ? "My Stakeholders" : <><SearchIcon />Search</>}
                        </span>
                    </div>
                </div>
                {
                    screenName === MY_STAKEHOLDERS && <div className="stakeholder-count">
                        {stakeholderCount} {stakeholderCount > 1 ? 'Stakeholders' : 'Stakeholder'}
                    </div>
                }
            </div>
        }
        {
            screenName === MY_STAKEHOLDERS && <div
                className='my-stakeholder-div'
                style={{ maxHeight: maxParentHeight - aoSectionHeight - 30 }}
            >
                {
                    filterVisible && <div className="ao-filters-options">
                        <div className='ao-maps'>
                            <span><b>My Map:</b></span>
                            <div className='ao-maps-options-list'>
                                {
                                    myMaps.map((item, idx) => {
                                        let isSelected = myMapsIds.includes(item.segment_my_map_id)
                                        return <MappingIcon
                                            key={item.segment_my_map_id}
                                            icon={item.icon_path}
                                            title={item.segment_my_map_name}
                                            onClick={() => handleSelectMaps('myMapsIds', item.segment_my_map_id)}
                                            isSelected={isSelected}
                                            styles={isSelected ? { backgroundColor: mapsColors[idx] } : {}}
                                            screen={'filter'}
                                        />
                                    })
                                }
                            </div>
                        </div>
                        <div className='ao-maps'>
                            <span><b>Project Map:</b></span>
                            <div className='ao-maps-options-list'>
                                {
                                    projectMaps.map((item, idx) => {
                                        let isSelected = projectMapsIds.includes(item.segment_project_map_id)
                                        return <MappingIcon
                                            key={item.segment_project_map_id}
                                            icon={item.icon_path}
                                            title={item.segment_project_map_name}
                                            onClick={() => handleSelectMaps('projectMapsIds', item.segment_project_map_id)}
                                            isSelected={isSelected}
                                            styles={isSelected ? { backgroundColor: mapsColors[idx] } : {}}
                                            screen={'filter'}
                                        />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }

                <MyStakeholder
                    setScreenName={handleScreenChange}
                    myMaps={myMaps.filter(item => myMapsIds.includes(item.segment_my_map_id))}
                    projectMaps={projectMaps.filter(item => projectMapsIds.includes(item.segment_project_map_id))}
                    setStakeholderCount={setStakeholderCount}
                    setSelectedUser={setSelectedUser}
                    maxAvailableHeight={maxParentHeight - aoSectionHeight}
                />
            </div>
        }

        {
            screenName === PROJECT_USERS_LIST && <SearchSection
                setScreenName={handleScreenChange}
                setEditUserDetails={setEditUserDetails}
                maxAvailableHeight={maxParentHeight - aoSectionHeight}
            />
        }
        {
            screenName === ADD_STAKEHOLDER && <AddStakeHolderForm
                setScreenName={handleScreenChange}
                myMaps={myMaps}
                projectMaps={projectMaps}
                maxAvailableHeight={maxParentHeight - aoSectionHeight}
                setReloadMap={args.setReloadMap}
            />
        }
        {
            screenName === UPDATE_STAKEHOLDER && <AddStakeHolderForm
                setScreenName={handleScreenChange}
                myMaps={myMaps}
                projectMaps={projectMaps}
                isEdit={true}
                user={editUserDetails}
                maxAvailableHeight={maxParentHeight - aoSectionHeight}
                setReloadMap={args.setReloadMap}
            />
        }

        {
            screenName === QUESTIONS_LIST && selectedUser && <AOQuestionsList
                setScreenName={handleScreenChange}
                user={selectedUser}
                setView={setView}
                view={view}
                myMaps={myMaps}
                projectMaps={projectMaps}
                maxAvailableHeight={maxParentHeight - aoSectionHeight}
                setReloadMap={args.setReloadMap}
                resetUserNodeClick={args?.resetUserNodeClick}
            />
        }
    </div>
}


export default UserSection