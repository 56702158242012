import './QuestionRowContent.scss'


const QuestionFieldDiv = ({ label, text }) => {

    return <div className="question-field-div">
        <div className='field-label'>{label}</div>
        <div className='field-value'>{text}</div>
    </div>
}

const TextQuestionRowContent = ({ questionData }) => {
    
    return <div className='question-row-content'>
        <div className='top-content'>
            <QuestionFieldDiv
                label={'Comment Prompt'}
                text={questionData?.comment_prompt}
            />
        </div>
        <div className='bottom-content'>
        {(questionData?.skip_option.length > 0) &&
            questionData?.skip_option.map((value, index) => {
                return <QuestionFieldDiv
                    label={'Skip Option ' + (index + 1)}
                    text={value.skip_option_name}
                />
            })
        }
        </div>
    </div>
}

const SliderQuestionRowContent = ({ questionData }) => {

    return <div className='question-row-content'>
        <div className='top-content'>
            <QuestionFieldDiv
                label={'Slider Left'}
                text={questionData?.slider_text_left}
            />
            <QuestionFieldDiv
                label={'SliderRight'}
                text={questionData?.slider_text_right}
            />
            <QuestionFieldDiv
                label={'Comment Prompt'}
                text={questionData?.comment_prompt}
            />
        </div>
        <div className='bottom-content'>
        {(questionData?.skip_option.length > 0) &&
            questionData?.skip_option.map((value, index) => {
                return <QuestionFieldDiv
                    label={'Skip Option ' + (index + 1)}
                    text={value.skip_option_name}
                />
            })
        }
        </div>
    </div>
}


const MultiTopicQuestionRowContent = ({ questionData }) => {

    return <div className='question-row-content'>
        <div className='top-content'>
            <QuestionFieldDiv
                label={'Topic Prompt'}
                text={questionData?.topic_prompt}
            />
            <QuestionFieldDiv
                label={'Comment Prompt'}
                text={questionData?.comment_prompt}
            />
        </div>
        <div className='bottom-content'>
        {(questionData?.skip_option.length > 0) &&
            questionData?.skip_option.map((value, index) => {
                return <QuestionFieldDiv
                    label={'Skip Option ' + (index + 1)}
                    text={value.skip_option_name}
                />
            })
        }
        </div>
    </div>
}


const MultiChoiceQuestionRowContent = ({ questionData }) => {

    return <div className='question-row-content'>
        <div className='top-content'>
            {(questionData?.option.length > 0) &&
                questionData?.option.map((value, index) => {
                    return <QuestionFieldDiv
                        key={(index + 1)}
                        label={'Option ' + (index + 1)}
                        text={value?.option_name || value?.option}
                    />
                })
            }
            <QuestionFieldDiv
                label={'Comment Prompt'}
                text={questionData?.comment_prompt}
            />
        </div>
        <div className='bottom-content'>
        {(questionData?.skip_option.length > 0) &&
            questionData?.skip_option.map((value, index) => {
                return <QuestionFieldDiv
                    label={'Skip Option ' + (index + 1)}
                    text={value.skip_option_name}
                />
            })
        }
        </div>
    </div>
}

export {
    TextQuestionRowContent,
    SliderQuestionRowContent,
    MultiTopicQuestionRowContent,
    MultiChoiceQuestionRowContent
}