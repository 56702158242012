import { useEffect, useState } from 'react'
import { BaseButton } from '../../Elements/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import LabeledInput from '../../Elements/LabeledInput';
import { ApiClient } from '../../api/client';
import { trash } from '../../assets/svgs/questions';
import { downloadBulk, downloadTemplate, uploadUsers } from '../../assets/svgs/users';
import { exportToCSV, exportToEXCEL } from './../../utilities';
import Table from './Table';
import './index.scss';
import ProjectUsersUploadComponent from '../ProjectUsersUpload';


const UserTableQuickActions = ({
    handleAddUser,
    handleDeleteSelected,
    handleDownloadBulk,
    handleDownloadTemplate,
    handleSearchUser,
    handleInvitation,
    searchedUserValue,
    selectedUsers,
    allowQuickAction,
    editMode,
    setShowUserUploadComponent
}) => {

    return <>
        <span className='table-title'>
            <LabeledInput
                type='text'
                name='search'
                value={searchedUserValue}
                onChange={handleSearchUser}
                error={false}
                required={false}
                placeholder='Search for a Name'

            />
        </span>
        <div className='user-quick-actions'>
            {((editMode && allowQuickAction) || (!editMode && !allowQuickAction)) &&
                <BaseButton
                    variant='text'
                    className={'delete action'}
                    text={<>
                        <img src={trash} alt='delete' />
                        <span>Delete</span>
                    </>}
                    onClick={handleDeleteSelected}
                    isDisabled={!selectedUsers.length}
                />
            }
            <BaseButton
                variant='text'
                className={'download-bulk action'}
                text={<>
                    <img src={downloadBulk} alt='Download Bulk' />
                    <span>Download Bulk</span>
                </>}
                onClick={handleDownloadBulk}
                isDisabled={!selectedUsers.length}
            />
            {((editMode && allowQuickAction) || (!editMode && !allowQuickAction)) &&
                <BaseButton
                    variant='text'
                    className={'download-template action'}
                    text={<>
                        <img src={downloadTemplate} alt='Download Template' />
                        <span>Download Template</span>
                    </>}
                    onClick={handleDownloadTemplate}
                />
            }

            {((editMode && allowQuickAction) || (!editMode && !allowQuickAction)) &&
                <BaseButton
                    variant='text'
                    className={'upload-users action'}
                    text={
                        <label htmlFor='users-file-upload'>
                            <img src={uploadUsers} alt='Upload Users' />
                            <span>Upload Users</span>
                        </label>
                    }
                    onClick={() => setShowUserUploadComponent(true)}
                />
            }
            {((editMode && allowQuickAction) || (!editMode && !allowQuickAction)) &&
                <span className='add-new-user'>
                    <BaseButton
                        variant='outlined'
                        className={'add-new-user-btn'}
                        text={<> + Add New</>}
                        onClick={() => handleAddUser(true)}
                    />
                </span>
            }
            <span className='invite-user'>
                {editMode &&
                    <BaseButton
                        variant='outlined'
                        className={'invite-user-btn'}
                        text={(allowQuickAction) ? <> <ArrowRightAltIcon /> Send Invitation</> : <> <ArrowRightAltIcon /> Resend Invitation</>}
                        onClick={() => (allowQuickAction) ? handleInvitation(undefined, 'send') : handleInvitation(undefined, 'resend') }
                    />
                }
            </span>
        </div>
    </>
}


const UsersTable = ({
    userResponse,
    handleAddUser,
    handleUserEdit,
    handleSearchUser,
    searchedUserValue,
    segmentsData,
    handleSorting,
    sort,
    setDeleteBulkPopup,
    isAllSelected,
    selectedUsers,
    updateSelectedUser,
    setUserSelect,
    projectId,
    allowQuickAction,
    editMode,
    setUserResponse,
    activeUserTypeQueryParams,
    segmentsFilters,
    setSegmentFilters,
    setErrMsg
}) => {

    const [showUserUploadComponent, setShowUserUploadComponent] = useState(false)
    const [showInviteHeader, setShowInviteHeader] = useState(false)
    
    const handleInvitation = (userId, action) => {
        const client = ApiClient()
        setErrMsg('')
        
        let API_URL = '/api/v1/project-user-invitation/' + projectId + '/'
        if (userId) {
            API_URL = API_URL + '?toggle=on&project_user_id=' + userId + ((action) ? '&action=' + action : '')
        } 
        else if (isAllSelected) {
            API_URL = API_URL + '?all_selected=' + isAllSelected + ((action) ? '&action=' + action : '')
        }
        else if (selectedUsers.length) {
            API_URL = API_URL + '?project_user_ids=' + selectedUsers + ((action) ? '&action=' + action : '')
        }
        client.put(API_URL)
            .then((response) => {
                if (response?.data) {
                    setUserResponse({
                        users: response.data,
                        nextLink: response.next,
                        prevLink: response.prev,
                        usersCount: response.count
                    })
                }
                if(response?.err){
                    setErrMsg(response.err)
                    setUserResponse({
                        users: response.data,
                        nextLink: response.next,
                        prevLink: response.prev,
                        usersCount: response.count
                    })
                }
            })
    }

    const handleDeleteSelected = () => {
        setDeleteBulkPopup(true)
    }
    const getRecursiveData = async (base_url, data = [], page = 1) => {
        if (!page) return data
        const client = ApiClient()
        let response = await client.get(base_url + page)
        // if (response?.data) {
        //     setBulkData(prevState => [...prevState, ...response.data])
        //     setHasNext(response.next ? true : false)
        // }
        let nextPage = response.next ? page + 1 : null
        return getRecursiveData(base_url, [...data, ...response.data], nextPage)
    }

    const handleDownloadBulk = async () => {

        let params = isAllSelected ? `?all_selected=${isAllSelected}` : `?project_user_ids=${selectedUsers}`
        params = params + '&page_size=50' + activeUserTypeQueryParams
        if (segmentsFilters.checkedSegments.length > 0) {
            let segments_list_string = segmentsFilters.checkedSegments.join(",")
            let segmentsTypes_list_string = segmentsFilters.filteredSegmentTypes.join(",")

            params = params + '&segments=' + segments_list_string + '&segmentTypes=' + segmentsTypes_list_string
        }
        let API_URL = `/api/v1/bulk-download-users/${projectId}/${params}&page=`
        let data = await getRecursiveData(API_URL)
        exportToCSV(data, 'users')
    }

    const handleDownloadTemplate = () => {
        const client = ApiClient()
        client.getBlob('api/v1/project-users-template/' + projectId + '/')
            .then(response => {
                exportToEXCEL(response.data, 'Users Template', 'blob')
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        setShowInviteHeader(allowQuickAction)
    }, [allowQuickAction])

    return <div className='users-table-container'>
        <div className='container-header'>
            <UserTableQuickActions
                handleAddUser={handleAddUser}
                handleDeleteSelected={handleDeleteSelected}
                handleDownloadBulk={handleDownloadBulk}
                handleDownloadTemplate={handleDownloadTemplate}
                handleSearchUser={handleSearchUser}
                handleInvitation={handleInvitation}
                searchedUserValue={searchedUserValue}
                selectedUsers={selectedUsers}
                allowQuickAction={allowQuickAction}
                editMode={editMode}
                setShowUserUploadComponent={setShowUserUploadComponent}
                />
        </div>

        <div className='table-box'>
            <Table
                userResponse={userResponse}
                handleUserEdit={handleUserEdit}
                segmentsData={segmentsData}
                handleSorting={handleSorting}
                sort={sort}
                handleInvitation={handleInvitation}
                setUserSelect={setUserSelect}
                isAllSelected={isAllSelected}
                selectedUsers={selectedUsers}
                updateSelectedUser={updateSelectedUser}
                setUserResponse={setUserResponse}
                projectId={projectId}
                activeUserTypeQueryParams={activeUserTypeQueryParams}
                segmentsFilters={segmentsFilters}
                setSegmentFilters={setSegmentFilters}
                showInviteHeader={showInviteHeader}
                editMode={editMode}
            />
        </div>
        {
            showUserUploadComponent &&
            <ProjectUsersUploadComponent
                projectId={projectId}
                handleModal={() => {
                    setShowUserUploadComponent(false)
                    const client = ApiClient()
                    let API_URL = `/api/v1/project-user/${projectId}/?page_size=50`
                    client.get(API_URL)
                        .then((response) => {
                            if (response?.data) {
                                setUserResponse({
                                    users: response.data,
                                    nextLink: response.next,
                                    prevLink: response.prev,
                                    usersCount: response.count
                                })
                            }
                        })
                }}
            />
        }
    </div>
}


export default UsersTable;




