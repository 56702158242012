import { useEffect, useState, } from 'react'
import { ApiClient } from '../../api/client'
import './index.scss'
import { useSelector } from 'react-redux'
import SelectDropDown from '../../Elements/SelectDropDown'
import { BaseButton } from '../../Elements/Button'
import Pgraph from './Graph/Pgraph'
import { Popover } from '@mui/material'
import { MaterialUISwitch } from '../../Elements/Switch'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import PeopleGalaxyGraph from './amPGraph'

const PeopleGalaxy2 = () => {
    const navigate = useNavigate()
    const [level1_list, setLevel1_list] = useState([{
        "segment_type_name": "None",
        "segment_type_id": 0
    }]);
    const [level2_list, setLevel2_list] = useState([{
        "segment_type_name": "None",
        "segment_type_id": 0
    }]);
    const { isAdmin } = useSelector(state => state.user)
    const [subdriverlist, setSubdriverlist] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [errorMsg, setError] = useState('');
    const [combineSegmentNames, setCombineSegmentNames] = useState([]);
    const [selectedlevel1, setSelectedlevel1] = useState('None');
    const [selectedlevel2, setSelectedlevel2] = useState('None');
    const [graphDisplayMode, setGraphDisplayMode] = useState('dark');
    const [uncombineGraph, setUncombineGraph] = useState(false);
    const [toggleActionButtonDisplay, setToggleActionButtonDisplay] = useState(false);
    const [graphCombineDisplayMode, setGraphCombineDisplayMode] = useState('');
    const [selectedSubdriver, setSelectedSubdriver] = useState('Overall Sentiment');
    const { projectId } = useSelector(state => state.project);
    const [isLoading, setLoading] = useState(true)
    const [showerror, setShowerror] = useState(false)

    const [graphdata, setGraphdata] = useState();

    const client = ApiClient()

    useEffect(() => {
        if (projectId === null || projectId === undefined || projectId === '') {
            navigate('/app/project-not-found')
        }
        if (projectId) {
            client
                .get(`/api/v1/project-status/${projectId}/`)
                .then(res => {
                    if (res?.data?.is_locked) {

                        navigate('/app/access-denied')
                    }
                    else {
                        if(!isAdmin){

                        client.get('/api/v1/project-anonymity-threshold/' + projectId + '/')
                            .then((response) => {
                                if (response?.data) {
                                    if (!response?.data?.criteria_met) {
                                        
                                        navigate('/app/no-dashboard', {
                                            state: {
                                                anonymity_threshold: response?.data?.anonymity_threshold,
                                                criteria_met: response?.data?.criteria_met,
                                                status_type: response?.data?.status_type,
                                            }
                                        })
                                        
                                    }
                                }
                            })
                        }
                        }
                })
        }

    }, [projectId, navigate, client])

    const level1Change = (value) => {
        setError('')
        setSelectedlevel1(value)
        return true
    }

    const level2Change = (value) => {
        setError('')
        setSelectedlevel2(value)
        return true
    }

    const subdriverChange = (value) => {
        setSelectedSubdriver(value)
        return true
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setGraphCombineDisplayMode('')
    };

    useEffect(() => {
        if (projectId) {
            client.get(`api/v1/peoplegalaxy-subdriver/${projectId}`).then(res => {
                if (res?.data) {
                    setLevel1_list(prev => [...prev, ...res.data.segment_data])
                    setLevel2_list(prev => [...prev, ...res.data.segment_data])
                    setSubdriverlist(res.data.subdriver_data)
                }
            })
        }
    }, [projectId,])

    useEffect(() => {
        if (projectId) {
            setLoading(true)
            client.get(`api/v1/people-galaxy-graph/${projectId}/?sub_driver=${selectedSubdriver}`).then(res => {
                if (res?.data) {
                    setGraphdata(res.data)
                    setLoading(false)
                }
            })

        }
    }, [projectId, selectedSubdriver])

   
    useEffect(() => {
        if (!uncombineGraph) {
            setSelectedlevel1('None')
            setSelectedlevel2('None')
        }
    }, [uncombineGraph])

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubmit = () => {
        if (selectedlevel1.length > 0 && selectedlevel1 !== 'None' && selectedlevel2.length > 0 && selectedlevel2 !== 'None') {
            setCombineSegmentNames([[selectedlevel1, selectedlevel2], [selectedlevel1]])
            setToggleActionButtonDisplay(true)
        } else if (selectedlevel1.length > 0 && selectedlevel1 !== 'None') {
            setCombineSegmentNames([[selectedlevel1]])
            setToggleActionButtonDisplay(true)

        }
        else if (selectedlevel1 == selectedlevel2) {
            setError('*Please Select Different Levels')
            return false
        }
        else {
            if (toggleActionButtonDisplay) {
                handleUncombine()
                return false
            }
            setToggleActionButtonDisplay(false)
            setError('Select segment type')
            return false
        }
        setAnchorEl(null);
    };

    const handleGraphDisplayModeChange = (e) => {
        setGraphDisplayMode((e.target.checked) ? 'dark' : 'light')
    }

    const handleUncombine = () => {
        setUncombineGraph(true)
    }

    const handleCollapseAll = () => {
        setGraphCombineDisplayMode('collapse')
    }

    const handleExpandAll = () => {
        setGraphCombineDisplayMode('expand')
    }

    const NoContent = () => {
        return isLoading ?
            <Loader />
            :
            ''
    }

    // const handleUncombineGraph = () => {
    //     setCombineSegmentNames([])
    //     setSelectedlevel1('None')
    //     setSelectedlevel2('None')
    // }

    const open = Boolean(anchorEl);
    const id = open ? 'group-popover' : undefined;


    console.log({selectedlevel1, selectedlevel2, uncombineGraph})

    return <>
        {isLoading &&
            <NoContent />
        }
        <div className="map-content-section1">
            <div className='map-header1' style={{ width: '100%' }}>
                <div className='k-graph-nav1'>
                    <div className='div1'>
                        <div className='group-popup1' >
                            <div className="map-title1 "  >
                                {subdriverlist &&
                                    <SelectDropDown
                                        isRequired
                                        optionList={subdriverlist}
                                        handleOnChange={subdriverChange}
                                        valueField='sub_driver'
                                        itemField='sub_driver'

                                        defaultValue={selectedSubdriver}
                                    />
                                }
                            </div>
                            <div className='innerdiv'>

                                <BaseButton
                                    aria-describedby={id}
                                    variant='text'
                                    text='Group'
                                    className="grouping_btn1"
                                    onClick={handleClick}
                                />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <div className='popover-content'>
                                        <div className='popover-content-data'>
                                            {level1_list &&
                                                <SelectDropDown
                                                    label={'Level 1'}
                                                    optionList={level1_list}
                                                    handleOnChange={level1Change}
                                                    valueField='segment_type_name'
                                                    itemField='segment_type_name'
                                                    defaultValue={selectedlevel1}
                                                />
                                            }
                                            {level2_list &&
                                                <SelectDropDown
                                                    label={'Level 2'}
                                                    optionList={level2_list}
                                                    handleOnChange={level2Change}
                                                    valueField='segment_type_name'
                                                    itemField='segment_type_name'
                                                    defaultValue={selectedlevel2}
                                                />
                                            }
                                            <BaseButton
                                                className="ok_btn1 "
                                                text="Combine"
                                                variant="filled"
                                                onClick={() => { handleSubmit() }}
                                            />
                                            {errorMsg.length > 0 && <p style={{ color: '#ff0000', fontSize: '10px', marginTop: '10px' }}>{errorMsg}</p>}
                                        </div>
                                    </div>
                                </Popover>

                                {/* **keep this for peoplegalaxy */}
                                <BaseButton
                                    variant='text'
                                    text='Uncombine'
                                    className="btn1"
                                    onClick={handleUncombine}
                                    isDisabled={(!toggleActionButtonDisplay && !uncombineGraph)}
                                />

                                {/* <BaseButton
                                    variant='text'
                                    text='Uncombine'
                                    className="btn1"
                                    onClick={handleUncombineGraph}
                                    isDisabled={(!toggleActionButtonDisplay && !uncombineGraph)}
                                /> */}
                            </div>
                        </div>
                    </div >
                    <div className='group-popup1' >

                        <BaseButton
                            className="btn1"
                            text="Collapse All"
                            variant="filled"
                            onClick={handleCollapseAll}
                            isDisabled={!toggleActionButtonDisplay}
                        />
                        <BaseButton
                            variant='text'
                            text='Expand All'
                            className="btn1"
                            onClick={handleExpandAll}
                            isDisabled={!toggleActionButtonDisplay}
                        />
                        <MaterialUISwitch
                            checked={(graphDisplayMode === 'light') ? false : true}
                            onChange={handleGraphDisplayModeChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="success"
                        />
                    </div>
                </div>
            </div>
            <div className={'graph-container ' + graphDisplayMode}>
               {/* <PeopleGalaxyGraph
                    graphdata={graphdata}
                    combineSegmentNames={combineSegmentNames}
                    uncombineGraph={uncombineGraph}
                    graphCombineDisplayMode={graphCombineDisplayMode}
                    // setGraphCombineDisplayMode={setGraphCombineDisplayMode}
                    // setToggleActionButtonDisplay={setToggleActionButtonDisplay}
                    // setUncombineGraph={setUncombineGraph}
                    selectedlevel1={selectedlevel1}
                    selectedlevel2={selectedlevel2}
                    // graphDisplayMode={graphDisplayMode}
                    // setCombineSegmentNames={setCombineSegmentNames}
                    
                />  */}
                <Pgraph
                    graphdata={graphdata}
                    combineSegmentNames={combineSegmentNames}
                    uncombineGraph={uncombineGraph}
                    graphCombineDisplayMode={graphCombineDisplayMode}
                    setGraphCombineDisplayMode={setGraphCombineDisplayMode}
                    setToggleActionButtonDisplay={setToggleActionButtonDisplay}
                    setUncombineGraph={setUncombineGraph}
                    graphDisplayMode={graphDisplayMode}
                                    /> 
            </div>
        </div>
    </>
}


export default PeopleGalaxy2;