
import './popup.scss';
import React, { useState } from 'react';
import { BaseButton } from '../../Elements/Button';
import LabeledInput from '../../Elements/LabeledInput';
import logo from '../../assets/images/new_shtype.png';
import CustomIconPicker from '../../components/IconPicker';
import { ApiClient } from '../../api/client';



const Popup = ({
    hasDescription,
    activeSegmentTypeName,
    activeSegmentType,
    projectId,
    setSegmentData,
    setAddSegmentModal,
    isSegmentEdit,
    editSegmentData
}) => {

    const [newSegmentform, setNewSegmentform] = useState({
        newSegment: isSegmentEdit ? editSegmentData.title : '',
        desc: isSegmentEdit ? editSegmentData.subtitle : '',
    })

    const [iconValue, setIconValue] = useState(isSegmentEdit ? editSegmentData.icon : null)
    const [defaultSelectedIcon, setDefaultSelectedIcon] = useState(isSegmentEdit)

    const [err, setErr] = useState({
        errField: '',
        errMsg: ''
    })
    const { errField, errMsg } = err


    const handleIconChange = (value) => {
        setIconValue(value);
        setDefaultSelectedIcon(false)
        setErr({ errField: '', errMsg: '' })
    }

    const handleChange = (e) => {
        setNewSegmentform(prevState => (
            { ...prevState, [e.target.name]: e.target.value }
        ))
        setErr({ errField: '', errMsg: '' })
    }

    const handleConfirm = (e) => {
        let formData = {
            'title': newSegmentform.newSegment,
            'subtitle': newSegmentform.desc,
            'icon': iconValue,
            'project': projectId,
            'isChecked': true,
            'segment_type': activeSegmentType
        }
        if ((formData.title).trim().length === 0) {
            setErr({ errField: 'title', errMsg: 'This field should not be empty.' })
            return
        }
        else if (formData?.title?.length > 40) {
            setErr({ errField: 'title', errMsg: 'The text content of the this can contain only up to 40 characters.' })
            return
        }
        if (formData?.subtitle?.length > 280) {
            setErr({ errField: 'desc', errMsg: 'The text content of the Description can contain only up to 280 characters.' })
            return
        }

        if (!formData.icon) {
            setErr({ errField: 'icon', errMsg: 'Icon must be Selected.' })
            return
        }

        const client = ApiClient(setSegmentData)
        if (projectId) {
            const apiUrl = '/api/v1/segments/' + projectId + '/'
            if (isSegmentEdit) {
                let data = {
                    segmentData: [{
                        segmentsList: [{
                            segmentId: editSegmentData.segmentId,
                            ...(formData['title'] !== editSegmentData.title && { title: formData['title'] }),
                            ...(formData['subtitle'] !== editSegmentData.subtitle && { subtitle: formData['subtitle'] }),
                            ...(formData['icon'] !== editSegmentData.icon && { icon: formData['icon'] }),
                        }]
                    }]
                }
                client.put(apiUrl + '?check=SEGMENT', {
                    data: data
                }).then((response) => {
                    if (response?.data) {
                        setSegmentData(response.data)
                        setAddSegmentModal(false)
                    }
                }).catch((error) => {
                    let res = error.response.data.data
                    setErr({ errField: Object.keys(res)[0], errMsg: res[Object.keys(res)[0]][0] })
                })
            }
            else {

                client.post(apiUrl, {
                    data: formData
                }).then((response) => {
                    if (response?.data) {
                        setSegmentData(response.data)
                        setAddSegmentModal(false)
                    }
                }).catch((error) => {
                    let res = error.response.data.data
                    setErr({ errField: Object.keys(res)[0], errMsg: res[Object.keys(res)[0]][0] })
                })
            }
        }
    }

    const handleCancel = () => {
        setAddSegmentModal(false)
    }

    return <div style={{ width: '100%' }}>
        <div style={{ textAlign: 'center' }}>
            <img src={logo} width={50} alt="New Segmenttype logo here" />
        </div>
        <h3 className="heading">Add New Segment</h3>
        <LabeledInput
            name='newSegment'
            label={activeSegmentTypeName + ' Name'}
            type='text'
            value={newSegmentform.newSegment}
            error={errField === 'title' && errMsg}
            required={true}
            onChange={handleChange}

        />
        {
            hasDescription && <LabeledInput
                name='desc'
                label='Description'
                type='text'
                value={newSegmentform.desc}
                error={errField === 'desc' && errMsg}
                required={false}
                onChange={handleChange}
                textarea={true}
                isOptional={true}
            />}
        <span className="icon_heading">Select Icon Category:</span>
        <div>
            <CustomIconPicker hasCategory handleIconChange={handleIconChange} icon={iconValue} isEdit={defaultSelectedIcon} />
            {errField === 'icon' && <p style={{ color: 'red', fontSize: '0.75rem', marginTop: '0.3rem' }}>{errMsg}</p>}
        </div>
        <div className='modal-action-button-section'>
            <BaseButton
                className="segment-cancel-btn modal-cancel-btn"
                text="Cancel"
                variant="filled"
                onClick={handleCancel}
            />

            <BaseButton
                className="segment-submit-btn modal-submit-btn"
                text={isSegmentEdit ? 'Update' : 'Add'}
                variant="filled"
                onClick={handleConfirm}
            />
        </div>

    </div>
}

export default Popup;