import SelectDropDown from '../../Elements/SelectDropDown';
import './index.scss'

const DriverBar = ({driverData, segmentsData, handleDriverSelection, handleSegmentSelection, activeDriver}) => {

    return <div className='driver-bar'>
        <div className='segment-dropdown'>
            <SelectDropDown
                isRequired
                optionList={segmentsData}
                itemField='segmentTypeName'
                handleOnChange={handleSegmentSelection}
            />
        </div>
        <div className="drivers-bar" >
            {
                driverData.map((item) => {
                    return <div key={'div-'+ item.driverId}
                        className={`drivers-list-item ${activeDriver?.driverId === item?.driverId ? 'active' : ''}`}
                        onClick={() => handleDriverSelection(item)}
                    >
                        <p key={'p-'+ item.driverId}> <span className='driver-icon'><i className={item.icon} /></span>{item.title}</p>
                    </div>
                })
            }
        </div>
    </div>
}


export default DriverBar;