

export const MY_PROJECTS_PATH_NAME = 'my-projects'

export const controlType = {
    TEXT: 1,
    SLIDER: 2,
    TWO_OPTIONS: 3,
    CHOICE: 4,
    SMART_TEXT: 5,
    MULTI_TOPICS: 6,
};

export const TOPIC_LIMIT = 10;

export const DEBOUNCE_DELAY = 500; // delay in milliseconds to make answer API call.
