import { useEffect, useState } from "react"
import { useDropzone } from 'react-dropzone'
import { BaseButton } from "../../Elements/Button"
import Progressbar from "../../Elements/ProgressBar"
import cloudupload from './../../assets/svgs/cloudupload.svg'
import Modal from './../Modal'
import "./index.scss"

import * as XLSX from "xlsx"
import { ApiClient } from "../../api/client"
import { exportToEXCEL } from "../../utilities"


const bytesToKBMB = (bytes, to = 'KB') => {
    let result = to === 'KB' ? (bytes / 1024) : to === 'MB' ? (bytes / (1024 * 1024)) : bytes
    return result.toFixed(0)
}

const splitIntoChunk = (arr, chunk) => {
    let result = []
    for (let i = 0; i < arr.length; i += chunk) {
        let tempArray;
        tempArray = arr.slice(i, i + chunk);
        result.push(tempArray)
    }
    return result

}

const UPLOAD_NOT_STARTED = 'UPLOAD_NOT_STARTED'
const UPLOAD_READY = 'UPLOAD_READY'
const UPLOAD_STARTED = 'UPLOAD_STARTED'
const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED'


const ProjectUsersUploadComponent = ({ projectId, handleModal }) => {

    const chunkSize = 15
    const sheetUserLimit = 1000
    const maxFileSize = 1000000
    const responseFileName = 'UsersUploadResponse'
    const [xlFile, setXlFile] = useState()
    const [fileErr, setFileErr] = useState('')
    const [responseData, setResponseData] = useState([])
    const [uploadData, setUploadData] = useState([])
    const [progress, setProgress] = useState(0)
    const [uploadStatus, setUploadStatus] = useState(UPLOAD_NOT_STARTED)

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone(
        {
            onDrop: (acceptedFiles) => {
                setXlFile(acceptedFiles[0]);
                setResponseData([]);
                setFileErr('')
                setProgress(0)
                if (acceptedFiles.length > 0) setUploadStatus(UPLOAD_READY)
            },
            accept: {
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
            },
            maxFiles: 1,
            validator: (file) => {
                if (file.size > maxFileSize) {
                    return {
                        code: "file-too-large",
                        message: `File is larger than ${bytesToKBMB(maxFileSize)} KB`
                    };
                }
                return null
            }
        }
    )

    useEffect(() => {
        if (fileRejections.length > 1) {
            setFileErr(`Too Many Files: ${fileRejections.map(({ file }) => file.path + ', ')}`)
        }
        else {
            // 'File type must be application/vnd.ms-excel,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            fileRejections.map(({ file, errors }) => (
                errors.map(e => {
                    if (e.code === 'file-invalid-type') {
                        setFileErr(`Unsupported File Type. (${file.path})`)
                    }
                    else if (e.code === 'file-too-large') {
                        setFileErr(`${e.message} ${file.path}: ${bytesToKBMB(file.size)} KB`)
                    }
                    else {
                        setFileErr(`${e.message} ${file.path}`)
                    }
                })
            ));
        }
    }, [fileRejections, fileErr])

    useEffect(() => {
        setFileErr('')
    }, [xlFile])


    const handleFileUpload = () => {
        if (!acceptedFiles.length) {
            setFileErr('No File Selected')
            return
        }
        const file = xlFile;
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = async (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            let data = XLSX.utils.sheet_to_json(ws, { defval: "" });

            if (data.length > sheetUserLimit) {
                setFileErr(`Sheet should not have more than ${sheetUserLimit} users.`)
                return
            }

            setUploadData(data)

            let chunkedArr = splitIntoChunk(data, chunkSize)

            try {
                setUploadStatus(UPLOAD_STARTED)
                const client = ApiClient()
                let responseArr = []
                for (let i = 0; i < chunkedArr.length; i++) {
                    let response = await client.post('/api/v1/project-users-template/' + projectId + '/', {
                        data: chunkedArr[i]
                    })
                    if (response?.data) {
                        setProgress(Math.ceil(((i + 1) / chunkedArr.length) * 100))
                        responseArr.push(...response.data)
                    } else {
                       
                    }
                }
                setResponseData(responseArr)
            }
            catch (err) {
                console.log(err)
            }
            finally {
                setUploadStatus(UPLOAD_COMPLETED)
            }
        }
    }

    let erroredUsersData = responseData?.filter(user => user?.Message && user?.Message !== 'SUCCESS')

    return <Modal
        handleModal={uploadStatus === UPLOAD_STARTED ? () => { } : handleModal}

        modal_content={
            <div className="project-users-upload-container">
                <div className="project-users-upload-title">
                    <h3 className="title">Upload Users</h3>
                    <span className="description">Upload a Excel file to import users to Pulse.</span>
                </div>
                <div className="project-users-upload">
                    {
                        ![UPLOAD_STARTED, UPLOAD_COMPLETED].includes(uploadStatus) &&
                        <>
                            <section className="dropzone-section" {...getRootProps()}>
                                <div className="dropzone-content" >
                                    <input id="dropzone-input" {...getInputProps()} />
                                    <img src={cloudupload} />
                                    <h4>Drag .xls or .xlsx file here</h4>
                                    <p>or Click to browse (max size {bytesToKBMB(maxFileSize, 'MB')} MB)</p>
                                </div>
                            </section>
                            {
                                fileErr && <div className="error-div">
                                    <h4>Error:</h4>
                                    <ul>
                                        <li>{fileErr}</li>
                                    </ul>
                                </div>
                            }
                            {
                                acceptedFiles.length > 0 && <div className="accepted-files">
                                    <span><strong>Upload File:</strong> {acceptedFiles.map(file => `${file.path}, (${bytesToKBMB(file.size)} KB).`)}</span>
                                </div>

                            }
                        </>
                    }
                    {
                        progress > 0 && <Progressbar progress={progress} />
                    }
                    {
                        erroredUsersData.length > 0 ?
                            <div className="response-file-div">
                                <span><strong>Response File:</strong> {responseFileName}.xlsx</span>
                                <BaseButton
                                    variant='contained'
                                    text={"Download"}
                                    className='response-download-btn'
                                    onClick={() => {
                                        let data = responseData.filter(item => item.Message !== 'SUCCESS')
                                        data = data.map(item => ({...item, Message: JSON.stringify(item.Message)}))
                                        exportToEXCEL(data, responseFileName, 'json')
                                    }}
                                />
                                <span className="upload-error-msg">
                                    {`${erroredUsersData.length} of ${uploadData.length} failed to upload.`}
                                </span>
                            </div>
                            :
                            responseData.length > 0 && <div className="response-file-div">
                                <div className="upload-success-msg">Users are uploaded Successfully.</div>
                            </div>

                    }
                </div>

                <div className='modal-action-button-section'>
                    {
                        uploadStatus === UPLOAD_COMPLETED ?
                            <BaseButton
                                variant='contained'
                                text={"Done"}
                                className='add-user-submit-btn modal-submit-btn'
                                onClick={handleModal}
                            />
                            :
                            <>
                                <BaseButton
                                    text="Cancel"
                                    className='add-user-cancel-btn modal-cancel-btn'
                                    onClick={handleModal}
                                    isDisabled={uploadStatus === UPLOAD_STARTED}
                                />

                                <BaseButton
                                    variant='contained'
                                    text={"Upload"}
                                    className='add-user-submit-btn modal-submit-btn'
                                    onClick={handleFileUpload}
                                    isDisabled={(fileErr.length > 0) || !acceptedFiles.length || (uploadStatus === UPLOAD_STARTED)}
                                />
                            </>
                    }

                </div>
            </div>

        }

    />
}

export default ProjectUsersUploadComponent

