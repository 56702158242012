import './index.scss'
import { useEffect, useState } from 'react';
import Layout from "../../components/Layout";
import QuestionTable from '../../components/QuestionTable';
import Modal from '../../components/Modal';
import DeleteConfirmationPopup from '../../components/ConfirmationPopup';
import NewQuestion from '../../components/QuestionPopup';
import { ApiClient } from '../../api/client';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const AboutMe = () => {

    const client = ApiClient()
    const navigate = useNavigate()
    const { state } = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeDriver, setActiveDriver] = useState()
    const [addNewQuestionPopup, setAddNewQuestionPopup] = useState(false)
    const [editQuestionPopup, setEditQuestionPopup] = useState(false)
    const [deleteQuestionPopup, setDeleteQuestionPopup] = useState({
        show: false,
        count: 0,
        data: []
    })
    const [projectId, setProjectId] = useState();
    const [segmentsData, setSegmentData] = useState([])
    const [driverData, setDriverData] = useState([])
    const [questionArr, setQuestionArr] = useState([])

    const [questionSubmitError, setQuestionSubmitError] = useState()

    useEffect(() => {
        if (state?.projectId) {
            setProjectId(state?.projectId)
        } else if (searchParams.get('projectId')) {
            setProjectId(searchParams.get('projectId'))
        }
    }, [])

    useEffect(() => {
        setQuestionSubmitError()
    }, [addNewQuestionPopup, editQuestionPopup])

    useEffect(() => {
        if (projectId) {
            const client = ApiClient(setSegmentData)
            client.get('/api/v1/segments/' + projectId + '/?is_active=true')
            .then((response) => {
                if (response?.data) {
                    setSegmentData(response.data)
                }
            })

            client.get('/api/v1/survey/drivers/' + projectId + '/?is_active=true').then((response) => {
                if(response?.data){
                    setDriverData(response.data.driversList)
                }
            })
        }
    }, [projectId])

    useEffect(() => {
        if(driverData){
            setActiveDriver(driverData[0]?.driverId)
        }
    }, [driverData])

    useEffect(() => {
        if(activeDriver){
            const client = ApiClient(setQuestionArr)
            client.get('/api/v1/aboutme/' + projectId + '/?driver_id=' + activeDriver + '&is_active=true')
            .then((response) => {
                if (response?.data) {
                    setQuestionArr(response.data.questions)
                }
            })
        }
    }, [projectId, activeDriver])

    const nextBtnConditions = () => {
        client.post('api/v1/journeysteps/', {
            project: projectId,
            step_id: 4,
            step_name: 'About Me'
        }).then((res) => {
            // console.log(res)
        }).catch((error) => {
            console.log(error)
        })

        return navigate({
            pathname: '/setup/about-others',
            search: '?projectId=' + projectId
        }, { state: { projectId: projectId } })

    }

    const handleDeleteSelected = (data) => {
        if(data.length){
            setDeleteQuestionPopup(prev => ({...prev, show: true, data: data, count: data.length}))
        }
    }

    const confirmDeleteSelected = (data) => {
        if(data.length){
            const client = ApiClient(setQuestionArr)
            client.del('/api/v1/aboutme/' + projectId + '/?driver_id=' + activeDriver + '&is_active=true&question_ids=' + data)
            .then((response) => {
                if (response?.data) {
                    setQuestionArr(response.data.questions)
                }
            })
        }
    }

    const handleNewQuestionSubmit = (data) => {
        if(data){
            const client = ApiClient(setQuestionArr)
            client.post('/api/v1/aboutme/' + projectId + '/?driver_id=' + activeDriver + '&is_active=true', data)
            .then((response) => {
                if (response?.data) {
                    setQuestionArr(response.data.questions)
                    setAddNewQuestionPopup(false)
                }
            })
            .catch((error) => {
                setQuestionSubmitError(error.response.data)
            })
        }
    }

    const handleUpdateQuestionSubmit = (data) => {
        if(data){
            const am_question = questionArr.filter(item => item.id === editQuestionPopup)[0]
            const client = ApiClient(setQuestionArr)
            client.put('/api/v1/aboutme/' + projectId + '/?driver_id=' + activeDriver + '&is_active=true', {
                data: {
                    'question_data' : data,
                    'am_question_id' : am_question.id,
                },
                action : 'UPDATE_QUESTION_DATA'
            })
            .then((response) => {
                if (response?.data) {
                    setQuestionArr(response.data.questions)
                    setEditQuestionPopup(false)
                }
            })
            .catch((error) => {
                setQuestionSubmitError(error.response.data)
            })
        }
    }

    const handleQuestionSegmentToggle = (rowIdx, columnIdx) => {
        const newArr = [...questionArr]
        newArr[rowIdx].segments[columnIdx].is_selected = !newArr[rowIdx].segments[columnIdx].is_selected
        setQuestionArr(newArr)
        const client = ApiClient(setQuestionArr)
        client.put('/api/v1/aboutme/' + projectId + '/' , {
            data: {
                'segment_am_question_id' : questionArr[rowIdx].segments[columnIdx].segment_am_question_id,
                'is_selected' : questionArr[rowIdx].segments[columnIdx].is_selected},
            action : 'UPDATE_SEGMENT_SELECTION_ONLY'
        }).then((response) => {
            
        }).catch((error) => {
            
        })
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination || result.source.index === result.destination.index) return;

        let mandatory_ques = questionArr.filter(q => q.is_mandatory === true)

        let items = Array.from(questionArr.filter(q => q.is_mandatory !== true));
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let OrderedItems = items.map((item, idx) => {
            item['amq_order'] = idx + mandatory_ques.length
            return item
        })

        client.put(`/api/v1/amq-order/${projectId}/?driver=${activeDriver}`, {
            data: OrderedItems.map(item => ({ id: parseInt(item.id), amq_order: item.amq_order }))
        }).then(res => {})
        setQuestionArr([...mandatory_ques, ...OrderedItems])
    }

    return <Layout
        active_page={'About Me'}
        onClickNext={() => nextBtnConditions()}
        onClickHelp={() => window.open('https://projectai.com/pulse-admin-help/#AboutMeOverview','_blank')}
        onClickBack={() => navigate({
            pathname: '/setup/drivers',
            search: '?projectId=' + projectId
        }, { state: { projectId } })}
    >
        <div className="about-me-section section">
            <div className='about-me section-container'>
                <div className='section-content' >
                    <div >
                        <h1 className='section-title' >About Me</h1>
                        <p className="section-description">
                        Now, you can create or modify the questions you would like users to answers about their own perspectives of the project. Click on the driver to see the questions associated with each - we’ve provided some default questions to get you started. For other ideas, check out the Question Library.
                           </p>
                        <p className="section-instructions">
                        You can use the tick boxes to define the Stakeholder Segments which will be asked each questions.
                        </p>
                    </div>
                    <div className="section-main-content" style={{ marginTop: '30px' }}>
                        <div className="drivers-bar" >
                            {
                                driverData.length > 0
                                &&
                                driverData
                                    .sort((a, b) => a.driverOrder - b.driverOrder)
                                    .map((item, idx) => {
                                        return <div
                                            key={item.driverId}
                                            className={`drivers-list-item ${activeDriver === item.driverId ? 'active' : ''}`}
                                            onClick={() => setActiveDriver(item.driverId)}
                                        >
                                            <p key={item.driverId}>{item.title}</p>
                                        </div>
                                    })
                            }
                        </div>
                        <div className="questions-section" >
                            <QuestionTable
                                questionArr={questionArr}
                                handleAddQuestion={setAddNewQuestionPopup}
                                handleQuestionEdit={setEditQuestionPopup}
                                handleDeleteSelected={handleDeleteSelected}
                                segmentArr={segmentsData}
                                handleQuestionSegmentToggle={handleQuestionSegmentToggle}
                                handleOnDragEnd={handleOnDragEnd}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {
            addNewQuestionPopup && <Modal
                modalClassName="question-modal-wrapper"
                modal_content={
                    <>
                        <NewQuestion
                            sectionType="ABOUT_ME"
                            handleSubmit={handleNewQuestionSubmit}
                            handleCancel={setAddNewQuestionPopup}
                            segmentsData={segmentsData}
                            questionSubmitErr={questionSubmitError}
                        />
                    </>
                }
                handleModal={setAddNewQuestionPopup}
            />
        }

        {
            editQuestionPopup && <Modal
                modalClassName="question-modal-wrapper"
                modal_content={
                    <>
                        <NewQuestion
                            sectionType="ABOUT_ME"
                            isEdit={true}
                            handleSubmit={handleUpdateQuestionSubmit}
                            handleCancel={setEditQuestionPopup}
                            segmentsData={segmentsData}
                            questionData={questionArr.filter(item => item.id === editQuestionPopup)[0]}
                            questionSubmitErr={questionSubmitError}
                        />
                    </>
                }
                handleModal={setEditQuestionPopup}
            />
        }
        {
            deleteQuestionPopup.show && <DeleteConfirmationPopup
                text={"Are you sure you want to delete " + ((deleteQuestionPopup.count > 1) ? deleteQuestionPopup.count + " questions" : "the question") + "?"}
                handleCancel={() => setDeleteQuestionPopup(prev => ({...prev, show: false}))}
                handleSubmit={() => {
                    confirmDeleteSelected(deleteQuestionPopup.data)
                    setDeleteQuestionPopup(prev => ({...prev, show: false}))
                }}
            />
        }


    </Layout>
}

export default AboutMe;