import { useLayoutEffect, useRef, useState } from 'react';
import { replaceQuestionTextKeyWord } from '../../../utilities';
import Radio from '../../Radio';
import './index.scss'
import SkipQuestionOptionList from '../CommentSkipQuestion';
import { DEBOUNCE_DELAY } from '../../../utilities/constants';



const MultipleOptionsQuestion = ({
    currentUser,
    question,
    onAnswer,
    projectTitle,
    surveyType,
    isFlagged,
    otherUser
}) => {

    const firstUpdate = useRef(true);
    const [answer, setAnswer] = useState({
        'project_user': currentUser,
        'project': question.project,
        ...(surveyType === 'me' && {'am_question': parseInt(question.id)}),
        ...(surveyType === 'others' && {'ao_question': parseInt(question.id)}),
        'control_type': question.control_type_readable,
        'option_id': null,
        'integer_value': 0,
        'topic_value': '',
        'comment_value': '',
        'skip_value': '',
        'topic_tags': '',
        'comment_tags': '',
        ...question.response_data[0]
    })

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const debounce = setTimeout(() => {
            onAnswer(answer)
        }, DEBOUNCE_DELAY)

        return () => {
            clearTimeout(debounce)
        }
    }, [answer.topic_value, answer.comment_value, answer.skip_value])

    const onSelectOption = (option_id, option_text) => {
        setAnswer(state => ({ ...state, topic_value: option_text, skip_value: "", option_id: option_id }))
    }

    const handleSkip = (skip_text) => {
        setAnswer(state => ({ ...state, skip_value: skip_text, topic_value: '', option_id: '' }))
    }

    const handleComment = (comment_text) => {
        setAnswer(state => ({ ...state, comment_value: comment_text }))
    }

    return <div className="main-question multiple-option-question">
        <div>
            <h2 className="question-text">
                {replaceQuestionTextKeyWord(question.question_text, otherUser, projectTitle)}
            </h2>
        </div>
        <div className="answer-section question-selector">
            {question.options_list.map((item, index) => {
                const active = item.id.toString() === answer?.option_id?.toString();
                return (
                    <div key={item.id} className="option-item">
                        <Radio
                            checked={active}
                            onChange={() => onSelectOption(item.id, item.option_name)}
                        >
                            {item.option_name}
                        </Radio>
                    </div>
                );
            })}
        </div>
        <div className="comment-skip-section">

            <SkipQuestionOptionList
                answerValue={answer?.integer_value}
                commentValue={answer?.comment_value}
                commentPrompt={question.comment_prompt}
                skipValue={answer?.skip_value}
                skipOptionsList={question.skip_option_list}
                skipOption={question.skipOption}
                onSkip={(skipAnswer) => handleSkip(skipAnswer)}
                onComment={(commentAnswer) => handleComment(commentAnswer)}
            />
        </div>
    </div>
}


export default MultipleOptionsQuestion;