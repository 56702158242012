import './index.scss'

const ProjectNotFound = ({ className }) => {
    return <div className={`project-not-found-container ${className}`}>
        <div className="content-wrapper">
            <h1 className="title">Select Project</h1>
            <div className="content">
                Please select a project first.<br />
                <span>
                    Please select a project from My Projects to begin.
                </span>
            </div>
        </div>
    </div>
}

export default ProjectNotFound;
