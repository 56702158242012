import './index.scss'
import { useState } from "react"
import iconfinals from "./iconDefs"

const CustomIconPicker = ({ hasSearch, hasCategory, handleIconChange, icon, ...args }) => {

    const [activeCategory, setActiveCategory] = useState('all')
    const [selectedIcon, setSelectedIcon] = useState(icon)
    

    const handleIconSelect = (icon) => {
        setSelectedIcon(icon)
        handleIconChange(icon)
    }

    let iconOptions = Object.keys(iconfinals)
    let allIcons = []
    for (let icon of iconOptions) {
        allIcons.push(...iconfinals[icon])
    }
    let icons = activeCategory === 'all' ? (args.isEdit ? [icon, ...allIcons.filter(ic => ic!==icon)] : allIcons) : iconfinals[activeCategory]
    
    return <div className="icon-picker-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
           
                {
                    hasSearch && <div className="icon-picker-search">
                        <input />
                    </div>

                }
                {
                    hasCategory && <div className='select-option-box'>
                        <select 
                            style={{paddingLeft:'7px'}}
                            className='select-option'
                            onChange={(e) => {
                                setActiveCategory(e.target.value)
                            }}
                        >
                            <option value="all">All</option>
                            {iconOptions.map(opt => {
                                return <option value={opt}>{opt}</option>
                            })}
                        </select>
                    </div>
                }
          
        </div>

        <div>
        </div>
        <div className="icon-container">
            {
                icons.map(item => {
                    return <span
                        className={`icon-span ${selectedIcon === item ? 'selected' : ''}`}
                        onClick={() => handleIconSelect(item)}
                    >
                        <i className={item} />
                    </span>
                })
            }
        </div>
    </div>
}

export default CustomIconPicker;