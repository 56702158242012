import './index.scss';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2'
import { BaseButton } from '../../Elements/Button';
ChartJS.register(...registerables);
const Preview_dashboard = () => {
    return(
        <div className="App">
           
        <div style={{ maxWidth: "350px" }}>
          <Bar
            data={{
              // Name of the variables on x-axies for each bar
              labels: ["1st bar", "2nd bar", "3rd bar", "4th bar"],
              datasets: [
                {
                  // Label for bars
                  label: "total count/value",
                  // Data or value of your each variable
                  data: [1552, 1319, 613, 1400],
                  // Color of each bar
                  backgroundColor: ["aqua", "green", "red", "yellow"],
                  // Border color of each bar
                  borderColor: ["aqua", "green", "red", "yellow"],
                  borderWidth: 0.5,
                },
              ],
            }}
            // Height of graph
            height={300}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      // The y-axis value will start from zero
                      beginAtZero: true,
                    },
                  },
                ],
              },
              legend: {
                labels: {
                  fontSize: 10,
                },
              },
            }}
            />
          </div>
        </div>
      );

}

export default Preview_dashboard;