


import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import {
	Welcome,
	ProjectAttributes,
	Segments,
	Drivers,
	AboutMe,
	AboutOthers,
	Users,
	Permissions,
	Preview,
	Launch,
	Register,
	
} from './../pages'

import Activation from "../pages/Activation";
import LoginForm from "../pages/Login";
import Coming_Soon from '../pages/ComingSoon';
import ForgotPassword from "../pages/ForgotPassword";
import PasswordResetConfirm from "../pages/ForgotPassword/PasswordResetConfirm";
import PasswordSetConfirm from "../pages/ForgotPassword/PasswordSetConfirm";
import ProtectedRoutes from "./ProtectedRoutes";
import UserProfile from "../pages/Profile";
import EndUserRoutes from "./EndUserRoutes";
import FlaggedResponse from "../pages/FlaggedResponse";



const Router = () => {

	return <BrowserRouter>
		<Routes>
			<Route path="/" element={<Navigate to="/login" replace />} />
			<Route path="/register" element={<Register />} />
			<Route path="/login" element={<LoginForm />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/password/reset/confirm/:uid/:token" element={<PasswordResetConfirm />} />
			<Route path="/password/set/confirm/:uid/:token" element={<PasswordSetConfirm />} />
			<Route path="/activate/:uid/:token" element={<Activation />} />
			<Route path={"/coming-soon"} element={<Coming_Soon/>} />
			<Route path="/userinterface/"></Route>
			<Route path="/setup/">
				<Route path="welcome" element={<ProtectedRoutes Component={Welcome} />} />
				<Route path="project-attributes" element={<ProtectedRoutes Component={ProjectAttributes} />} />
				<Route path="segments" element={<ProtectedRoutes Component={Segments} />} />
				<Route path="drivers" element={<ProtectedRoutes Component={Drivers} />} />
				<Route path="about-me" element={<ProtectedRoutes Component={AboutMe} />} />
				<Route path="about-others" element={<ProtectedRoutes Component={AboutOthers} />} />
				<Route path="users" element={<ProtectedRoutes Component={Users} />} />
				<Route path="permissions" element={<ProtectedRoutes Component={Permissions} />} />
				{/* <Route path="preview" element={<ProtectedRoutes Component={Preview} />} /> */}
				<Route path="launch" element={<ProtectedRoutes Component={Launch} />} />
				<Route path="profile" element={<ProtectedRoutes Component={UserProfile} />} />
				<Route path="flagged-response" element={<ProtectedRoutes Component={FlaggedResponse} />} />
			</Route>
			<Route path="/*" element={<ProtectedRoutes Component={() => <h1>404 Error Page not found.</h1>} />} />
			<Route path="/app/*" element={<EndUserRoutes />} />
		</Routes>
	</BrowserRouter>
}

export default Router;

