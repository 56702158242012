import { useEffect, useRef, useState } from 'react';
import './index.scss'
import checkGray from "../../assets/images/check-gray.png"

const useResize = () => {
    const [dimensions, setDimensions] = useState({ width: null, height: null });
    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.clientWidth,
                height: window.clientHeight,
            });
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return dimensions;
};



const DriverPanel = ({ driverList, onDriverClick, activeDriverId }) => {
    const [selectedDriver, selectDriver] = useState(activeDriverId);
    const [leftArrowVisible, setLeftArrowVisible] = useState(false);
    const [rightArrowVisible, setRightArrowVisible] = useState(false);
    const dimensions = useResize();

    const root = useRef(null);
    const container = useRef(null);
    const dragRef = useRef(null);

    const updateArrowsVisibility = () => {
        setLeftArrowVisible(root.current.scrollLeft > 0);
        setRightArrowVisible(
            container.current.clientWidth - root.current.scrollLeft >
            root.current.clientWidth
        );
    };

    useEffect(() => {
        if (!container.current || !root.current) {
            return;
        }
        updateArrowsVisibility();

        selectDriver(activeDriverId);
    }, [driverList, selectedDriver, dimensions, activeDriverId]);

    // during dragging scroll happens, which triggers rerender but our old data will be lost,
    // so need to cache it
    const [dragData] = useState({
        dragStart: null,
        dragEnd: null,
        oldScroll: null,
        dragStartTimestamp: null,
    });

    const [chevronScrollInfo] = useState({
        chevronScrollAction: null,
        scrollAmount: 0,
    });

    // debounced scroll
    const scrollPanel = (direction) => {
        if (chevronScrollInfo.chevronScrollAction) {
            clearTimeout(chevronScrollInfo.chevronScrollAction);
        }
        const sgn = direction === "left" ? -1 : 1;
        chevronScrollInfo.scrollAmount =
            2 * chevronScrollInfo.scrollAmount + root.current.clientWidth * 0.3 * sgn;
        chevronScrollInfo.chevronScrollAction = setTimeout(() => {
            root.current.scrollBy({
                left: chevronScrollInfo.scrollAmount,
                behavior: "smooth",
            });
            Object.assign(chevronScrollInfo, {
                chevronScrollAction: null,
                scrollAmount: 0,
            });
        }, 200);
    };

    return <div className="driver-panel-container">
        <div
            className={`driver-panel-arrow arrowleft ${leftArrowVisible ? 'visible' : null}`}
            onClick={() => scrollPanel("left")}
        >
            <i className="fa-solid fa-chevron-left"></i>
        </div>
        <div
            className={`driver-panel-arrow arrowright ${rightArrowVisible ? 'visible' : null}`}
            onClick={() => scrollPanel("right")}
        >
            <i className="fa-solid fa-chevron-right"></i>
        </div>
        <div
            ref={root}
            draggable
            className="driver-panel"
            onScroll={updateArrowsVisibility}
            onDragStart={(e) => {
                e.dataTransfer.setDragImage(dragRef.current, 0, 0);
                dragData.dragStart = e.pageX;
                dragData.dragStartTimestamp = Date.now();
                dragData.oldScroll = root.current.scrollLeft;
            }}
            onDragOver={(e) => {
                const dragEnd = e.pageX;
                const distance = dragEnd - dragData.dragStart;
                root.current.scrollLeft = dragData.oldScroll - distance;
            }}
            onDragEnd={(e) => {
                const dragEnd = e.pageX;
                const distance = dragEnd - dragData.dragStart;
                const dragEndTimestamp = Date.now();
                const timeDelta = dragEndTimestamp - dragData.dragStartTimestamp;
                const factor = timeDelta > 800 ? 1 : Math.min(2000 / timeDelta, 10);
                const finalScroll = dragData.oldScroll - distance * factor;
                root.current.scrollTo({
                    left: finalScroll,
                    behavior: "smooth",
                });
            }}
        >
            <div
                ref={dragRef}
                style={{ position: "fixed", width: "1px", height: "1px" }}
            />
            <div ref={container} className="driver-container">
                {driverList.map((d) => (
                    <div key={d.driverId} className="wrapper">
                        <div
                            onClick={() => onDriverClick(d.driverId)}
                            className={
                                `driver-component ${d.driverId === selectedDriver ? 'selected' : ''}`
                            }
                        >
                            <span className='driver-icon'><i className={d.icon} /></span>
                            <div>{d.title}</div>
                            {
                                d['answered_ids'].length > 0 && d['answered_ids'].length === d['questions_ids'].length &&
                                <div className='driver-question-complete-check'>
                                    <img src={checkGray} alt="" />
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
}


export default DriverPanel;