import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes = (props) => {
    const {Component} = props;
    const navigate = useNavigate();
    useEffect(() => {
        let token = localStorage.getItem("access_token");
        if (!token) {
            navigate('/login')
        };
    })

    return (
        <Component/>
    )

}

export default ProtectedRoutes