import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import Loader from "../../components/Loader";
import './index.scss';


am5.addLicense("AM5CC-0920-7853-9251-8187")

const PeopleGalaxyGraph = ({
    graphdata,
    combineSegmentNames,
    uncombineGraph,
    graphCombineDisplayMode,
    anonymity_threshold,
    // setGraphCombineDisplayMode,
    // setToggleActionButtonDisplay,
    // setUncombineGraph,
    // setCombineSegmentNames,
    selectedlevel1,
    selectedlevel2,
    graphDisplayMode 
}) => {


   
    const { projectId, projectUserId } = useSelector(state => state.project);
    const [structuriseData, setStructurisedata] = useState([]);

    const [isLoading, setIsLoading] = useState(false)



    function calculateAverage(array) {
        if (array.length === 0) {
            return 0;
        }

        const sum = array.reduce((total, obj) => total + obj.score, 0);
        return sum / array.length;
    }

    function bgColorOnAvg(score) {
        let bgColor;
        if (0 < score && score < 3) {
            bgColor = '#E56865'
        }
        else if (3 <= score && score <= 4) {
            bgColor = '#C5B4C9'
        }
        else if (4 < score && score <= 6) {
            //node backgroundcolor
            //Font icon color
            bgColor = '#95A2CE'
        }
        else if (6 < score && score <= 8) {
            bgColor = '#50A8F2'
        }
        else if (8 < score && score <= 10) {
            bgColor = '#03B7A5'
        }
        else {
            bgColor = '#E56865'
        }
        return bgColor
    }

    function setCoordinates(n, idx) {
        let radius = Math.sqrt(n) * 8;
        let angle = (2 * Math.PI * idx) / n;
        let x = am5.percent(50 + radius * Math.cos(angle));
        let y = am5.percent(50 + radius * Math.sin(angle));
        return [x, y]
    }

    function setCoordinatesForLargeNumber(n, idx, maxElementsPerCircle, circleNum) {
        const elementsInThisCircle = Math.min(maxElementsPerCircle, n - idx);
        const radius = 3 * circleNum; // Increase radius for each circle
        const angleIncrement = (2 * Math.PI) / maxElementsPerCircle;
        const angle = idx * angleIncrement;
        const x = am5.percent(50 + Math.round(radius * Math.cos(angle)));
        const y = am5.percent(50 + Math.round(radius * Math.sin(angle)));
        return [x, y]
    }

    function flattenNodes(arrayOfObjects) {
        let flatNodes = [];
        arrayOfObjects.forEach(obj => {
            flatNodes.push(...obj.mapped_nodes);
            if (obj.mapped_nodes.length) flatNodes.push(obj.node_id)
        });
        return flatNodes;
    }

    function returnInitials(full_name) {
        let split_name = full_name.trim().split(' ')
        return split_name[0].split('')[0] + (split_name.length > 1 ? split_name[split_name.length - 1]?.split('')?.[0] : '')

    }


    useEffect(() => {
        setIsLoading(true)
        if (graphdata && graphdata.length) {
            let mapped_nodes = flattenNodes(graphdata)
            let haveMappedNodes = graphdata.filter(item => mapped_nodes.includes(item.node_id))
            let noMappedNodes = graphdata.filter(item => !mapped_nodes.includes(item.node_id))
            let maxElementsPerCircle = 5; // max elements in innermost circle
            let oldMaxElementsPerCircle = 0
            let circleNum = 1
            let crossMappedNodes = false
            let plusElemCount = 5
            let transformedData = [...haveMappedNodes, ...noMappedNodes]
                .map((item, idx) => {
                    idx += 1
                    if (!crossMappedNodes && idx > haveMappedNodes.length) {
                        circleNum = circleNum + 2
                        crossMappedNodes = true
                        oldMaxElementsPerCircle = idx
                        plusElemCount = 20
                        maxElementsPerCircle = maxElementsPerCircle + plusElemCount
                    }

                    // if count from oldMaxElementsPerCircle is greater than max elements in that circle than add  one more circle
                    if ((idx - oldMaxElementsPerCircle) > maxElementsPerCircle) {
                        oldMaxElementsPerCircle = oldMaxElementsPerCircle + maxElementsPerCircle
                        maxElementsPerCircle = maxElementsPerCircle + plusElemCount // increase 8 elements per circle 
                        circleNum = circleNum + 1

                    }
                    let [xa, ya] = setCoordinatesForLargeNumber(graphdata.length, idx, maxElementsPerCircle, circleNum)

                    return {
                        name: item.user_full_name,
                        node_id: item.node_id,
                        value: 30,
                        mapped_node_id: item.mapped_nodes,
                        icon: 'fa fa-user',
                        bgColor: '#fff',
                        nodeSettings: {
                            fillOpacity: 1,
                            fill: (graphDisplayMode==='light')? '#E9EAEA' : '#fff'
                        },
                        x: xa,
                        y: ya,
                        ini: returnInitials(item.user_full_name)
                    }
                })


            if (combineSegmentNames.length) {
                setIsLoading(true)
                setStructurisedata([])
                transformedData = []
                if (selectedlevel1 !== 'None') {
                    let [segmentType1, segmentType2] = [selectedlevel1, selectedlevel2] //combineSegmentNames?.[0]

                    let l1SegmentsObjList = []
                    let l1Segments = []

                    graphdata.forEach(item => {
                        if (!l1Segments.includes(item['segment_data'][segmentType1])) {
                            l1SegmentsObjList.push({ name: item['segment_data'][segmentType1], icon: item['segment_icons'][segmentType1] })
                            l1Segments.push(item['segment_data'][segmentType1])
                        }
                    })

                    transformedData = l1SegmentsObjList.map((s1, index) => {

                        let s1GroupData = graphdata.filter(item => item['segment_data'][segmentType1] === s1.name)

                        let linkedNodes = flattenNodes(s1GroupData)
                        let idNodes = s1GroupData.map(user => user.node_id)

                        let nodeL1List = s1GroupData.map(user => ({
                            name: user.user_full_name,
                            value: 5,
                            icon: 'fa fa-user',
                            score: user.score,
                            bgColor: '#fff',
                            nodeSettings: {
                                fillOpacity: 1,
                                fill: (graphDisplayMode==='light')?'#E9EAEA':'#fff'
                            },
                            ini: returnInitials(user.user_full_name),
                        }))
                        let avgScore = calculateAverage(nodeL1List)
                        let nodeL2List;

                        if (segmentType2 && segmentType2 !== 'None') {
                            let l2Segments = []
                            let l2SegmentsObjList = []
                            graphdata
                                .filter(item => (item['segment_data'][segmentType1] === s1.name))
                                .forEach(item => {
                                    if (!l2Segments.includes(item['segment_data'][segmentType2])) {
                                        l2SegmentsObjList.push({ name: item['segment_data'][segmentType2], icon: item['segment_icons'][segmentType2] })
                                        l2Segments.push(item['segment_data'][segmentType2])
                                    }
                                })
                            nodeL2List = l2SegmentsObjList.map(s2 => {
                                let usersList = graphdata
                                    .filter(item => (item['segment_data'][segmentType1] === s1.name) && (item['segment_data'][segmentType2] === s2.name))
                                    .map(user => ({
                                        name: user.user_full_name,
                                        value: 5,
                                        icon: 'fa fa-user',
                                        score: user.score,
                                        bgColor: '#000',
                                        nodeSettings: {
                                            fillOpacity: 1,
                                            fill: (graphDisplayMode==='light')?'#E9EAEA':'#fff'
                                        },
                                        ini: returnInitials(user.user_full_name),
                                    })
                                    )
                                return {
                                    name: s2.name,
                                    value: usersList.length * 6,
                                    icon: s2.icon,
                                    score: '',
                                    bgColor: (usersList.length<=anonymity_threshold)? '#BFBFBF' : bgColorOnAvg(calculateAverage(usersList)),
                                    nodeSettings: {
                                        fill: (usersList.length<=anonymity_threshold)? '#BFBFBF' : bgColorOnAvg(calculateAverage(usersList))// backgroundcolor for level 2 segments nodes
                                    },
                                    count: usersList.length,
                                    children: usersList,
                                }
                            })
                        }

                        let [x1, y1] = setCoordinates(l1SegmentsObjList.length, index)
                        return {
                            name: s1.name,
                            value: nodeL1List.length * 10, //(segmentType2 && segmentType2 !== 'None') ? 15 + nodeL2List.length * 3 : 15 + nodeL1List.length * 10,
                            node_id: s1.name + '-segment-1',
                            mapped_node_id: [],
                            icon: s1.icon,
                            bgColor: (nodeL1List.length <= anonymity_threshold) ? '#BFBFBF' : bgColorOnAvg(avgScore),
                            nodeSettings: {
                                fill: (nodeL1List.length <=anonymity_threshold) ? '#BFBFBF' : bgColorOnAvg(avgScore)
                            },
                            children: (segmentType2 && segmentType2 !== 'None') ? nodeL2List : nodeL1List,
                            avgScore,
                            count: nodeL1List.length,
                            // x: x1,
                            // y: y1,
                            linkedNodes,
                            idNodes
                        }
                    })

                    for (let i = 0; i < transformedData.length; i++) {
                        let currentIdNodes = transformedData[i].idNodes;
                        let linkNames = [];

                        // Iterate over other objects to find matches
                        for (let j = 0; j < transformedData.length; j++) {
                            if (i !== j) {
                                let currentLinkedNodes = transformedData[j].linkedNodes;
                                let intersection = currentIdNodes.filter(x => currentLinkedNodes.includes(x));

                                // If there's an intersection, add the id of the other object to linkNames
                                if (intersection.length > 0) {
                                    linkNames.push(transformedData[j].node_id);
                                }
                            }
                        }
                        transformedData[i].mapped_node_id = linkNames;
                    }
                }
            }

            setTimeout(() => {
                setStructurisedata([{ node: 'Root', value: 30, x: am5.p50, y: am5.p50, children: transformedData }])
                setIsLoading(false)
            }, 2000)
        }


    }, [graphdata, combineSegmentNames, graphCombineDisplayMode, uncombineGraph])


    useEffect(() => {

        // select div to create chart
        let root = am5.Root.new("people-galaxy-chart");

        //add theme to chart
        root.setThemes([
            // am5themes_Animated.new(root)
            // am5hierarchy.DefaultTheme.new(root),
            am5themes_Responsive.newEmpty(root)
        ]);

        // append a zoomable container to div
        var container = root.container.children.push(
            am5.ZoomableContainer.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                layout: root.verticalLayout,
            })
        );

        // add zooming tools
        container.children.push(am5.ZoomTools.new(root, {
            target: container
        }));

        // setInitialDepth(selectedlevel2 && selectedlevel2 !== 'None' ? 1 : selectedlevel1 && selectedlevel1 !== 'None' ? 0 : 1)

        let initialDepthLevel = selectedlevel2 && selectedlevel2 !== 'None' ? 0 : selectedlevel1 && selectedlevel1 !== 'None' ? 0 : 1
        let nodePadding = selectedlevel1 && selectedlevel1 !== 'None' ? 30 : 0
        let maxRadius = selectedlevel1 && selectedlevel1 !== 'None' ? 70 : 15
        let minRadius = selectedlevel1 && selectedlevel1 !== 'None' ? 15 : 8
        let manyBodyStrength = selectedlevel1 && selectedlevel1 !== 'None' ? -30 : -8
        let centerStrength = selectedlevel1 && selectedlevel1 !== 'None' ? 1 : 0.9
        let velocityDecay = selectedlevel1 && selectedlevel1 !== 'None' ? .9 : .5
        let linkWithStrength = selectedlevel1 && selectedlevel1 !== 'None' ? .5 : .1

        if (graphCombineDisplayMode === 'expand') {
            initialDepthLevel = 3
        } else if (graphCombineDisplayMode === 'collapse') {
            initialDepthLevel = 0
        }

        // add heirarchy - Force Directed chart
        var series = container.contents.children.push(
            am5hierarchy.ForceDirected.new(root, {
                downDepth: 1,
                initialDepth: initialDepthLevel,
                topDepth: 1,
                valueField: "value",
                categoryField: "name",
                childDataField: "children",
                idField: 'node_id',
                linkWithField: "mapped_node_id",
                // xField: "x",
                // yField: "y",
                nodePadding,
                minRadius,
                maxRadius,
                // velocityDecay: 0.3,
                manyBodyStrength,
                // centerStrength: 0.8,
                centerStrength,
                initialFrames: 2000,
                showOnFrame: 50,
                velocityDecay,
                linkWithStrength
            })
        );


        // settings for node circle
        series.circles.template.setAll({
            templateField: "nodeSettings",
            fillOpacity: 1,
            // strokeWidth: 5,
            strokeOpacity: 1,
            stroke: '#000'
            // fill: '#ffffff'
        });

        series.links.template.setAll({
            strokeWidth: 1,
            strokeOpacity: 0.5,
            stroke: '#00ff00',
            color: '#00ff00',
            fill: '#00ff00',
        });

        // // settings for node outer circle
        // series.outerCircles.template.setAll({
        //     templateField: "nodeOuterCircleSettings",
        //     strokeWidth: 1,
        //     strokeDasharray: 5,
        //     strokeOpacity: 0
        // });
        // series.outerCircles.template.states.create("hover", {
        //     strokeOpacity: 0.5,
        //     strokeDasharray: 0
        // });
        // series.outerCircles.template.states.create("hoverDisabled", {
        //     strokeOpacity: 0.5,
        //     strokeDasharray: 0
        // });

        // // to set node properties
        // series.nodes.template.setAll({
        //     draggable: true
        // });

        // set label property
        series.labels.template.setAll({
            fill: am5.color(0x000000),

            // ignoreFormatting: true,
            // isMeasured: false,
            lineHeight: am5.p100,
            // // height: am5.p100,
            // verticalScrollbar: null,
            // horizontalScrollbar: null,
        });

        // change links color to white
        // series.links.template.adapters.add('stroke', function (stroke, target) {
        //     return '#808080'
        // })

        series.links.template.states.create("active", {
            strokeWidth: 2,
            strokeOpacity: 1,
            // stroke: '#ff0000'
        });
        series.nodes.template.events.on("pointerover", function (ev) {
            am5.array.each(ev.target.dataItem.get("links"), function (link) {
                link.set("active", true);
            });
        });

        series.nodes.template.events.on("pointerout", function (ev) {
            am5.array.each(ev.target.dataItem.get("links"), function (link) {
                link.set("active", false);
            });
        });


        // // adding custom label
        // if ((selectedlevel1 && selectedlevel1 === 'None') && (selectedlevel2 && selectedlevel2 === 'None')) {
        //     series.labels.template.adapters.add("html", function (html, target) {
        //         // var circle = target.dataItem.get("circle");
        //         // var radius = target.get("radius") + 10;
        //         // var width = target.width();
        //         // console.log(target?.parent?.dataItem?.get("circle").get('radius'), '{count}')
        //         let count = target?.parent?.dataItem?.dataContext?.count
        //         let icon = target?.parent?.dataItem?.dataContext?.icon

        //         let countHtml = count !== undefined ?
        //             ` <div 
        //             style="position: absolute; top:0px; right:5px; 
        //                     display:flex; align-items:center; justify-content:center;
        //                     height: 15px; min-width: 15px; padding: 2px; width: fit-content; 
        //                     background-color:#ff0000; color: #fff; font-size:10px;
        //                     border-radius:50%;
        //                     "
        //         >
        //         {count}
        //     </div>`
        //             :
        //             ''

        //         html = icon === 'fa fa-user' ?
        //             `
        //     <div className="node-wrapper" style="position:relative; align-items: center; border-radius: 50%; text-align: center; display: flex ;justify-content: center; flex-direction:column">

        //         <div className="node-icon-box" 
        //             style="height:12px; width:12px; border-radius: 50%;padding: 5px 5px;display: flex;align-items: center;justify-content: center;
        //                 background-color: {bgColor}"
        //         >
        //             <i style="font-size: 8px" class="{icon}"></i>
        //         </div>
        //         <p style="font-size: 5px; color: #fff; ">{name}</p>
        //     </div>
        //     `
        //             :
        //             html

        //         return html;
        //     });
        // }

        // series.nodes.template.events.on('click', function (ev) {
        //   console.log(ev.target.dataItem.dataContext)
        // })

       
        ///Disable Kardo
        // series.nodes.template.on("disabled", function (disabled, targetNode) {
        //     let targetLevel = targetNode.dataItem.get("depth");


        //     if (!disabled && !groupData )  {

        //         series.nodes.each(function(node) {
        //           if (node.dataItem.get("depth")== targetLevel && node !== targetNode){
        //             node.set("disabled", true);}

        //         })
        //       }

        //       else{
        //         targetLevel=targetLevel+1
        //         series.nodes.each(function(node) {
        //             //console.log(node.dataItem.dataContext.name,node.dataItem)
        //             if(node.dataItem.get("depth") == targetLevel && node !== targetNode){
        //             node.set("disabled", true);}

        //         })

        //       }



        // })
        ///Disable Kardo


        // series.nodes.template.events.on('click', function (ev) {
        //     clickHandler(ev.target.dataItem.dataContext.project_user_id)
        //     // console.log(ev.target.dataItem.dataContext)
        // })

        let tooltip = series.set("tooltip", am5.Tooltip.new(root, {
        }))

        series.nodes.template.setAll({
            tooltipText: "{name}",
        });
        // if ((selectedlevel1 && selectedlevel1 !== 'None') || (selectedlevel2 && selectedlevel2 !== 'None')) {
        //     series.nodes.template.setAll({
        //         tooltipText: "{name}",
        //     });
        // }
        // else {
        //     //disable tooltip on hover
        //     tooltip.setAll({ forceHidden: true })

        // }

        // series.nodes.template.adapters.add('tooltipText', function (tooltipText, target){
        //     // console.log(target?.parent?.dataItem, tooltipText);
        //     return tooltipText
        // })

        // if (combineSegmentNames.length && selectedlevel1 !== 'None') {
        series.labels.template.setAll({
            text: "{name}\n({count})",
            textAlign: 'center',
        });
        // }

        series.labels.template.adapters.add('text', function (text, target) {
            let ini = target?.parent?.dataItem?.dataContext?.ini
            let count = target?.parent?.dataItem?.dataContext?.count
            let name = target?.parent?.dataItem?.dataContext?.name
            return ini ? ini : `${name}\n(${count})`
        })
        series.labels.template.adapters.add('fontSize', function (fontSize, target) {
            let ini = target?.parent?.dataItem?.dataContext?.ini
            // if(!ini && target.parent?.width()==0)
            //     {
            //        return '0.55em'
                
            //     }  
            return ini ? '10px' : fontSize
        })


        // add data to chart

        series.data.setAll(structuriseData)

        series.set("selectedDataItem", series.dataItems[0]);


        return () => {
            // remove chart
            root.dispose();
        };
    }, [projectId, structuriseData])



    return <div className={ (graphDisplayMode==='light')? 'graph-container-light': 'graph-container-dark'} style={{  overflow: 'auto' }}>
        {
            isLoading ?
                <Loader />
                :
                ''
        }

        <div id="people-galaxy-chart" style={{ overflow: 'auto' }}>

        </div>
    </div>
}


export default PeopleGalaxyGraph;