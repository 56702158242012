import { useEffect, useRef, useState } from 'react'
import './index.scss'
import SelectDropDown from '../../../Elements/SelectDropDown'
import { ApiClient } from '../../../api/client'
import { useSelector } from 'react-redux'
import DoughnutChart from './DoughnutChart'


const SegmentUsersParticipation = ({ segmentTypesList, pHeight }) => {

    const headerRef = useRef(1)
    const barRef = useRef(2)

    const { projectId } = useSelector(state => state.project)

    const [defaultSegmentType, setDefaultSegmentType] = useState()
    const [participationData, setParticipationData] = useState([])


    useEffect(() => {
        if (segmentTypesList.length > 0) {
            setDefaultSegmentType(segmentTypesList?.[0]?.['segmentTypeId'])
        }
    }, [segmentTypesList])

    useEffect(() => {
        if (defaultSegmentType) {
            const client = ApiClient()
            client.get(`/api/v1/participation/${projectId}/?segment_type=${defaultSegmentType}`)
                .then((response) => {
                    if (response?.data) {
                        setParticipationData(response.data)
                    }
                })
        }
    }, [defaultSegmentType])



    const segmentBarOptions = [
        { title: 'Not Issued', className: 'not-issued' },
        { title: 'Awaiting', className: 'awaiting' },
        { title: 'Completed', className: 'completed' }
    ]


    return <div className='segment-user-participation-box'>

        <div ref={headerRef} className='segment-user-participation-header'>
            <span className='title'>Participation</span>
            <SelectDropDown
                optionList={segmentTypesList}
                handleOnChange={setDefaultSegmentType}
                valueField='segmentTypeId'
                itemField='segmentTypeName'
                defaultValue={defaultSegmentType}
            />
        </div>

        <div ref={barRef} className='segment-user-participation-bar'>
            {
                segmentBarOptions.map(item => {
                    return <div className={`bar-item ${item.className}`}>
                        {item.title}
                    </div>
                })
            }
        </div>

        <div 
        className='segment-user-participation-content' 
        style={
            {
                // height: pHeight - headerRef.current.clientHeight - barRef.current.clientHeight
            }
        }>
            {
                participationData.length > 0 && participationData.map(pd => {
                    return <DoughnutChart
                        chartData={
                            {
                                labels: segmentBarOptions.map(item => item.title),
                                datasets: [
                                    {
                                        label: '',
                                        data: [pd['users_uninvited'], pd['users_await'], pd['users_complete']],
                                        backgroundColor: ["#cccccc", "#ffc15c", "#29cc97"],
                                        borderRadius: 10,
                                        borderWidth: 2,
                                    }
                                ]

                            }
                        }
                        totalSegmentUsers={pd['segment_users']}
                        segmentTitle={pd['segment_name']}
                    />
                })
            }
        </div>

    </div>
}

export default SegmentUsersParticipation;